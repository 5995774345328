// LICENSE_CODE MIT
import axios from 'axios';
import https from 'https';
import {HttpsProxyAgent as Https_proxy_agent} from 'https-proxy-agent';
import process from 'process';
import eserf from './eserf.js';
import xurl from './xurl.js';
import str from './str.js';

let E = {};
export default E;
let is_node = process&&process.title!='browser';
E.is_verbose = is_node&&0;
E.fd_count = 0;

E.auth_hdr = token=>{ return {Authorization: `Bearer ${token}`}; };
E.auth_hdr_vnd = token=>{
  return {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/vnd.api+json',
  };
};
// btoa only supported in node17 and up
E.auth_hdr_user = (user, passwd)=>{
  return {Authorization: `Basic ${global.btoa ? btoa(user+':'+passwd)
    : Buffer.from(user+':'+passwd).toString('base64')}`};
};

// XXX colin: move to abortcontroller
const cancel_token = axios.CancelToken;
let ess = [];
E.default = (method, url, config)=>{
  const source = cancel_token.source();
  let proxy_opt = '';
  let proxy = config?.proxy;
  let https_proxy_agent;
  if (config?.proxy)
  {
    config.proxy = false;
    let __url = `${proxy.protocol}://${proxy.auth.username}:`
      +`${proxy.auth.password}@${proxy.host||proxy.hostname}${proxy.port
        ? ':'+proxy.port : ''}`;
    https_proxy_agent = new Https_proxy_agent(__url);
    proxy_opt = `--proxy '${__url}'`;
  }
  let is_running, curl = `curl ${proxy_opt} -X ${method.toUpperCase()} `
    +`'${url}'`;
  return eserf(function* _req(){
    // XXX colin: use eserf.pool instead
    E.fd_count++;
    this.finally(()=>{
      E.fd_count--;
      if (is_running)
        source.cancel('ereq cancelled');
      if (!ess.length)
        return;
      let es = ess.pop();
      es.continue();
    });
    if (E.fd_limit && E.fd_count>=E.fd_limit)
    {
      let es = this.wait();
      ess.push(es);
      yield es;
    }
    is_running = true;
    config = config||{};
    config.transformResponse = [function(data, headers){
      let _headers = {};
      for (let h in headers)
      {
        let arr = typeof headers[h]=='string' ? headers[h].split(';')
          : headers[h];
        _headers[h.toLowerCase()] = arr;
      }
      if (config.is_resp_json || _headers['content-type']
        && _headers['content-type'][0]=='application/json')
      { // eslint-disable-line
        data = xurl.json_parse(data);
      }
      return data;
    }];
    let qs = config.qs;
    let hs = config.hs;
    if (qs || hs)
      url = xurl.url(url, qs, hs);
    config = Object.assign(config, {url, method, cancelToken: source.token});
    let _headers = {};
    for (let h in config.headers)
      _headers[h.toLowerCase()] = config.headers[h];
    let content_type = _headers['content-type'];
    if (config.data && typeof config.data=='object')
    {
      if (!content_type)
      {
        config.headers = config.headers||{};
        content_type = config.headers['Content-Type'] = 'application/json';
      }
      if ((/application\/.*json/u).test(content_type))
        config.data = JSON.stringify(config.data);
      else if (content_type=='application/x-www-form-urlencoded' ||
        (/application\/x-www-form-urlencoded.*/u).test(content_type))
      {
        config.data = xurl.qs(config.data);
      }
    }
    let accept = _headers.accept;
    if (!accept && config.is_event_stream)
      accept = config.headers.Accept = 'text/event-stream';
    if (https_proxy_agent)
      config.httpsAgent = https_proxy_agent;
    else if (config.unsafe)
      config.httpsAgent = new https.Agent({rejectUnauthorized: false});
    if (config.is_resp_buf)
      config.responseType = 'arraybuffer';
    let res, _err;
    let retry = config.retry || 1;
    for (let h in config.headers)
      curl += ` -H '${h}: ${config.headers[h]}'`;
    if (config.data)
      curl += ` --data-raw '${config.data}'`;
    while (retry)
    {
      _err = undefined;
      axios(config).then(resp=>{
        this.continue(resp);
      // eslint-disable-next-line no-loop-func
      }).catch(err=>{
        if (axios.isCancel(err))
          return;
        _err = err;
        this.continue();
      });
      res = yield this.wait();
      if (!_err)
        break;
      retry--;
    }
    is_running = false;
    if (_err)
    {
      if (_err.errno=='EMFILE')
        throw new Error(`EMFILE to many open fds ${E.fd_count}`);
      if (_err.errno=='ERR_NETWORK')
        return {err: 'err_network'};
      let msg = 'ereq failed '+url+' '+_err.message;
      if (!config.no_print)
        console.error(msg, config, _err.stack, this.ps());
      let status = _err.response&&_err.response.status;
      let status_txt = _err.response&&_err.response.statusText;
      let err_data = _err&&_err.response&&_err.response.data;
      return {err: msg, status, status_txt, err_data, errno: _err.errno,
        curl};
    }
    if (E.is_verbose)
    {
      console.log('ereq', config.method, config.url,
        'data : ' + (config.data ? str.j2s(config.data) : ''),
        config.headers||'', res.data);
    }
    if (config.is_event_stream)
      res.data = xurl.parse_stream(res.data);
    return res;
  });
};

E.get = (url, config)=>E.default('GET', url, config);
E.head = (url, config)=>E.default('HEAD', url, config);
E.patch = (url, config)=>E.default('PATCH', url, config);
E.post = (url, config)=>E.default('POST', url, config);
E.put = (url, config)=>E.default('PUT', url, config);
E.delete = (url, config)=>E.default('DELETE', url, config);

// XXX colin: add proc support in browser using babel/polyfill
//if (proc.is_main(import.meta.url))
//{
//  eserf(function* ereq_main(){
//    let res = yield E.get('http://localhost:4000/pub/ping.json',
//      {qs: {is_header: true}, headers: {
//        'User-Agent': 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) '
//        +'AppleWebKit/537.36 (KHTML, like Gecko) Chrome/115.0.0.0 '
//        +'Safari/537.36',
//      }});
//    console.log(str.j2s(res.data));
//  });
//}
