// LICENSE_CODE ABCAI
import {Col, Row, Typography, Space, Input, message, Avatar,
  Button} from 'antd';
import {isEmpty, get, find, last, flow, sample, trim, first,
} from 'lodash/fp.js';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import auth from './auth.js';
import eserf from '../../../util/eserf.js';
import back_app from './back_app.js';
import {Loading} from './comp.js';
import {useNavigate, useMatch} from 'react-router-dom';
import config_ext from './config_ext.js';
import {SendOutlined} from '@ant-design/icons';
import xurl from '../../../util/xurl.js';

let {Text, Title} = Typography;
let {TextArea} = Input;

export const E = ()=>{
  let navigate = useNavigate();
  let match = useMatch('/c/:char_id');
  let [messageApi, contextHolder] = message.useMessage();
  let {user, token, org, user_full} = auth.use_auth();
  let char_id = useMemo(()=>get(['params', 'char_id'], match), [match]);
  let {chars} = use_chars();
  let char = useMemo(()=>find(_char=>_char.char_id === char_id, chars),
    [chars, char_id]);
  useEffect(()=>{
    if (!char_id)
      navigate('/char_select');
  }, [char_id, navigate]);
  if (!token || isEmpty(chars) || !user_full)
    return <Loading/>;
  return <>{contextHolder}<Row>
    <Col md={{span: 6}}/>
    <Col md={{span: 12}}>
      <div style={{maxWidth: 768, width: '100%', display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)'}/*XXX fix ugly calc */}>
        <Char_header char={char}/>
        <Chat {...{char, char_id, token, user, org, messageApi}}/>
      </div>
    </Col>
    <Col md={{span: 6}}/>
  </Row></>;
};

let char_to_img = char=>{
  let prefix = 'https://abcai-front-share-prod.s3.amazonaws.com/img';
  let img_src = `${prefix}/${char.img}`;
  return img_src;
};

let Chat = React.memo(({char, char_id, token, user, org, messageApi})=>{
  let [chat, set_chat] = useState([]);
  let [loading, set_loading] = useState(false);
  let [error, set_error] = useState('');
  let chat_el_ref = useRef(null);
  let input_ref = useRef(null);
  let [val, set_val] = useState('');
  let nick = useMemo(()=>'Nickname', [user]); // XXX add nickname support
  let {t} = useTranslation();
  let qs_o = useMemo(()=>xurl.qs_parse(location.search, true), []);
  useEffect(()=>{
    if (!char)
      return;
    let greeting = flow(
      get('greetings'),
      sample,
    )(char) || t('Hello, how are you?');
    set_chat([{is_char: true, txt: greeting}]);
  }, [char, t]);
  useEffect(()=>{
    if (!char)
      return;
    let is_mock_chat = get('mock_chat', qs_o);
    if (is_mock_chat)
    {
      set_chat(curr=>[...curr, {is_user: true, txt: 'hello'},
        {is_char: true, txt: 'hello how are you?'},
        {is_user: true, txt: 'hello i am good how are you?'},
        {is_char: true, txt: 'i am  good how are you today?'},
        {is_user: true, txt: 'i am good thank you!'}]);
    }
  }, [char, qs_o]);
  let scroll = useCallback((block='end')=>{
    chat_el_ref.current?.scrollIntoView({
      behavior: 'smooth', block});
  }, []);
  let on_submit = ()=>eserf(function* _chat_on_submit(){
    if (loading)
      return;
    let _txt = trim(val).slice(0, 400);
    if (!_txt)
      return;
    set_loading(true);
    let my_msg = {txt: _txt, is_user: true};
    set_chat(curr=>[...curr, my_msg]);
    setTimeout(()=>{
      scroll();
      setTimeout(scroll, 50);
    }, 0);
    let gid = get('gid', last(chat));
    let prompt_id;
    let _ret;
    if (!gid)
    {
      _ret = yield back_app.schar_start(token, user.email, org?.id, _txt,
        char_id);
      gid = _ret.gid;
      prompt_id = _ret.prompt_id;
    }
    else
    {
      _ret = yield back_app.schar_continue(token, user.email, org?.id,
        _txt, gid, char_id);
      prompt_id = _ret.prompt_id;
    }
    if (_ret.err)
    {
      set_error(_ret.err);
      messageApi.open({
        type: 'error',
        content: t('Error sending the chat message. Please try again'),
      });
      set_loading(false);
      set_val('');
      return;
    }
    let {txt: ret_txt} = _ret;
    let char_msg = {is_char: true, txt: ret_txt, gid, prompt_id};
    set_chat(curr=>[...curr, char_msg]);
    set_val('');
    set_loading(false);
    input_ref.current.focus();
    setTimeout(()=>{
      scroll();
      setTimeout(scroll, 50);
    }, 0);
  });

  let on_key_up = e=>{
    if (loading)
      return;
    if (e.keyCode === 13 && !e.shiftKey)
    {
      set_val('');
      e.preventDefault();
      on_submit();
    }
  };

  return <div style={{flex: 1, position: 'relative'}}>
    <div style={{display: 'flex', flexDirection: 'column',
      position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}
    >
      <div style={{flex: 1, overflow: 'auto', marginBottom: '1rem',
        scrollbarColor: '#333 #999', scrollbarWidth: 'thin'}}>
        {chat.map((msg, index)=>{
          let aos_props = index === 0? {'data-aos': 'fade-up',
            'data-aos-delay': 500, 'data-aos-once': true}: {};
          return <Row key={index} {...aos_props}>
            <Col span={24}>
              {msg.is_char ? <Row align="middle">
                <Col>
                  <Avatar style={{marginRight: 4}} src={char_to_img(char)}
                    size={32}/>
                </Col>
                <Col>
                  <Text>
                    {char.lbl}
                  </Text>
                </Col>
              </Row>: <Row align="middle" justify="end">
                <Col>
                  <Avatar style={{marginRight: 4}} size={32}>{first(nick)}
                  </Avatar>
                </Col>
                <Col>
                  <Text>
                    {nick}
                  </Text>
                </Col>
              </Row>}
              {/*XXX use Antd for this style */}
              <Row style={{marginTop: 4, marginBottom: 8}}
                justify={msg.is_char ? 'start': 'end'}>
                <Col style={{background: '#222', borderRadius: 16,
                  padding: '4px 8px'}}>
                  <Text >{msg.txt}</Text>
                </Col>
              </Row>
            </Col>
          </Row>;
        })}
        <div ref={chat_el_ref} style={{height: 10, width: '100%'}}/>
      </div>
      <div style={{flex: 0, height: 50, minHeight: 50}}>
        <Space.Compact style={{width: '100%', height: '100%'}}>
          <TextArea rows={1} value={val} autoFocus
            onChange={e=>set_val(e.target.value)} autoComplete="off"
            placeholder={t('Write something to') + ' '+char.lbl}
            bordered={false} className="chat-textarea"
            onKeyUp={on_key_up} ref={input_ref}
          />
          <Button type="primary" onClick={on_submit} icon={<SendOutlined/>}
            loading={loading} style={{height: '100%', minWidth: 60}} />
        </Space.Compact>
      </div>
    </div>
  </div>;
});

let Char_header = React.memo(({char})=>{
  let {t} = useTranslation();
  let company_lbl = config_ext.front.company_lbl;
  let created_by = char.created_by || company_lbl;
  return <Row align="center">
    <Col offset={1} span={22}>
      <Space direction="vertical" size="small" style={{paddingBlock: '2vh'}}>
        <Row align="center">
          <Avatar src={char_to_img(char)} size={64}/>
        </Row>
        <Row align="center">
          <Title level={3}>{char.lbl}</Title>
        </Row>
        <Row align="center">
          {char.desc && <Text italic>{char.desc}</Text>}
        </Row>
        <Row align="center">
          <Text>{t('Created by')}</Text>&nbsp;<Text strong>{created_by}</Text>
        </Row>
      </Space>
    </Col>
  </Row>;
});

// XXX move hooks outside
let use_chars = E.use_chars = E.useChars = ()=>{
  let [chars, set_chars] = useState([]);
  let [err, set_err] = useState('');
  useEffect(()=>{
    let es = eserf(function* _get_chars_use_effect(){
      let _ret = yield back_app.produce_char_get();
      if (_ret.err)
      {
        set_err(_ret.err);
        return;
      }
      set_chars(_ret.chars);
    });
    return ()=>es.return();
  }, []);

  return {chars, err};
};
export default auth.with_auth_req(E);
