// LICENSE_CODE ABCAI
import React from 'react';
import {Row, Col, Space, Typography} from 'antd';
import {MailFilled, PhoneFilled} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import {Clickable} from './comp.js';
import config_ext from './config_ext.js';
import str from '../../../util/str.js';

let {Title} = Typography;
let email = config_ext.front.email;
let E = props=>{
  let {t} = useTranslation();
  return <Row justify="center">
    {props.is_addr && <>
      <Space direction="vertical" size="large" align="center"
        data-aos="flip-left" data-aos-anchor-placement="top-center"
        data-aos-duration="2000" >
        <Row><Title>{t('CONTACT US')}</Title></Row>
        <Row>
          <Col xs={{offset: 4, span: 16}} sm={{offset: 4, span: 16}}>
            <Title level={2}>{config_ext.front.company_lbl}</Title>
            <Title level={5}>{t('Office:')} {config_ext.front.addr_lbl}</Title>
            <Title level={5}>
              <Clickable>
                <a href={'mailto:'+email}>Email: {email}</a>
              </Clickable>
            </Title>
            <Title level={5}>
              <Clickable>
                <a href={'tel:'+config_ext.front.phone}>{t('Phone number')}:
                  <br/>{str.phone2lbl(config_ext.front.phone)}</a>
              </Clickable>
            </Title>
          </Col>
        </Row>
      </Space>
    </>}
    {!props.is_addr && <Space direction="vertical">
      {!props.is_no_txt && <Row justify={props.is_center&&'center'||'left'}>
        <Col>
          {t('Contact us with any question at')}
        </Col>
      </Row>}
      <Row justify={props.is_center&&'center'||'left'}>
        <Space>
          <Col>
            <MailFilled />:
          </Col>
          <Col>
            <Clickable><a href={'mailto:'+email}>{email}</a></Clickable>
          </Col>
        </Space>
      </Row>
      <Row justify={props.is_center&&'center'||'left'}>
        <Space>
          <Col>
            <PhoneFilled />:
          </Col>
          <Col>
            <Clickable>
              <a href={'tel:'+config_ext.front.phone}>
                {t('Call us at')+' '+str.phone2lbl(config_ext.front.phone)}</a>
            </Clickable>
          </Col>
        </Space>
      </Row>
    </Space>}
  </Row>;
};

export default E;
