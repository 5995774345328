// LICENSE_CODE ABC
import React from 'react';
import {useLocation} from 'react-router-dom';
import {Col, Row, Avatar, Button, Space, Typography} from 'antd';
import auth from './auth.js';
import xurl from '../../../util/xurl.js';
import back_app from './back_app.js';
import config_ext from './config_ext.js';
import {PayPalScriptProvider, PayPalButtons} from '@paypal/react-paypal-js';

let {Title} = Typography;
export const E = ()=>{
  let location = useLocation();
  let qs_o = xurl.qs_parse(location.search, true);
  let {user, token, user_full, org} = auth.use_auth();
  let name;
  if (user_full && user_full.first && user_full.last)
    name = user_full.first+' '+user_full.last;
  return (
    <Row justify="center">
      <Space direction="vertical" size="large" align="center">
        <Row><Title>PAY</Title></Row>
        <Row>
          <PayPalScriptProvider options={{
              intent: 'subscription',
              'client-id': config_ext.paypal.client_id,
              vault: true
            }}>
            <PayPalButtons style={{shape: 'rect', color: 'white',
              layout: 'vertical', label: 'subscribe'}}
              createSubscription={function(data, actions){
                return actions.subscription.create({
                  plan_id: 'P-XXXX'
                });
              }}
              onApprove={function(data, actions) {
                alert(data.subscriptionID);
                // You can add optional success message for the subscriber here
              }}
            />
          </PayPalScriptProvider>
        </Row>
      </Space>
    </Row>
  );
};

export default auth.with_auth_req(E);

