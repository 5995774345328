// LICENSE_CODE MIT
import React from 'react';
import {createRoot} from 'react-dom/client';
import metric from './metric.js';
import {useNavigate, BrowserRouter} from 'react-router-dom';
import {Auth0_provider, Auth_provider} from './auth.js';
import {ConfigProvider, theme} from 'antd';
import {blue, red} from '@ant-design/colors';
import 'antd/dist/reset.css';
import './index.css';
import App from './app.js';
import * as svc_worker from './serviceWorker.js';
import config_ext from './config_ext.js';
import je from '../../../util/je.js';
import './i18n.js';

metric.init();
je.init(8000, metric.error, metric.error_once);
let App_root = ()=>{
  let navigate = useNavigate();
  let [direction, direction_set] = React.useState('ltr');
  React.useEffect(()=>{
    let listener = je.on('app.is_rtl', ()=>{
      let is_rtl = je.get('app.is_rtl');
      direction_set(is_rtl ? 'rtl' : 'ltr');
    });
    return ()=>je.off(listener);
  }, []);
  //let location = useLocation();
  //let qs_o = xurl.qs_parse(location.search, true);
  // XXX colin: add support for qs params example dbg
  let on_redirect_cb = app_state=>{
    navigate(app_state && app_state.returnTo
      ? app_state.returnTo : window.location.pathname);
  };
  return <Auth0_provider domain={config_ext.auth0.domain}
    clientId={config_ext.auth0.client_id} authorizationParams={{
      redirect_uri: window.location.origin,
      audience: config_ext.auth0.audience,
    }} onRedirectCallback={on_redirect_cb}>
    <ConfigProvider direction={direction} theme={{
      algorithm: config_ext.is_b2b
        ? theme.defaultAlgorithm : theme.darkAlgorithm,
      components: {
        Slider: {
          trackBg: blue.primary,
          handleColor: blue.primary,
        },
      },
      token: {
        colorPrimary: blue.primary,
        colorInfo: blue.primary,
        fontSize: 16,
        colorHighlight: red[4],
        fontfamily: 'sans-serif',
      }
    }}>
      <Auth_provider on_redirect_cb={on_redirect_cb} >
        <App />
      </Auth_provider>
    </ConfigProvider>
  </Auth0_provider>;
};

let root = createRoot(document.getElementById('root'));
root.render(<BrowserRouter><App_root /></BrowserRouter>);
svc_worker.unregister();
