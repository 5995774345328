// LICENSE_CODE ABCAI
import {Button, Col, Input, Row, Typography, Progress, message,
  Modal, Select} from 'antd';
import {isEmpty, capitalize, map} from 'lodash/fp.js';
import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import auth from './auth.js';
import eserf from '../../../util/eserf.js';
import back_app from './back_app.js';
import {Clickable, Loading, Task_tooltip} from './comp.js';
import {DownloadOutlined, ShareAltOutlined,
  CopyOutlined, StarFilled, FireFilled, DollarOutlined, ThunderboltOutlined,
  SmileOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import je from '../../../util/je.js';
import metric from './metric.js';

let {TextArea} = Input;
let {Title, Link} = Typography;
let url2share_url = _url=>{
  return _url;
  //return `${window.location.origin}/vid?url=${_url}`;
};

let img_styles = ['none', 'pixel art', 'anime', 'scenic', 'expressive',
  'cute', 'cyberpunk', 'futuristic', 'oil paint', 'tattoo art', 'dark fantasy',
  'illustration', 'low poly', 'origami', 'cinematic', 'isometric',
  'neon punk'].map(key=>({value: key,
  label: capitalize(key)}));

let img_sizes = ['landscape', 'portrait'].map(key=>({value: key,
  label: capitalize(key)}));

export let E = React.memo(()=>{
  let {t} = useTranslation();
  let [messageApi, contextHolder] = message.useMessage();
  let {user, token, org, user_full} = auth.use_auth();
  let [concept, set_concept] =useState('');
  let [style, set_style] =useState(null);
  let [size, set_size] =useState(null);
  let [loading, set_loading] = useState(false);
  let [img_url, set_img_url] = useState(null);
  let [error, set_error] = useState('');
  let [counter, set_counter]=useState(0);
  let [is_onboard_modal, set_is_onboard_modal] = useState(false);
  let [is_ad_modal, set_is_ad_modal] = useState(false);
  let [is_show_ad, set_is_show_ad] = useState(false);
  let [did_ask_show_ad, set_did_ask_show_ad] = useState(false);
  let [tasks, set_tasks] = useState([]);
  let nav = useNavigate();

  useEffect(()=>{
    let _counter = localStorage.getItem('simg_counter');
    _counter = _counter ? parseInt(_counter, 10): 0;
    set_counter(_counter);
    let _is_show_ad = localStorage.getItem('is_show_ad');
    _is_show_ad = _is_show_ad ? JSON.parse(_is_show_ad): false;
    set_is_show_ad(_is_show_ad);
    let _did_ask_show_ad = localStorage.getItem('did_ask_show_ad');
    _did_ask_show_ad = _did_ask_show_ad ? JSON.parse(_did_ask_show_ad): false;
    set_did_ask_show_ad(_did_ask_show_ad);
  }, []);
  useEffect(()=>{
    if (!token || !user_full)
      return;
    let es = eserf(function* _user_task_pub_get_list(){
      let _ret = yield back_app.user_task_pub_get_list(token, user_full.email,
        'simg');
      if (_ret.err)
      {
        set_error(_ret.err);
        return;
      }
      set_tasks(_ret);
    });
    return ()=>es.return();
  }, [token, user_full]);

  let on_submit = ()=>eserf(function* _on_submit(){
    let is_onboard = je.get('auth.is_onboard');
    if (counter >= 10 && !is_onboard)
    {
      metric.event('onboard_modal', true);
      set_is_onboard_modal(true);
      return;
    }
    if (counter >= 15 && is_onboard && !did_ask_show_ad && false)
    {
      metric.event('ad_modal', true);
      set_is_ad_modal(true);
      return;
    }
    set_loading(true);
    let _style = style !== 'none' ? style: null;
    let _ret = yield back_app.simg_start(token, user.email, org?.id, concept,
      _style, size);
    if (_ret.err)
    {
      set_error(_ret.err);
      set_loading(false);
      return;
    }
    set_tasks(curr=>[_ret, ...curr]);
    set_loading(false);
    set_img_url(_ret.url);
    set_counter(curr=>curr+1);
    localStorage.setItem('simg_counter', JSON.stringify(counter+1));
  });
  let on_share = _url=>{
    let data={
      title: 'AbcAiArt.com image',
      text: 'Check out this amazing image',
      url: url2share_url(_url),
    };
    window.navigator.share && window.navigator.share(data)
      .catch(console.error);
  };
  let on_copy_url = _url=>{
    navigator.clipboard.writeText(url2share_url(_url));
    messageApi.open({
      type: 'success',
      content: t('Link copied to clipboard'),
    });
  };
  let on_ad_modal_ok =()=>{
    set_is_show_ad(true);
    localStorage.setItem('is_show_ad', 'true');
    set_did_ask_show_ad(true);
    localStorage.setItem('did_ask_show_ad', 'true');
    set_is_ad_modal(false);
  };
  let on_ad_modal_cancel =()=>{
    set_is_onboard_modal(false);
    set_did_ask_show_ad(true);
    localStorage.setItem('did_ask_show_ad', 'true');
    set_is_ad_modal(false);
  };

  if (!token || !user_full)
    return <Loading/>;

  if (error)
    return <Row>{t('Error')} {error}</Row>;

  if (loading)
    return <Simg_loading is_show_ad={is_show_ad}/>;

  if (!img_url)
  {
    return <><Row justify="space-around">
      <Col xs={22} md={16}>
        <Row style={{marginTop: 24}}>
          <Title level={4}>
            {t('Create images from your prompts')}
          </Title>
        </Row>
        {is_show_ad && <Ad1/>}
        <Row>
          <TextArea rows={2} value={concept} maxLength={300}
            onChange={e=>set_concept(e.target.value)} autoComplete="off"
            placeholder={t('Monkey sitting on a chair and eating Pasta')}
            style={{lineHeight: 2.5, marginBottom: 16}}
            className="simg-input"/>
        </Row>
        <Row>
          <Col xs={{span: 12}} md={{span: 6}} lg={{span: 4}}>
            <Select
              value={style} style={{width: '100%'}}
              placeholder={t('Style')} onChange={set_style}
              options={img_styles}/>
          </Col>
          <Col xs={{offset: 1, span: 11}} md={{ofset: 1, span: 6}}
            lg={{span: 4, offset: 1}}>
            <Select
              value={size} style={{width: '100%'}}
              placeholder={t('Shape')} onChange={set_size}
              options={img_sizes}/>
          </Col>
        </Row>
        <Row style={{marginTop: 16}}>
          <Button type="primary" size="large" block
            disabled={isEmpty(concept)}
            onClick={on_submit}>
            { t('Create image')}
          </Button>
        </Row>
        {!isEmpty(tasks) && <><Row style={{marginTop: 16}}>
          <Title level={4}>{t('My images')}</Title></Row>
        <Row gutter={[12, 12]}>
          {map(_task=>{
            return <Col xs={{span: 24}} md={{span: 4}} key={_task.url}>
              <Clickable cursor onClick={()=>window.open(_task.url, '_blank')}>
                <Task_tooltip task={_task}>
                  <img src={_task.url} style={{width: '100%', height: 'auto',
                    objectFit: 'contain'}}/></Task_tooltip></Clickable>
            </Col>;}, tasks)}
        </Row></>}
      </Col>
    </Row>
    <Modal
      title={t('You need to complete your onboarding in order to continue')}
      open={is_onboard_modal}
      okText={t('Complete onboarding')} cancelText={t('Cancel')}
      onOk={()=>nav('/onboard')}
      onCancel={()=>set_is_onboard_modal(false)}>
      <p><StarFilled style={{color: 'yellow', marginRight: 4}}/>
        {t('Looks like you are a heavy user of our platform!')}
      </p>
      <p>
        <FireFilled style={{color: 'orange', marginRight: 4}}/>
        {t('Please complete your onboarding in order to keep using our '+
          'platform for free.')}
      </p>
    </Modal>
    <Modal
      closable={false}
      maskClosable={false}
      title={t('Just one question')}
      open={is_ad_modal}
      onOk={on_ad_modal_ok}
      onCancel={on_ad_modal_cancel}
      footer={[
        <Button key="submit" type="primary" onClick={on_ad_modal_ok}
          style={{marginBottom: 16}} size="large">
          {t('Yes, show me ads. I understand why you do it')}
        </Button>,
        <Button key="cancel" onClick={on_ad_modal_cancel}>
          {t('No, do not show me ads, I hate ads')}
        </Button>,
      ]}
    >
      <p style={{color: '#00dd44'}}><DollarOutlined style={{marginRight: 4}}/>
        {t('It costs us a lot of money to operate this platform.')}
      </p>
      <p>
        <ThunderboltOutlined style={{color: 'orange', marginRight: 4}}/>
        {t('It would really help us if you allowed us to show you some ads.')}
      </p>
      <p>
        <SmileOutlined style={{color: 'yellow', marginRight: 4}}/>
        {t('This way you can keep using our platform for free, while '
        +'helping us break even.')}
      </p>
    </Modal>
    </>;
  }

  return <>{contextHolder}
    <Row justify="space-around">
      <Col xs={22} md={16}>
        <Row justify="space-around" style={{marginTop: 16}}>
          <Col xs={22}>
            <img src={img_url} style={{width: '100%', height: 'auto',
              maxHeight: '60vh', objectFit: 'contain'}}/>
          </Col>
        </Row>
        <Row align="center" style={{marginTop: 16}}>
          <Col xs={{span: 2}}>
            <Link href={img_url} download target="_blank">
              <Button title={t('Download image')} type="primary"
                icon={<DownloadOutlined/>}/>
            </Link>
          </Col>
          <Col xs={{span: 2, offset: 1}}>
            <Button title={t('Share')} type="primary"
              onClick={()=>on_share(img_url)}
              icon={<ShareAltOutlined/>}/>
          </Col>
          <Col xs={{span: 2, offset: 1}}>
            <Button type="primary" onClick={()=>on_copy_url(img_url)}
              icon={<CopyOutlined/>} title={t('Copy link')}/>
          </Col>
        </Row>
        <Row style={{marginBlock: 32}}>
          <Col xs={{span: 20, offset: 2}} md={{span: 16, offset: 4}}>
            <Button block type="primary" onClick={()=>set_img_url(null)}>
              {t('Start Over')}</Button>
          </Col>
        </Row>
      </Col>
    </Row>
  </>;
});

let Simg_loading = React.memo(({is_show_ad})=>{
  let {t} = useTranslation();
  let [percent, set_percent] = useState(5);
  useEffect(()=>{
    let interval_id = setInterval(()=>{
      set_percent(curr=>Math.min(95, curr+3));
    }, 1000);
    return ()=>{
      clearInterval(interval_id);
    };
  }, []);
  return <Row justify="space-around">
    <Col xs={22} md={16}>
      <Row justify="space-around"><Col xs={24} md={12} style={{marginTop: 16}}>
        <Title level={5}>{t('Please wait (10-30 seconds)')}</Title>
        <Progress percent={percent} status="active" showInfo={false}/>
        {is_show_ad && <Ad1/>}
      </Col></Row>
    </Col>
  </Row>;
});

let Ad1 = React.memo(()=>{
  return null;

  if (!localStorage.getItem('real_ad'))
    return <Fake_ad/>;

  return null;
});

let Fake_ad = React.memo(()=>{
  return <>
    <Row style={{paddingBlock: 16}}>
      <Col span={8} offset={8}>
        <img src="https://abcai-front-share-prod.s3.amazonaws.com/simg_ret_3fa66b59-cb1a-47f4-80db-07406f7fa51b.png"
          style={{height: 150, width: 'auto', borderRadius: 20}}/>
      </Col>
    </Row>
  </>;
});


export default auth.with_auth_req(E);
