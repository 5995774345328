// LICENSE_CODE ABC
import React from 'react';
import {Typography, Row, Col, Button} from 'antd';
import {useTranslation} from 'react-i18next';
import config_ext from './config_ext.js';
import {Closable, Video} from './comp.js';
import {useNavigate} from 'react-router-dom';
import metric from './metric.js';

let email = config_ext.front.email;
let vid = 'https://abcai-front-share-prod.s3.amazonaws.com/bill_money_1.mp4';

const {Title, Paragraph, Text} = Typography;
let E = ()=>{
  let {t} = useTranslation();
  return <>
    <Row>
      <Col xs={{span: 22, offset: 1}} md={{span: 16, offset: 4}}>
        <Row>
          <Title level={1}>{t('We need a sponsor')}</Title>
        </Row>
        <Row>
          <Title level={2}>{t('Help us break even')}</Title>
        </Row>
        <Row>
          <Paragraph>
            Dear Valued Users, we're reaching out to ask for your support in keeping our AI content generation platform—offering text-to-speech, text-to-video, text-to-GIF and chatting with AI characters tools—free for everyone.
          </Paragraph>
          <Paragraph>
            Operating this platform costs us thousands of dollars monthly, and to avoid introducing fees, we're seeking a sponsor to help cover these expenses.
          </Paragraph>
          <Paragraph>
            If you or someone you know can assist us in this quest, please get in touch.

          </Paragraph>
          <Paragraph>
            Your help can make a significant difference in keeping our innovative services accessible to all.
          </Paragraph>
          <Paragraph>
            Thank you for being a part of our community and for any support you can offer in finding a sponsor.
          </Paragraph>
          <Paragraph>
            your brand will be featured prominently on our website, ensuring that our community knows who our beloved sponsor is.
          </Paragraph>
          <Paragraph>
            We're committed to shouting your support from the digital rooftops, making sure everyone recognizes the hero behind the magic!
          </Paragraph>
        </Row>
        <Row>
          <Title level={4}>
            {email}
          </Title>
        </Row>
        <Row>
          <Col xs={{span: 24}} md={{span: 8}}>
            <Closable>
              <Video src={vid}/>
            </Closable>
          </Col>
        </Row>

      </Col></Row>
  </>;
};

export default E;


export let Sponsor_banner = React.memo(()=>{
  let {t} = useTranslation();
  let nav = useNavigate();
  return <div style={{height: 64}}>
    <Row><Col xs={{offset: 1, span: 22}} md={{ofset: 1, span: 8}}>
      <Button type="primary" size="large" block onClick={()=>{
        metric.event('sponser_click', true);
        nav('/sponsor');
      }}>
        <Title level={5}>{t('We need a sponsor')}</Title>
      </Button>
    </Col><Col xs={{span: 0}} md={{span: 8, offset: 1}}>
      <Text>{t('Help us keep the platform free')}</Text></Col>
    </Row></div>;
});
