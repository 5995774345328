// LICENSE_CODE ABCAI
import {Button, Col, Input, Row, Typography, Progress, message} from 'antd';
import {isEmpty, sample, map} from 'lodash/fp.js';
import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import auth from './auth.js';
import eserf from '../../../util/eserf.js';
import back_app from './back_app.js';
import {Loading, Video, Task_tooltip, Clickable} from './comp.js';
import {DownloadOutlined, ShareAltOutlined,
  CopyOutlined} from '@ant-design/icons';
import {Sgif_loading} from './sgif.js';

let {TextArea} = Input;
let {Title, Link} = Typography;
let url2share_url = _url=>{
  return _url;
  //return `${window.location.origin}/vid?url=${_url}`;
};

export let E = React.memo(()=>{
  let {t} = useTranslation();
  let [messageApi, contextHolder] = message.useMessage();
  let {user, token, org, user_full} = auth.use_auth();
  let [txt, set_txt] =useState('');
  let [loading, set_loading] = useState(false);
  let [vid_url, set_vid_url] = useState(null);
  let [error, set_error] = useState('');
  let [tasks, set_tasks] = useState([]);
  useEffect(()=>{
    if (!token || !user_full)
      return;
    let es = eserf(function* _user_task_pub_get_svid(){
      let _ret = yield back_app.user_task_pub_get_list(token, user_full.email,
        'svid');
      if (_ret.err)
      {
        set_error(_ret.err);
        return;
      }
      set_tasks(_ret);
    });
    return ()=>es.return();
  }, [token, user_full]);
  let on_submit = ()=>eserf(function* _on_submit(){
    set_loading(true);
    let _ret = yield back_app.sgif_start(token, user.email, org?.id, txt,
      true);
    if (_ret.err)
    {
      set_error(_ret.err);
      set_loading(false);
      return;
    }
    let _url = _ret.url;
    set_loading(false);
    set_tasks(curr=>[_ret, ...curr]);
    set_vid_url(_url);
  });
  let on_share = _url=>{
    let data={
      title: 'ABCAIShow.com Video',
      text: 'Check out this amazing Video',
      url: url2share_url(_url),
    };
    window.navigator.share && window.navigator.share(data)
      .catch(console.error);
  };
  let on_copy_url = _url=>{
    navigator.clipboard.writeText(url2share_url(_url));
    messageApi.open({
      type: 'success',
      content: t('Link copied to clipboard'),
    });
  };

  if (!token || !user_full)
    return <Loading/>;

  if (error)
    return <Row>{t('Error')} {error}</Row>;

  if (loading)
    return <Sgif_loading/>;

  if (!vid_url)
  {
    return <Row justify="space-around">
      <Col xs={22} md={16}>
        <Row>
          <Title level={4}>
            {t('Create short videos from your prompts')}
          </Title>
        </Row>
        <Row>
          <TextArea rows={2} value={txt} maxLength={300}
            onChange={e=>set_txt(e.target.value)} autoComplete="off"
            placeholder={t('Monkey sitting on a chair and eating Pasta')}
            style={{lineHeight: 2.5, marginBottom: 16}}/>
        </Row>
        <Row>
          <Button type="primary" size="large" block
            disabled={isEmpty(txt)}
            onClick={on_submit}>
            { t('Create video')}
          </Button>
        </Row>
        {!isEmpty(tasks) &&
        <>
          <Row style={{marginTop: 32}}>
            <Title level={4}>{t('My videos')}</Title>
          </Row>
          <Row gutter={[12, 12]}>{map(_task=>{
            return <Col xs={{span: 24}} md={{span: 4}} key={_task.url}>
              <Clickable cursor onClick={()=>window.open(_task.url, '_blank')}>
                <Task_tooltip task={_task}>
                  <img src={_task.url} style={{width: '100%', height: 'auto',
                    objectFit: 'contain'}}/></Task_tooltip></Clickable>
            </Col>;}, tasks)}
          </Row>
        </>
        }
      </Col>
    </Row>;
  }

  return <>{contextHolder}
    <Row justify="space-around">
      <Col xs={22} md={12}>
        <Row justify="space-around">
          <Col xs={22} md={16}>
            <Video url={vid_url}/>
          </Col>
        </Row>
        <Row align="center" style={{marginTop: 16}}>
          <Col xs={{span: 2}}>
            <Link href={vid_url} download target="_blank">
              <Button title={t('Download video')} type="primary"
                icon={<DownloadOutlined/>}/>
            </Link>
          </Col>
          <Col xs={{span: 2, offset: 1}}>
            <Button title={t('Share')} type="primary"
              onClick={()=>on_share(vid_url)}
              icon={<ShareAltOutlined/>}/>
          </Col>
          <Col xs={{span: 2, offset: 1}}>
            <Button type="primary" onClick={()=>on_copy_url(vid_url)}
              icon={<CopyOutlined/>} title={t('Copy link')}/>
          </Col>
        </Row>
        <Row style={{marginTop: 32}}>
          <Button block type="primary" onClick={()=>set_vid_url(null)}>
            {t('Start Over')}</Button>
        </Row>
      </Col>
    </Row>
  </>;
});

export default auth.with_auth_req(E);


