// LICENSE_CODE ABC
import React, {useState, useEffect, useMemo} from 'react';
import {Row, Col, Typography, Tooltip} from 'antd';
import {useTranslation} from 'react-i18next';
import eserf from '../../../util/eserf.js';
import back_app from './back_app.js';
import {filter, get, flow, endsWith} from 'lodash/fp.js';
import {Clickable, Video} from './comp.js';

const {Title} = Typography;
let E = ()=>{
  let {t} = useTranslation();
  let [imgs, set_imgs] = useState([]);
  let [gifs, set_gifs] = useState([]);
  let [vids, set_vids] = useState([]);
  let [error, set_error] = useState(null);

  useEffect(()=>{
    let es = eserf(function* _gallery_tasks_get_imgs(){
      let _ret = yield back_app.task_pub_get_list('simg', 100);
      if (_ret.err)
      {
        set_error(_ret.err);
        return;
      }
      set_imgs(_ret);
    });
    return ()=>es.return();
  }, []);

  useEffect(()=>{
    let es = eserf(function* _gallery_tasks_get_gifs(){
      let _ret = yield back_app.task_pub_get_list('sgif', 100);
      if (_ret.err)
      {
        set_error(_ret.err);
        return;
      }
      set_gifs(_ret);
    });
    return ()=>es.return();
  }, []);

  useEffect(()=>{
    let es = eserf(function* _gallery_tasks_get_vids(){
      let _ret = yield back_app.task_pub_get_list('svid', 100);
      if (_ret.err)
      {
        set_error(_ret.err);
        return;
      }
      set_vids(_ret);
    });
    return ()=>es.return();
  }, []);

  let _imgs = useMemo(()=>flow(
    filter(_task=>get('type', _task) === 'simg'),
  )(imgs), [imgs]);
  let _gifs = useMemo(()=>flow(
    filter(_task=>get('type', _task) === 'sgif'),
  )(gifs), [gifs]);
  let _vids = useMemo(()=>flow(
    filter(_task=>get('type', _task) === 'svid'),
  )(vids), [vids]);

  if (error)
    return <Row>Error</Row>;

  let margin = 64;
  return <Row><Col xs={{span: 22, offset: 1}} md={{span: 22, offset: 1}}>
    <Row><Title level={2}>{t('Gallery')}</Title></Row>
    <Row style={{marginBlock: margin}}><Title level={3}>{t('Images')}</Title>
    </Row>
    <Row gutter={[12, 12]}>
      <Media tasks={_imgs}/>
    </Row>
    <Row style={{marginBlock: margin}}><Title level={3}>{t('GIFs')}</Title>
    </Row>
    <Row gutter={[12, 12]}>
      <Media tasks={_gifs}/>
    </Row>
    <Row style={{marginBlock: margin}}><Title level={3}>{t('Videos')}</Title>
    </Row>
    <Row gutter={[12, 12]}>
      <Media tasks={_vids} xs={24} md={8} />
    </Row>
  </Col></Row>;
};

let Media = React.memo(({tasks, xs=12, md=3})=>{
  return tasks.map(_task=>{
    let tooltip = `Prompt: ${get('txt', _task)}`;
    let _url = get('url', _task);
    let is_video = endsWith('mp4', _url);
    console.log(_url);
    return <Col xs={{span: xs}}
      md={{span: md}} key={get('_id', _task)}>
      <Clickable cursor onClick={()=>window.open(_url)}>
        <Tooltip title={tooltip}>
          {!is_video ? <img src={_url}
            style={{width: '100%', height: 'auto',
              borderRadius: 16}}/> : <Video url={_url}
            style={{borderRadius: 16}}/>}</Tooltip>
      </Clickable></Col>;
  });
});

export default E;

