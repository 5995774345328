// LICENSE_CODE MIT
import React from 'react';
import {Row, Col, Typography} from 'antd';
import config_ext from './config_ext.js';

let {Text} = Typography;
let company_lbl = config_ext.front.company_lbl;
let url = config_ext.front.url;
let email = config_ext.front.email;
let E = ()=><>
  <Row justify="left">
    <Col offset={4} span={16}><Text>
      <h1>Disclaimer for {company_lbl}</h1>

      <p>If you require any more information or have any questions about our site's disclaimer, please feel free to contact us by email at {email}.</p>

      <h2>Disclaimers for {company_lbl}</h2>

      <p>
        All the information on this website - {url} - is published in good
        faith and for general information purpose only. {company_lbl} does not
        make any warranties about the completeness, reliability and accuracy of
        this information. Any action you take upon the information you find on
        this website ({company_lbl}), is strictly at your own risk.
        {company_lbl} will not be liable for any losses and/or damages in
        connection with the use of our website.
      </p>

      <p>
        From our website, you can visit other websites by following hyperlinks
        to such external sites. While we strive to provide only quality links
        to useful and ethical websites, we have no control over the content and
        nature of these sites. These links to other websites do not imply a
        recommendation for all the content found on these sites. Site owners
        and content may change without notice and may occur before we have the
        opportunity to remove a link which may have gone 'bad'.
      </p>

      <p>
        All images, diagrams, highlights, headings, expressions, screenshots or
        photos presented are for illustrative purposes only. All intellectual
        property rights described and/or contained herein relating to
        {company_lbl} and/or its products and/or its services are owned by
        {company_lbl} and/or its affiliates.
        The information contained herein may include information and statistics
        related to third parties, markets and sectors in which {company_lbl}
        competes and other industry data. {company_lbl} has collected and
        obtained third-party information and data through public sources. Any
        reliance on the information is at the recipient’s own responsibility.
        {company_lbl} has not independently verified the accuracy or
        completeness of any such third-party information. {company_lbl} does
        not assert any claim of copyright for any quotation, statistics, fact,
        figure, data or any other content that has been sourced from the public
        domain.
        The information contained herein may include products and companies’
        names which may be trademarks™ or registered ® trademarks of their
        respective holders. Use of them does not imply any affiliation,
        endorsement, relationship or sponsorship between {company_lbl} and the
        owners of these trademarks. All references to other trademarks
        belonging to third parties or referring to branded instruments, that
        appear herein, shall be understood to refer to those registered
        trademarks owned by others, and not owned by {company_lbl}. All
        third-party trademarks (including logos and icons) are copied from
        public sources and remain the property of their respective owners. All
        references by {company_lbl} to third-party trademarks and data are for
        illustrative purposes only. {company_lbl} assumes no responsibility or
        liability for the accuracy of any such third-party trademark, data or
        its use or publication.
      </p>

      <p>
        Each party may disclose in regulatory filings, marketing materials and
        in other communications the fact that the parties have entered into
        this Agreement, however, neither party may disclose the specific terms
        of this Agreement including the fees agreed by and between the parties
        without the prior written consent of the other party, unless disclosure
        of such fee information is already public, is required to be disclosed
        by SEC rules and regulations, or pursuant to a court order.
      </p>
      <p>
        Please be also aware that when you leave our website, other sites may
        have different privacy policies and terms which are beyond our control.
        Please be sure to check the Privacy Policies of these sites as well as
        their "Terms of Service" before engaging in any business or uploading
        any information.
      </p>

      <h2>Consent</h2>

      <p>
        By using our website, you hereby consent to our disclaimer and agree to
        its terms.
      </p>

      <h2>Update</h2>

      <p>
        Should we update, amend or make any changes to this document, those
        changes will be prominently posted here.
      </p>
    </Text></Col>
  </Row>
</>;

export default E;
