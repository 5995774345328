// LICENSE_CODE ABCAI
import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Row, Typography} from 'antd';
import {useNavigate} from 'react-router-dom';
import eserf from '../../../util/eserf.js';
import back_app from './back_app.js';
import {Chars} from './comp.js'

const Land_schar = ()=>{
  let {t} = useTranslation();
  let nav = useNavigate();
  let [error, set_error] = useState('');
  let [chars, set_chars] = useState([]);

  useEffect(()=>{
    let es = eserf(function* _get_chars_use_effect(){
      let _ret = yield back_app.produce_char_get();
      if (_ret.err)
      {
        set_error(_ret.err);
        return;
      }
      set_chars(_ret.chars);
    });
    return ()=>es.return();
  }, []);
  if (error)
    return <div>Error: {error}</div>;

  return <>
    <Row>
      <Chars chars={chars} on_select={char_id=>{
        nav('/schar?char_id='+char_id);
      }}/>
    </Row>
  </>;
};

export default Land_schar;
