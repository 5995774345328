// LICENSE_CODE ABCAI
import {Button, Col, Radio, Row, Typography, Progress, message} from 'antd';
import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import auth from './auth.js';
import eserf from '../../../util/eserf.js';
import back_app from './back_app.js';
import {Loading} from './comp.js';
import {DownloadOutlined, ShareAltOutlined,
  CopyOutlined} from '@ant-design/icons';


export let person_examples = [
  'simage/sperson__example_1.jpg',
  'sperson_ret_9697c8a6-cdd0-4844-9e58-8bcd1da677ac.png',
  'sperson_ret_4ba3a0b8-b455-4b3e-a91e-b5b1e84b72e5.png',
].map(url=>`https://abcai-front-share-prod.s3.amazonaws.com/${url}`);

let {Title, Link, Text} = Typography;
let url2share_url = _url=>{
  return _url;
  //return `${window.location.origin}/vid?url=${_url}`;
};

let gender_values = [
  {value: 'random', lbl: 'Random'},
  {value: 'male', lbl: 'Male'},
  {value: 'female', lbl: 'Female'},
];
let ethnic_values = [{value: 'random', lbl: 'Random'},
  {value: 'asian', lbl: 'Asian'},
  {value: 'black', lbl: 'Black'},
  {value: 'white', lbl: 'White'},
  {value: 'indian', lbl: 'Indian'},
  {value: 'middle_eastern', lbl: 'Middle Eastern'},
  {value: 'latino_hispanic', lbl: 'Latino hispanic'},
];
let age_values = [
  {value: 'random', lbl: 'Random'},
  {value: 18, lbl: '18-25'},
  {value: 26, lbl: '26-35'},
  {value: 35, lbl: '35-50'},
  {value: 50, lbl: '50'},
];

export let E = React.memo(()=>{
  let {t} = useTranslation();
  let [messageApi, contextHolder] = message.useMessage();
  let {user, token, org, user_full} = auth.use_auth();
  let [loading, set_loading] = useState(false);
  let [img_url, set_img_url] = useState(null);
  let [params, set_params] = useState({
    gender: 'random', age: 'random', ethnic: 'random'});
  let [error, set_error] = useState('');
  let on_submit = ()=>eserf(function* _on_submit(){
    set_loading(true);
    let _ret = yield back_app.sperson_start(token, user.email, org?.id,
      params);
    if (_ret.err)
    {
      set_error(_ret.err);
      set_loading(false);
      return;
    }
    let _url = _ret.url;
    set_loading(false);
    set_img_url(_url);
  });
  let on_share = _url=>{
    let data={
      title: 'ABCAIShow.com video',
      text: 'Check out this amazing image',
      url: url2share_url(_url),
    };
    window.navigator.share && window.navigator.share(data)
      .catch(console.error);
  };
  let on_copy_url = _url=>{
    navigator.clipboard.writeText(url2share_url(_url));
    messageApi.open({
      type: 'success',
      content: t('Link copied to clipboard'),
    });
  };
  let on_change= (key, val)=>{
    set_params(curr=>({...curr, [key]: val}));
  };

  if (!token || !user_full)
    return <Loading/>;

  if (error)
    return <Row>{t('Error')} {error}</Row>;

  if (loading)
    return <Sperson_loading/>;

  if (!img_url)
  {
    return <Row justify="space-around">
      <Col xs={22} md={16}>
        <Row style={{marginTop: 32}}>
          <Title level={4}>
            {t('Fake Person Generator')}
          </Title>
        </Row>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Group title={t('Select gender')} values={gender_values}
              value={params.gender}
              on_change={_val=>on_change('gender', _val)}/>
          </Col>
          <Col span={24}>
            <Group title={t('Select age')} values={age_values}
              value={params.age}
              on_change={_val=>on_change('age', _val)}/>
          </Col>
          <Col span={24}>
            <Group title={t('Select ethnic')} values={ethnic_values}
              value={params.ethnic}
              on_change={_val=>on_change('ethnic', _val)}/>
          </Col>
          <Col span={24}>
            <Button type="primary" size="large" block
              onClick={on_submit}>
              { t('Create fake person')}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>;
  }

  return <>{contextHolder}
    <Row justify="space-around">
      <Col xs={22} md={12}>
        <Row justify="space-around">
          <Col xs={22} md={16}>
            <img src={img_url} style={{width: '100%', height: 'auto',
              maxHeight: '60vh', objectFit: 'contain'}}/>
          </Col>
        </Row>
        <Row align="center" style={{marginTop: 16}}>
          <Col xs={{span: 2}}>
            <Link href={img_url} download target="_blank">
              <Button title={t('Download image')} type="primary"
                icon={<DownloadOutlined/>}/>
            </Link>
          </Col>
          <Col xs={{span: 2, offset: 1}}>
            <Button title={t('Share')} type="primary"
              onClick={()=>on_share(img_url)}
              icon={<ShareAltOutlined/>}/>
          </Col>
          <Col xs={{span: 2, offset: 1}}>
            <Button type="primary" onClick={()=>on_copy_url(img_url)}
              icon={<CopyOutlined/>} title={t('Copy link')}/>
          </Col>
        </Row>
        <Row style={{marginTop: 32}}>
          <Col xs={{span: 20, offset: 2}} md={{span: 16, offset: 4}}>
            <Button block type="primary" onClick={()=>set_img_url(null)}>
              {t('Start Over')}</Button>
          </Col>
        </Row>
      </Col>
    </Row>
  </>;
});

let Group = React.memo(({title, values, value, on_change})=>{
  return <><Row>
    <Text>{title}</Text>
  </Row>
  <Row>
    <Radio.Group onChange={e=>on_change(e.target.value)}
      value={value}>
      {values.map(_value=><Radio.Button key={_value.value}
        value={_value.value}>{_value.lbl}</Radio.Button>)}
    </Radio.Group></Row></>;
});
let Sperson_loading = React.memo(()=>{
  let {t} = useTranslation();
  let [percent, set_percent] = useState(5);
  useEffect(()=>{
    let interval_id = setInterval(()=>{
      set_percent(curr=>Math.min(95, curr+3));
    }, 1000);
    return ()=>{
      clearInterval(interval_id);
    };
  }, []);
  return <Row justify="space-around">
    <Col xs={22} md={16}>
      <Row justify="space-around"><Col xs={24} md={12} style={{marginTop: 16}}>
        <Title level={5}>{t('Please wait (10-30 seconds)')}</Title>
        <Progress percent={percent} status="active" showInfo={false}/>
      </Col></Row>
    </Col>
  </Row>;
});

export default auth.with_auth_req(E);

