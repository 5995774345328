// LICENSE_CODE ABCAI
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Row, Col, Typography, Button, Timeline} from 'antd';
import config_ext from './config_ext.js';
import {useNavigate} from 'react-router-dom';
let email = config_ext.front.email;

let {Title, Text} = Typography;
const Home = ()=>{
  let {t} = useTranslation();
  let nav = useNavigate();
  return <>
    <Row data-aos="fade-down" justify="space-around">
      <Col xs={22} md={16}>
        <Row>
          <Title level={2}>
            {t('Win $100 Gift Cards for the best GIF!')}
          </Title>
        </Row>
        <Row>
          <Title level={3}>
            {t('How can I win?')}
          </Title>
        </Row>
        <Row>
          <Timeline items = {[
            'Create a GIF',
            'Post it on Reddit',
            'Send us a link to your post',
            'The GIF with the most upvotes wins a weekly $100 Gift Card!',
            'Minimum 20 upvotes required',
          ].map(item=>({children: t(item)}))}/>
        </Row>
        <Row gutter={[12,12]}>
          <Col xs={24} md={12}>
            <Button onClick={()=>window.location = 'mailto:'+email}
              type="primary" block>
              <Title level={4}>{t('Email us your GIF')}</Title>
            </Button>
          </Col>
          <Col xs={24} md={12}>
            <Button onClick={()=>nav('/sgif')} type="primary" block>
              <Title level={4}>{t('Create your GIF')}</Title>
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  </>;
};

export default Home;
