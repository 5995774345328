// LICENSE_CODE MIT
import {Row, Col, Typography} from 'antd';
import config_ext from './config_ext.js';

let {Text} = Typography;
let company_lbl = config_ext.front.company_lbl;
let url = config_ext.front.url;
let E = ()=><>
  <Row justify="left">
    <Col offset={4} span={16}><Text>
      <h2><strong>Terms and Conditions</strong></h2>

      <p>Welcome to {company_lbl}!</p>

      <p>AbcAiArt trains customizable models from scratch using a licensed datasets, optimized for enterprise needs. Provides advanced capabilities tuned for your industry applications while protecting you from copyright infringement, privacy violations, and harmful content risks.</p>

      <p>With the AbcAiArt platform, you gain commercially viable AI with complete liability coverage, unlocking innovation without compliance concerns.</p>

      <p>This Agreement consists of these Terms and Conditions, the applicable Service Specific Terms (if any) and any additional terms AbcAiArt presents when you access a Service. The Agreement takes effect when User accepts these Terms and Conditions or accesses or uses a Service, the earlier. You must be 18 years or older and able to form a binding contract with AbcAiArt to use the Services. The individual who accepts these Terms and Conditions represent that they are authorized to enter into this Agreement on behalf of User.</p>
      <p>Capitalized terms have the meanings given under Definitions.</p>

      <p>1 License to use AbcAiArt Services</p>

      <p>1.1 License grant. Subject to User's compliance with this Agreement, AbcAiArt grants to User a nonexclusive and limited license to access and use the Services. The licenses are non-transferable except as expressly permitted under this Agreement or applicable law.</p>

      <p>1.2 Services. Services are provided "AS-IS", "WITH ALL FAULTS" and "AS AVAILABLE". AbcAiArt may change or discontinue Services at any time without notice. AbcAiArt may provide additional terms that apply to User's use of updates, new features, or related software.</p>

      <p>1.3 Compliance with laws. User's use of the Services must not violate any applicable laws</p>

      <p>1.4 Feedback by User is optional and voluntary. Feedback may be used by AbcAiArt for any purpose without obligation of any kind as long as the User's confidential information remains confidential.</p>

      <p>1.5 Restrictions. Unless expressly permitted in this Agreement or by law, User may not:</p>
      <p>(a) reverse engineer, decompile, or disassemble any Service, or try to do so;</p>
      <p>(b) run, upgrade or downgrade, or transfer parts of a Service separately at different times or on different</p>
      devices;
      <p>(c) install, use, or distribute other software or technology in any way that makes AbcAiArt's intellectual property or technology subject to any other license terms;</p>
      <p>(d) work around technical limitations in a Service or restrictions in Service documentation; or</p>
      <p>(e) sell, rent, lease, sublicense, distribute or lend any Services to others, in whole or in part, or host</p>

      <p>Services for use by others.</p>

      <p>2 Use of AbcAiArt Services</p>

      <p>2.1 License to Suggestions. Subject to the terms of this Agreement, AbcAiArt hereby grants the User a worldwide, non-exclusive, sublicensable, transferable, perpetual and irrevocable right to use, market and have marketed, distribute and have distributed any Suggestion, as a standalone Content or embedded in a User Content.</p>

      <p>2.2 License to AbcAiArt generated content . As part of the Services, AbcAiArt may make available to the User AbcAiArt generated content . Subject to the terms of this Agreement, AbcAiArt hereby grants the User a worldwide, non-exclusive, non- transferable, perpetual and irrevocable right to:</p>
      <p>(a) use the AbcAiArt generated content  for commercial or advertisement purposes in magazines and newspapers or as a design element for a video game or digital media;</p>
      <p>(b) use the AbcAiArt generated content  online in electronic publications, email, social media (including, for example, Facebook, Instagram, LinkedIn, YouTube, and other social) or websites or software product for distribution or resale by others;</p>
      <p>(c) use the AbcAiArt generated content  as part of marketing, advertising, or promotional materials, including print advertisements, mailers, handouts, and packaging with print run;</p>
      <p>(d) use AbcAiArt generated content  for manufacturing or producing physical or digital items for promotional use only (e.g., greeting cards, magnets, mousepads, calendars, mugs, t-shirts, bookmarks, etc.).</p>
      <p>(e) use the AbcAiArt generated content  for all forms of vehicle livery and signage;</p>
      <p>(f) use the AbcAiArt generated content  as design elements in video, film, or television;</p>
      <p>(g) edit or modify the AbcAiArt generated content  using the Services.</p>

      <p>2.3 Rights to User Content. User retains all rights to User Content. User Content may be transmitted to AbcAiArt to generate Suggestions. User gives AbcAiArt a nonexclusive and limited license to store, access and use the User Content as reasonably required for the performance and development of the Services. AbcAiArt hereby warrants, User Content shall not be used to train generative AI models, unless User expressly consents to in writing.</p>

      <p>2.4 Content Guidelines. Your use of a Service is subject to the Content Guidelines.</p>

      <p>2.5 Personal Data. User Content shall not include any personal data. User and AbcAiArt will comply with applicable data protection laws.</p>

      <p>3 Indemnity, Disclaimer of warranty and Limitation of liability</p>

      <p>3.1 Indemnity. User will defend, indemnify, and hold harmless AbcAiArt, its affiliates, and personnel, from and against any claims, losses, and expenses (including attorneys’ fees) arising from or relating to User's use of the Services, including User Content, products or services you develop or offer in connection with the Services, and User's breach of this Agreement or violation of applicable law.</p>

      <p>3.2 Disclaimer. THE PRODUCTS, SUGGESTIONS, AbcAiArt generated content  AND ANY OTHER CONTENT ARE PROVIDED “AS IS.” EXCEPT TO THE EXTENT PROHIBITED BY LAW, AbcAiArt AND ITS AFFILIATES AND LICENSORS MAKE NO WARRANTIES (EXPRESS, IMPLIED, STATUTORY OR OTHERWISE) WITH RESPECT TO THE PRODUCTS, SUGGESTIONS, AbcAiArt generated content  AND ANY OTHER CONTENT, AND DISCLAIM ALL WARRANTIES INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY, NON-INFRINGEMENT, AND QUIET ENJOYMENT, AND ANY WARRANTIES ARISING OUT OF ANY COURSE OF DEALING OR TRADE USAGE. WE DO NOT WARRANT THAT THE PRODUCTS, SUGGESTIONS, AbcAiArt generated content  AND ANY OTHER CONTENT WILL BE UNINTERRUPTED, ACCURATE OR ERROR FREE, OR THAT ANY CONTENT WILL BE SECURE OR NOT LOST OR ALTERED.</p>

      <p>3.3 Limitations of Liability. NEITHER WE NOR ANY OF AbcAiArt'S AFFILIATES OR LICENSORS WILL BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, OR DATA OR OTHER LOSSES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. OUR AGGREGATE LIABILITY UNDER THIS AGREEMENT SHALL NOT EXCEED THE GREATER OF THE AMOUNT YOU PAID FOR THE SERVICE THAT GAVE RISE TO THE CLAIM DURING THE 12 MONTHS BEFORE THE LIABILITY AROSE OR US $100. THE LIMITATIONS IN THIS SECTION APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.</p>

      <p>4 Pricing and payment</p>

      <p>4.1 Fees. User agrees to pay fees charged to User account according to the prices and terms on the applicable pricing page, or as otherwise agreed between us in writing. AbcAiArt has the right to correct pricing errors or mistakes even if it has already issued an invoice or received payment. User will provide complete and accurate billing information including a valid and authorized payment method. AbcAiArt will charge User's payment method on an agreed-upon periodic basis but may reasonably change the date on which the charge is posted. If payment cannot be completed, AbcAiArt will provide User with written notice and may suspend access to the Services until payment is received. Fees are payable in US dollars and are due upon invoice issuance. Payments are non-refundable. If billed based on usage, AbcAiArt will invoice according to the billing model described in the applicable pricing page, or as otherwise agreed between us in writing.</p>

      <p>4.2 Late payment. If User fails to pay fees on time, AbcAiArt has the right to charge 2% monthly interest on past due amounts as allowed by law. AbcAiArt also has the right to charge User for all expenses of recovery, to terminate User's access to Services and to take any other action at law.</p>

      <p>5 Term and termination</p>

      <p>5.1 Term. This Agreement takes effect when you first use the Services and remain in effect until terminated. You may terminate this Agreement at any time for any reason by discontinuing the use of the Services and Content. We may terminate this Agreement for any reason by providing you at least 30 days' notice. We may terminate this Agreement immediately upon notice to you if you breach this Agreement, if there are changes in relationships with third party technology providers outside of our control, or to comply with law or government requests.</p>

      <p>5.2 Effect on Termination. Upon termination, you will stop using the Services. The sections of this Agreement which by their nature should survive termination or expiration should survive, including but not limited to Sections 3, 6 and 7.</p>

      <p>6 Miscellaneous</p>

      <p>6.1 DMCA Notice. AbcAiArt respects the intellectual property rights of others and expects its users to do the same. If you believe that your copyrighted work has been copied and is accessible on our online service in a way that constitutes copyright infringement, please notify us as set forth in the Digital Millennium Copyright Act of 1998 ("DMCA") by providing the following information:</p>
      <p>(a) A physical or electronic signature of the copyright owner or a person authorized to act on their behalf;</p>
      <p>(b) Identification of the copyrighted work claimed to have been infringed;</p>
      <p>(c) Identification of the material that is claimed to be infringing and where it is located on the Services;</p>
      <p>(d) Your contact information, including your name, address, telephone number, and email address;</p>
      <p>(e) A statement by you that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and</p>
      <p>(f) A statement, under penalty of perjury, that the information in the notification is accurate, and that you are authorized to act on behalf of the copyright owner.</p>
      <p>The above information must be submitted to the following address:</p>
      <p>Email: legal@AbcAiArt.com</p>
      Upon receipt of a valid DMCA notice, we will take down the infringing content or material and take
      <p>reasonable steps to notify the user responsible for posting the infringing material of the takedown. Repeat</p>
      <p>infringers may have their access to our Services terminated.</p>
      <p>independently without using the other's Confidential Information.</p>

      <p>6.2 Amendments. AbcAiArt may require User to accept revised or additional terms before accessing a Service. Any additional or conflicting terms and conditions presented by User are expressly rejected and will not apply.</p>

      <p>6.3 Severability. If any part of this Agreement is held to be unenforceable, the rest of the Agreement will remain in full force and effect.</p>

      <p>6.4 Waiver. Failure to enforce any provision of this Agreement will not constitute a waiver. Any waiver must be in writing and signed by the waiving party.</p>

      <p>6.6 No third-party beneficiaries. This Agreement does not create any third-party beneficiary rights except as expressly provided by its terms.</p>

      <p>6.5 Survival. All provisions survive termination of this Agreement except those requiring performance only during the term of the Agreement.</p>

      <p>6.6 Notices. Notices to AbcAiArt may be submitted via email to legal@AbcAiArt.com. If User wishes to formally service notice on AbcAiArt.</p>

      <p>6.7 Applicable law and venue. This Agreement will be governed by and construed in accordance with the laws of the State of New York, USA and federal laws of the United States.</p>
            
    </Text></Col>
  </Row>
</>;

export default E;
