// LICENSE_CODE ABC

/* eslint-disable quote-props,quotes,max-len */
let E = {
  "en": {
    "translation": {
      "Home": "Home",
      "Logout": "Logout",
      "Profile": "Profile",
      "Onboard": "Onboard",
      "Blog": "Blog",
      "Contact Us": "Contact Us",
      "Login": "Login",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.",
      "Resend verification email": "Resend verification email",
      "Call us at +1-877-775-5232": "Call us at +1-877-775-5232",
      "Super Pipeline": "Super Pipeline",
      "About": "About",
      "Signup Free": "Signup Free",
      "Disclaimer": "Disclaimer",
      "Contact us with any question at": "Contact us with any question at",
      "Call us at": "Call us at",
      "Plans": "Plans",
      "Privacy policy": "Privacy policy",
      "Terms of service": "Terms of service",
      "Finished": "Finished",
      "Continue": "Continue",
      "Start Over": "Start Over",
      "Start (100% FREE)": "Start (100% FREE)",
      "Submit": "Submit",
      "Examples": "Examples",
      "Select character": "Select character",
      "Video Examples": "Video Examples",
      "Write a poem about life": "Write a poem about life",
      "Explain how molecules work": "Explain how molecules work",
      "Write a story about life in Mars": "Write a story about life in Mars",
      "Create an entire video from a single prompt": "Create an entire video from a single prompt",
      "Entertain, inform and inspire": "Entertain, inform and inspire",
      "through the power of AI": "through the power of AI",
      "Create Video!": "Create Video!",
      "one more step before you start Just": "one more step before you start",
      "First name": "First name",
      "What is your first name": "What is your first name",
      "Please input your first name!": "Please input your first name!",
      "Last/family name": "Last/family name",
      "What is your last/family name": "What is your last/family name",
      "Please input your last/family name!": "Please input your last/family name!",
      "Nickname": "Nickname",
      "What do you want others to call you?": "What do you want others to call you?",
      "Please input your nickname!": "Please input your nickname!",
      "Company": "Company",
      "What is the company you represent?": "What is the company you represent?",
      "Please input your company!": "Please input your company!",
      "Phone Number": "Phone Number",
      "What is your phone number?": "What is your phone number?",
      "Why we ask for your phone number": "Why we ask for your phone number",
      "Account Recovery": "Account Recovery",
      "If you forget your password or get locked out of your account, a phone number provides an additional way to verify your identity and regain access.": "If you forget your password or get locked out of your account, a phone number provides an additional way to verify your identity and regain access.",
      "Fraud Prevention": "Fraud Prevention",
      "by asking for phone number it limits fake or fraudulent account creation. It's more challenging for scammers to maintain numerous unique phone numbers than it is for email addresses.": "by asking for phone number it limits fake or fraudulent account creation. It's more challenging for scammers to maintain numerous unique phone numbers than it is for email addresses.",
      "Communication": "Communication",
      "we may communicate with users directly through phone calls or SMS, whether for support or account issues": "we may communicate with users directly through phone calls or SMS, whether for support or account issues",
      "Please input your phone number! Example +18777755232": "Please input your phone number! Example +18777755232",
      "I agree to the": "I agree to the",
      "privacy policy": "privacy policy",
      "terms of service": "terms of service",
      "disclaimer": "disclaimer",
      "Just one more step before you start": "Just one more step before you start",
      "Phone number": "Phone number",
      "CONTACT US": "CONTACT US",
      "BLOGS": "BLOGS",
      "Published": "Published",
      "Office": "Office",
      "Super Character": "Super Character",
      "Super Meme": "Super Meme",
      "Hello, how are you?": "Hello, how are you?",
      "Say something": "Say something",
      "Download video": "Download video",
      "Check out this cool AI text to video tool": "Check out this cool AI text to video tool",
      "Share": "Share",
      "Download": "Download",
      "Select or search a character": "Select or search a character",
      "Copy link": "Copy link",
      "Link copied to clipboard": "Link copied to clipboard",
      "This character cannot create videos for now": "This character cannot create videos for now",
      "Create GIFs from your prompts": "Create GIFs from your prompts",
      "Create GIF": "Create GIF",
      "Monkey sitting on a chair and eating Pasta": "Monkey sitting on a chair and eating Pasta",
      "Please wait (30-60 seconds)": "Please wait (30-60 seconds)",
      "Please wait (30-120 seconds)": "Please wait (30-120 seconds)",
      "Prompt": "Prompt",
      "The future of A.I. content creation": "The future of A.I. content creation",
      "Try these prompts": "Try these prompts",
      "Signup FREE": "Signup FREE",
      "Cancel": "Cancel",
      "Error": "Error",
      "Hide video": "Hide video",
      "It's 100% FREE!": "It's 100% FREE!",
      "Win $100 Gift Cards for the best GIF!": "Win $100 Gift Cards for the best GIF!",
      "How can I win?": "How can I win?",
      "Create a GIF": "Create a GIF",
      "Post it on Reddit": "Post it on Reddit",
      "Send us a link to your post": "Send us a link to your post",
      "The GIF with the most upvotes wins a weekly $100 Gift Card!": "The GIF with the most upvotes wins a weekly $100 Gift Card!",
      "Minimum 20 upvotes required": "Minimum 20 upvotes required",
      "Email us your GIF": "Email us your GIF",
      "Create your GIF": "Create your GIF",
      "Submit your GIF to Reddit - Get $100 Gift Card!": "Submit your GIF to Reddit - Get $100 Gift Card!",
      "Welcome to the chat room with": "Welcome to the chat room with",
      "Click on one of these messages": "Click on one of these messages",
      "Super GIF": "Super GIF",
      "Generating your video": "Generating your video",
      "Click this button in order to generate a video from this response": "Click this button in order to generate a video from this response",
      "Start": "Start",
      "You need to signup for free in order to create a GIF": "You need to signup for free in order to create a GIF",
      "Create video": "Create video",
      "Share video": "Share video",
      "Create images from your prompts": "Create images from your prompts",
      "Create image": "Create image",
      "Please wait (10-30 seconds)": "Please wait (10-30 seconds)",
      "Download GIF": "Download GIF",
      "Download image": "Download image",
      "Super Image": "Super Image",
      "Super Person": "Super Person",
      "Create fake person": "Create fake person",
      "Select gender": "Select gender",
      "Select age": "Select age",
      "Select ethnic": "Select ethnic",
      "Fake Person Generator": "Fake Person Generator",
      "We create AI tools for content creators": "We create AI tools for content creators",
      "You need to signup for free in order to use our tools": "You need to signup for free in order to use our tools",
      "Show more": "Show more",
      "Chat with your favorite characters": "Chat with your favorite characters",
      "Chat with over 60 characters": "Chat with over 60 characters",
      "Style": "Style",
      "Error sending the chat message. Please try again": "Error sending the chat message. Please try again",
      "Error generating the video": "Error generating the video",
      "Twitch stream": "Twitch stream",
      "Join the stream and chat with AI Characters": "Join the stream and chat with AI Characters",
      "Please complete your onboarding in order to keep using our platform for free.": "Please complete your onboarding in order to keep using our platform for free.",
      "Looks like you are a heavy user of our platform!": "Looks like you are a heavy user of our platform!",
      "You need to complete your onboarding in order to continue": "You need to complete your onboarding in order to continue",
      "Complete onboarding": "Complete onboarding",
      "Shape": "Shape",
      "Yes, show me ads. I understand why you do it": "Yes, show me ads. I understand why you do it",
      "No, do not show me ads, I hate ads": "No, do not show me ads, I hate ads",
      "It costs us a lot of money to operate this platform.": "It costs us a lot of money to operate this platform.",
      "Just one question": "Just one question",
      "It would really help us if you allowed us to show you some ads.": "It would really help us if you allowed us to show you some ads.",
      "This way you can keep using our platform for free, while helping us break even.": "This way you can keep using our platform for free, while helping us break even.",
      "Email address": "Email address",
      "Create a Password": "Create a Password",
      "Your email address": "Your email address",
      "Your password": "Your password",
      "Sign up": "Sign up",
      "Signing up ...": "Signing up ...",
      "Sign in with": "Sign in with",
      "Don't have an account? Sign up": "Don't have an account? Sign up",
      "Check your email for the confirmation link": "Check your email for the confirmation link",
      "Your Password": "Your Password",
      "Sign in": "Sign in",
      "Signing in ...": "Signing in ...",
      "Already have an account? Sign in": "Already have an account? Sign in",
      "Send Magic Link": "Send Magic Link",
      "Sending Magic Link ...": "Sending Magic Link ...",
      "Send a magic link email": "Send a magic link email",
      "Check your email for the magic link": "Check your email for the magic link",
      "Send reset password instructions": "Send reset password instructions",
      "Sending reset instructions ...": "Sending reset instructions ...",
      "Forgot your password?": "Forgot your password?",
      "Check your email for the password reset link": "Check your email for the password reset link",
      "New password": "New password",
      "Your new password": "Your new password",
      "Update password": "Update password",
      "Updating password ...": "Updating password ...",
      "Your password has been updated": "Your password has been updated",
      "Your phone number": "Your phone number",
      "Token": "Token",
      "Your Otp token": "Your Otp token",
      "Verify token": "Verify token",
      "We need a sponsor": "We need a sponsor",
      "Help us break even": "Help us break even",
      "Help us keep the platform free": "Help us keep the platform free",
      "My images": "My images",
      "My GIFs": "My GIFs",
      "Gallery": "Gallery",
      "Super Voice": "Super Voice",
      "Create speech from text": "Create speech from text",
      "Create speech": "Create speech",
      "This is Gordon Ramsay, what would you like to eat?": "This is Gordon Ramsay, what would you like to eat?",
      "New": "New",
      "Images": "Images",
      "GIFs": "GIFs",
      "Create short videos from your prompts": "Create short videos from your prompts",
      "My videos": "My videos",
      "Super Video": "Super Video",
      "Videos": "Videos",
      "Thank you for verifing your email": "Thank you for verifing your email",
      "Created by": "Created by",
      "Write something to": "Write something to",
    }
  },
  "fr": {
    "translation": {
      "Home": "Maison",
      "Logout": "Se déconnecter",
      "Profile": "Profil",
      "Onboard": "À bord",
      "Blog": "Blog",
      "Contact Us": "Contactez-nous",
      "Login": "Se connecter",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "Veuillez vérifier votre adresse e-mail afin de pouvoir continuer à utiliser le site Web. Nous vous avons envoyé un e-mail avec un lien sur lequel cliquer.",
      "Resend verification email": "Renvoyer l'e-mail de vérification",
      "Call us at +1-877-775-5232": "Appelez-nous au +1-877-775-5232",
      "Super Pipeline": "Super pipeline",
      "About": "À propos",
      "Signup Free": "Inscription gratuite",
      "Disclaimer": "Clause de non-responsabilité",
      "Contact us with any question at": "Contactez-nous pour toute question à",
      "Call us at": "Appelez-nous au",
      "Plans": "Des plans",
      "Privacy policy": "Politique de confidentialité",
      "Terms of service": "Conditions d'utilisation",
      "Finished": "Fini",
      "Continue": "Continuer",
      "Start Over": "Recommencer",
      "Start (100% FREE)": "Commencez (100% GRATUIT)",
      "Submit": "Soumettre",
      "Examples": "Exemples",
      "Select character": "Sélectionner un personnage",
      "Video Examples": "Exemples de vidéos",
      "Write a poem about life": "Écrivez un poème sur la vie",
      "Explain how molecules work": "Expliquer comment fonctionnent les molécules",
      "Write a story about life in Mars": "Écrivez une histoire sur la vie sur Mars",
      "Create an entire video from a single prompt": "Créez une vidéo entière à partir d'une seule invite",
      "Entertain, inform and inspire": "Divertir, informer et inspirer",
      "through the power of AI": "grâce à la puissance de l'IA",
      "Create Video!": "Créez une vidéo !",
      "one more step before you start Just": "encore une étape avant de commencer",
      "First name": "Prénom",
      "What is your first name": "Quel est votre prénom",
      "Please input your first name!": "Veuillez saisir votre prénom !",
      "Last/family name": "Nom de famille",
      "What is your last/family name": "Quel est votre nom de famille",
      "Please input your last/family name!": "Veuillez saisir votre nom de famille !",
      "Nickname": "Surnom",
      "What do you want others to call you?": "Comment voulez-vous que les autres vous appellent ?",
      "Please input your nickname!": "Veuillez saisir votre pseudo !",
      "Company": "Entreprise",
      "What is the company you represent?": "Quelle est l'entreprise que vous représentez ?",
      "Please input your company!": "Veuillez indiquer votre entreprise !",
      "Phone Number": "Numéro de téléphone",
      "What is your phone number?": "Quel est votre numéro de téléphone?",
      "Why we ask for your phone number": "Pourquoi nous demandons votre numéro de téléphone",
      "Account Recovery": "Récupération du compte",
      "If you forget your password or get locked out of your account, a phone number provides an additional way to verify your identity and regain access.": "Si vous oubliez votre mot de passe ou si votre compte est bloqué, un numéro de téléphone constitue un moyen supplémentaire de vérifier votre identité et de retrouver l'accès.",
      "Fraud Prevention": "Prévention de la fraude",
      "by asking for phone number it limits fake or fraudulent account creation. It's more challenging for scammers to maintain numerous unique phone numbers than it is for email addresses.": "en demandant un numéro de téléphone, cela limite la création de comptes faux ou frauduleux. Il est plus difficile pour les fraudeurs de conserver de nombreux numéros de téléphone uniques que d'adresses e-mail.",
      "Communication": "Communication",
      "we may communicate with users directly through phone calls or SMS, whether for support or account issues": "nous pouvons communiquer avec les utilisateurs directement par appels téléphoniques ou SMS, que ce soit pour des questions d'assistance ou de compte.",
      "Please input your phone number! Example +18777755232": "Veuillez saisir votre numéro de téléphone ! Exemple +18777755232",
      "I agree to the": "je suis d'accord avec le",
      "privacy policy": "politique de confidentialité",
      "terms of service": "conditions d'utilisation",
      "disclaimer": "clause de non-responsabilité",
      "Just one more step before you start": "Encore une étape avant de commencer",
      "Phone number": "Numéro de téléphone",
      "CONTACT US": "CONTACTEZ-NOUS",
      "BLOGS": "BLOGUES",
      "Published": "Publié",
      "Office": "Bureau",
      "Super Character": "Super personnage",
      "Super Meme": "Super Mème",
      "Hello, how are you?": "Bonjour comment allez-vous?",
      "Say something": "Dire quelque chose",
      "Download video": "Télécharger la video",
      "Check out this cool AI text to video tool": "Découvrez cet outil génial de texte IA en vidéo",
      "Share": "Partager",
      "Download": "Télécharger",
      "Select or search a character": "Sélectionner ou rechercher un personnage",
      "Copy link": "Copier le lien",
      "Link copied to clipboard": "Lien copié dans le presse-papier",
      "This character cannot create videos for now": "Ce personnage ne peut pas créer de vidéos pour le moment",
      "Create GIFs from your prompts": "Créez des GIF à partir de vos invites",
      "Create GIF": "Créer un GIF",
      "Monkey sitting on a chair and eating Pasta": "Singe assis sur une chaise et mangeant des pâtes",
      "Please wait (30-60 seconds)": "Veuillez patienter (30 à 60 secondes)",
      "Please wait (30-120 seconds)": "Veuillez patienter (30 à 120 secondes)",
      "Prompt": "Rapide",
      "The future of A.I. content creation": "L'avenir de l'IA création de contenu",
      "Try these prompts": "Essayez ces invites",
      "Signup FREE": "Inscription gratuite",
      "Cancel": "Annuler",
      "Error": "Erreur",
      "Hide video": "Masquer la vidéo",
      "It's 100% FREE!": "C'est 100% GRATUIT !",
      "Win $100 Gift Cards for the best GIF!": "Gagnez des cartes-cadeaux de 100 $ pour le meilleur GIF !",
      "How can I win?": "Comment puis-je gagner ?",
      "Create a GIF": "Créer un GIF",
      "Post it on Reddit": "Postez-le sur Reddit",
      "Send us a link to your post": "Envoyez-nous un lien vers votre message",
      "The GIF with the most upvotes wins a weekly $100 Gift Card!": "Le GIF avec le plus de votes positifs remporte une carte-cadeau hebdomadaire de 100 $ !",
      "Minimum 20 upvotes required": "Minimum 20 votes positifs requis",
      "Email us your GIF": "Envoyez-nous votre GIF",
      "Create your GIF": "Créez votre GIF",
      "Submit your GIF to Reddit - Get $100 Gift Card!": "Soumettez votre GIF à Reddit - Obtenez une carte-cadeau de 100 $ !",
      "Welcome to the chat room with": "Bienvenue dans le salon de discussion avec",
      "Click on one of these messages": "Cliquez sur un de ces messages",
      "Super GIF": "Super GIF",
      "Generating your video": "Générer votre vidéo",
      "Click this button in order to generate a video from this response": "Cliquez sur ce bouton afin de générer une vidéo à partir de cette réponse",
      "Start": "Commencer",
      "You need to signup for free in order to create a GIF": "Vous devez vous inscrire gratuitement pour créer un GIF",
      "Create video": "Créer une vidéo",
      "Share video": "Partager la vidéo",
      "Create images from your prompts": "Créez des images à partir de vos invites",
      "Create image": "Créer une image",
      "Please wait (10-30 seconds)": "Veuillez patienter (10 à 30 secondes)",
      "Download GIF": "Télécharger GIF",
      "Download image": "Télécharger l'image",
      "Super Image": "Super image",
      "Super Person": "Super personne",
      "Create fake person": "Créer une fausse personne",
      "Select gender": "Sélectionnez le sexe",
      "Select age": "Sélectionnez l'âge",
      "Select ethnic": "Sélectionnez ethnique",
      "Fake Person Generator": "Générateur de fausses personnes",
      "We create AI tools for content creators": "Nous créons des outils d'IA pour les créateurs de contenu",
      "You need to signup for free in order to use our tools": "Vous devez vous inscrire gratuitement pour utiliser nos outils",
      "Show more": "Montre plus",
      "Chat with your favorite characters": "Discutez avec vos personnages préférés",
      "Chat with over 60 characters": "Discutez avec plus de 60 caractères",
      "Style": "Style",
      "Error sending the chat message. Please try again": "Erreur lors de l'envoi du message de discussion. Veuillez réessayer",
      "Error generating the video": "Erreur lors de la génération de la vidéo",
      "Twitch stream": "Flux Twitch",
      "Join the stream and chat with AI Characters": "Rejoignez le flux et discutez avec des personnages IA",
      "Please complete your onboarding in order to keep using our platform for free.": "Veuillez finaliser votre intégration afin de continuer à utiliser notre plateforme gratuitement.",
      "Looks like you are a heavy user of our platform!": "On dirait que vous êtes un grand utilisateur de notre plateforme !",
      "You need to complete your onboarding in order to continue": "Vous devez terminer votre intégration pour continuer",
      "Complete onboarding": "Intégration complète",
      "Shape": "Forme",
      "Yes, show me ads. I understand why you do it": "Oui, montre-moi des publicités. Je comprends pourquoi tu fais ça",
      "No, do not show me ads, I hate ads": "Non, ne me montre pas de publicité, je déteste les publicités",
      "It costs us a lot of money to operate this platform.": "Cela nous coûte beaucoup d’argent pour exploiter cette plateforme.",
      "Just one question": "Juste une question",
      "It would really help us if you allowed us to show you some ads.": "Cela nous aiderait vraiment si vous nous permettiez de vous montrer des publicités.",
      "This way you can keep using our platform for free, while helping us break even.": "De cette façon, vous pouvez continuer à utiliser notre plateforme gratuitement, tout en nous aidant à atteindre notre seuil de rentabilité.",
      "Email address": "Adresse e-mail",
      "Create a Password": "Créer un mot de passe",
      "Your email address": "Votre adresse e-mail",
      "Your password": "Votre mot de passe",
      "Sign up": "S'inscrire",
      "Signing up ...": "S'enregistrer ...",
      "Sign in with": "Se connecter avec",
      "Don't have an account? Sign up": "Vous n'avez pas de compte ? S'inscrire",
      "Check your email for the confirmation link": "Vérifiez votre courrier électronique pour le lien de confirmation",
      "Your Password": "Votre mot de passe",
      "Sign in": "Se connecter",
      "Signing in ...": "Connectez-vous ...",
      "Already have an account? Sign in": "Vous avez déjà un compte? Se connecter",
      "Send Magic Link": "Envoyer un lien magique",
      "Sending Magic Link ...": "Envoi du lien magique...",
      "Send a magic link email": "Envoyer un e-mail de lien magique",
      "Check your email for the magic link": "Vérifiez votre courrier électronique pour le lien magique",
      "Send reset password instructions": "Envoyer les instructions de réinitialisation du mot de passe",
      "Sending reset instructions ...": "Envoi des instructions de réinitialisation...",
      "Forgot your password?": "Mot de passe oublié?",
      "Check your email for the password reset link": "Vérifiez votre courrier électronique pour le lien de réinitialisation du mot de passe",
      "New password": "Nouveau mot de passe",
      "Your new password": "Votre nouveau mot de passe",
      "Update password": "Mettre à jour le mot de passe",
      "Updating password ...": "Mise à jour du mot de passe...",
      "Your password has been updated": "Votre mot de passe a été mis à jour",
      "Your phone number": "Votre numéro de téléphone",
      "Token": "Jeton",
      "Your Otp token": "Votre jeton Otp",
      "Verify token": "Vérifier le jeton",
      "We need a sponsor": "Nous avons besoin d'un sponsor",
      "Help us break even": "Aidez-nous à atteindre le seuil de rentabilité",
      "Help us keep the platform free": "Aidez-nous à garder la plateforme gratuite",
      "My images": "Mes images",
      "Gallery": "Galerie",
      "Super Voice": "Super voix",
      "Create speech from text": "Créer un discours à partir d'un texte",
      "Create speech": "Créer un discours",
      "This is Gordon Ramsay, what would you like to eat?": "Ici Gordon Ramsay, qu'est-ce que tu aimerais manger ?",
      "New": "Nouveau",
      "Images": "Images",
      "GIFs": "GIF"
    }
  },
  "bn": {
    "translation": {
      "Home": "বাড়ি",
      "Logout": "প্রস্থান",
      "Profile": "প্রোফাইল",
      "Onboard": "বোর্ডে",
      "Blog": "ব্লগ",
      "Contact Us": "যোগাযোগ করুন",
      "Login": "প্রবেশ করুন",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "দয়া করে যান এবং আপনার ইমেল যাচাই করুন যাতে আপনি ওয়েবসাইটটি ব্যবহার করা চালিয়ে যেতে পারেন। আমরা আপনাকে ক্লিক করার জন্য একটি লিঙ্ক সহ একটি ইমেল পাঠিয়েছি।",
      "Resend verification email": "যাচাইকরণ ইমেল পুনরায় পাঠান",
      "Call us at +1-877-775-5232": "+1-877-775-5232 এ আমাদের কল করুন",
      "Super Pipeline": "সুপার পাইপলাইন",
      "About": "সম্পর্কিত",
      "Signup Free": "আমার স্নাতকের",
      "Disclaimer": "দাবিত্যাগ",
      "Contact us with any question at": "এ যেকোন প্রশ্নে আমাদের সাথে যোগাযোগ করুন",
      "Call us at": "আমাদের কল করুন",
      "Plans": "পরিকল্পনা সমূহ",
      "Privacy policy": "গোপনীয়তা নীতি",
      "Terms of service": "সেবা পাবার শর্ত",
      "Finished": "সমাপ্ত",
      "Continue": "চালিয়ে যান",
      "Start Over": "নতুন করে শুরু কর",
      "Start (100% FREE)": "শুরু করুন (100% বিনামূল্যে)",
      "Submit": "জমা দিন",
      "Examples": "উদাহরণ",
      "Select character": "চরিত্র নির্বাচন করুন",
      "Video Examples": "ভিডিও উদাহরণ",
      "Write a poem about life": "জীবন সম্পর্কে একটি কবিতা লিখুন",
      "Explain how molecules work": "অণু কিভাবে কাজ করে ব্যাখ্যা কর",
      "Write a story about life in Mars": "মঙ্গল গ্রহের জীবন সম্পর্কে একটি গল্প লিখুন",
      "Create an entire video from a single prompt": "একটি একক প্রম্পট থেকে একটি সম্পূর্ণ ভিডিও তৈরি করুন",
      "Entertain, inform and inspire": "বিনোদন, অবহিত এবং অনুপ্রাণিত",
      "through the power of AI": "এআই এর শক্তির মাধ্যমে",
      "Create Video!": "ভিডিও তৈরি করুন!",
      "one more step before you start Just": "আপনি শুরু করার আগে আরও একটি ধাপ",
      "First name": "নামের প্রথম অংশ",
      "What is your first name": "তোমার প্রথম নাম কি",
      "Please input your first name!": "আপনার প্রথম নাম ইনপুট করুন!",
      "Last/family name": "শেষ / পারিবারিক নাম",
      "What is your last/family name": "আপনার শেষ/পরিবারের নাম কি",
      "Please input your last/family name!": "অনুগ্রহ করে আপনার শেষ/পরিবারের নাম ইনপুট করুন!",
      "Nickname": "ডাকনাম",
      "What do you want others to call you?": "আপনি কি চান অন্যরা আপনাকে ডাকুক?",
      "Please input your nickname!": "আপনার ডাকনাম ইনপুট করুন!",
      "Company": "প্রতিষ্ঠান",
      "What is the company you represent?": "আপনি প্রতিনিধিত্ব করা কোম্পানি কি?",
      "Please input your company!": "আপনার কোম্পানি ইনপুট করুন!",
      "Phone Number": "ফোন নম্বর",
      "What is your phone number?": "আপনার ফোন নম্বর কি?",
      "Why we ask for your phone number": "কেন আমরা আপনার ফোন নম্বর চাই",
      "Account Recovery": "একাউন্ট পুনরুদ্ধার",
      "If you forget your password or get locked out of your account, a phone number provides an additional way to verify your identity and regain access.": "আপনি যদি আপনার পাসওয়ার্ড ভুলে যান বা আপনার অ্যাকাউন্ট থেকে লক হয়ে যান, তাহলে একটি ফোন নম্বর আপনার পরিচয় যাচাই করার এবং অ্যাক্সেস পুনরুদ্ধার করার একটি অতিরিক্ত উপায় প্রদান করে৷",
      "Fraud Prevention": "প্রতারনা প্রতিরোধ",
      "by asking for phone number it limits fake or fraudulent account creation. It's more challenging for scammers to maintain numerous unique phone numbers than it is for email addresses.": "ফোন নম্বর জিজ্ঞাসা করে এটি জাল বা প্রতারণামূলক অ্যাকাউন্ট তৈরিকে সীমাবদ্ধ করে। স্ক্যামারদের জন্য ইমেল ঠিকানার তুলনায় অসংখ্য অনন্য ফোন নম্বর বজায় রাখা আরও চ্যালেঞ্জিং।",
      "Communication": "যোগাযোগ",
      "we may communicate with users directly through phone calls or SMS, whether for support or account issues": "আমরা ব্যবহারকারীদের সাথে সরাসরি ফোন কল বা এসএমএস এর মাধ্যমে যোগাযোগ করতে পারি, সমর্থন বা অ্যাকাউন্ট সংক্রান্ত সমস্যার জন্য",
      "Please input your phone number! Example +18777755232": "আপনার ফোন নম্বর ইনপুট করুন! উদাহরণ +18777755232",
      "I agree to the": "আমি একমত",
      "privacy policy": "গোপনীয়তা নীতি",
      "terms of service": "সেবা পাবার শর্ত",
      "disclaimer": "দাবিত্যাগ",
      "Just one more step before you start": "আপনি শুরু করার আগে আরও একটি ধাপ",
      "Phone number": "ফোন নম্বর",
      "CONTACT US": "যোগাযোগ করুন",
      "BLOGS": "ব্লগ",
      "Published": "প্রকাশিত হয়েছে",
      "Office": "দপ্তর",
      "Super Character": "সুপার ক্যারেক্টার",
      "Super Meme": "সুপার মেম",
      "Hello, how are you?": "হ্যালো, আপনি কেমন আছেন?",
      "Say something": "কিছু বল",
      "Download video": "ভিডিও ডাউনলোড",
      "Check out this cool AI text to video tool": "এই দুর্দান্ত AI পাঠ্য থেকে ভিডিও সরঞ্জামটি দেখুন",
      "Share": "শেয়ার করুন",
      "Download": "ডাউনলোড করুন",
      "Select or search a character": "একটি অক্ষর নির্বাচন বা অনুসন্ধান করুন",
      "Copy link": "লিংক কপি করুন",
      "Link copied to clipboard": "ক্লিপবোর্ডে লিঙ্ক কপি করা হয়েছে",
      "This character cannot create videos for now": "এই চরিত্রটি এখন ভিডিও তৈরি করতে পারবে না",
      "Create GIFs from your prompts": "আপনার প্রম্পট থেকে GIF তৈরি করুন",
      "Create GIF": "GIF তৈরি করুন",
      "Monkey sitting on a chair and eating Pasta": "বানর চেয়ারে বসে পাস্তা খাচ্ছে",
      "Please wait (30-60 seconds)": "অনুগ্রহ করে অপেক্ষা করুন (30-60 সেকেন্ড)",
      "Please wait (30-120 seconds)": "অনুগ্রহ করে অপেক্ষা করুন (30-120 সেকেন্ড)",
      "Prompt": "শীঘ্র",
      "The future of A.I. content creation": "A.I এর ভবিষ্যৎ বিষয়বস্তু তৈরি",
      "Try these prompts": "এই প্রম্পট চেষ্টা করুন",
      "Signup FREE": "আমার স্নাতকের",
      "Cancel": "বাতিল করুন",
      "Error": "ত্রুটি",
      "Hide video": "ভিডিও লুকান",
      "It's 100% FREE!": "এটা 100% বিনামূল্যে!",
      "Win $100 Gift Cards for the best GIF!": "সেরা GIF এর জন্য $100 উপহার কার্ড জিতুন!",
      "How can I win?": "আমি কিভাবে জিততে পারি?",
      "Create a GIF": "একটি GIF তৈরি করুন",
      "Post it on Reddit": "Reddit এ পোস্ট করুন",
      "Send us a link to your post": "আমাদের আপনার পোস্টের একটি লিঙ্ক পাঠান",
      "The GIF with the most upvotes wins a weekly $100 Gift Card!": "সর্বাধিক আপভোট সহ GIF একটি সাপ্তাহিক $100 উপহার কার্ড জিতেছে!",
      "Minimum 20 upvotes required": "ন্যূনতম 20টি আপভোট প্রয়োজন৷",
      "Email us your GIF": "আমাদের আপনার GIF ইমেল করুন",
      "Create your GIF": "আপনার GIF তৈরি করুন",
      "Submit your GIF to Reddit - Get $100 Gift Card!": "Reddit-এ আপনার GIF জমা দিন - $100 উপহার কার্ড পান!",
      "Welcome to the chat room with": "সাথে চ্যাট রুমে স্বাগতম",
      "Click on one of these messages": "এই বার্তাগুলির একটিতে ক্লিক করুন",
      "Super GIF": "সুপার জিআইএফ",
      "Generating your video": "আপনার ভিডিও তৈরি করা হচ্ছে",
      "Click this button in order to generate a video from this response": "এই প্রতিক্রিয়া থেকে একটি ভিডিও তৈরি করতে এই বোতামটি ক্লিক করুন৷",
      "Start": "শুরু করুন",
      "You need to signup for free in order to create a GIF": "একটি GIF তৈরি করার জন্য আপনাকে বিনামূল্যে সাইন আপ করতে হবে৷",
      "Create video": "ভিডিও তৈরি করুন",
      "Share video": "ভিডিও ভাগ করুন",
      "Create images from your prompts": "আপনার প্রম্পট থেকে ছবি তৈরি করুন",
      "Create image": "ইমেজ তৈরি করুন",
      "Please wait (10-30 seconds)": "অনুগ্রহ করে অপেক্ষা করুন (10-30 সেকেন্ড)",
      "Download GIF": "GIF ডাউনলোড করুন",
      "Download image": "ছবি ডাউনলোড করুন",
      "Super Image": "সুপার ইমেজ",
      "Super Person": "সুপার পারসন",
      "Create fake person": "ভুয়া মানুষ তৈরি করুন",
      "Select gender": "লিংগ নির্বাচন",
      "Select age": "বয়স নির্বাচন করুন",
      "Select ethnic": "জাতিগত নির্বাচন করুন",
      "Fake Person Generator": "জাল ব্যক্তি জেনারেটর",
      "We create AI tools for content creators": "আমরা বিষয়বস্তু নির্মাতাদের জন্য এআই টুল তৈরি করি",
      "You need to signup for free in order to use our tools": "আমাদের সরঞ্জামগুলি ব্যবহার করার জন্য আপনাকে বিনামূল্যে সাইন আপ করতে হবে",
      "Show more": "আরো দেখুন",
      "Chat with your favorite characters": "আপনার প্রিয় চরিত্রের সাথে চ্যাট করুন",
      "Chat with over 60 characters": "60 টিরও বেশি অক্ষরের সাথে চ্যাট করুন",
      "Style": "শৈলী",
      "Error sending the chat message. Please try again": "চ্যাট বার্তা পাঠাতে ত্রুটি. অনুগ্রহপূর্বক আবার চেষ্টা করুন",
      "Error generating the video": "ভিডিও তৈরিতে ত্রুটি",
      "Twitch stream": "টুইচ স্ট্রীম",
      "Join the stream and chat with AI Characters": "স্ট্রীমে যোগ দিন এবং এআই ক্যারেক্টারদের সাথে চ্যাট করুন",
      "Please complete your onboarding in order to keep using our platform for free.": "বিনামূল্যে আমাদের প্ল্যাটফর্ম ব্যবহার চালিয়ে যেতে আপনার অনবোর্ডিং সম্পূর্ণ করুন.",
      "Looks like you are a heavy user of our platform!": "মনে হচ্ছে আপনি আমাদের প্ল্যাটফর্মের একজন ভারী ব্যবহারকারী!",
      "You need to complete your onboarding in order to continue": "চালিয়ে যাওয়ার জন্য আপনাকে আপনার অনবোর্ডিং সম্পূর্ণ করতে হবে",
      "Complete onboarding": "অনবোর্ডিং সম্পূর্ণ করুন",
      "Shape": "আকৃতি",
      "Yes, show me ads. I understand why you do it": "হ্যাঁ, আমাকে বিজ্ঞাপন দেখান। আমি বুঝতে পারছি আপনি কেন এটা করছেন",
      "No, do not show me ads, I hate ads": "না, আমাকে বিজ্ঞাপন দেখাবেন না, আমি বিজ্ঞাপন ঘৃণা করি",
      "It costs us a lot of money to operate this platform.": "এই প্ল্যাটফর্মটি পরিচালনা করতে আমাদের অনেক টাকা খরচ হয়।",
      "Just one question": "শুধু একটা প্রশ্ন",
      "It would really help us if you allowed us to show you some ads.": "আপনি যদি আমাদের আপনাকে কিছু বিজ্ঞাপন দেখানোর অনুমতি দেন তাহলে এটি সত্যিই আমাদের সাহায্য করবে৷",
      "This way you can keep using our platform for free, while helping us break even.": "এইভাবে আপনি বিনামূল্যে আমাদের প্ল্যাটফর্ম ব্যবহার চালিয়ে যেতে পারেন, এমনকি আমাদের বিরতিতে সাহায্য করতে পারেন।",
      "Email address": "ইমেইল ঠিকানা",
      "Create a Password": "একটি পাসওয়ার্ড তৈরি করুন",
      "Your email address": "আপনার ইমেইল ঠিকানা",
      "Your password": "আপনার পাসওয়ার্ড",
      "Sign up": "নিবন্ধন করুন",
      "Signing up ...": "নিবন্ধন করা ...",
      "Sign in with": "দিয়ে সাইন ইন",
      "Don't have an account? Sign up": "একটি অ্যাকাউন্ট নেই? নিবন্ধন করুন",
      "Check your email for the confirmation link": "নিশ্চিতকরণ লিঙ্ক পেতে আপনার ইমেইল চেক করুণ",
      "Your Password": "আপনার পাসওয়ার্ড",
      "Sign in": "সাইন ইন করুন",
      "Signing in ...": "সাইন ইন ...",
      "Already have an account? Sign in": "ইতিমধ্যে একটি সদস্যপদ আছে? সাইন ইন করুন",
      "Send Magic Link": "ম্যাজিক লিঙ্ক পাঠান",
      "Sending Magic Link ...": "ম্যাজিক লিঙ্ক পাঠানো হচ্ছে...",
      "Send a magic link email": "একটি জাদু লিঙ্ক ইমেল পাঠান",
      "Check your email for the magic link": "ম্যাজিক লিঙ্কের জন্য আপনার ইমেল চেক করুন",
      "Send reset password instructions": "পাসওয়ার্ড রিসেট করার নির্দেশাবলী পাঠান",
      "Sending reset instructions ...": "রিসেট নির্দেশাবলী পাঠানো হচ্ছে...",
      "Forgot your password?": "আপনি কি পাসওয়ার্ড ভুলে গেছেন?",
      "Check your email for the password reset link": "পাসওয়ার্ড রিসেট লিঙ্কের জন্য আপনার ইমেল চেক করুন",
      "New password": "নতুন পাসওয়ার্ড",
      "Your new password": "আপনার নতুন গোপন নাম্বার",
      "Update password": "পাসওয়ার্ড আপডেট করুন",
      "Updating password ...": "পাসওয়ার্ড আপডেট করা হচ্ছে...",
      "Your password has been updated": "আপনার পাসওয়ার্ড আপডেট করা হয়েছে",
      "Your phone number": "আপনার ফোন নম্বর",
      "Token": "টোকেন",
      "Your Otp token": "আপনার Otp টোকেন",
      "Verify token": "টোকেন যাচাই করুন",
      "We need a sponsor": "আমাদের একজন স্পনসর দরকার",
      "Help us break even": "আমাদের এমনকি বিরতি সাহায্য",
      "Help us keep the platform free": "প্ল্যাটফর্মটি বিনামূল্যে রাখতে আমাদের সাহায্য করুন",
      "My images": "আমার ছবি",
      "Gallery": "গ্যালারি",
      "Super Voice": "সুপার ভয়েস",
      "Create speech from text": "পাঠ্য থেকে বক্তৃতা তৈরি করুন",
      "Create speech": "বক্তৃতা তৈরি করুন",
      "This is Gordon Ramsay, what would you like to eat?": "এই গর্ডন রামসে, আপনি কি খেতে চান?",
      "New": "নতুন",
      "Images": "ছবি",
      "GIFs": "জিআইএফ"
    }
  },
  "de": {
    "translation": {
      "Home": "Heim",
      "Logout": "Ausloggen",
      "Profile": "Profil",
      "Onboard": "An Bord",
      "Blog": "Blog",
      "Contact Us": "Kontaktiere uns",
      "Login": "Anmeldung",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "Bitte überprüfen Sie Ihre E-Mail-Adresse, damit Sie die Website weiterhin nutzen können. Wir haben Ihnen eine E-Mail mit einem Link zum Anklicken gesendet.",
      "Resend verification email": "Bestätigungsmail erneut senden",
      "Call us at +1-877-775-5232": "Rufen Sie uns unter +1-877-775-5232 an",
      "Super Pipeline": "Super-Pipeline",
      "About": "Um",
      "Signup Free": "Melde dich kostenlos an",
      "Disclaimer": "Haftungsausschluss",
      "Contact us with any question at": "Kontaktieren Sie uns bei Fragen unter",
      "Call us at": "Ruf uns an um",
      "Plans": "Pläne",
      "Privacy policy": "Datenschutzrichtlinie",
      "Terms of service": "Nutzungsbedingungen",
      "Finished": "Fertig",
      "Continue": "Weitermachen",
      "Start Over": "Von vorn anfangen",
      "Start (100% FREE)": "Starten (100 % KOSTENLOS)",
      "Submit": "Einreichen",
      "Examples": "Beispiele",
      "Select character": "Charakter auswählen",
      "Video Examples": "Videobeispiele",
      "Write a poem about life": "Schreiben Sie ein Gedicht über das Leben",
      "Explain how molecules work": "Erklären Sie, wie Moleküle funktionieren",
      "Write a story about life in Mars": "Schreiben Sie eine Geschichte über das Leben auf dem Mars",
      "Create an entire video from a single prompt": "Erstellen Sie ein ganzes Video aus einer einzigen Eingabeaufforderung",
      "Entertain, inform and inspire": "Unterhalten, informieren und inspirieren",
      "through the power of AI": "durch die Kraft der KI",
      "Create Video!": "Video erstellen!",
      "one more step before you start Just": "Noch ein Schritt, bevor Sie beginnen",
      "First name": "Vorname",
      "What is your first name": "Wie lautet dein Vorname",
      "Please input your first name!": "Bitte geben Sie Ihren Vornamen ein!",
      "Last/family name": "Nachname/Familienname",
      "What is your last/family name": "Wie lautet Ihr Nachname/Familienname?",
      "Please input your last/family name!": "Bitte geben Sie Ihren Nachnamen/Familiennamen ein!",
      "Nickname": "Spitzname",
      "What do you want others to call you?": "Wie möchten Sie, dass andere Sie nennen?",
      "Please input your nickname!": "Bitte geben Sie Ihren Spitznamen ein!",
      "Company": "Unternehmen",
      "What is the company you represent?": "Welches Unternehmen vertreten Sie?",
      "Please input your company!": "Bitte geben Sie Ihr Unternehmen ein!",
      "Phone Number": "Telefonnummer",
      "What is your phone number?": "Was ist Ihre Telefonnummer?",
      "Why we ask for your phone number": "Warum wir Sie nach Ihrer Telefonnummer fragen",
      "Account Recovery": "Konto-Wiederherstellung",
      "If you forget your password or get locked out of your account, a phone number provides an additional way to verify your identity and regain access.": "Wenn Sie Ihr Passwort vergessen oder Ihr Konto gesperrt wird, bietet eine Telefonnummer eine zusätzliche Möglichkeit, Ihre Identität zu überprüfen und wieder Zugriff zu erhalten.",
      "Fraud Prevention": "Betrugsprävention",
      "by asking for phone number it limits fake or fraudulent account creation. It's more challenging for scammers to maintain numerous unique phone numbers than it is for email addresses.": "Durch die Abfrage einer Telefonnummer wird die Erstellung gefälschter oder betrügerischer Konten eingeschränkt. Für Betrüger ist es schwieriger, zahlreiche eindeutige Telefonnummern zu pflegen, als für E-Mail-Adressen.",
      "Communication": "Kommunikation",
      "we may communicate with users directly through phone calls or SMS, whether for support or account issues": "Wir können mit Benutzern direkt über Telefonanrufe oder SMS kommunizieren, sei es bei Support- oder Kontoproblemen",
      "Please input your phone number! Example +18777755232": "Bitte geben Sie Ihre Telefonnummer ein! Beispiel +18777755232",
      "I agree to the": "Ich stimme dem zu",
      "privacy policy": "Datenschutzrichtlinie",
      "terms of service": "Nutzungsbedingungen",
      "disclaimer": "Haftungsausschluss",
      "Just one more step before you start": "Nur noch ein Schritt, bevor Sie beginnen",
      "Phone number": "Telefonnummer",
      "CONTACT US": "KONTAKTIERE UNS",
      "BLOGS": "BLOGS",
      "Published": "Veröffentlicht",
      "Office": "Büro",
      "Super Character": "Super Charakter",
      "Super Meme": "Super Meme",
      "Hello, how are you?": "Hallo, wie geht es dir?",
      "Say something": "Sag etwas",
      "Download video": "Video herunterladen",
      "Check out this cool AI text to video tool": "Schauen Sie sich dieses coole KI-Text-zu-Video-Tool an",
      "Share": "Aktie",
      "Download": "Herunterladen",
      "Select or search a character": "Wählen Sie ein Zeichen aus oder suchen Sie es",
      "Copy link": "Link kopieren",
      "Link copied to clipboard": "Link in die Zwischenablage kopiert",
      "This character cannot create videos for now": "Dieser Charakter kann derzeit keine Videos erstellen",
      "Create GIFs from your prompts": "Erstellen Sie GIFs aus Ihren Eingabeaufforderungen",
      "Create GIF": "GIF erstellen",
      "Monkey sitting on a chair and eating Pasta": "Affe sitzt auf einem Stuhl und isst Pasta",
      "Please wait (30-60 seconds)": "Bitte warten (30-60 Sekunden)",
      "Please wait (30-120 seconds)": "Bitte warten (30-120 Sekunden)",
      "Prompt": "Prompt",
      "The future of A.I. content creation": "Die Zukunft der KI Inhaltserstellung",
      "Try these prompts": "Probieren Sie diese Eingabeaufforderungen aus",
      "Signup FREE": "Melde dich kostenlos an",
      "Cancel": "Stornieren",
      "Error": "Fehler",
      "Hide video": "Video ausblenden",
      "It's 100% FREE!": "Es ist 100 % KOSTENLOS!",
      "Win $100 Gift Cards for the best GIF!": "Gewinnen Sie Geschenkkarten im Wert von 100 $ für das beste GIF!",
      "How can I win?": "Wie kann ich gewinnen?",
      "Create a GIF": "Erstellen Sie ein GIF",
      "Post it on Reddit": "Posten Sie es auf Reddit",
      "Send us a link to your post": "Senden Sie uns einen Link zu Ihrem Beitrag",
      "The GIF with the most upvotes wins a weekly $100 Gift Card!": "Das GIF mit den meisten Upvotes gewinnt eine wöchentliche Geschenkkarte im Wert von 100 $!",
      "Minimum 20 upvotes required": "Mindestens 20 Upvotes erforderlich",
      "Email us your GIF": "Schicken Sie uns Ihr GIF per E-Mail",
      "Create your GIF": "Erstellen Sie Ihr GIF",
      "Submit your GIF to Reddit - Get $100 Gift Card!": "Senden Sie Ihr GIF an Reddit – erhalten Sie eine Geschenkkarte im Wert von 100 $!",
      "Welcome to the chat room with": "Willkommen im Chatraum mit",
      "Click on one of these messages": "Klicken Sie auf eine dieser Nachrichten",
      "Super GIF": "Super GIF",
      "Generating your video": "Generieren Sie Ihr Video",
      "Click this button in order to generate a video from this response": "Klicken Sie auf diese Schaltfläche, um aus dieser Antwort ein Video zu generieren",
      "Start": "Start",
      "You need to signup for free in order to create a GIF": "Um ein GIF zu erstellen, müssen Sie sich kostenlos registrieren",
      "Create video": "Video erstellen",
      "Share video": "Video teilen",
      "Create images from your prompts": "Erstellen Sie Bilder aus Ihren Eingabeaufforderungen",
      "Create image": "Bild erstellen",
      "Please wait (10-30 seconds)": "Bitte warten (10-30 Sekunden)",
      "Download GIF": "GIF herunterladen",
      "Download image": "Bild herunterladen",
      "Super Image": "Super Bild",
      "Super Person": "Super Mensch",
      "Create fake person": "Erstelle eine falsche Person",
      "Select gender": "Wähle Geschlecht",
      "Select age": "Alter auswählen",
      "Select ethnic": "Wählen Sie „ethnisch“ aus",
      "Fake Person Generator": "Fake-Personen-Generator",
      "We create AI tools for content creators": "Wir erstellen KI-Tools für Content-Ersteller",
      "You need to signup for free in order to use our tools": "Um unsere Tools nutzen zu können, müssen Sie sich kostenlos registrieren",
      "Show more": "Zeig mehr",
      "Chat with your favorite characters": "Chatten Sie mit Ihren Lieblingscharakteren",
      "Chat with over 60 characters": "Chatten Sie mit über 60 Charakteren",
      "Style": "Stil",
      "Error sending the chat message. Please try again": "Fehler beim Senden der Chat-Nachricht. Bitte versuche es erneut",
      "Error generating the video": "Fehler beim Generieren des Videos",
      "Twitch stream": "Twitch-Stream",
      "Join the stream and chat with AI Characters": "Treten Sie dem Stream bei und chatten Sie mit AI-Charakteren",
      "Please complete your onboarding in order to keep using our platform for free.": "Bitte schließen Sie Ihr Onboarding ab, um unsere Plattform weiterhin kostenlos nutzen zu können.",
      "Looks like you are a heavy user of our platform!": "Sieht aus, als wären Sie ein intensiver Nutzer unserer Plattform!",
      "You need to complete your onboarding in order to continue": "Sie müssen Ihr Onboarding abschließen, um fortfahren zu können",
      "Complete onboarding": "Komplettes Onboarding",
      "Shape": "Form",
      "Yes, show me ads. I understand why you do it": "Ja, zeig mir Werbung. Ich verstehe, warum du es tust",
      "No, do not show me ads, I hate ads": "Nein, zeig mir keine Werbung, ich hasse Werbung",
      "It costs us a lot of money to operate this platform.": "Der Betrieb dieser Plattform kostet uns viel Geld.",
      "Just one question": "Nur eine Frage",
      "It would really help us if you allowed us to show you some ads.": "Es würde uns wirklich helfen, wenn Sie uns erlauben würden, Ihnen einige Anzeigen zu zeigen.",
      "This way you can keep using our platform for free, while helping us break even.": "Auf diese Weise können Sie unsere Plattform weiterhin kostenlos nutzen und uns gleichzeitig helfen, die Gewinnschwelle zu erreichen.",
      "Email address": "E-Mail-Adresse",
      "Create a Password": "Erstellen Sie ein Passwort",
      "Your email address": "Ihre E-Mail-Adresse",
      "Your password": "Ihr Passwort",
      "Sign up": "Melden Sie sich an",
      "Signing up ...": "Anmelden ...",
      "Sign in with": "Melden Sie sich an mit",
      "Don't have an account? Sign up": "Sie haben noch kein Konto? Melden Sie sich an",
      "Check your email for the confirmation link": "Checken Sie Ihre E-Mails für den Bestätigungslink",
      "Your Password": "Ihr Passwort",
      "Sign in": "anmelden",
      "Signing in ...": "Anmelden ...",
      "Already have an account? Sign in": "Sie haben bereits ein Konto? anmelden",
      "Send Magic Link": "Senden Sie einen magischen Link",
      "Sending Magic Link ...": "Magischer Link wird gesendet ...",
      "Send a magic link email": "Senden Sie eine E-Mail mit einem magischen Link",
      "Check your email for the magic link": "Überprüfen Sie Ihre E-Mails auf den magischen Link",
      "Send reset password instructions": "Anweisungen zum Zurücksetzen des Passworts senden",
      "Sending reset instructions ...": "Anweisungen zum Zurücksetzen werden gesendet ...",
      "Forgot your password?": "Haben Sie Ihr Passwort vergessen?",
      "Check your email for the password reset link": "Überprüfen Sie Ihre E-Mails auf den Link zum Zurücksetzen des Passworts",
      "New password": "Neues Kennwort",
      "Your new password": "Dein neues Passwort",
      "Update password": "Kennwort aktualisieren",
      "Updating password ...": "Passwort wird aktualisiert ...",
      "Your password has been updated": "Dein Passwort wurde aktualisiert",
      "Your phone number": "Deine Telefonnummer",
      "Token": "Zeichen",
      "Your Otp token": "Ihr OTP-Token",
      "Verify token": "Token überprüfen",
      "We need a sponsor": "Wir brauchen einen Sponsor",
      "Help us break even": "Helfen Sie uns, die Gewinnschwelle zu erreichen",
      "Help us keep the platform free": "Helfen Sie uns, die Plattform kostenlos zu halten",
      "My images": "Meine Bilder",
      "Gallery": "Galerie",
      "Super Voice": "Super Stimme",
      "Create speech from text": "Erstellen Sie eine Rede aus Text",
      "Create speech": "Sprache erstellen",
      "This is Gordon Ramsay, what would you like to eat?": "Hier ist Gordon Ramsay, was möchten Sie essen?",
      "New": "Neu",
      "Images": "Bilder",
      "GIFs": "GIFs"
    }
  },
  "hi": {
    "translation": {
      "Home": "घर",
      "Logout": "लॉग आउट",
      "Profile": "प्रोफ़ाइल",
      "Onboard": "सवार",
      "Blog": "ब्लॉग",
      "Contact Us": "संपर्क करें",
      "Login": "लॉग इन करें",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "कृपया जाएं और अपना ईमेल सत्यापित करें ताकि आप वेबसाइट का उपयोग जारी रख सकें। हमने आपको क्लिक करने के लिए एक लिंक के साथ एक ईमेल भेजा है।",
      "Resend verification email": "सत्यापन ईमेल पुनः भेजे",
      "Call us at +1-877-775-5232": "हमें +1-877-775-5232 पर कॉल करें",
      "Super Pipeline": "सुपर पाइपलाइन",
      "About": "के बारे में",
      "Signup Free": "निशुल्क साइन अप करें",
      "Disclaimer": "अस्वीकरण",
      "Contact us with any question at": "किसी भी प्रश्न के लिए हमसे संपर्क करें",
      "Call us at": "हमें पर फोन करो",
      "Plans": "योजनाओं",
      "Privacy policy": "गोपनीयता नीति",
      "Terms of service": "सेवा की शर्तें",
      "Finished": "खत्म",
      "Continue": "जारी रखना",
      "Start Over": "प्रारंभ करें",
      "Start (100% FREE)": "प्रारंभ करें (100% मुफ़्त)",
      "Submit": "जमा करना",
      "Examples": "उदाहरण",
      "Select character": "चरित्र का चयन करें",
      "Video Examples": "वीडियो उदाहरण",
      "Write a poem about life": "जीवन के बारे में एक कविता लिखें",
      "Explain how molecules work": "बताएं कि अणु कैसे काम करते हैं",
      "Write a story about life in Mars": "मंगल ग्रह पर जीवन के बारे में एक कहानी लिखें",
      "Create an entire video from a single prompt": "एक ही प्रॉम्प्ट से संपूर्ण वीडियो बनाएं",
      "Entertain, inform and inspire": "मनोरंजन करें, सूचित करें और प्रेरित करें",
      "through the power of AI": "एआई की शक्ति के माध्यम से",
      "Create Video!": "वीडियो बनाएं!",
      "one more step before you start Just": "शुरू करने से पहले एक और कदम",
      "First name": "पहला नाम",
      "What is your first name": "आपका पहला नाम क्या है",
      "Please input your first name!": "कृपया अपना पहला नाम दर्ज करें!",
      "Last/family name": "अंतिम / पारिवारिक नाम",
      "What is your last/family name": "आपका उपनाम/परिवार का नाम क्या है?",
      "Please input your last/family name!": "कृपया अपना अंतिम/परिवार का नाम दर्ज करें!",
      "Nickname": "उपनाम",
      "What do you want others to call you?": "आप क्या चाहते हैं कि दूसरे आपको क्या कहें?",
      "Please input your nickname!": "कृपया अपना उपनाम दर्ज करें!",
      "Company": "कंपनी",
      "What is the company you represent?": "आप किस कंपनी का प्रतिनिधित्व करते हैं?",
      "Please input your company!": "कृपया अपनी कंपनी इनपुट करें!",
      "Phone Number": "फ़ोन नंबर",
      "What is your phone number?": "आपका फ़ोन नंबर क्या है?",
      "Why we ask for your phone number": "हम आपका फ़ोन नंबर क्यों मांगते हैं?",
      "Account Recovery": "खाता पुनर्प्राप्ति",
      "If you forget your password or get locked out of your account, a phone number provides an additional way to verify your identity and regain access.": "यदि आप अपना पासवर्ड भूल जाते हैं या आपका खाता लॉक हो जाता है, तो एक फ़ोन नंबर आपकी पहचान सत्यापित करने और पहुंच पुनः प्राप्त करने का एक अतिरिक्त तरीका प्रदान करता है।",
      "Fraud Prevention": "धोखाधड़ी रोकथाम",
      "by asking for phone number it limits fake or fraudulent account creation. It's more challenging for scammers to maintain numerous unique phone numbers than it is for email addresses.": "फ़ोन नंबर मांगकर यह नकली या धोखाधड़ी वाले खाता निर्माण को सीमित करता है। घोटालेबाजों के लिए ईमेल पतों की तुलना में कई अद्वितीय फ़ोन नंबर बनाए रखना अधिक चुनौतीपूर्ण है।",
      "Communication": "संचार",
      "we may communicate with users directly through phone calls or SMS, whether for support or account issues": "हम उपयोगकर्ताओं से सीधे फोन कॉल या एसएमएस के माध्यम से संवाद कर सकते हैं, चाहे सहायता के लिए हो या खाता संबंधी मुद्दों के लिए",
      "Please input your phone number! Example +18777755232": "कृपया अपना फ़ोन नंबर दर्ज करें! उदाहरण +18777755232",
      "I agree to the": "मैं करने के लिए सहमत हूं",
      "privacy policy": "गोपनीयता नीति",
      "terms of service": "सेवा की शर्तें",
      "disclaimer": "अस्वीकरण",
      "Just one more step before you start": "शुरू करने से पहले बस एक और कदम",
      "Phone number": "फ़ोन नंबर",
      "CONTACT US": "संपर्क करें",
      "BLOGS": "ब्लॉग",
      "Published": "प्रकाशित",
      "Office": "कार्यालय",
      "Super Character": "सुपर चरित्र",
      "Super Meme": "सुपर मेम",
      "Hello, how are you?": "नमस्ते, आप कैसे हैं?",
      "Say something": "कुछ कहो",
      "Download video": "वीडियो डाउनलोड करें J",
      "Check out this cool AI text to video tool": "इस शानदार AI टेक्स्ट टू वीडियो टूल को देखें",
      "Share": "शेयर करना",
      "Download": "डाउनलोड करना",
      "Select or search a character": "कोई वर्ण चुनें या खोजें",
      "Copy link": "लिंक की प्रतिलिपि करें",
      "Link copied to clipboard": "लिंक को क्लिपबोर्ड पर कॉपी किया गया",
      "This character cannot create videos for now": "यह पात्र अभी वीडियो नहीं बना सकता",
      "Create GIFs from your prompts": "अपने संकेतों से GIF बनाएं",
      "Create GIF": "GIF बनाएं",
      "Monkey sitting on a chair and eating Pasta": "बंदर कुर्सी पर बैठकर पास्ता खा रहा है",
      "Please wait (30-60 seconds)": "कृपया प्रतीक्षा करें (30-60 सेकंड)",
      "Please wait (30-120 seconds)": "कृपया प्रतीक्षा करें (30-120 सेकंड)",
      "Prompt": "तत्पर",
      "The future of A.I. content creation": "ए.आई. का भविष्य सामग्री निर्माण",
      "Try these prompts": "इन संकेतों को आज़माएँ",
      "Signup FREE": "निशुल्क साइन अप करें",
      "Cancel": "रद्द करना",
      "Error": "गलती",
      "Hide video": "वीडियो छिपाएँ",
      "It's 100% FREE!": "यह 100% मुफ़्त है!",
      "Win $100 Gift Cards for the best GIF!": "सर्वोत्तम GIF के लिए $100 उपहार कार्ड जीतें!",
      "How can I win?": "मैं कैसे जीत सकता हूँ?",
      "Create a GIF": "एक GIF बनाएं",
      "Post it on Reddit": "इसे Reddit पर पोस्ट करें",
      "Send us a link to your post": "हमें अपनी पोस्ट का लिंक भेजें",
      "The GIF with the most upvotes wins a weekly $100 Gift Card!": "सर्वाधिक अपवोट वाला GIF साप्ताहिक $100 का उपहार कार्ड जीतता है!",
      "Minimum 20 upvotes required": "न्यूनतम 20 अपवोट आवश्यक हैं",
      "Email us your GIF": "हमें अपना जीआईएफ ईमेल करें",
      "Create your GIF": "अपना GIF बनाएं",
      "Submit your GIF to Reddit - Get $100 Gift Card!": "Reddit पर अपना GIF सबमिट करें - $100 का उपहार कार्ड प्राप्त करें!",
      "Welcome to the chat room with": "चैट रूम में आपका स्वागत है",
      "Click on one of these messages": "इनमें से किसी एक संदेश पर क्लिक करें",
      "Super GIF": "सुपर जीआईएफ",
      "Generating your video": "आपका वीडियो तैयार किया जा रहा है",
      "Click this button in order to generate a video from this response": "इस प्रतिक्रिया से एक वीडियो बनाने के लिए इस बटन पर क्लिक करें",
      "Start": "शुरू",
      "You need to signup for free in order to create a GIF": "GIF बनाने के लिए आपको निःशुल्क साइनअप करना होगा",
      "Create video": "वीडियो बनाएं",
      "Share video": "वीडियो शेयर करें",
      "Create images from your prompts": "अपने संकेतों से छवियां बनाएं",
      "Create image": "चित्र बनाएं",
      "Please wait (10-30 seconds)": "कृपया प्रतीक्षा करें (10-30 सेकंड)",
      "Download GIF": "जीआईएफ डाउनलोड करें",
      "Download image": "छवि डाउनलोड करें",
      "Super Image": "सुपर इमेज",
      "Super Person": "सुपर पर्सन",
      "Create fake person": "नकली व्यक्ति बनाओ",
      "Select gender": "लिंग चुनें",
      "Select age": "आयु चुनें",
      "Select ethnic": "जातीय का चयन करें",
      "Fake Person Generator": "नकली व्यक्ति जेनरेटर",
      "We create AI tools for content creators": "हम सामग्री निर्माताओं के लिए AI उपकरण बनाते हैं",
      "You need to signup for free in order to use our tools": "हमारे टूल का उपयोग करने के लिए आपको निःशुल्क साइनअप करना होगा",
      "Show more": "और दिखाओ",
      "Chat with your favorite characters": "अपने पसंदीदा पात्रों के साथ चैट करें",
      "Chat with over 60 characters": "60 से अधिक पात्रों के साथ चैट करें",
      "Style": "शैली",
      "Error sending the chat message. Please try again": "चैट संदेश भेजने में त्रुटि. कृपया पुन: प्रयास करें",
      "Error generating the video": "वीडियो जनरेट करने में त्रुटि",
      "Twitch stream": "चिकोटी धारा",
      "Join the stream and chat with AI Characters": "स्ट्रीम में शामिल हों और AI कैरेक्टर के साथ चैट करें",
      "Please complete your onboarding in order to keep using our platform for free.": "कृपया हमारे प्लेटफ़ॉर्म का निःशुल्क उपयोग जारी रखने के लिए अपनी ऑनबोर्डिंग पूरी करें।",
      "Looks like you are a heavy user of our platform!": "ऐसा लगता है कि आप हमारे प्लेटफ़ॉर्म के भारी उपयोगकर्ता हैं!",
      "You need to complete your onboarding in order to continue": "जारी रखने के लिए आपको अपनी ऑनबोर्डिंग पूरी करनी होगी",
      "Complete onboarding": "पूर्ण ऑनबोर्डिंग",
      "Shape": "आकार",
      "Yes, show me ads. I understand why you do it": "हाँ, मुझे विज्ञापन दिखाओ। मैं समझता हूं कि आप ऐसा क्यों करते हैं",
      "No, do not show me ads, I hate ads": "नहीं, मुझे विज्ञापन मत दिखाओ, मुझे विज्ञापनों से नफरत है",
      "It costs us a lot of money to operate this platform.": "इस प्लेटफ़ॉर्म को संचालित करने में हमें बहुत पैसा खर्च करना पड़ता है।",
      "Just one question": "सिर्फ एक प्रश्न",
      "It would really help us if you allowed us to show you some ads.": "यदि आप हमें कुछ विज्ञापन दिखाने की अनुमति दें तो यह वास्तव में हमारी मदद करेगा।",
      "This way you can keep using our platform for free, while helping us break even.": "इस तरह आप हमारे प्लेटफ़ॉर्म का मुफ़्त में उपयोग करना जारी रख सकते हैं, साथ ही हमें संतुलन बनाए रखने में भी मदद कर सकते हैं।",
      "Email address": "मेल पता",
      "Create a Password": "एक पासवर्ड बनाएं",
      "Your email address": "आपका ईमेल पता",
      "Your password": "आपका पासवर्ड",
      "Sign up": "साइन अप करें",
      "Signing up ...": "साइन उप हो रहा है ...",
      "Sign in with": "के साथ साइन इन करें",
      "Don't have an account? Sign up": "कोई खाता नहीं है? साइन अप करें",
      "Check your email for the confirmation link": "लिंक की पुष्टि के लिए अपने ई - मेल को चेक करें",
      "Your Password": "आपका पासवर्ड",
      "Sign in": "दाखिल करना",
      "Signing in ...": "इन कर रहे हैं ...",
      "Already have an account? Sign in": "क्या आपके पास पहले से एक खाता मौजूद है? दाखिल करना",
      "Send Magic Link": "मैजिक लिंक भेजें",
      "Sending Magic Link ...": "जादुई लिंक भेजा जा रहा है...",
      "Send a magic link email": "एक जादुई लिंक ईमेल भेजें",
      "Check your email for the magic link": "जादुई लिंक के लिए अपना ईमेल जांचें",
      "Send reset password instructions": "पासवर्ड रीसेट निर्देश भेजें",
      "Sending reset instructions ...": "रीसेट निर्देश भेजा जा रहा है...",
      "Forgot your password?": "अपना कूट शब्द भूल गए?",
      "Check your email for the password reset link": "पासवर्ड रीसेट लिंक के लिए अपना ईमेल जांचें",
      "New password": "नया पासवर्ड",
      "Your new password": "आपका नया पासवर्ड",
      "Update password": "पासवर्ड अपडेट करें",
      "Updating password ...": "पासवर्ड अपडेट हो रहा है...",
      "Your password has been updated": "आपका पासवर्ड बदला जा चुका है",
      "Your phone number": "आपका फोन नंबर",
      "Token": "टोकन",
      "Your Otp token": "आपका ओटीपी टोकन",
      "Verify token": "टोकन सत्यापित करें",
      "We need a sponsor": "हमें एक प्रायोजक की जरूरत है",
      "Help us break even": "हमें संतुलन तोड़ने में मदद करें",
      "Help us keep the platform free": "प्लेटफ़ॉर्म को मुक्त रखने में हमारी सहायता करें",
      "My images": "मेरी छवियां",
      "Gallery": "गैलरी",
      "Super Voice": "सुपर आवाज",
      "Create speech from text": "पाठ से भाषण बनाएँ",
      "Create speech": "भाषण बनाएँ",
      "This is Gordon Ramsay, what would you like to eat?": "मैं गॉर्डन रामसे हूं, आप क्या खाना चाहेंगे?",
      "New": "नया",
      "Images": "इमेजिस",
      "GIFs": "जीआईएफ"
    }
  },
  "zh": {
    "translation": {
      "Home": "家",
      "Logout": "登出",
      "Profile": "轮廓",
      "Onboard": "在船上",
      "Blog": "博客",
      "Contact Us": "联系我们",
      "Login": "登录",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "请去验证您的电子邮件，以便您可以继续使用该网站。我们向您发送了一封电子邮件，其中包含可点击的链接。",
      "Resend verification email": "重新发送验证邮件",
      "Call us at +1-877-775-5232": "请致电 +1-877-775-5232 联系我们",
      "Super Pipeline": "超级管道",
      "About": "关于",
      "Signup Free": "免费注册",
      "Disclaimer": "免责声明",
      "Contact us with any question at": "如有任何问题请联系我们",
      "Call us at": "请致电我们",
      "Plans": "计划",
      "Privacy policy": "隐私政策",
      "Terms of service": "服务条款",
      "Finished": "完成的",
      "Continue": "继续",
      "Start Over": "重来",
      "Start (100% FREE)": "开始（100% 免费）",
      "Submit": "提交",
      "Examples": "例子",
      "Select character": "选择角色",
      "Video Examples": "视频示例",
      "Write a poem about life": "写一首关于人生的诗",
      "Explain how molecules work": "解释分子如何工作",
      "Write a story about life in Mars": "写一个关于火星生命的故事",
      "Create an entire video from a single prompt": "从单个提示创建整个视频",
      "Entertain, inform and inspire": "娱乐、告知和启发",
      "through the power of AI": "通过人工智能的力量",
      "Create Video!": "创建视频！",
      "one more step before you start Just": "开始前还有一步",
      "First name": "名",
      "What is your first name": "你的名字是什么",
      "Please input your first name!": "请输入您的名字！",
      "Last/family name": "姓氏/姓氏",
      "What is your last/family name": "您的姓氏是什么",
      "Please input your last/family name!": "请输入您的姓氏！",
      "Nickname": "昵称",
      "What do you want others to call you?": "你希望别人怎么称呼你？",
      "Please input your nickname!": "请输入您的昵称！",
      "Company": "公司",
      "What is the company you represent?": "你所代表的公司是什么？",
      "Please input your company!": "请输入您的公司！",
      "Phone Number": "电话号码",
      "What is your phone number?": "你的电话号码是什么？",
      "Why we ask for your phone number": "为什么我们要求您提供电话号码",
      "Account Recovery": "帐户恢复",
      "If you forget your password or get locked out of your account, a phone number provides an additional way to verify your identity and regain access.": "如果您忘记密码或帐户被锁定，电话号码可以提供另一种方式来验证您的身份并重新获得访问权限。",
      "Fraud Prevention": "预防诈骗",
      "by asking for phone number it limits fake or fraudulent account creation. It's more challenging for scammers to maintain numerous unique phone numbers than it is for email addresses.": "通过询问电话号码，它可以限制虚假或欺诈性帐户的创建。对于诈骗者来说，维护大量唯一的电话号码比维护电子邮件地址更具挑战性。",
      "Communication": "沟通",
      "we may communicate with users directly through phone calls or SMS, whether for support or account issues": "我们可能会通过电话或短信直接与用户沟通，无论是支持还是帐户问题",
      "Please input your phone number! Example +18777755232": "请输入您的电话号码！例如+18777755232",
      "I agree to the": "我同意",
      "privacy policy": "隐私政策",
      "terms of service": "服务条款",
      "disclaimer": "免责声明",
      "Just one more step before you start": "开始前只需再执行一步",
      "Phone number": "电话号码",
      "CONTACT US": "联系我们",
      "BLOGS": "博客",
      "Published": "已发表",
      "Office": "办公室",
      "Super Character": "超级人物",
      "Super Meme": "超级模因",
      "Hello, how are you?": "你好吗？",
      "Say something": "说些什么",
      "Download video": "下载视频",
      "Check out this cool AI text to video tool": "看看这个很酷的 AI 文本转视频工具",
      "Share": "分享",
      "Download": "下载",
      "Select or search a character": "选择或搜索字符",
      "Copy link": "复制链接",
      "Link copied to clipboard": "链接已复制到剪贴板",
      "This character cannot create videos for now": "该角色暂时无法创建视频",
      "Create GIFs from your prompts": "根据提示创建 GIF",
      "Create GIF": "创建 GIF",
      "Monkey sitting on a chair and eating Pasta": "猴子坐在椅子上吃意大利面",
      "Please wait (30-60 seconds)": "请稍候（30-60 秒）",
      "Please wait (30-120 seconds)": "请稍候（30-120 秒）",
      "Prompt": "迅速的",
      "The future of A.I. content creation": "人工智能的未来内容创作",
      "Try these prompts": "尝试这些提示",
      "Signup FREE": "免费注册",
      "Cancel": "取消",
      "Error": "错误",
      "Hide video": "隐藏视频",
      "It's 100% FREE!": "100% 免费！",
      "Win $100 Gift Cards for the best GIF!": "赢取 100 美元礼品卡以获得最佳 GIF！",
      "How can I win?": "我怎样才能赢？",
      "Create a GIF": "创建一个 GIF",
      "Post it on Reddit": "将其发布到 Reddit 上",
      "Send us a link to your post": "向我们发送您帖子的链接",
      "The GIF with the most upvotes wins a weekly $100 Gift Card!": "获得最多支持的 GIF 将赢得每周价值 100 美元的礼品卡！",
      "Minimum 20 upvotes required": "至少需要 20 票赞成",
      "Email us your GIF": "通过电子邮件给我们发送您的 GIF",
      "Create your GIF": "创建您的 GIF",
      "Submit your GIF to Reddit - Get $100 Gift Card!": "将您的 GIF 提交到 Reddit - 获得 100 美元礼品卡！",
      "Welcome to the chat room with": "欢迎来到聊天室",
      "Click on one of these messages": "单击其中一条消息",
      "Super GIF": "超级动图",
      "Generating your video": "生成您的视频",
      "Click this button in order to generate a video from this response": "单击此按钮可根据此回复生成视频",
      "Start": "开始",
      "You need to signup for free in order to create a GIF": "您需要免费注册才能创建 GIF",
      "Create video": "创建视频",
      "Share video": "分享视频",
      "Create images from your prompts": "根据提示创建图像",
      "Create image": "创建图像",
      "Please wait (10-30 seconds)": "请稍候（10-30 秒）",
      "Download GIF": "下载动图",
      "Download image": "下载图片",
      "Super Image": "超级形象",
      "Super Person": "超级人物",
      "Create fake person": "创造假人",
      "Select gender": "选择性别",
      "Select age": "选择年龄",
      "Select ethnic": "选择种族",
      "Fake Person Generator": "假人生成器",
      "We create AI tools for content creators": "我们为内容创作者创建人工智能工具",
      "You need to signup for free in order to use our tools": "您需要免费注册才能使用我们的工具",
      "Show more": "展示更多",
      "Chat with your favorite characters": "与您最喜欢的角色聊天",
      "Chat with over 60 characters": "与 60 多个角色聊天",
      "Style": "风格",
      "Error sending the chat message. Please try again": "发送聊天消息时出错。请再试一次",
      "Error generating the video": "生成视频时出错",
      "Twitch stream": "抽搐流",
      "Join the stream and chat with AI Characters": "加入直播并与 AI 角色聊天",
      "Please complete your onboarding in order to keep using our platform for free.": "请完成您的入职培训，以便继续免费使用我们的平台。",
      "Looks like you are a heavy user of our platform!": "看来您是我们平台的重度用户！",
      "You need to complete your onboarding in order to continue": "您需要完成入职培训才能继续",
      "Complete onboarding": "完成入职培训",
      "Shape": "形状",
      "Yes, show me ads. I understand why you do it": "是的，给我看广告。我明白你为什么这样做",
      "No, do not show me ads, I hate ads": "不，不要给我看广告，我讨厌广告",
      "It costs us a lot of money to operate this platform.": "我们运营这个平台需要花费很多钱。",
      "Just one question": "只有一个问题",
      "It would really help us if you allowed us to show you some ads.": "如果您允许我们向您展示一些广告，这对我们确实有帮助。",
      "This way you can keep using our platform for free, while helping us break even.": "这样您就可以继续免费使用我们的平台，同时帮助我们实现收支平衡。",
      "Email address": "电子邮件地址",
      "Create a Password": "创建密码",
      "Your email address": "您的电子邮件地址",
      "Your password": "你的密码",
      "Sign up": "报名",
      "Signing up ...": "报名 ...",
      "Sign in with": "登陆使用",
      "Don't have an account? Sign up": "没有帐户？报名",
      "Check your email for the confirmation link": "查看您邮件里的确认链接",
      "Your Password": "你的密码",
      "Sign in": "登入",
      "Signing in ...": "登录中 ...",
      "Already have an account? Sign in": "已经有帐户？登入",
      "Send Magic Link": "发送魔法链接",
      "Sending Magic Link ...": "正在发送魔法链接...",
      "Send a magic link email": "发送魔法链接电子邮件",
      "Check your email for the magic link": "检查您的电子邮件中是否有神奇链接",
      "Send reset password instructions": "发送重置密码说明",
      "Sending reset instructions ...": "正在发送重置指令...",
      "Forgot your password?": "忘记密码了吗？",
      "Check your email for the password reset link": "检查您的电子邮件中的密码重置链接",
      "New password": "新密码",
      "Your new password": "您的新密码",
      "Update password": "更新密码",
      "Updating password ...": "正在更新密码...",
      "Your password has been updated": "您的密码已更新",
      "Your phone number": "你的电话号码",
      "Token": "代币",
      "Your Otp token": "您的 OTP 令牌",
      "Verify token": "验证令牌",
      "We need a sponsor": "我们需要一个赞助商",
      "Help us break even": "帮助我们收支平衡",
      "Help us keep the platform free": "帮助我们保持平台免费",
      "My images": "我的图片",
      "Gallery": "画廊",
      "Super Voice": "超级声音",
      "Create speech from text": "从文本创建语音",
      "Create speech": "创建演讲",
      "This is Gordon Ramsay, what would you like to eat?": "我是戈登拉姆齐，你想吃什么？",
      "New": "新的",
      "Images": "图片",
      "GIFs": "动图"
    }
  },
  "es": {
    "translation": {
      "Home": "Hogar",
      "Logout": "Cerrar sesión",
      "Profile": "Perfil",
      "Onboard": "A bordo",
      "Blog": "Blog",
      "Contact Us": "Contáctenos",
      "Login": "Acceso",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "Vaya y verifique su correo electrónico para poder continuar usando el sitio web. Le enviamos un correo electrónico con un enlace para hacer clic.",
      "Resend verification email": "Reenviar correo electrónico de verificación",
      "Call us at +1-877-775-5232": "Llámanos al +1-877-775-5232",
      "Super Pipeline": "Súper oleoducto",
      "About": "Acerca de",
      "Signup Free": "Regístrate gratis",
      "Disclaimer": "Descargo de responsabilidad",
      "Contact us with any question at": "Contáctanos con cualquier pregunta en",
      "Call us at": "Llamenos al",
      "Plans": "Planes",
      "Privacy policy": "Política de privacidad",
      "Terms of service": "Términos de servicio",
      "Finished": "Finalizado",
      "Continue": "Continuar",
      "Start Over": "Comenzar de nuevo",
      "Start (100% FREE)": "Empezar (100% GRATIS)",
      "Submit": "Entregar",
      "Examples": "Ejemplos",
      "Select character": "Selecciona personaje",
      "Video Examples": "Ejemplos de vídeos",
      "Write a poem about life": "Escribe un poema sobre la vida.",
      "Explain how molecules work": "Explica cómo funcionan las moléculas.",
      "Write a story about life in Mars": "Escribe una historia sobre la vida en Marte.",
      "Create an entire video from a single prompt": "Crea un vídeo completo a partir de un solo mensaje",
      "Entertain, inform and inspire": "Entretener, informar e inspirar",
      "through the power of AI": "a través del poder de la IA",
      "Create Video!": "¡Crea vídeo!",
      "one more step before you start Just": "un paso más antes de empezar",
      "First name": "Nombre de pila",
      "What is your first name": "Cuál es tu primer nombre",
      "Please input your first name!": "¡Por favor ingrese su nombre!",
      "Last/family name": "Apellido/apellido",
      "What is your last/family name": "¿Cuál es tu apellido/apellido?",
      "Please input your last/family name!": "¡Por favor ingrese su apellido/apellido!",
      "Nickname": "Apodo",
      "What do you want others to call you?": "¿Cómo quieres que te llamen los demás?",
      "Please input your nickname!": "¡Por favor ingresa tu apodo!",
      "Company": "Compañía",
      "What is the company you represent?": "¿Cuál es la empresa que representa?",
      "Please input your company!": "¡Por favor ingrese su empresa!",
      "Phone Number": "Número de teléfono",
      "What is your phone number?": "¿Cuál es tu número de teléfono?",
      "Why we ask for your phone number": "Por qué te pedimos tu número de teléfono",
      "Account Recovery": "Recuperación de Cuenta",
      "If you forget your password or get locked out of your account, a phone number provides an additional way to verify your identity and regain access.": "Si olvida su contraseña o se bloquea su cuenta, un número de teléfono proporciona una forma adicional de verificar su identidad y recuperar el acceso.",
      "Fraud Prevention": "Prevención del fraude",
      "by asking for phone number it limits fake or fraudulent account creation. It's more challenging for scammers to maintain numerous unique phone numbers than it is for email addresses.": "al solicitar el número de teléfono, se limita la creación de cuentas falsas o fraudulentas. Para los estafadores es más difícil mantener numerosos números de teléfono únicos que las direcciones de correo electrónico.",
      "Communication": "Comunicación",
      "we may communicate with users directly through phone calls or SMS, whether for support or account issues": "Podemos comunicarnos con los usuarios directamente a través de llamadas telefónicas o SMS, ya sea para soporte o problemas de cuenta.",
      "Please input your phone number! Example +18777755232": "¡Por favor ingrese su número de teléfono! Ejemplo +18777755232",
      "I agree to the": "Estoy de acuerdo con la",
      "privacy policy": "política de privacidad",
      "terms of service": "términos de servicio",
      "disclaimer": "descargo de responsabilidad",
      "Just one more step before you start": "Sólo un paso más antes de empezar",
      "Phone number": "Número de teléfono",
      "CONTACT US": "CONTÁCTENOS",
      "BLOGS": "BLOGS",
      "Published": "Publicado",
      "Office": "Oficina",
      "Super Character": "Súper personaje",
      "Super Meme": "Súper meme",
      "Hello, how are you?": "¿Hola, cómo estás?",
      "Say something": "Di algo",
      "Download video": "Descargar video",
      "Check out this cool AI text to video tool": "Echa un vistazo a esta genial herramienta de texto a vídeo con IA",
      "Share": "Compartir",
      "Download": "Descargar",
      "Select or search a character": "Seleccionar o buscar un personaje",
      "Copy link": "Copiar link",
      "Link copied to clipboard": "Enlace copiado al portapapeles",
      "This character cannot create videos for now": "Este personaje no puede crear videos por ahora.",
      "Create GIFs from your prompts": "Crea GIF a partir de tus indicaciones",
      "Create GIF": "Crear GIF",
      "Monkey sitting on a chair and eating Pasta": "Mono sentado en una silla y comiendo pasta",
      "Please wait (30-60 seconds)": "Espere (30-60 segundos)",
      "Please wait (30-120 seconds)": "Espere (30-120 segundos)",
      "Prompt": "Inmediato",
      "The future of A.I. content creation": "El futuro de la IA creación de contenido",
      "Try these prompts": "Pruebe estas indicaciones",
      "Signup FREE": "Regístrate gratis",
      "Cancel": "Cancelar",
      "Error": "Error",
      "Hide video": "Ocultar vídeo",
      "It's 100% FREE!": "¡Es 100% GRATIS!",
      "Win $100 Gift Cards for the best GIF!": "¡Gana tarjetas de regalo de $100 para obtener el mejor GIF!",
      "How can I win?": "¿Cómo puedo ganar?",
      "Create a GIF": "Crear un GIF",
      "Post it on Reddit": "Publicarlo en Reddit",
      "Send us a link to your post": "Envíanos un enlace a tu publicación.",
      "The GIF with the most upvotes wins a weekly $100 Gift Card!": "¡El GIF con más votos gana una tarjeta de regalo semanal de $100!",
      "Minimum 20 upvotes required": "Se requieren un mínimo de 20 votos a favor",
      "Email us your GIF": "Envíanos tu GIF por correo electrónico",
      "Create your GIF": "Crea tu GIF",
      "Submit your GIF to Reddit - Get $100 Gift Card!": "Envía tu GIF a Reddit: ¡obtén una tarjeta de regalo de $100!",
      "Welcome to the chat room with": "Bienvenido a la sala de chat con",
      "Click on one of these messages": "Haga clic en uno de estos mensajes",
      "Super GIF": "Súper GIF",
      "Generating your video": "Generando tu vídeo",
      "Click this button in order to generate a video from this response": "Haga clic en este botón para generar un video a partir de esta respuesta.",
      "Start": "Comenzar",
      "You need to signup for free in order to create a GIF": "Necesitas registrarte gratis para poder crear un GIF",
      "Create video": "Crear vídeo",
      "Share video": "Compartir video",
      "Create images from your prompts": "Crea imágenes a partir de tus indicaciones",
      "Create image": "Crear imagen",
      "Please wait (10-30 seconds)": "Espere (10-30 segundos)",
      "Download GIF": "Descargar GIF",
      "Download image": "Descargar imagen",
      "Super Image": "Súper imagen",
      "Super Person": "súper persona",
      "Create fake person": "crear persona falsa",
      "Select gender": "Seleccione género",
      "Select age": "Selecciona edad",
      "Select ethnic": "Seleccionar étnico",
      "Fake Person Generator": "Generador de personas falsas",
      "We create AI tools for content creators": "Creamos herramientas de IA para creadores de contenido",
      "You need to signup for free in order to use our tools": "Debes registrarte gratis para poder utilizar nuestras herramientas.",
      "Show more": "Mostrar más",
      "Chat with your favorite characters": "Chatea con tus personajes favoritos",
      "Chat with over 60 characters": "Chatea con más de 60 personajes",
      "Style": "Estilo",
      "Error sending the chat message. Please try again": "Error al enviar el mensaje de chat. Inténtalo de nuevo",
      "Error generating the video": "Error al generar el vídeo",
      "Twitch stream": "transmisión de contracción",
      "Join the stream and chat with AI Characters": "Únase a la transmisión y charle con personajes de IA",
      "Please complete your onboarding in order to keep using our platform for free.": "Complete su incorporación para seguir usando nuestra plataforma de forma gratuita.",
      "Looks like you are a heavy user of our platform!": "¡Parece que eres un gran usuario de nuestra plataforma!",
      "You need to complete your onboarding in order to continue": "Debes completar tu incorporación para poder continuar.",
      "Complete onboarding": "Incorporación completa",
      "Shape": "Forma",
      "Yes, show me ads. I understand why you do it": "Sí, muéstrame anuncios. Entiendo por qué lo haces",
      "No, do not show me ads, I hate ads": "No, no me muestres anuncios, odio los anuncios.",
      "It costs us a lot of money to operate this platform.": "Nos cuesta mucho dinero operar esta plataforma.",
      "Just one question": "Solo una pregunta",
      "It would really help us if you allowed us to show you some ads.": "Nos ayudaría mucho si nos permitiera mostrarle algunos anuncios.",
      "This way you can keep using our platform for free, while helping us break even.": "De esta manera, podrá seguir usando nuestra plataforma de forma gratuita y, al mismo tiempo, ayudarnos a alcanzar el punto de equilibrio.",
      "Email address": "Dirección de correo electrónico",
      "Create a Password": "Crear una contraseña",
      "Your email address": "Su dirección de correo electrónico",
      "Your password": "Tu contraseña",
      "Sign up": "Inscribirse",
      "Signing up ...": "Registrarse...",
      "Sign in with": "Inicia sesión con",
      "Don't have an account? Sign up": "¿No tienes una cuenta? Inscribirse",
      "Check your email for the confirmation link": "Revisa tu correo electrónico para el link de confirmación",
      "Your Password": "Tu contraseña",
      "Sign in": "Iniciar sesión",
      "Signing in ...": "Iniciando sesión ...",
      "Already have an account? Sign in": "¿Ya tienes una cuenta? Iniciar sesión",
      "Send Magic Link": "Enviar enlace mágico",
      "Sending Magic Link ...": "Enviando enlace mágico...",
      "Send a magic link email": "Enviar un correo electrónico con enlace mágico",
      "Check your email for the magic link": "Revisa tu correo electrónico para ver el enlace mágico.",
      "Send reset password instructions": "Enviar instrucciones para restablecer la contraseña",
      "Sending reset instructions ...": "Enviando instrucciones de reinicio...",
      "Forgot your password?": "¿Olvidaste tu contraseña?",
      "Check your email for the password reset link": "Revise su correo electrónico para ver el enlace para restablecer su contraseña",
      "New password": "Nueva contraseña",
      "Your new password": "Tu nueva contraseña",
      "Update password": "Actualiza contraseña",
      "Updating password ...": "Actualizando contraseña...",
      "Your password has been updated": "Tu contraseña ha sido actualizada",
      "Your phone number": "Su número de teléfono",
      "Token": "Simbólico",
      "Your Otp token": "Tu token OTP",
      "Verify token": "Verificar token",
      "We need a sponsor": "Necesitamos un patrocinador",
      "Help us break even": "Ayúdanos a alcanzar el punto de equilibrio",
      "Help us keep the platform free": "Ayúdanos a mantener la plataforma gratuita",
      "My images": "Mis imagenes",
      "Gallery": "Galería",
      "Super Voice": "Súper Voz",
      "Create speech from text": "Crear voz a partir de texto",
      "Create speech": "crear discurso",
      "This is Gordon Ramsay, what would you like to eat?": "Este es Gordon Ramsay, ¿qué te gustaría comer?",
      "New": "Nuevo",
      "Images": "Imágenes",
      "GIFs": "GIF"
    }
  },
  "pl": {
    "translation": {
      "Home": "Dom",
      "Logout": "Wyloguj",
      "Profile": "Profil",
      "Onboard": "Na pokładzie",
      "Blog": "Bloga",
      "Contact Us": "Skontaktuj się z nami",
      "Login": "Zaloguj sie",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "Przejdź i zweryfikuj swój adres e-mail, aby móc nadal korzystać z witryny. Wysłaliśmy do Ciebie e-mail z linkiem, w który możesz kliknąć.",
      "Resend verification email": "Ponownie wysłać e-mail weryfikacyjny",
      "Call us at +1-877-775-5232": "Zadzwoń do nas pod numer +1-877-775-5232",
      "Super Pipeline": "Super rurociąg",
      "About": "O",
      "Signup Free": "Zarejestruj się za darmo",
      "Disclaimer": "Zastrzeżenie",
      "Contact us with any question at": "Skontaktuj się z nami w przypadku jakichkolwiek pytań pod adresem",
      "Call us at": "Zadzwoń do nas o",
      "Plans": "Plany",
      "Privacy policy": "Polityka prywatności",
      "Terms of service": "Warunki usługi",
      "Finished": "Skończone",
      "Continue": "Kontynuować",
      "Start Over": "Zacząć od nowa",
      "Start (100% FREE)": "Rozpocznij (100% ZA DARMO)",
      "Submit": "Składać",
      "Examples": "Przykłady",
      "Select character": "Wybierz znak",
      "Video Examples": "Przykłady wideo",
      "Write a poem about life": "Napisz wiersz o życiu",
      "Explain how molecules work": "Wyjaśnij, jak działają cząsteczki",
      "Write a story about life in Mars": "Napisz opowiadanie o życiu na Marsie",
      "Create an entire video from a single prompt": "Utwórz cały film z jednego monitu",
      "Entertain, inform and inspire": "Zabawiaj, informuj i inspiruj",
      "through the power of AI": "dzięki mocy sztucznej inteligencji",
      "Create Video!": "Utwórz wideo!",
      "one more step before you start Just": "jeszcze jeden krok, zanim zaczniesz",
      "First name": "Imię",
      "What is your first name": "Jakie jest Twoje pierwsze imię",
      "Please input your first name!": "Proszę wpisać swoje imię!",
      "Last/family name": "Nazwisko/nazwisko",
      "What is your last/family name": "Jakie jest Twoje nazwisko/nazwisko",
      "Please input your last/family name!": "Proszę wpisać swoje nazwisko/nazwisko!",
      "Nickname": "Przezwisko",
      "What do you want others to call you?": "Jak chcesz, żeby inni Cię nazywali?",
      "Please input your nickname!": "Proszę wpisać swój nick!",
      "Company": "Firma",
      "What is the company you represent?": "Jaką firmę reprezentujesz?",
      "Please input your company!": "Proszę wpisać swoją firmę!",
      "Phone Number": "Numer telefonu",
      "What is your phone number?": "Jaki jest Twój numer telefonu?",
      "Why we ask for your phone number": "Dlaczego prosimy o Twój numer telefonu",
      "Account Recovery": "odzyskiwanie konta",
      "If you forget your password or get locked out of your account, a phone number provides an additional way to verify your identity and regain access.": "Jeśli zapomnisz hasła lub utracisz dostęp do konta, numer telefonu zapewnia dodatkową możliwość weryfikacji Twojej tożsamości i odzyskania dostępu.",
      "Fraud Prevention": "Zapobieganie oszustwom",
      "by asking for phone number it limits fake or fraudulent account creation. It's more challenging for scammers to maintain numerous unique phone numbers than it is for email addresses.": "prosząc o numer telefonu, ogranicza możliwość tworzenia fałszywych lub oszukańczych kont. Utrzymanie wielu unikalnych numerów telefonów jest dla oszustów większym wyzwaniem niż w przypadku adresów e-mail.",
      "Communication": "Komunikacja",
      "we may communicate with users directly through phone calls or SMS, whether for support or account issues": "możemy komunikować się z użytkownikami bezpośrednio poprzez rozmowy telefoniczne lub SMS-y, zarówno w sprawie pomocy technicznej, jak i problemów z kontem",
      "Please input your phone number! Example +18777755232": "Proszę podać swój numer telefonu! Przykład +18777755232",
      "I agree to the": "zgadzam się z",
      "privacy policy": "Polityka prywatności",
      "terms of service": "warunki usługi",
      "disclaimer": "zastrzeżenie",
      "Just one more step before you start": "Jeszcze tylko jeden krok, zanim zaczniesz",
      "Phone number": "Numer telefonu",
      "CONTACT US": "SKONTAKTUJ SIĘ Z NAMI",
      "BLOGS": "BLOGI",
      "Published": "Opublikowany",
      "Office": "Biuro",
      "Super Character": "Super charakter",
      "Super Meme": "Super mem",
      "Hello, how are you?": "Witam, jak się masz?",
      "Say something": "Powiedz coś",
      "Download video": "Ściągnij wideo",
      "Check out this cool AI text to video tool": "Sprawdź to fajne narzędzie AI do zamiany tekstu na wideo",
      "Share": "Udział",
      "Download": "Pobierać",
      "Select or search a character": "Wybierz lub wyszukaj znak",
      "Copy link": "Skopiuj link",
      "Link copied to clipboard": "Link skopiowany do schowka",
      "This character cannot create videos for now": "Ta postać nie może na razie tworzyć filmów",
      "Create GIFs from your prompts": "Twórz GIF-y na podstawie podpowiedzi",
      "Create GIF": "Utwórz GIF",
      "Monkey sitting on a chair and eating Pasta": "Małpa siedzi na krześle i je makaron",
      "Please wait (30-60 seconds)": "Proszę czekać (30-60 sekund)",
      "Please wait (30-120 seconds)": "Proszę czekać (30-120 sekund)",
      "Prompt": "Podpowiedź",
      "The future of A.I. content creation": "Przyszłość AI Tworzenie treści",
      "Try these prompts": "Wypróbuj te podpowiedzi",
      "Signup FREE": "Zarejestruj się za darmo",
      "Cancel": "Anulować",
      "Error": "Błąd",
      "Hide video": "Ukryj wideo",
      "It's 100% FREE!": "To jest w 100% BEZPŁATNE!",
      "Win $100 Gift Cards for the best GIF!": "Wygraj karty podarunkowe o wartości 100 $ na najlepszy GIF!",
      "How can I win?": "Jak mogę wygrać?",
      "Create a GIF": "Utwórz GIF",
      "Post it on Reddit": "Opublikuj to na Reddicie",
      "Send us a link to your post": "Wyślij nam link do swojego wpisu",
      "The GIF with the most upvotes wins a weekly $100 Gift Card!": "GIF z największą liczbą głosów „za” wygrywa cotygodniową kartę podarunkową o wartości 100 USD!",
      "Minimum 20 upvotes required": "Wymagane minimum 20 głosów „za”.",
      "Email us your GIF": "Wyślij nam swój GIF",
      "Create your GIF": "Stwórz swojego GIF-a",
      "Submit your GIF to Reddit - Get $100 Gift Card!": "Prześlij swój GIF do Reddit – zdobądź kartę podarunkową o wartości 100 $!",
      "Welcome to the chat room with": "Witamy w pokoju rozmów z",
      "Click on one of these messages": "Kliknij jedną z tych wiadomości",
      "Super GIF": "Super GIF",
      "Generating your video": "Generowanie wideo",
      "Click this button in order to generate a video from this response": "Kliknij ten przycisk, aby wygenerować film na podstawie tej odpowiedzi",
      "Start": "Początek",
      "You need to signup for free in order to create a GIF": "Aby utworzyć GIF, musisz się zarejestrować bezpłatnie",
      "Create video": "Utwórz wideo",
      "Share video": "Udostępnij film",
      "Create images from your prompts": "Twórz obrazy na podstawie podpowiedzi",
      "Create image": "Stwórz obraz",
      "Please wait (10-30 seconds)": "Proszę czekać (10-30 sekund)",
      "Download GIF": "Pobierz GIF-a",
      "Download image": "Pobierz obraz",
      "Super Image": "Super obraz",
      "Super Person": "Super Osoba",
      "Create fake person": "Stwórz fałszywą osobę",
      "Select gender": "Wybierz płeć",
      "Select age": "Wybierz wiek",
      "Select ethnic": "Wybierz etniczne",
      "Fake Person Generator": "Generator fałszywych osób",
      "We create AI tools for content creators": "Tworzymy narzędzia AI dla twórców treści",
      "You need to signup for free in order to use our tools": "Aby móc korzystać z naszych narzędzi, musisz się zarejestrować bezpłatnie",
      "Show more": "Pokaż więcej",
      "Chat with your favorite characters": "Rozmawiaj ze swoimi ulubionymi postaciami",
      "Chat with over 60 characters": "Czatuj z ponad 60 znakami",
      "Style": "Styl",
      "Error sending the chat message. Please try again": "Błąd podczas wysyłania wiadomości na czacie. Proszę spróbuj ponownie",
      "Error generating the video": "Błąd podczas generowania filmu",
      "Twitch stream": "Strumień na Twitchu",
      "Join the stream and chat with AI Characters": "Dołącz do transmisji i rozmawiaj z postaciami AI",
      "Please complete your onboarding in order to keep using our platform for free.": "Aby nadal korzystać z naszej platformy za darmo, prosimy o dokończenie procesu wdrożenia.",
      "Looks like you are a heavy user of our platform!": "Wygląda na to, że jesteś częstym użytkownikiem naszej platformy!",
      "You need to complete your onboarding in order to continue": "Aby kontynuować, musisz ukończyć proces wdrażania",
      "Complete onboarding": "Zakończ wdrażanie",
      "Shape": "Kształt",
      "Yes, show me ads. I understand why you do it": "Tak, pokazuj mi reklamy. Rozumiem dlaczego to robisz",
      "No, do not show me ads, I hate ads": "Nie, nie pokazuj mi reklam, nienawidzę reklam",
      "It costs us a lot of money to operate this platform.": "Obsługa tej platformy kosztuje nas mnóstwo pieniędzy.",
      "Just one question": "Tylko jedno pytanie",
      "It would really help us if you allowed us to show you some ads.": "Bardzo by nam pomogło, gdybyś pozwolił nam wyświetlać Ci reklamy.",
      "This way you can keep using our platform for free, while helping us break even.": "W ten sposób możesz nadal korzystać z naszej platformy za darmo, pomagając nam jednocześnie osiągnąć rentowność.",
      "Email address": "Adres e-mail",
      "Create a Password": "Stwórz hasło",
      "Your email address": "Twój adres email",
      "Your password": "Twoje hasło",
      "Sign up": "Zapisać się",
      "Signing up ...": "Logować się ...",
      "Sign in with": "Zaloguj się korzystając z",
      "Don't have an account? Sign up": "Nie masz konta? Zapisać się",
      "Check your email for the confirmation link": "Sprawdź czy otrzymałeś na swój e-mail link potwierdzający",
      "Your Password": "Twoje hasło",
      "Sign in": "Zalogować się",
      "Signing in ...": "Dołączanie do ...",
      "Already have an account? Sign in": "Posiadasz już konto? Zalogować się",
      "Send Magic Link": "Wyślij magiczny link",
      "Sending Magic Link ...": "Wysyłam magiczny link...",
      "Send a magic link email": "Wyślij e-mail z magicznym linkiem",
      "Check your email for the magic link": "Sprawdź, czy w skrzynce e-mail znajduje się magiczny link",
      "Send reset password instructions": "Wyślij instrukcje resetowania hasła",
      "Sending reset instructions ...": "Wysyłam instrukcje resetowania...",
      "Forgot your password?": "Zapomniałeś hasła?",
      "Check your email for the password reset link": "Sprawdź, czy w skrzynce e-mail znajduje się link do resetowania hasła",
      "New password": "Nowe hasło",
      "Your new password": "Twoje nowe hasło",
      "Update password": "Aktualizować hasło",
      "Updating password ...": "Aktualizuję hasło...",
      "Your password has been updated": "Twoje hasło zostało zaktualizowane",
      "Your phone number": "Twój numer telefonu",
      "Token": "Znak",
      "Your Otp token": "Twój token Otp",
      "Verify token": "Zweryfikuj token",
      "We need a sponsor": "Potrzebujemy sponsora",
      "Help us break even": "Pomóż nam wyjść na zero",
      "Help us keep the platform free": "Pomóż nam utrzymać platformę wolną",
      "My images": "Moje obrazy",
      "Gallery": "Galeria",
      "Super Voice": "Super głos",
      "Create speech from text": "Utwórz mowę z tekstu",
      "Create speech": "Utwórz mowę",
      "This is Gordon Ramsay, what would you like to eat?": "Mówi Gordon Ramsay, co chciałbyś zjeść?",
      "New": "Nowy",
      "Images": "Obrazy",
      "GIFs": "Gify"
    }
  },
  "it": {
    "translation": {
      "Home": "Casa",
      "Logout": "Disconnettersi",
      "Profile": "Profilo",
      "Onboard": "A bordo",
      "Blog": "Blog",
      "Contact Us": "Contattaci",
      "Login": "Login",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "Vai e verifica la tua email in modo da poter continuare a utilizzare il sito web. Ti abbiamo inviato un'e-mail con un collegamento su cui fare clic.",
      "Resend verification email": "Invia nuovamente email di verifica",
      "Call us at +1-877-775-5232": "Chiamaci al +1-877-775-5232",
      "Super Pipeline": "Super conduttura",
      "About": "Di",
      "Signup Free": "Iscriviti gratis",
      "Disclaimer": "Disclaimer",
      "Contact us with any question at": "Contattaci per qualsiasi domanda a",
      "Call us at": "Chiamaci al",
      "Plans": "Piani",
      "Privacy policy": "Politica sulla riservatezza",
      "Terms of service": "Termini di servizio",
      "Finished": "Finito",
      "Continue": "Continua",
      "Start Over": "Ricominciare",
      "Start (100% FREE)": "Inizia (100% GRATUITO)",
      "Submit": "Invia",
      "Examples": "Esempi",
      "Select character": "Seleziona il personaggio",
      "Video Examples": "Esempi video",
      "Write a poem about life": "Scrivi una poesia sulla vita",
      "Explain how molecules work": "Spiegare come funzionano le molecole",
      "Write a story about life in Mars": "Scrivi una storia sulla vita su Marte",
      "Create an entire video from a single prompt": "Crea un intero video da un singolo prompt",
      "Entertain, inform and inspire": "Intrattenere, informare e ispirare",
      "through the power of AI": "attraverso il potere dell’intelligenza artificiale",
      "Create Video!": "Crea video!",
      "one more step before you start Just": "ancora un passo prima di iniziare",
      "First name": "Nome di battesimo",
      "What is your first name": "Qual è il tuo nome",
      "Please input your first name!": "Per favore inserisci il tuo nome!",
      "Last/family name": "Cognome/cognome",
      "What is your last/family name": "Qual è il tuo cognome/cognome?",
      "Please input your last/family name!": "Per favore inserisci il tuo cognome/cognome!",
      "Nickname": "Soprannome",
      "What do you want others to call you?": "Come vuoi che gli altri ti chiamino?",
      "Please input your nickname!": "Per favore inserisci il tuo nickname!",
      "Company": "Azienda",
      "What is the company you represent?": "Qual è l'azienda che rappresenti?",
      "Please input your company!": "Inserisci la tua azienda!",
      "Phone Number": "Numero di telefono",
      "What is your phone number?": "Qual è il tuo numero di telefono?",
      "Why we ask for your phone number": "Perché chiediamo il tuo numero di telefono",
      "Account Recovery": "Recupero dell'account",
      "If you forget your password or get locked out of your account, a phone number provides an additional way to verify your identity and regain access.": "Se dimentichi la password o non riesci ad accedere al tuo account, un numero di telefono fornisce un ulteriore modo per verificare la tua identità e riottenere l'accesso.",
      "Fraud Prevention": "Prevenzione frodi",
      "by asking for phone number it limits fake or fraudulent account creation. It's more challenging for scammers to maintain numerous unique phone numbers than it is for email addresses.": "chiedendo il numero di telefono limita la creazione di account falsi o fraudolenti. Per i truffatori è più difficile mantenere numerosi numeri di telefono univoci rispetto agli indirizzi e-mail.",
      "Communication": "Comunicazione",
      "we may communicate with users directly through phone calls or SMS, whether for support or account issues": "potremmo comunicare con gli utenti direttamente tramite telefonate o SMS, sia per supporto che per problemi relativi all'account",
      "Please input your phone number! Example +18777755232": "Per favore inserisci il tuo numero di telefono! Esempio +18777755232",
      "I agree to the": "sono d'accordo con",
      "privacy policy": "politica sulla riservatezza",
      "terms of service": "Termini di servizio",
      "disclaimer": "disclaimer",
      "Just one more step before you start": "Solo un altro passo prima di iniziare",
      "Phone number": "Numero di telefono",
      "CONTACT US": "CONTATTACI",
      "BLOGS": "BLOG",
      "Published": "Pubblicato",
      "Office": "Ufficio",
      "Super Character": "Super personaggio",
      "Super Meme": "Super meme",
      "Hello, how are you?": "Ciao, come stai?",
      "Say something": "Di 'qualcosa",
      "Download video": "Scarica video",
      "Check out this cool AI text to video tool": "Dai un'occhiata a questo fantastico strumento AI per testo in video",
      "Share": "Condividere",
      "Download": "Scaricamento",
      "Select or search a character": "Seleziona o cerca un personaggio",
      "Copy link": "Copia link",
      "Link copied to clipboard": "Link copiato negli appunti",
      "This character cannot create videos for now": "Questo personaggio non può creare video per ora",
      "Create GIFs from your prompts": "Crea GIF dalle tue richieste",
      "Create GIF": "Crea GIF",
      "Monkey sitting on a chair and eating Pasta": "Scimmia seduta su una sedia e mangia pasta",
      "Please wait (30-60 seconds)": "Attendi (30-60 secondi)",
      "Please wait (30-120 seconds)": "Attendi (30-120 secondi)",
      "Prompt": "Richiesta",
      "The future of A.I. content creation": "Il futuro dell'A.I. creazione di contenuti",
      "Try these prompts": "Prova queste istruzioni",
      "Signup FREE": "Iscriviti gratis",
      "Cancel": "Annulla",
      "Error": "Errore",
      "Hide video": "Nascondi video",
      "It's 100% FREE!": "È GRATUITO al 100%!",
      "Win $100 Gift Cards for the best GIF!": "Vinci buoni regalo da $ 100 per la migliore GIF!",
      "How can I win?": "Come posso vincere?",
      "Create a GIF": "Crea una GIF",
      "Post it on Reddit": "Postalo su Reddit",
      "Send us a link to your post": "Inviaci un collegamento al tuo post",
      "The GIF with the most upvotes wins a weekly $100 Gift Card!": "La GIF con il maggior numero di voti vince una carta regalo settimanale da $ 100!",
      "Minimum 20 upvotes required": "Sono richiesti almeno 20 voti positivi",
      "Email us your GIF": "Inviaci la tua GIF via email",
      "Create your GIF": "Crea la tua GIF",
      "Submit your GIF to Reddit - Get $100 Gift Card!": "Invia la tua GIF a Reddit: ricevi una carta regalo da $ 100!",
      "Welcome to the chat room with": "Benvenuto nella chat room con",
      "Click on one of these messages": "Fare clic su uno di questi messaggi",
      "Super GIF": "Ottima GIF",
      "Generating your video": "Generazione del tuo video",
      "Click this button in order to generate a video from this response": "Fare clic su questo pulsante per generare un video da questa risposta",
      "Start": "Inizio",
      "You need to signup for free in order to create a GIF": "È necessario registrarsi gratuitamente per creare una GIF",
      "Create video": "Crea video",
      "Share video": "Condividi il Video",
      "Create images from your prompts": "Crea immagini dalle tue istruzioni",
      "Create image": "Crea immagine",
      "Please wait (10-30 seconds)": "Attendi (10-30 secondi)",
      "Download GIF": "Scarica GIF",
      "Download image": "Scarica l'immagine",
      "Super Image": "Immagine eccellente",
      "Super Person": "Super Persona",
      "Create fake person": "Crea una persona falsa",
      "Select gender": "Selezionare genere",
      "Select age": "Seleziona l'età",
      "Select ethnic": "Seleziona etnico",
      "Fake Person Generator": "Generatore di persone false",
      "We create AI tools for content creators": "Creiamo strumenti AI per i creatori di contenuti",
      "You need to signup for free in order to use our tools": "È necessario registrarsi gratuitamente per utilizzare i nostri strumenti",
      "Show more": "Mostra di più",
      "Chat with your favorite characters": "Chatta con i tuoi personaggi preferiti",
      "Chat with over 60 characters": "Chatta con oltre 60 personaggi",
      "Style": "Stile",
      "Error sending the chat message. Please try again": "Errore durante l'invio del messaggio di chat. Per favore riprova",
      "Error generating the video": "Errore durante la generazione del video",
      "Twitch stream": "Flusso di contrazione",
      "Join the stream and chat with AI Characters": "Unisciti allo streaming e chatta con i personaggi AI",
      "Please complete your onboarding in order to keep using our platform for free.": "Completa l'onboarding per continuare a utilizzare la nostra piattaforma gratuitamente.",
      "Looks like you are a heavy user of our platform!": "Sembra che tu sia un utente accanito della nostra piattaforma!",
      "You need to complete your onboarding in order to continue": "È necessario completare l'onboarding per continuare",
      "Complete onboarding": "Onboarding completo",
      "Shape": "Forma",
      "Yes, show me ads. I understand why you do it": "Sì, mostrami gli annunci. Capisco perché lo fai",
      "No, do not show me ads, I hate ads": "No, non mostrarmi annunci, odio gli annunci",
      "It costs us a lot of money to operate this platform.": "Gestire questa piattaforma ci costa un sacco di soldi.",
      "Just one question": "Solo una domanda",
      "It would really help us if you allowed us to show you some ads.": "Ci aiuterebbe davvero se ci permettessi di mostrarti alcuni annunci.",
      "This way you can keep using our platform for free, while helping us break even.": "In questo modo potrai continuare a utilizzare la nostra piattaforma gratuitamente, aiutandoci a raggiungere il pareggio.",
      "Email address": "Indirizzo e-mail",
      "Create a Password": "Crea una password",
      "Your email address": "Il tuo indirizzo di posta elettronica",
      "Your password": "La tua password",
      "Sign up": "Iscrizione",
      "Signing up ...": "Iscrizione ...",
      "Sign in with": "Accedi con",
      "Don't have an account? Sign up": "Non hai un account? Iscrizione",
      "Check your email for the confirmation link": "Controlla la tua e-mail per il link di conferma",
      "Your Password": "La tua password",
      "Sign in": "Registrazione",
      "Signing in ...": "Registrarsi ...",
      "Already have an account? Sign in": "Hai già un account? Registrazione",
      "Send Magic Link": "Invia collegamento magico",
      "Sending Magic Link ...": "Invio del collegamento magico in corso...",
      "Send a magic link email": "Invia un'e-mail con collegamento magico",
      "Check your email for the magic link": "Controlla la tua email per il collegamento magico",
      "Send reset password instructions": "Invia istruzioni per reimpostare la password",
      "Sending reset instructions ...": "Invio istruzioni di ripristino...",
      "Forgot your password?": "Hai dimenticato la password?",
      "Check your email for the password reset link": "Controlla la tua email per il collegamento per la reimpostazione della password",
      "New password": "Nuova password",
      "Your new password": "La tua nuova password",
      "Update password": "Aggiorna password",
      "Updating password ...": "Aggiornamento password...",
      "Your password has been updated": "La tua password è stata aggiornata",
      "Your phone number": "Il tuo numero di telefono",
      "Token": "Gettone",
      "Your Otp token": "Il tuo gettone OTP",
      "Verify token": "Verifica gettone",
      "We need a sponsor": "Abbiamo bisogno di uno sponsor",
      "Help us break even": "Aiutaci a raggiungere il pareggio",
      "Help us keep the platform free": "Aiutaci a mantenere la piattaforma gratuita",
      "My images": "Le mie immagini",
      "Gallery": "Galleria",
      "Super Voice": "Supervoce",
      "Create speech from text": "Crea discorso dal testo",
      "Create speech": "Crea discorso",
      "This is Gordon Ramsay, what would you like to eat?": "Sono Gordon Ramsay, cosa vorresti mangiare?",
      "New": "Nuovo",
      "Images": "immagini",
      "GIFs": "GIF"
    }
  },
  "pt": {
    "translation": {
      "Home": "Lar",
      "Logout": "Sair",
      "Profile": "Perfil",
      "Onboard": "A bordo",
      "Blog": "Blogue",
      "Contact Us": "Contate-nos",
      "Login": "Conecte-se",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "Por favor, verifique seu e-mail para poder continuar usando o site. Enviamos um e-mail para você com um link para clicar.",
      "Resend verification email": "Reenviar email de verificação",
      "Call us at +1-877-775-5232": "Ligue para +1-877-775-5232",
      "Super Pipeline": "Super pipeline",
      "About": "Sobre",
      "Signup Free": "Inscreva-se gratuitamente",
      "Disclaimer": "Isenção de responsabilidade",
      "Contact us with any question at": "Contate-nos com qualquer dúvida em",
      "Call us at": "Ligue para nós em",
      "Plans": "Planos",
      "Privacy policy": "Política de Privacidade",
      "Terms of service": "Termos de serviço",
      "Finished": "Finalizado",
      "Continue": "Continuar",
      "Start Over": "Recomeçar",
      "Start (100% FREE)": "Iniciar (100% GRATUITO)",
      "Submit": "Enviar",
      "Examples": "Exemplos",
      "Select character": "Selecione o personagem",
      "Video Examples": "Exemplos de vídeo",
      "Write a poem about life": "Escreva um poema sobre a vida",
      "Explain how molecules work": "Explique como funcionam as moléculas",
      "Write a story about life in Mars": "Escreva uma história sobre a vida em Marte",
      "Create an entire video from a single prompt": "Crie um vídeo inteiro a partir de um único prompt",
      "Entertain, inform and inspire": "Entreter, informar e inspirar",
      "through the power of AI": "através do poder da IA",
      "Create Video!": "Crie um vídeo!",
      "one more step before you start Just": "mais um passo antes de começar",
      "First name": "Primeiro nome",
      "What is your first name": "Qual é o seu primeiro nome",
      "Please input your first name!": "Por favor insira seu primeiro nome!",
      "Last/family name": "Último nome de família",
      "What is your last/family name": "Qual é o seu sobrenome/nome de família",
      "Please input your last/family name!": "Por favor, insira seu sobrenome/nome de família!",
      "Nickname": "Apelido",
      "What do you want others to call you?": "Como você quer que os outros te chamem?",
      "Please input your nickname!": "Por favor insira seu apelido!",
      "Company": "Empresa",
      "What is the company you represent?": "Qual é a empresa que você representa?",
      "Please input your company!": "Por favor, insira sua empresa!",
      "Phone Number": "Número de telefone",
      "What is your phone number?": "Qual é o seu número de telefone?",
      "Why we ask for your phone number": "Por que pedimos seu número de telefone",
      "Account Recovery": "Recuperação de conta",
      "If you forget your password or get locked out of your account, a phone number provides an additional way to verify your identity and regain access.": "Se você esquecer sua senha ou sua conta for bloqueada, um número de telefone fornece uma maneira adicional de verificar sua identidade e recuperar o acesso.",
      "Fraud Prevention": "Prevenção de Fraude",
      "by asking for phone number it limits fake or fraudulent account creation. It's more challenging for scammers to maintain numerous unique phone numbers than it is for email addresses.": "ao solicitar um número de telefone, ele limita a criação de contas falsas ou fraudulentas. É mais desafiador para os golpistas manter vários números de telefone exclusivos do que para endereços de e-mail.",
      "Communication": "Comunicação",
      "we may communicate with users directly through phone calls or SMS, whether for support or account issues": "podemos nos comunicar com os usuários diretamente por meio de chamadas telefônicas ou SMS, seja para suporte ou problemas de conta",
      "Please input your phone number! Example +18777755232": "Por favor insira seu número de telefone! Exemplo +18777755232",
      "I agree to the": "Estou de acordo com",
      "privacy policy": "política de Privacidade",
      "terms of service": "termos de serviço",
      "disclaimer": "isenção de responsabilidade",
      "Just one more step before you start": "Só mais um passo antes de começar",
      "Phone number": "Número de telefone",
      "CONTACT US": "CONTATE-NOS",
      "BLOGS": "BLOGUES",
      "Published": "Publicados",
      "Office": "Escritório",
      "Super Character": "Superpersonagem",
      "Super Meme": "Supermemes",
      "Hello, how are you?": "Olá, como vai?",
      "Say something": "Dizer algo",
      "Download video": "Baixar video",
      "Check out this cool AI text to video tool": "Confira esta ferramenta legal de texto para vídeo de IA",
      "Share": "Compartilhar",
      "Download": "Download",
      "Select or search a character": "Selecione ou pesquise um personagem",
      "Copy link": "Link de cópia",
      "Link copied to clipboard": "Link copiado para a área de transferência",
      "This character cannot create videos for now": "Este personagem não pode criar vídeos por enquanto",
      "Create GIFs from your prompts": "Crie GIFs a partir de suas solicitações",
      "Create GIF": "Criar GIF",
      "Monkey sitting on a chair and eating Pasta": "Macaco sentado em uma cadeira e comendo macarrão",
      "Please wait (30-60 seconds)": "Aguarde (30-60 segundos)",
      "Please wait (30-120 seconds)": "Aguarde (30-120 segundos)",
      "Prompt": "Incitar",
      "The future of A.I. content creation": "O futuro da IA Criação de conteúdo",
      "Try these prompts": "Experimente estas instruções",
      "Signup FREE": "Inscreva-se gratuitamente",
      "Cancel": "Cancelar",
      "Error": "Erro",
      "Hide video": "Ocultar vídeo",
      "It's 100% FREE!": "É 100% GRATUITO!",
      "Win $100 Gift Cards for the best GIF!": "Ganhe vales-presente de $ 100 para o melhor GIF!",
      "How can I win?": "Como posso vencer?",
      "Create a GIF": "Crie um GIF",
      "Post it on Reddit": "Poste no Reddit",
      "Send us a link to your post": "Envie-nos um link para sua postagem",
      "The GIF with the most upvotes wins a weekly $100 Gift Card!": "O GIF com mais votos positivos ganha um vale-presente semanal de US$ 100!",
      "Minimum 20 upvotes required": "Mínimo de 20 votos positivos necessários",
      "Email us your GIF": "Envie-nos seu GIF por e-mail",
      "Create your GIF": "Crie seu GIF",
      "Submit your GIF to Reddit - Get $100 Gift Card!": "Envie seu GIF para o Reddit - Ganhe um vale-presente de $ 100!",
      "Welcome to the chat room with": "Bem-vindo à sala de bate-papo com",
      "Click on one of these messages": "Clique em uma dessas mensagens",
      "Super GIF": "Supergifs",
      "Generating your video": "Gerando seu vídeo",
      "Click this button in order to generate a video from this response": "Clique neste botão para gerar um vídeo a partir desta resposta",
      "Start": "Começar",
      "You need to signup for free in order to create a GIF": "Você precisa se inscrever gratuitamente para criar um GIF",
      "Create video": "Criar vídeo",
      "Share video": "Compartilhar video",
      "Create images from your prompts": "Crie imagens a partir de seus prompts",
      "Create image": "Criar imagem",
      "Please wait (10-30 seconds)": "Aguarde (10 a 30 segundos)",
      "Download GIF": "Baixar GIF",
      "Download image": "Baixar imagem",
      "Super Image": "Superimagem",
      "Super Person": "Superpessoa",
      "Create fake person": "Criar pessoa falsa",
      "Select gender": "Selecione o sexo",
      "Select age": "Selecione a idade",
      "Select ethnic": "Selecione étnico",
      "Fake Person Generator": "Gerador de pessoa falsa",
      "We create AI tools for content creators": "Criamos ferramentas de IA para criadores de conteúdo",
      "You need to signup for free in order to use our tools": "Você precisa se inscrever gratuitamente para usar nossas ferramentas",
      "Show more": "Mostre mais",
      "Chat with your favorite characters": "Converse com seus personagens favoritos",
      "Chat with over 60 characters": "Converse com mais de 60 caracteres",
      "Style": "Estilo",
      "Error sending the chat message. Please try again": "Erro ao enviar a mensagem de bate-papo. Por favor, tente novamente",
      "Error generating the video": "Erro ao gerar o vídeo",
      "Twitch stream": "Transmissão do Twitch",
      "Join the stream and chat with AI Characters": "Participe da transmissão e converse com personagens de IA",
      "Please complete your onboarding in order to keep using our platform for free.": "Conclua sua integração para continuar usando nossa plataforma gratuitamente.",
      "Looks like you are a heavy user of our platform!": "Parece que você é um usuário frequente da nossa plataforma!",
      "You need to complete your onboarding in order to continue": "Você precisa concluir sua integração para continuar",
      "Complete onboarding": "Integração completa",
      "Shape": "Forma",
      "Yes, show me ads. I understand why you do it": "Sim, mostre-me anúncios. Eu entendo por que você faz isso",
      "No, do not show me ads, I hate ads": "Não, não me mostre anúncios, odeio anúncios",
      "It costs us a lot of money to operate this platform.": "Custa-nos muito dinheiro operar esta plataforma.",
      "Just one question": "Apenas uma pergunta",
      "It would really help us if you allowed us to show you some ads.": "Realmente nos ajudaria se você nos permitisse mostrar alguns anúncios.",
      "This way you can keep using our platform for free, while helping us break even.": "Dessa forma, você pode continuar usando nossa plataforma gratuitamente, ao mesmo tempo que nos ajuda a equilibrar as contas.",
      "Email address": "Endereço de email",
      "Create a Password": "Criar uma senha",
      "Your email address": "Seu endereço de email",
      "Your password": "Sua senha",
      "Sign up": "Inscrever-se",
      "Signing up ...": "Inscrever-se ...",
      "Sign in with": "Entrar com",
      "Don't have an account? Sign up": "Não tem uma conta? Inscrever-se",
      "Check your email for the confirmation link": "Verifique seu e-mail para obter o link de confirmação",
      "Your Password": "Sua senha",
      "Sign in": "Entrar",
      "Signing in ...": "Entrando ...",
      "Already have an account? Sign in": "já tem uma conta? Entrar",
      "Send Magic Link": "Enviar link mágico",
      "Sending Magic Link ...": "Enviando link mágico...",
      "Send a magic link email": "Envie um e-mail com link mágico",
      "Check your email for the magic link": "Verifique seu e-mail para obter o link mágico",
      "Send reset password instructions": "Enviar instruções de redefinição de senha",
      "Sending reset instructions ...": "Enviando instruções de redefinição...",
      "Forgot your password?": "Esqueceu sua senha?",
      "Check your email for the password reset link": "Verifique seu e-mail para obter o link de redefinição de senha",
      "New password": "Nova Senha",
      "Your new password": "Sua nova senha",
      "Update password": "Atualizar senha",
      "Updating password ...": "Atualizando senha...",
      "Your password has been updated": "Sua senha foi atualizada",
      "Your phone number": "Seu número de telefone",
      "Token": "Símbolo",
      "Your Otp token": "Seu token Otp",
      "Verify token": "Verificar token",
      "We need a sponsor": "Precisamos de um patrocinador",
      "Help us break even": "Ajude-nos a empatar",
      "Help us keep the platform free": "Ajude-nos a manter a plataforma gratuita",
      "My images": "Minhas imagens",
      "Gallery": "Galeria",
      "Super Voice": "Super Voz",
      "Create speech from text": "Crie um discurso a partir de texto",
      "Create speech": "Criar discurso",
      "This is Gordon Ramsay, what would you like to eat?": "Aqui é Gordon Ramsay, o que você gostaria de comer?",
      "New": "Novo",
      "Images": "Imagens",
      "GIFs": "Gifs"
    }
  },
  "nl": {
    "translation": {
      "Home": "Thuis",
      "Logout": "Uitloggen",
      "Profile": "Profiel",
      "Onboard": "Aan boord",
      "Blog": "Bloggen",
      "Contact Us": "Neem contact met ons op",
      "Login": "Log in",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "Ga uw e-mailadres verifiëren zodat u de website kunt blijven gebruiken. We hebben u een e-mail gestuurd met een link waarop u kunt klikken.",
      "Resend verification email": "Verificatie-e-mail opnieuw verzenden",
      "Call us at +1-877-775-5232": "Bel ons op +1-877-775-5232",
      "Super Pipeline": "Superpijplijn",
      "About": "Over",
      "Signup Free": "Schrijf je gratis in",
      "Disclaimer": "Vrijwaring",
      "Contact us with any question at": "Neem bij vragen contact met ons op via",
      "Call us at": "Bel ons op",
      "Plans": "Plannen",
      "Privacy policy": "Privacybeleid",
      "Terms of service": "Servicevoorwaarden",
      "Finished": "Afgerond",
      "Continue": "Doorgaan",
      "Start Over": "Begin opnieuw",
      "Start (100% FREE)": "Beginnen (100% GRATIS)",
      "Submit": "Indienen",
      "Examples": "Voorbeelden",
      "Select character": "Selecteer karakter",
      "Video Examples": "Videovoorbeelden",
      "Write a poem about life": "Schrijf een gedicht over het leven",
      "Explain how molecules work": "Leg uit hoe moleculen werken",
      "Write a story about life in Mars": "Schrijf een verhaal over het leven op Mars",
      "Create an entire video from a single prompt": "Maak een hele video vanuit één enkele prompt",
      "Entertain, inform and inspire": "Vermaken, informeren en inspireren",
      "through the power of AI": "door de kracht van AI",
      "Create Video!": "Maak video!",
      "one more step before you start Just": "nog één stap voordat u begint",
      "First name": "Voornaam",
      "What is your first name": "Wat is je voornaam",
      "Please input your first name!": "Voer uw voornaam in!",
      "Last/family name": "Achter / familienaam",
      "What is your last/family name": "Wat is uw achternaam/familienaam",
      "Please input your last/family name!": "Voer uw achternaam/achternaam in!",
      "Nickname": "Bijnaam",
      "What do you want others to call you?": "Hoe wil je dat anderen je noemen?",
      "Please input your nickname!": "Voer uw bijnaam in!",
      "Company": "Bedrijf",
      "What is the company you represent?": "Wat is het bedrijf dat u vertegenwoordigt?",
      "Please input your company!": "Voer uw bedrijf in!",
      "Phone Number": "Telefoonnummer",
      "What is your phone number?": "Wat is uw telefoonnummer?",
      "Why we ask for your phone number": "Waarom wij om uw telefoonnummer vragen",
      "Account Recovery": "Accountherstel",
      "If you forget your password or get locked out of your account, a phone number provides an additional way to verify your identity and regain access.": "Als u uw wachtwoord vergeet of geen toegang meer heeft tot uw account, biedt een telefoonnummer een extra manier om uw identiteit te verifiëren en weer toegang te krijgen.",
      "Fraud Prevention": "Fraudepreventie",
      "by asking for phone number it limits fake or fraudulent account creation. It's more challenging for scammers to maintain numerous unique phone numbers than it is for email addresses.": "door om een ​​telefoonnummer te vragen, wordt het aanmaken van valse of frauduleuze accounts beperkt. Het is voor oplichters een grotere uitdaging om talloze unieke telefoonnummers te behouden dan voor e-mailadressen.",
      "Communication": "Communicatie",
      "we may communicate with users directly through phone calls or SMS, whether for support or account issues": "we kunnen rechtstreeks met gebruikers communiceren via telefoontjes of sms, of het nu gaat om ondersteuning of accountproblemen",
      "Please input your phone number! Example +18777755232": "Voer uw telefoonnummer in! Voorbeeld +18777755232",
      "I agree to the": "Ik ga akkoord met de",
      "privacy policy": "privacybeleid",
      "terms of service": "servicevoorwaarden",
      "disclaimer": "vrijwaring",
      "Just one more step before you start": "Nog één stap voordat u begint",
      "Phone number": "Telefoonnummer",
      "CONTACT US": "NEEM CONTACT MET ONS OP",
      "BLOGS": "BLOGS",
      "Published": "Gepubliceerd",
      "Office": "Kantoor",
      "Super Character": "Super karakter",
      "Super Meme": "Super meme",
      "Hello, how are you?": "Hallo hoe is het?",
      "Say something": "Zeg iets",
      "Download video": "Download video",
      "Check out this cool AI text to video tool": "Bekijk deze coole AI-tekst-naar-video-tool",
      "Share": "Deel",
      "Download": "Downloaden",
      "Select or search a character": "Selecteer of zoek een teken",
      "Copy link": "Kopieer link",
      "Link copied to clipboard": "Link gekopieerd naar klembord",
      "This character cannot create videos for now": "Dit personage kan voorlopig geen video's maken",
      "Create GIFs from your prompts": "Maak GIF's op basis van uw aanwijzingen",
      "Create GIF": "Maak GIF",
      "Monkey sitting on a chair and eating Pasta": "Aap zittend op een stoel en pasta eten",
      "Please wait (30-60 seconds)": "Even geduld a.u.b. (30-60 seconden)",
      "Please wait (30-120 seconds)": "Even geduld a.u.b. (30-120 seconden)",
      "Prompt": "Snel",
      "The future of A.I. content creation": "De toekomst van A.I. content creatie",
      "Try these prompts": "Probeer deze aanwijzingen",
      "Signup FREE": "Schrijf je gratis in",
      "Cancel": "Annuleren",
      "Error": "Fout",
      "Hide video": "Video verbergen",
      "It's 100% FREE!": "Het is 100% GRATIS!",
      "Win $100 Gift Cards for the best GIF!": "Win cadeaubonnen ter waarde van € 100 voor de beste GIF!",
      "How can I win?": "Hoe kan ik winnen?",
      "Create a GIF": "Maak een GIF",
      "Post it on Reddit": "Plaats het op Reddit",
      "Send us a link to your post": "Stuur ons een link naar uw bericht",
      "The GIF with the most upvotes wins a weekly $100 Gift Card!": "De GIF met de meeste stemmen wint een wekelijkse cadeaubon van $ 100!",
      "Minimum 20 upvotes required": "Minimaal 20 stemmen vereist",
      "Email us your GIF": "E-mail ons uw GIF",
      "Create your GIF": "Maak uw GIF",
      "Submit your GIF to Reddit - Get $100 Gift Card!": "Dien uw GIF in op Reddit - Ontvang een cadeaubon van $ 100!",
      "Welcome to the chat room with": "Welkom in de chatroom met",
      "Click on one of these messages": "Klik op een van deze berichten",
      "Super GIF": "SuperGIF",
      "Generating your video": "Het genereren van uw video",
      "Click this button in order to generate a video from this response": "Klik op deze knop om van deze reactie een video te genereren",
      "Start": "Begin",
      "You need to signup for free in order to create a GIF": "U moet zich gratis aanmelden om een ​​GIF te maken",
      "Create video": "Maak een video",
      "Share video": "Deel Video",
      "Create images from your prompts": "Maak afbeeldingen op basis van uw aanwijzingen",
      "Create image": "Maak afbeelding",
      "Please wait (10-30 seconds)": "Even geduld a.u.b. (10-30 seconden)",
      "Download GIF": "GIF downloaden",
      "Download image": "Afbeelding downloaden",
      "Super Image": "Superbeeld",
      "Super Person": "Super persoon",
      "Create fake person": "Creëer een neppersoon",
      "Select gender": "Selecteer geslacht",
      "Select age": "Selecteer leeftijd",
      "Select ethnic": "Selecteer etnisch",
      "Fake Person Generator": "Valse persoonsgenerator",
      "We create AI tools for content creators": "We creëren AI-tools voor makers van inhoud",
      "You need to signup for free in order to use our tools": "Om onze tools te kunnen gebruiken, moet u zich gratis aanmelden",
      "Show more": "Laat meer zien",
      "Chat with your favorite characters": "Chat met je favoriete personages",
      "Chat with over 60 characters": "Chat met meer dan 60 karakters",
      "Style": "Stijl",
      "Error sending the chat message. Please try again": "Fout bij het verzenden van het chatbericht. Probeer het opnieuw",
      "Error generating the video": "Fout bij het genereren van de video",
      "Twitch stream": "Twitch-stream",
      "Join the stream and chat with AI Characters": "Sluit je aan bij de stream en chat met AI-personages",
      "Please complete your onboarding in order to keep using our platform for free.": "Voltooi uw onboarding om ons platform gratis te blijven gebruiken.",
      "Looks like you are a heavy user of our platform!": "Het lijkt erop dat je een intensieve gebruiker bent van ons platform!",
      "You need to complete your onboarding in order to continue": "U moet uw onboarding voltooien om door te kunnen gaan",
      "Complete onboarding": "Volledige onboarding",
      "Shape": "Vorm",
      "Yes, show me ads. I understand why you do it": "Ja, laat mij advertenties zien. Ik begrijp waarom je het doet",
      "No, do not show me ads, I hate ads": "Nee, laat mij geen advertenties zien, ik haat advertenties",
      "It costs us a lot of money to operate this platform.": "Het kost ons veel geld om dit platform te exploiteren.",
      "Just one question": "Slechts een vraag",
      "It would really help us if you allowed us to show you some ads.": "Het zou ons enorm helpen als u ons enkele advertenties zou laten zien.",
      "This way you can keep using our platform for free, while helping us break even.": "Op deze manier kun je ons platform gratis blijven gebruiken en help je ons break-even te draaien.",
      "Email address": "E-mailadres",
      "Create a Password": "Creëer een wachtwoord",
      "Your email address": "jouw e-mailadres",
      "Your password": "Je wachtwoord",
      "Sign up": "Aanmelden",
      "Signing up ...": "Inschrijven ...",
      "Sign in with": "Aanmelden met",
      "Don't have an account? Sign up": "Heeft u geen account? Aanmelden",
      "Check your email for the confirmation link": "Bekijk je e-mail voor de bevestigingslink",
      "Your Password": "Je wachtwoord",
      "Sign in": "Aanmelden",
      "Signing in ...": "Inloggen ...",
      "Already have an account? Sign in": "Heeft u al een account? Aanmelden",
      "Send Magic Link": "Stuur een magische link",
      "Sending Magic Link ...": "Magische link verzenden...",
      "Send a magic link email": "Stuur een magische link-e-mail",
      "Check your email for the magic link": "Controleer uw e-mail voor de magische link",
      "Send reset password instructions": "Stuur instructies voor het opnieuw instellen van het wachtwoord",
      "Sending reset instructions ...": "Reset-instructies verzenden...",
      "Forgot your password?": "Je wachtwoord vergeten?",
      "Check your email for the password reset link": "Controleer uw e-mail voor de link voor het opnieuw instellen van uw wachtwoord",
      "New password": "Nieuw paswoord",
      "Your new password": "uw nieuwe wachtwoord",
      "Update password": "Vernieuw wachtwoord",
      "Updating password ...": "Wachtwoord bijwerken...",
      "Your password has been updated": "Uw wachtwoord is geüpdatet",
      "Your phone number": "Jouw telefoon nummer",
      "Token": "Token",
      "Your Otp token": "Uw Otp-token",
      "Verify token": "Token verifiëren",
      "We need a sponsor": "Wij hebben een sponsor nodig",
      "Help us break even": "Help ons break-even te draaien",
      "Help us keep the platform free": "Help ons het platform gratis te houden",
      "My images": "Mijn afbeeldingen",
      "Gallery": "Galerij",
      "Super Voice": "Superstem",
      "Create speech from text": "Maak spraak van tekst",
      "Create speech": "Maak spraak",
      "This is Gordon Ramsay, what would you like to eat?": "Dit is Gordon Ramsay, wat wil je eten?",
      "New": "Nieuw",
      "Images": "Afbeeldingen",
      "GIFs": "GIF's"
    }
  },
  "sv": {
    "translation": {
      "Home": "Hem",
      "Logout": "Logga ut",
      "Profile": "Profil",
      "Onboard": "Ombord",
      "Blog": "Blogg",
      "Contact Us": "Kontakta oss",
      "Login": "Logga in",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "Gå och verifiera din e-post så att du kan fortsätta använda webbplatsen. Vi har skickat ett mail till dig med en länk att klicka på.",
      "Resend verification email": "Skicka verifieringsemail igen",
      "Call us at +1-877-775-5232": "Ring oss på +1-877-775-5232",
      "Super Pipeline": "Super Pipeline",
      "About": "Handla om",
      "Signup Free": "Registrera dig gratis",
      "Disclaimer": "varning",
      "Contact us with any question at": "Kontakta oss med alla frågor på",
      "Call us at": "Ring oss på",
      "Plans": "Planer",
      "Privacy policy": "Integritetspolicy",
      "Terms of service": "Användarvillkor",
      "Finished": "Färdiga",
      "Continue": "Fortsätta",
      "Start Over": "Börja om",
      "Start (100% FREE)": "Start (100 % GRATIS)",
      "Submit": "Skicka in",
      "Examples": "Exempel",
      "Select character": "Välj tecken",
      "Video Examples": "Video exempel",
      "Write a poem about life": "Skriv en dikt om livet",
      "Explain how molecules work": "Förklara hur molekyler fungerar",
      "Write a story about life in Mars": "Skriv en berättelse om livet på Mars",
      "Create an entire video from a single prompt": "Skapa en hel video från en enda uppmaning",
      "Entertain, inform and inspire": "Underhålla, informera och inspirera",
      "through the power of AI": "genom kraften av AI",
      "Create Video!": "Skapa video!",
      "one more step before you start Just": "ett steg till innan du börjar",
      "First name": "Förnamn",
      "What is your first name": "Vad är ditt förnamn",
      "Please input your first name!": "Ange ditt förnamn!",
      "Last/family name": "Efter-/familjenamn",
      "What is your last/family name": "Vad är ditt efternamn/släktnamn",
      "Please input your last/family name!": "Ange ditt efter-/familjenamn!",
      "Nickname": "Smeknamn",
      "What do you want others to call you?": "Vad vill du att andra ska kalla dig?",
      "Please input your nickname!": "Vänligen ange ditt smeknamn!",
      "Company": "Företag",
      "What is the company you represent?": "Vilket företag representerar du?",
      "Please input your company!": "Ange ditt företag!",
      "Phone Number": "Telefonnummer",
      "What is your phone number?": "Vad är ditt telefonnummer?",
      "Why we ask for your phone number": "Varför ber vi om ditt telefonnummer",
      "Account Recovery": "Kontoåterhämtning",
      "If you forget your password or get locked out of your account, a phone number provides an additional way to verify your identity and regain access.": "Om du glömmer ditt lösenord eller blir utestängd från ditt konto ger ett telefonnummer ett ytterligare sätt att verifiera din identitet och återfå åtkomst.",
      "Fraud Prevention": "Bedrägeri förhindring",
      "by asking for phone number it limits fake or fraudulent account creation. It's more challenging for scammers to maintain numerous unique phone numbers than it is for email addresses.": "genom att fråga efter telefonnummer begränsar det skapande av falska eller bedrägliga konton. Det är mer utmanande för bedragare att behålla många unika telefonnummer än det är för e-postadresser.",
      "Communication": "Kommunikation",
      "we may communicate with users directly through phone calls or SMS, whether for support or account issues": "vi kan kommunicera med användare direkt via telefonsamtal eller SMS, oavsett om det gäller support eller kontoproblem",
      "Please input your phone number! Example +18777755232": "Vänligen ange ditt telefonnummer! Exempel +18777755232",
      "I agree to the": "Jag godkänner",
      "privacy policy": "integritetspolicy",
      "terms of service": "användarvillkor",
      "disclaimer": "varning",
      "Just one more step before you start": "Bara ett steg till innan du börjar",
      "Phone number": "Telefonnummer",
      "CONTACT US": "KONTAKTA OSS",
      "BLOGS": "BLOGGAR",
      "Published": "Publicerad",
      "Office": "Kontor",
      "Super Character": "Super karaktär",
      "Super Meme": "Super meme",
      "Hello, how are you?": "Hej hur mår du?",
      "Say something": "Säg något",
      "Download video": "Ladda ner video",
      "Check out this cool AI text to video tool": "Kolla in det här coola AI-text-till-videoverktyget",
      "Share": "Dela med sig",
      "Download": "Ladda ner",
      "Select or search a character": "Välj eller sök efter ett tecken",
      "Copy link": "Kopiera länk",
      "Link copied to clipboard": "Länken har kopierats till urklipp",
      "This character cannot create videos for now": "Den här karaktären kan inte skapa videor för närvarande",
      "Create GIFs from your prompts": "Skapa GIF-filer från dina meddelanden",
      "Create GIF": "Skapa GIF",
      "Monkey sitting on a chair and eating Pasta": "Apa sitter på en stol och äter pasta",
      "Please wait (30-60 seconds)": "Vänta (30-60 sekunder)",
      "Please wait (30-120 seconds)": "Vänta (30-120 sekunder)",
      "Prompt": "Prompt",
      "The future of A.I. content creation": "Framtiden för A.I. innehållsskapande",
      "Try these prompts": "Prova dessa uppmaningar",
      "Signup FREE": "Registrera dig gratis",
      "Cancel": "Annullera",
      "Error": "Fel",
      "Hide video": "Dölj video",
      "It's 100% FREE!": "Det är 100 % GRATIS!",
      "Win $100 Gift Cards for the best GIF!": "Vinn presentkort på 100 $ för bästa GIF!",
      "How can I win?": "Hur kan jag vinna?",
      "Create a GIF": "Skapa en GIF",
      "Post it on Reddit": "Lägg upp det på Reddit",
      "Send us a link to your post": "Skicka oss en länk till ditt inlägg",
      "The GIF with the most upvotes wins a weekly $100 Gift Card!": "GIF-en med flest röster vinner ett presentkort på $100 varje vecka!",
      "Minimum 20 upvotes required": "Minst 20 uppröstningar krävs",
      "Email us your GIF": "Maila oss din GIF",
      "Create your GIF": "Skapa din GIF",
      "Submit your GIF to Reddit - Get $100 Gift Card!": "Skicka in din GIF till Reddit - Få $100 presentkort!",
      "Welcome to the chat room with": "Välkommen till chattrummet med",
      "Click on one of these messages": "Klicka på ett av dessa meddelanden",
      "Super GIF": "Super GIF",
      "Generating your video": "Skapar din video",
      "Click this button in order to generate a video from this response": "Klicka på den här knappen för att skapa en video från det här svaret",
      "Start": "Start",
      "You need to signup for free in order to create a GIF": "Du måste registrera dig gratis för att skapa en GIF",
      "Create video": "Skapa video",
      "Share video": "Dela video",
      "Create images from your prompts": "Skapa bilder från dina meddelanden",
      "Create image": "Skapa bild",
      "Please wait (10-30 seconds)": "Vänta (10-30 sekunder)",
      "Download GIF": "Ladda ner GIF",
      "Download image": "Ladda ner bild",
      "Super Image": "Superbild",
      "Super Person": "Super person",
      "Create fake person": "Skapa en falsk person",
      "Select gender": "Välj kön",
      "Select age": "Välj ålder",
      "Select ethnic": "Välj etnisk",
      "Fake Person Generator": "Fake Person Generator",
      "We create AI tools for content creators": "Vi skapar AI-verktyg för innehållsskapare",
      "You need to signup for free in order to use our tools": "Du måste registrera dig gratis för att kunna använda våra verktyg",
      "Show more": "Visa mer",
      "Chat with your favorite characters": "Chatta med dina favoritkaraktärer",
      "Chat with over 60 characters": "Chatta med över 60 tecken",
      "Style": "Stil",
      "Error sending the chat message. Please try again": "Det gick inte att skicka chattmeddelandet. Var god försök igen",
      "Error generating the video": "Det gick inte att generera videon",
      "Twitch stream": "Twitch stream",
      "Join the stream and chat with AI Characters": "Gå med i strömmen och chatta med AI-karaktärer",
      "Please complete your onboarding in order to keep using our platform for free.": "Vänligen slutför din onboarding för att fortsätta använda vår plattform gratis.",
      "Looks like you are a heavy user of our platform!": "Det verkar som att du är en stor användare av vår plattform!",
      "You need to complete your onboarding in order to continue": "Du måste slutföra din onboarding för att kunna fortsätta",
      "Complete onboarding": "Komplett onboarding",
      "Shape": "Form",
      "Yes, show me ads. I understand why you do it": "Ja, visa mig annonser. Jag förstår varför du gör det",
      "No, do not show me ads, I hate ads": "Nej, visa mig inga annonser, jag hatar annonser",
      "It costs us a lot of money to operate this platform.": "Det kostar oss mycket pengar att driva den här plattformen.",
      "Just one question": "Bara en fråga",
      "It would really help us if you allowed us to show you some ads.": "Det skulle verkligen hjälpa oss om du tillåter oss att visa några annonser.",
      "This way you can keep using our platform for free, while helping us break even.": "På det här sättet kan du fortsätta använda vår plattform gratis, samtidigt som du hjälper oss att nå ut.",
      "Email address": "E-postadress",
      "Create a Password": "Skapa ett lösenord",
      "Your email address": "din e-postadress",
      "Your password": "Ditt lösenord",
      "Sign up": "Bli Medlem",
      "Signing up ...": "Registrerar dig ...",
      "Sign in with": "Logga in med",
      "Don't have an account? Sign up": "Har du inget konto? Bli Medlem",
      "Check your email for the confirmation link": "Kontrollera din e-post för bekräftelselänken",
      "Your Password": "Ditt lösenord",
      "Sign in": "Logga in",
      "Signing in ...": "Loggar in ...",
      "Already have an account? Sign in": "Har du redan ett konto? Logga in",
      "Send Magic Link": "Skicka Magic Link",
      "Sending Magic Link ...": "Skickar magisk länk ...",
      "Send a magic link email": "Skicka ett e-postmeddelande med en magisk länk",
      "Check your email for the magic link": "Kontrollera din e-post för den magiska länken",
      "Send reset password instructions": "Skicka instruktioner för återställning av lösenord",
      "Sending reset instructions ...": "Skickar återställningsinstruktioner ...",
      "Forgot your password?": "Glömt ditt lösenord?",
      "Check your email for the password reset link": "Kontrollera din e-post för länken för återställning av lösenord",
      "New password": "Nytt lösenord",
      "Your new password": "Ditt nya lösenord",
      "Update password": "Uppdatera lösenord",
      "Updating password ...": "Uppdaterar lösenord...",
      "Your password has been updated": "ditt lösenord har uppdaterats",
      "Your phone number": "Ditt telefonnummer",
      "Token": "Tecken",
      "Your Otp token": "Din otp-token",
      "Verify token": "Verifiera token",
      "We need a sponsor": "Vi behöver en sponsor",
      "Help us break even": "Hjälp oss att gå i balans",
      "Help us keep the platform free": "Hjälp oss att hålla plattformen fri",
      "My images": "Mina bilder",
      "Gallery": "Galleri",
      "Super Voice": "Super röst",
      "Create speech from text": "Skapa tal från text",
      "Create speech": "Skapa tal",
      "This is Gordon Ramsay, what would you like to eat?": "Det här är Gordon Ramsay, vad skulle du vilja äta?",
      "New": "Ny",
      "Images": "Bilder",
      "GIFs": "GIF-filer"
    }
  },
  "hu": {
    "translation": {
      "Home": "itthon",
      "Logout": "Kijelentkezés",
      "Profile": "Profil",
      "Onboard": "A fedélzeten",
      "Blog": "Blog",
      "Contact Us": "Lépjen kapcsolatba velünk",
      "Login": "Belépés",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "Kérjük, menjen, és igazolja e-mail-címét, hogy továbbra is használni tudja a webhelyet. Küldtünk egy e-mailt egy linkkel, amelyre kattinthat.",
      "Resend verification email": "Igazoló e-mail újraküldése",
      "Call us at +1-877-775-5232": "Hívjon minket a +1-877-775-5232 telefonszámon",
      "Super Pipeline": "Super Pipeline",
      "About": "Ról ről",
      "Signup Free": "Regisztrálj ingyen",
      "Disclaimer": "Jogi nyilatkozat",
      "Contact us with any question at": "Bármilyen kérdéssel forduljon hozzánk a telefonszámon",
      "Call us at": "Hívjon minket a számon",
      "Plans": "Tervek",
      "Privacy policy": "Adatvédelmi irányelvek",
      "Terms of service": "Szolgáltatás feltételei",
      "Finished": "Befejezett",
      "Continue": "Folytatni",
      "Start Over": "Elölről kezdeni",
      "Start (100% FREE)": "Kezdés (100% INGYENES)",
      "Submit": "Beküldés",
      "Examples": "Példák",
      "Select character": "Válasszon karaktert",
      "Video Examples": "Videó példák",
      "Write a poem about life": "Írj egy verset az életről",
      "Explain how molecules work": "Magyarázza el, hogyan működnek a molekulák!",
      "Write a story about life in Mars": "Írj egy történetet a marsi életről",
      "Create an entire video from a single prompt": "Hozzon létre egy teljes videót egyetlen promptból",
      "Entertain, inform and inspire": "Szórakoztat, informál és inspirál",
      "through the power of AI": "az AI erején keresztül",
      "Create Video!": "Videó létrehozása!",
      "one more step before you start Just": "még egy lépés, mielőtt elkezdené",
      "First name": "Keresztnév",
      "What is your first name": "Mi a keresztneve",
      "Please input your first name!": "Kérjük, adja meg a keresztnevét!",
      "Last/family name": "Vezeték-/családnév",
      "What is your last/family name": "Mi a vezeték-/családi neved",
      "Please input your last/family name!": "Kérjük, adja meg vezeték-/családnevét!",
      "Nickname": "Becenév",
      "What do you want others to call you?": "Hogy hívják mások?",
      "Please input your nickname!": "Kérjük, adja meg becenevét!",
      "Company": "Vállalat",
      "What is the company you represent?": "Milyen céget képvisel?",
      "Please input your company!": "Kérjük, adja meg cégét!",
      "Phone Number": "Telefonszám",
      "What is your phone number?": "Mi a telefonszáma?",
      "Why we ask for your phone number": "Miért kérjük a telefonszámát?",
      "Account Recovery": "fiók visszaállítás",
      "If you forget your password or get locked out of your account, a phone number provides an additional way to verify your identity and regain access.": "Ha elfelejti jelszavát, vagy kizárja magát fiókjából, egy telefonszám további lehetőséget biztosít személyazonosságának igazolására és a hozzáférés visszaszerzésére.",
      "Fraud Prevention": "Csalásmegelőzés",
      "by asking for phone number it limits fake or fraudulent account creation. It's more challenging for scammers to maintain numerous unique phone numbers than it is for email addresses.": "telefonszám kérésével korlátozza a hamis vagy csaló fiók létrehozását. A csalók számára nagyobb kihívást jelent számos egyedi telefonszám fenntartása, mint az e-mail címek esetében.",
      "Communication": "Kommunikáció",
      "we may communicate with users directly through phone calls or SMS, whether for support or account issues": "telefonon vagy SMS-en keresztül közvetlenül kommunikálhatunk a felhasználókkal, akár támogatási, akár fiókkal kapcsolatos problémák miatt",
      "Please input your phone number! Example +18777755232": "Kérjük adja meg telefonszámát! Példa +18777755232",
      "I agree to the": "egyet értek a",
      "privacy policy": "Adatvédelmi irányelvek",
      "terms of service": "szolgáltatás feltételei",
      "disclaimer": "felelősségkizárás",
      "Just one more step before you start": "Még egy lépés, mielőtt elkezdené",
      "Phone number": "Telefonszám",
      "CONTACT US": "LÉPJEN KAPCSOLATBA VELÜNK",
      "BLOGS": "BLOGOK",
      "Published": "Közzétett",
      "Office": "Hivatal",
      "Super Character": "Szuper karakter",
      "Super Meme": "Szuper mém",
      "Hello, how are you?": "Helló, hogy vagy?",
      "Say something": "Mondj valamit",
      "Download video": "Videó letöltése",
      "Check out this cool AI text to video tool": "Nézze meg ezt a klassz AI szövegből videó eszközt",
      "Share": "Ossza meg",
      "Download": "Letöltés",
      "Select or search a character": "Válasszon ki vagy keressen egy karaktert",
      "Copy link": "Link másolása",
      "Link copied to clipboard": "Link a vágólapra másolva",
      "This character cannot create videos for now": "Ez a karakter egyelőre nem tud videókat létrehozni",
      "Create GIFs from your prompts": "Hozzon létre GIF-eket az üzenetekből",
      "Create GIF": "GIF létrehozása",
      "Monkey sitting on a chair and eating Pasta": "Majom egy széken ül és tésztát eszik",
      "Please wait (30-60 seconds)": "Kérjük, várjon (30-60 másodperc)",
      "Please wait (30-120 seconds)": "Kérjük, várjon (30-120 másodperc)",
      "Prompt": "Gyors",
      "The future of A.I. content creation": "Az A.I. jövője tartalomkészítés",
      "Try these prompts": "Próbálja ki ezeket az utasításokat",
      "Signup FREE": "Regisztrálj ingyen",
      "Cancel": "Megszünteti",
      "Error": "Hiba",
      "Hide video": "Videó elrejtése",
      "It's 100% FREE!": "100% INGYENES!",
      "Win $100 Gift Cards for the best GIF!": "Nyerj 100 dolláros ajándékkártyát a legjobb GIF-ért!",
      "How can I win?": "Hogyan nyerhetek?",
      "Create a GIF": "Hozzon létre egy GIF-et",
      "Post it on Reddit": "Tedd közzé a Redditen",
      "Send us a link to your post": "Küldj nekünk egy linket a bejegyzésedhez",
      "The GIF with the most upvotes wins a weekly $100 Gift Card!": "A legtöbb pozitív szavazatot kapott GIF heti 100 dolláros ajándékkártyát nyer!",
      "Minimum 20 upvotes required": "Minimum 20 pozitív szavazat szükséges",
      "Email us your GIF": "Küldje el nekünk a GIF-et e-mailben",
      "Create your GIF": "Hozd létre a GIF-edet",
      "Submit your GIF to Reddit - Get $100 Gift Card!": "Küldje be GIF-jét a Redditnek – 100 dolláros ajándékutalványt kaphat!",
      "Welcome to the chat room with": "Üdvözöljük a csevegőszobában",
      "Click on one of these messages": "Kattintson az üzenetek egyikére",
      "Super GIF": "Szuper GIF",
      "Generating your video": "Videó létrehozása",
      "Click this button in order to generate a video from this response": "Kattintson erre a gombra, ha videót szeretne generálni ebből a válaszból",
      "Start": "Rajt",
      "You need to signup for free in order to create a GIF": "GIF létrehozásához ingyenesen kell regisztrálnia",
      "Create video": "Videó létrehozása",
      "Share video": "Videó megosztása",
      "Create images from your prompts": "Hozzon létre képeket az üzenetekből",
      "Create image": "Kép létrehozása",
      "Please wait (10-30 seconds)": "Kérjük, várjon (10-30 másodperc)",
      "Download GIF": "GIF letöltése",
      "Download image": "Kép letöltése",
      "Super Image": "Szuper kép",
      "Super Person": "Szuper Személy",
      "Create fake person": "Hamis személy létrehozása",
      "Select gender": "Válasszon nemet",
      "Select age": "Válassza ki az életkort",
      "Select ethnic": "Válassza ki az etnikumot",
      "Fake Person Generator": "Hamis személy generátor",
      "We create AI tools for content creators": "AI-eszközöket készítünk tartalomkészítők számára",
      "You need to signup for free in order to use our tools": "Eszközeink használatához ingyenes regisztráció szükséges",
      "Show more": "Mutass többet",
      "Chat with your favorite characters": "Csevegés kedvenc karaktereivel",
      "Chat with over 60 characters": "Csevegés több mint 60 karakterrel",
      "Style": "Stílus",
      "Error sending the chat message. Please try again": "Hiba a csevegőüzenet elküldésekor. Kérlek próbáld újra",
      "Error generating the video": "Hiba a videó generálásakor",
      "Twitch stream": "Twitch folyam",
      "Join the stream and chat with AI Characters": "Csatlakozz a streamhez, és csevegj az AI karakterekkel",
      "Please complete your onboarding in order to keep using our platform for free.": "Kérjük, fejezze be a regisztrációt, hogy továbbra is ingyenesen használhassa platformunkat.",
      "Looks like you are a heavy user of our platform!": "Úgy tűnik, Ön nagy felhasználója platformunknak!",
      "You need to complete your onboarding in order to continue": "A folytatáshoz be kell fejeznie a felvételt",
      "Complete onboarding": "Teljes beépülés",
      "Shape": "Alak",
      "Yes, show me ads. I understand why you do it": "Igen, mutasson hirdetéseket. Megértem, miért csinálja",
      "No, do not show me ads, I hate ads": "Nem, ne mutass nekem hirdetéseket, utálom a hirdetéseket",
      "It costs us a lot of money to operate this platform.": "Nagyon sok pénzünkbe kerül ennek a platformnak a működtetése.",
      "Just one question": "Csak egy kérdés",
      "It would really help us if you allowed us to show you some ads.": "Nagyon sokat segítene nekünk, ha megengedné, hogy megjelenítsünk Önnek néhány hirdetést.",
      "This way you can keep using our platform for free, while helping us break even.": "Így továbbra is ingyenesen használhatja platformunkat, miközben segít nekünk a nullszaldósításban.",
      "Email address": "Email cím",
      "Create a Password": "Hozzon létre egy jelszót",
      "Your email address": "az email címed",
      "Your password": "A jelszavad",
      "Sign up": "Regisztrálj",
      "Signing up ...": "Feliratkozás ...",
      "Sign in with": "Jelentkezz be vele",
      "Don't have an account? Sign up": "Nincs fiókod? Regisztrálj",
      "Check your email for the confirmation link": "Ellenőrizze e-mailjében a megerősítő linket",
      "Your Password": "A jelszavad",
      "Sign in": "Bejelentkezés",
      "Signing in ...": "Bejelentkezés ...",
      "Already have an account? Sign in": "Már van fiókja? Bejelentkezés",
      "Send Magic Link": "Varázslink küldése",
      "Sending Magic Link ...": "Varázslink küldése...",
      "Send a magic link email": "Küldj egy varázslatos linket e-mailben",
      "Check your email for the magic link": "Nézze meg e-mailben a varázslatos linket",
      "Send reset password instructions": "Küldje el a jelszó visszaállítására vonatkozó utasításokat",
      "Sending reset instructions ...": "Visszaállítási utasítások küldése...",
      "Forgot your password?": "Elfelejtette a jelszavát?",
      "Check your email for the password reset link": "Keresse meg e-mailben a jelszó-visszaállítási hivatkozást",
      "New password": "Új jelszó",
      "Your new password": "Az új jelszavad",
      "Update password": "Frissítse a jelszót",
      "Updating password ...": "Jelszó frissítése...",
      "Your password has been updated": "A jelszava frissítve lett",
      "Your phone number": "A telefonszámod",
      "Token": "Jelképes",
      "Your Otp token": "Az Ön Otp tokenje",
      "Verify token": "Token ellenőrzése",
      "We need a sponsor": "Szükségünk van egy szponzorra",
      "Help us break even": "Segíts nekünk megúszni",
      "Help us keep the platform free": "Segítsen nekünk, hogy a platform ingyenes legyen",
      "My images": "A képeim",
      "Gallery": "Képtár",
      "Super Voice": "Szuper hang",
      "Create speech from text": "Készítsen beszédet szövegből",
      "Create speech": "Készítsen beszédet",
      "This is Gordon Ramsay, what would you like to eat?": "Itt Gordon Ramsay, mit szeretnél enni?",
      "New": "Új",
      "Images": "Képek",
      "GIFs": "GIF-ek"
    }
  },
  "da": {
    "translation": {
      "Home": "Hjem",
      "Logout": "Log ud",
      "Profile": "Profil",
      "Onboard": "Ombord",
      "Blog": "Blog",
      "Contact Us": "Kontakt os",
      "Login": "Log på",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "Gå og bekræft din e-mail, så du kan fortsætte med at bruge hjemmesiden. Vi har sendt dig en e-mail med et link til at klikke på.",
      "Resend verification email": "Gensend bekræftelses mail",
      "Call us at +1-877-775-5232": "Ring til os på +1-877-775-5232",
      "Super Pipeline": "Super Pipeline",
      "About": "Om",
      "Signup Free": "Tilmelding gratis",
      "Disclaimer": "Ansvarsfraskrivelse",
      "Contact us with any question at": "Kontakt os med ethvert spørgsmål på",
      "Call us at": "Ring til os på",
      "Plans": "Planer",
      "Privacy policy": "Fortrolighedspolitik",
      "Terms of service": "Servicevilkår",
      "Finished": "Færdig",
      "Continue": "Blive ved",
      "Start Over": "Start forfra",
      "Start (100% FREE)": "Start (100 % GRATIS)",
      "Submit": "Indsend",
      "Examples": "Eksempler",
      "Select character": "Vælg tegn",
      "Video Examples": "Video eksempler",
      "Write a poem about life": "Skriv et digt om livet",
      "Explain how molecules work": "Forklar hvordan molekyler virker",
      "Write a story about life in Mars": "Skriv en historie om livet på Mars",
      "Create an entire video from a single prompt": "Opret en hel video fra en enkelt prompt",
      "Entertain, inform and inspire": "Underholde, informere og inspirere",
      "through the power of AI": "gennem kraften fra AI",
      "Create Video!": "Opret video!",
      "one more step before you start Just": "et skridt mere, før du starter",
      "First name": "Fornavn",
      "What is your first name": "Hvad er dit fornavn",
      "Please input your first name!": "Indtast venligst dit fornavn!",
      "Last/family name": "Efternavn/familienavn",
      "What is your last/family name": "Hvad er dit efternavn/familienavn",
      "Please input your last/family name!": "Indtast venligst dit efternavn/familienavn!",
      "Nickname": "Kaldenavn",
      "What do you want others to call you?": "Hvad vil du have, at andre skal kalde dig?",
      "Please input your nickname!": "Indtast venligst dit kaldenavn!",
      "Company": "Selskab",
      "What is the company you represent?": "Hvad er det firma, du repræsenterer?",
      "Please input your company!": "Indtast venligst din virksomhed!",
      "Phone Number": "Telefonnummer",
      "What is your phone number?": "Hvad er dit telefonnummer?",
      "Why we ask for your phone number": "Hvorfor beder vi om dit telefonnummer",
      "Account Recovery": "Kontogendannelse",
      "If you forget your password or get locked out of your account, a phone number provides an additional way to verify your identity and regain access.": "Hvis du glemmer din adgangskode eller bliver låst ude af din konto, giver et telefonnummer en yderligere måde at bekræfte din identitet på og genvinde adgang.",
      "Fraud Prevention": "Forebyggelse af svindel",
      "by asking for phone number it limits fake or fraudulent account creation. It's more challenging for scammers to maintain numerous unique phone numbers than it is for email addresses.": "ved at bede om telefonnummer begrænser det oprettelse af falsk eller svigagtig konto. Det er mere udfordrende for svindlere at opretholde adskillige unikke telefonnumre, end det er for e-mail-adresser.",
      "Communication": "Meddelelse",
      "we may communicate with users directly through phone calls or SMS, whether for support or account issues": "vi kan kommunikere med brugere direkte via telefonopkald eller SMS, uanset om det er til support eller kontoproblemer",
      "Please input your phone number! Example +18777755232": "Indtast venligst dit telefonnummer! Eksempel +18777755232",
      "I agree to the": "Jeg er enig i",
      "privacy policy": "Fortrolighedspolitik",
      "terms of service": "servicevilkår",
      "disclaimer": "ansvarsfraskrivelse",
      "Just one more step before you start": "Bare et skridt mere, før du starter",
      "Phone number": "Telefonnummer",
      "CONTACT US": "KONTAKT OS",
      "BLOGS": "BLOGS",
      "Published": "Udgivet",
      "Office": "Kontor",
      "Super Character": "Super karakter",
      "Super Meme": "Super meme",
      "Hello, how are you?": "Hej, hvordan går det?",
      "Say something": "Sig noget",
      "Download video": "Download video",
      "Check out this cool AI text to video tool": "Tjek dette seje AI tekst til video-værktøj",
      "Share": "Del",
      "Download": "Hent",
      "Select or search a character": "Vælg eller søg efter et tegn",
      "Copy link": "Kopier link",
      "Link copied to clipboard": "Linket er kopieret til udklipsholderen",
      "This character cannot create videos for now": "Denne karakter kan ikke oprette videoer i øjeblikket",
      "Create GIFs from your prompts": "Opret GIF'er fra dine prompter",
      "Create GIF": "Opret GIF",
      "Monkey sitting on a chair and eating Pasta": "Abe sidder på en stol og spiser pasta",
      "Please wait (30-60 seconds)": "Vent venligst (30-60 sekunder)",
      "Please wait (30-120 seconds)": "Vent venligst (30-120 sekunder)",
      "Prompt": "Hurtig",
      "The future of A.I. content creation": "Fremtiden for A.I. skabelse af indhold",
      "Try these prompts": "Prøv disse prompter",
      "Signup FREE": "Tilmeld dig GRATIS",
      "Cancel": "Afbestille",
      "Error": "Fejl",
      "Hide video": "Skjul video",
      "It's 100% FREE!": "Det er 100 % GRATIS!",
      "Win $100 Gift Cards for the best GIF!": "Vind gavekort på $100 til den bedste GIF!",
      "How can I win?": "Hvordan kan jeg vinde?",
      "Create a GIF": "Opret en GIF",
      "Post it on Reddit": "Post det på Reddit",
      "Send us a link to your post": "Send os et link til dit indlæg",
      "The GIF with the most upvotes wins a weekly $100 Gift Card!": "GIF'en med flest opstemmer vinder et ugentlig gavekort på $100!",
      "Minimum 20 upvotes required": "Minimum 20 stemmer for",
      "Email us your GIF": "Send os en e-mail med din GIF",
      "Create your GIF": "Opret din GIF",
      "Submit your GIF to Reddit - Get $100 Gift Card!": "Indsend din GIF til Reddit - Få $100 gavekort!",
      "Welcome to the chat room with": "Velkommen til chatrummet med",
      "Click on one of these messages": "Klik på en af ​​disse beskeder",
      "Super GIF": "Super GIF",
      "Generating your video": "Genererer din video",
      "Click this button in order to generate a video from this response": "Klik på denne knap for at generere en video fra dette svar",
      "Start": "Start",
      "You need to signup for free in order to create a GIF": "Du skal tilmelde dig gratis for at oprette en GIF",
      "Create video": "Opret video",
      "Share video": "Del video",
      "Create images from your prompts": "Opret billeder fra dine prompter",
      "Create image": "Opret billede",
      "Please wait (10-30 seconds)": "Vent venligst (10-30 sekunder)",
      "Download GIF": "Download GIF",
      "Download image": "Download billede",
      "Super Image": "Super billede",
      "Super Person": "Super person",
      "Create fake person": "Skab en falsk person",
      "Select gender": "Vælg køn",
      "Select age": "Vælg alder",
      "Select ethnic": "Vælg etnisk",
      "Fake Person Generator": "Fake Person Generator",
      "We create AI tools for content creators": "Vi skaber AI-værktøjer til indholdsskabere",
      "You need to signup for free in order to use our tools": "Du skal tilmelde dig gratis for at bruge vores værktøjer",
      "Show more": "Vis mere",
      "Chat with your favorite characters": "Chat med dine yndlingsfigurer",
      "Chat with over 60 characters": "Chat med over 60 tegn",
      "Style": "Stil",
      "Error sending the chat message. Please try again": "Fejl ved afsendelse af chatbeskeden. Prøv igen",
      "Error generating the video": "Fejl under generering af videoen",
      "Twitch stream": "Twitch stream",
      "Join the stream and chat with AI Characters": "Deltag i streamen og chat med AI-karakterer",
      "Please complete your onboarding in order to keep using our platform for free.": "Fuldfør venligst din onboarding for at fortsætte med at bruge vores platform gratis.",
      "Looks like you are a heavy user of our platform!": "Det ser ud til, at du er en stor bruger af vores platform!",
      "You need to complete your onboarding in order to continue": "Du skal fuldføre din onboarding for at fortsætte",
      "Complete onboarding": "Fuldstændig onboarding",
      "Shape": "Form",
      "Yes, show me ads. I understand why you do it": "Ja, vis mig annoncer. Jeg forstår hvorfor du gør det",
      "No, do not show me ads, I hate ads": "Nej, vis mig ikke annoncer, jeg hader annoncer",
      "It costs us a lot of money to operate this platform.": "Det koster os mange penge at drive denne platform.",
      "Just one question": "Bare et spørgsmål",
      "It would really help us if you allowed us to show you some ads.": "Det ville virkelig hjælpe os, hvis du gav os lov til at vise dig nogle annoncer.",
      "This way you can keep using our platform for free, while helping us break even.": "På denne måde kan du blive ved med at bruge vores platform gratis, samtidig med at du hjælper os med at break-even.",
      "Email address": "Email adresse",
      "Create a Password": "Lav et kodeord",
      "Your email address": "Din email adresse",
      "Your password": "Dit kodeord",
      "Sign up": "Tilmelde",
      "Signing up ...": "Tilmelding...",
      "Sign in with": "Log ind med",
      "Don't have an account? Sign up": "Har du ikke en konto? Tilmelde",
      "Check your email for the confirmation link": "Tjek din e-mail for bekræftelseslinket",
      "Your Password": "Dit kodeord",
      "Sign in": "Log ind",
      "Signing in ...": "Logger ind ...",
      "Already have an account? Sign in": "har du allerede en bruger? Log ind",
      "Send Magic Link": "Send Magic Link",
      "Sending Magic Link ...": "Sender Magic Link...",
      "Send a magic link email": "Send en magisk link-e-mail",
      "Check your email for the magic link": "Tjek din e-mail for det magiske link",
      "Send reset password instructions": "Send instruktioner til nulstilling af adgangskode",
      "Sending reset instructions ...": "Sender nulstillingsinstruktioner...",
      "Forgot your password?": "Glemt din adgangskode?",
      "Check your email for the password reset link": "Tjek din e-mail for linket til nulstilling af adgangskode",
      "New password": "Nyt kodeord",
      "Your new password": "Din nye adgangskode",
      "Update password": "Opdater adgangskode",
      "Updating password ...": "Opdaterer adgangskode...",
      "Your password has been updated": "Din adgangskode er blevet opdateret",
      "Your phone number": "Dit telefonnummer",
      "Token": "Polet",
      "Your Otp token": "Dit otp-token",
      "Verify token": "Bekræft token",
      "We need a sponsor": "Vi mangler en sponsor",
      "Help us break even": "Hjælp os med at gå i balance",
      "Help us keep the platform free": "Hjælp os med at holde platformen fri",
      "My images": "Mine billeder",
      "Gallery": "Galleri",
      "Super Voice": "Super stemme",
      "Create speech from text": "Opret tale ud fra tekst",
      "Create speech": "Skab tale",
      "This is Gordon Ramsay, what would you like to eat?": "Det her er Gordon Ramsay, hvad vil du gerne spise?",
      "New": "Ny",
      "Images": "Billeder",
      "GIFs": "GIF'er"
    }
  },
  "id": {
    "translation": {
      "Home": "Rumah",
      "Logout": "Keluar",
      "Profile": "Profil",
      "Onboard": "Di atas kapal",
      "Blog": "Blog",
      "Contact Us": "Hubungi kami",
      "Login": "Gabung",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "Silakan buka dan verifikasi email Anda sehingga Anda dapat terus menggunakan situs web. Kami mengirimi Anda email berisi tautan untuk diklik.",
      "Resend verification email": "Mengirim ulang email verifikasi",
      "Call us at +1-877-775-5232": "Hubungi kami di +1-877-775-5232",
      "Super Pipeline": "Pipa Super",
      "About": "Tentang",
      "Signup Free": "Pendaftaran Gratis",
      "Disclaimer": "Penafian",
      "Contact us with any question at": "Hubungi kami jika ada pertanyaan di",
      "Call us at": "Hubungi kami di",
      "Plans": "Rencana",
      "Privacy policy": "Kebijakan pribadi",
      "Terms of service": "Persyaratan layanan",
      "Finished": "Selesai",
      "Continue": "Melanjutkan",
      "Start Over": "Mulai dari awal",
      "Start (100% FREE)": "Mulai (100% GRATIS)",
      "Submit": "Kirim",
      "Examples": "Contoh",
      "Select character": "Pilih karakter",
      "Video Examples": "Contoh Video",
      "Write a poem about life": "Menulis puisi tentang kehidupan",
      "Explain how molecules work": "Jelaskan cara kerja molekul",
      "Write a story about life in Mars": "Tulislah cerita tentang kehidupan di Mars",
      "Create an entire video from a single prompt": "Buat seluruh video dari satu perintah",
      "Entertain, inform and inspire": "Menghibur, menginformasikan dan menginspirasi",
      "through the power of AI": "melalui kekuatan AI",
      "Create Video!": "Buat Video!",
      "one more step before you start Just": "satu langkah lagi sebelum Anda mulai",
      "First name": "Nama depan",
      "What is your first name": "Apa nama depan Anda",
      "Please input your first name!": "Silakan masukkan nama depan Anda!",
      "Last/family name": "Nama belakang",
      "What is your last/family name": "Apa nama belakang/keluarga Anda",
      "Please input your last/family name!": "Silakan masukkan nama belakang/keluarga Anda!",
      "Nickname": "Nama panggilan",
      "What do you want others to call you?": "Anda ingin orang lain memanggil Anda apa?",
      "Please input your nickname!": "Silakan masukkan nama panggilan Anda!",
      "Company": "Perusahaan",
      "What is the company you represent?": "Apa perusahaan yang Anda wakili?",
      "Please input your company!": "Silakan masukkan perusahaan Anda!",
      "Phone Number": "Nomor telepon",
      "What is your phone number?": "Berapa nomor telepon anda?",
      "Why we ask for your phone number": "Mengapa kami meminta nomor telepon Anda",
      "Account Recovery": "Pemulihan Akun",
      "If you forget your password or get locked out of your account, a phone number provides an additional way to verify your identity and regain access.": "Jika Anda lupa kata sandi atau akun Anda terkunci, nomor telepon memberikan cara tambahan untuk memverifikasi identitas Anda dan mendapatkan kembali akses.",
      "Fraud Prevention": "Pencegahan Penipuan",
      "by asking for phone number it limits fake or fraudulent account creation. It's more challenging for scammers to maintain numerous unique phone numbers than it is for email addresses.": "dengan meminta nomor telepon itu membatasi pembuatan akun palsu atau penipuan. Lebih sulit bagi penipu untuk menyimpan banyak nomor telepon unik dibandingkan alamat email.",
      "Communication": "Komunikasi",
      "we may communicate with users directly through phone calls or SMS, whether for support or account issues": "kami dapat berkomunikasi dengan pengguna secara langsung melalui panggilan telepon atau SMS, baik untuk dukungan atau masalah akun",
      "Please input your phone number! Example +18777755232": "Silakan masukkan nomor telepon Anda! Contoh +18777755232",
      "I agree to the": "Saya setuju dengan",
      "privacy policy": "Kebijakan pribadi",
      "terms of service": "ketentuan layanan",
      "disclaimer": "penafian",
      "Just one more step before you start": "Tinggal satu langkah lagi sebelum Anda mulai",
      "Phone number": "Nomor telepon",
      "CONTACT US": "HUBUNGI KAMI",
      "BLOGS": "BLOG",
      "Published": "Diterbitkan",
      "Office": "Kantor",
      "Super Character": "Karakter Super",
      "Super Meme": "Meme Super",
      "Hello, how are you?": "Halo apa kabarmu?",
      "Say something": "Katakan sesuatu",
      "Download video": "Unduh video",
      "Check out this cool AI text to video tool": "Lihat alat teks ke video AI yang keren ini",
      "Share": "Membagikan",
      "Download": "Unduh",
      "Select or search a character": "Pilih atau cari karakter",
      "Copy link": "Salin tautan",
      "Link copied to clipboard": "Tautan disalin ke papan klip",
      "This character cannot create videos for now": "Karakter ini tidak dapat membuat video untuk saat ini",
      "Create GIFs from your prompts": "Buat GIF dari perintah Anda",
      "Create GIF": "Buat GIF",
      "Monkey sitting on a chair and eating Pasta": "Monyet duduk di kursi dan makan Pasta",
      "Please wait (30-60 seconds)": "Harap tunggu (30-60 detik)",
      "Please wait (30-120 seconds)": "Harap tunggu (30-120 detik)",
      "Prompt": "Mengingatkan",
      "The future of A.I. content creation": "Masa depan A.I. pembuatan konten",
      "Try these prompts": "Coba petunjuk ini",
      "Signup FREE": "Daftar GRATIS",
      "Cancel": "Membatalkan",
      "Error": "Kesalahan",
      "Hide video": "Sembunyikan video",
      "It's 100% FREE!": "Ini 100% GRATIS!",
      "Win $100 Gift Cards for the best GIF!": "Menangkan Kartu Hadiah $100 untuk GIF terbaik!",
      "How can I win?": "Bagaimana saya bisa menang?",
      "Create a GIF": "Buat GIF",
      "Post it on Reddit": "Posting di Reddit",
      "Send us a link to your post": "Kirimi kami tautan ke postingan Anda",
      "The GIF with the most upvotes wins a weekly $100 Gift Card!": "GIF dengan suara positif terbanyak memenangkan Kartu Hadiah mingguan sebesar $100!",
      "Minimum 20 upvotes required": "Diperlukan minimal 20 suara positif",
      "Email us your GIF": "Kirimkan GIF Anda melalui email kepada kami",
      "Create your GIF": "Buat GIF Anda",
      "Submit your GIF to Reddit - Get $100 Gift Card!": "Kirim GIF Anda ke Reddit - Dapatkan Kartu Hadiah $100!",
      "Welcome to the chat room with": "Selamat datang di ruang obrolan dengan",
      "Click on one of these messages": "Klik salah satu pesan ini",
      "Super GIF": "GIF super",
      "Generating your video": "Menghasilkan video Anda",
      "Click this button in order to generate a video from this response": "Klik tombol ini untuk menghasilkan video dari respons ini",
      "Start": "Awal",
      "You need to signup for free in order to create a GIF": "Anda perlu mendaftar secara gratis untuk membuat GIF",
      "Create video": "Buat video",
      "Share video": "Berbagi video",
      "Create images from your prompts": "Buat gambar dari perintah Anda",
      "Create image": "Buat gambar",
      "Please wait (10-30 seconds)": "Harap tunggu (10-30 detik)",
      "Download GIF": "Unduh GIF",
      "Download image": "Unduh gambar",
      "Super Image": "Gambar Super",
      "Super Person": "Orang Super",
      "Create fake person": "Buat orang palsu",
      "Select gender": "Pilih jenis kelamin",
      "Select age": "Pilih usia",
      "Select ethnic": "Pilih etnis",
      "Fake Person Generator": "Generator Orang Palsu",
      "We create AI tools for content creators": "Kami menciptakan alat AI untuk pembuat konten",
      "You need to signup for free in order to use our tools": "Anda perlu mendaftar secara gratis untuk menggunakan alat kami",
      "Show more": "Menampilkan lebih banyak",
      "Chat with your favorite characters": "Ngobrol dengan karakter favorit Anda",
      "Chat with over 60 characters": "Ngobrol dengan lebih dari 60 karakter",
      "Style": "Gaya",
      "Error sending the chat message. Please try again": "Terjadi kesalahan saat mengirim pesan obrolan. Silakan coba lagi",
      "Error generating the video": "Terjadi kesalahan saat membuat video",
      "Twitch stream": "Aliran kedutan",
      "Join the stream and chat with AI Characters": "Bergabunglah dalam streaming dan ngobrol dengan Karakter AI",
      "Please complete your onboarding in order to keep using our platform for free.": "Silakan selesaikan orientasi Anda untuk terus menggunakan platform kami secara gratis.",
      "Looks like you are a heavy user of our platform!": "Sepertinya Anda adalah pengguna berat platform kami!",
      "You need to complete your onboarding in order to continue": "Anda harus menyelesaikan orientasi untuk melanjutkan",
      "Complete onboarding": "Selesaikan orientasi",
      "Shape": "Membentuk",
      "Yes, show me ads. I understand why you do it": "Ya, tampilkan iklan kepada saya. Saya mengerti mengapa Anda melakukannya",
      "No, do not show me ads, I hate ads": "Tidak, jangan tampilkan iklan kepada saya, saya benci iklan",
      "It costs us a lot of money to operate this platform.": "Kami menghabiskan banyak uang untuk mengoperasikan platform ini.",
      "Just one question": "Hanya satu pertanyaan",
      "It would really help us if you allowed us to show you some ads.": "Akan sangat membantu kami jika Anda mengizinkan kami menampilkan beberapa iklan kepada Anda.",
      "This way you can keep using our platform for free, while helping us break even.": "Dengan cara ini Anda dapat terus menggunakan platform kami secara gratis, sambil membantu kami mencapai titik impas.",
      "Email address": "Alamat email",
      "Create a Password": "Buat kata sandi",
      "Your email address": "Alamat email anda",
      "Your password": "Kata sandi Anda",
      "Sign up": "Mendaftar",
      "Signing up ...": "Mendaftar ...",
      "Sign in with": "Masuk dengan",
      "Don't have an account? Sign up": "Belum punya akun? Mendaftar",
      "Check your email for the confirmation link": "Periksa email Anda untuk tautan konfirmasi",
      "Your Password": "Kata sandi Anda",
      "Sign in": "Masuk",
      "Signing in ...": "Masuk ...",
      "Already have an account? Sign in": "Sudah memiliki akun? Masuk",
      "Send Magic Link": "Kirim Tautan Ajaib",
      "Sending Magic Link ...": "Mengirim Tautan Ajaib ...",
      "Send a magic link email": "Kirim email tautan ajaib",
      "Check your email for the magic link": "Periksa email Anda untuk tautan ajaib",
      "Send reset password instructions": "Kirim instruksi pengaturan ulang kata sandi",
      "Sending reset instructions ...": "Mengirim instruksi reset ...",
      "Forgot your password?": "lupa kata sandi Anda?",
      "Check your email for the password reset link": "Periksa email Anda untuk tautan pengaturan ulang kata sandi",
      "New password": "Kata sandi baru",
      "Your new password": "Kata sandi baru Anda",
      "Update password": "Perbarui kata sandi",
      "Updating password ...": "Memperbarui kata sandi ...",
      "Your password has been updated": "Kata sandi Anda telah diperbarui",
      "Your phone number": "Nomor telepon Anda",
      "Token": "Token",
      "Your Otp token": "Token Otp Anda",
      "Verify token": "Verifikasi token",
      "We need a sponsor": "Kami membutuhkan sponsor",
      "Help us break even": "Bantu kami mencapai titik impas",
      "Help us keep the platform free": "Bantu kami menjaga platform ini tetap gratis",
      "My images": "Gambar saya",
      "Gallery": "Galeri",
      "Super Voice": "Suara Super",
      "Create speech from text": "Buat pidato dari teks",
      "Create speech": "Buat pidato",
      "This is Gordon Ramsay, what would you like to eat?": "Ini Gordon Ramsay, kamu ingin makan apa?",
      "New": "Baru",
      "Images": "Gambar-gambar",
      "GIFs": "GIF"
    }
  },
  "ru": {
    "translation": {
      "Home": "Дом",
      "Logout": "Выйти",
      "Profile": "Профиль",
      "Onboard": "На борту",
      "Blog": "Блог",
      "Contact Us": "Связаться с нами",
      "Login": "Авторизоваться",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "Пожалуйста, зайдите и подтвердите свой адрес электронной почты, чтобы продолжить использование веб-сайта. Мы отправили вам электронное письмо со ссылкой, по которой можно перейти.",
      "Resend verification email": "Выслать повторно письмо для подтверждения",
      "Call us at +1-877-775-5232": "Позвоните нам по телефону +1-877-775-5232.",
      "Super Pipeline": "Супер Трубопровод",
      "About": "О",
      "Signup Free": "Бесплатная регистрация",
      "Disclaimer": "Отказ от ответственности",
      "Contact us with any question at": "Свяжитесь с нами по любому вопросу по адресу",
      "Call us at": "Позвоните нам по",
      "Plans": "Планы",
      "Privacy policy": "Политика конфиденциальности",
      "Terms of service": "Условия использования",
      "Finished": "Законченный",
      "Continue": "Продолжать",
      "Start Over": "Начать сначала",
      "Start (100% FREE)": "Старт (100% БЕСПЛАТНО)",
      "Submit": "Представлять на рассмотрение",
      "Examples": "Примеры",
      "Select character": "Выберите персонажа",
      "Video Examples": "Видео примеры",
      "Write a poem about life": "Написать стихотворение о жизни",
      "Explain how molecules work": "Объясните, как работают молекулы",
      "Write a story about life in Mars": "Напишите рассказ о жизни на Марсе.",
      "Create an entire video from a single prompt": "Создайте целое видео из одной подсказки",
      "Entertain, inform and inspire": "Развлекайте, информируйте и вдохновляйте",
      "through the power of AI": "благодаря силе ИИ",
      "Create Video!": "Создать видео!",
      "one more step before you start Just": "еще один шаг, прежде чем начать",
      "First name": "Имя",
      "What is your first name": "Как тебя зовут",
      "Please input your first name!": "Пожалуйста, введите свое имя!",
      "Last/family name": "Фамилия/фамилия",
      "What is your last/family name": "Какова ваша фамилия/фамилия?",
      "Please input your last/family name!": "Пожалуйста, введите свою фамилию/фамилию!",
      "Nickname": "Псевдоним",
      "What do you want others to call you?": "Как вы хотите, чтобы вас называли другие?",
      "Please input your nickname!": "Пожалуйста, введите свой никнейм!",
      "Company": "Компания",
      "What is the company you represent?": "Какую компанию вы представляете?",
      "Please input your company!": "Пожалуйста, укажите вашу компанию!",
      "Phone Number": "Номер телефона",
      "What is your phone number?": "Какой у тебя номер телефона?",
      "Why we ask for your phone number": "Почему мы просим ваш номер телефона",
      "Account Recovery": "Восстановление аккаунта",
      "If you forget your password or get locked out of your account, a phone number provides an additional way to verify your identity and regain access.": "Если вы забудете свой пароль или потеряете доступ к своей учетной записи, номер телефона предоставит дополнительный способ подтвердить вашу личность и восстановить доступ.",
      "Fraud Prevention": "Предотвращение мошенничества",
      "by asking for phone number it limits fake or fraudulent account creation. It's more challenging for scammers to maintain numerous unique phone numbers than it is for email addresses.": "запрос номера телефона ограничивает создание поддельных или мошеннических учетных записей. Мошенникам сложнее сохранить многочисленные уникальные номера телефонов, чем адреса электронной почты.",
      "Communication": "Коммуникация",
      "we may communicate with users directly through phone calls or SMS, whether for support or account issues": "мы можем общаться с пользователями напрямую посредством телефонных звонков или SMS, будь то по вопросам поддержки или по вопросам учетной записи.",
      "Please input your phone number! Example +18777755232": "Пожалуйста, введите свой номер телефона! Пример +18777755232",
      "I agree to the": "я согласен",
      "privacy policy": "политика конфиденциальности",
      "terms of service": "Условия использования",
      "disclaimer": "отказ от ответственности",
      "Just one more step before you start": "Еще один шаг, прежде чем начать",
      "Phone number": "Номер телефона",
      "CONTACT US": "СВЯЗАТЬСЯ С НАМИ",
      "BLOGS": "БЛОГИ",
      "Published": "Опубликовано",
      "Office": "Офис",
      "Super Character": "Супер Персонаж",
      "Super Meme": "Супер Мем",
      "Hello, how are you?": "Привет, как дела?",
      "Say something": "Скажите что-то",
      "Download video": "Скачать видео",
      "Check out this cool AI text to video tool": "Посмотрите этот крутой инструмент для преобразования текста в видео с помощью AI",
      "Share": "Делиться",
      "Download": "Скачать",
      "Select or search a character": "Выберите или найдите персонажа",
      "Copy link": "Копировать ссылку",
      "Link copied to clipboard": "Ссылка скопирована в буфер обмена",
      "This character cannot create videos for now": "Этот персонаж пока не может создавать видео.",
      "Create GIFs from your prompts": "Создавайте GIF-файлы из ваших подсказок",
      "Create GIF": "Создать гифку",
      "Monkey sitting on a chair and eating Pasta": "Обезьяна сидит на стуле и ест макароны",
      "Please wait (30-60 seconds)": "Пожалуйста, подождите (30-60 секунд)",
      "Please wait (30-120 seconds)": "Пожалуйста подождите (30-120 секунд)",
      "Prompt": "Быстрый",
      "The future of A.I. content creation": "Будущее ИИ. создание контента",
      "Try these prompts": "Попробуйте эти подсказки",
      "Signup FREE": "Бесплатная регистрация",
      "Cancel": "Отмена",
      "Error": "Ошибка",
      "Hide video": "Скрыть видео",
      "It's 100% FREE!": "Это 100% БЕСПЛАТНО!",
      "Win $100 Gift Cards for the best GIF!": "Выиграйте подарочные карты на 100 долларов за лучший GIF!",
      "How can I win?": "Как я могу выиграть?",
      "Create a GIF": "Создать гифку",
      "Post it on Reddit": "Опубликуйте это на Reddit",
      "Send us a link to your post": "Отправьте нам ссылку на ваш пост",
      "The GIF with the most upvotes wins a weekly $100 Gift Card!": "Гифка, набравшая наибольшее количество голосов, получает еженедельную подарочную карту на 100 долларов!",
      "Minimum 20 upvotes required": "Требуется минимум 20 голосов \"за\"",
      "Email us your GIF": "Отправьте нам свой GIF-файл по электронной почте",
      "Create your GIF": "Создайте свою гифку",
      "Submit your GIF to Reddit - Get $100 Gift Card!": "Отправьте свой GIF на Reddit — получите подарочную карту на 100 долларов!",
      "Welcome to the chat room with": "Добро пожаловать в чат с",
      "Click on one of these messages": "Нажмите на одно из этих сообщений",
      "Super GIF": "Супер гифка",
      "Generating your video": "Создание вашего видео",
      "Click this button in order to generate a video from this response": "Нажмите эту кнопку, чтобы создать видео из этого ответа.",
      "Start": "Начинать",
      "You need to signup for free in order to create a GIF": "Вам необходимо зарегистрироваться бесплатно, чтобы создать GIF.",
      "Create video": "Создать видео",
      "Share video": "Поделиться видео",
      "Create images from your prompts": "Создавайте изображения из ваших подсказок",
      "Create image": "Создать изображение",
      "Please wait (10-30 seconds)": "Пожалуйста, подождите (10-30 секунд)",
      "Download GIF": "Скачать гифку",
      "Download image": "Скачать изображение",
      "Super Image": "Супер Изображение",
      "Super Person": "Супер Человек",
      "Create fake person": "Создать фейкового человека",
      "Select gender": "Выберите пол",
      "Select age": "Выберите возраст",
      "Select ethnic": "Выберите этнический",
      "Fake Person Generator": "Генератор фейковых людей",
      "We create AI tools for content creators": "Мы создаем инструменты искусственного интеллекта для создателей контента",
      "You need to signup for free in order to use our tools": "Вам необходимо зарегистрироваться бесплатно, чтобы использовать наши инструменты.",
      "Show more": "Показать больше",
      "Chat with your favorite characters": "Общайтесь с любимыми персонажами",
      "Chat with over 60 characters": "Общайтесь с более чем 60 персонажами",
      "Style": "Стиль",
      "Error sending the chat message. Please try again": "Ошибка отправки сообщения в чат. Пожалуйста, попробуйте еще раз",
      "Error generating the video": "Ошибка создания видео",
      "Twitch stream": "Twitch-поток",
      "Join the stream and chat with AI Characters": "Присоединяйтесь к трансляции и общайтесь с AI-персонажами.",
      "Please complete your onboarding in order to keep using our platform for free.": "Пожалуйста, завершите регистрацию, чтобы продолжать бесплатно пользоваться нашей платформой.",
      "Looks like you are a heavy user of our platform!": "Похоже, вы активно пользуетесь нашей платформой!",
      "You need to complete your onboarding in order to continue": "Вам необходимо завершить регистрацию, чтобы продолжить",
      "Complete onboarding": "Полная адаптация",
      "Shape": "Форма",
      "Yes, show me ads. I understand why you do it": "Да, покажи мне рекламу. Я понимаю, почему ты это делаешь",
      "No, do not show me ads, I hate ads": "Нет, не показывай мне рекламу, я ненавижу рекламу",
      "It costs us a lot of money to operate this platform.": "Эксплуатация этой платформы обходится нам в большие деньги.",
      "Just one question": "Всего один вопрос",
      "It would really help us if you allowed us to show you some ads.": "Нам бы очень помогло, если бы вы разрешили нам показывать вам рекламу.",
      "This way you can keep using our platform for free, while helping us break even.": "Таким образом, вы сможете продолжать использовать нашу платформу бесплатно, помогая нам выйти на уровень безубыточности.",
      "Email address": "Адрес электронной почты",
      "Create a Password": "Создать пароль",
      "Your email address": "Ваш электронный адрес",
      "Your password": "Ваш пароль",
      "Sign up": "Зарегистрироваться",
      "Signing up ...": "Подписываясь ...",
      "Sign in with": "Войти в систему с",
      "Don't have an account? Sign up": "У вас нет учетной записи? Зарегистрироваться",
      "Check your email for the confirmation link": "Проверьте свой электронный ящик на наличие ссылки для подтверждения",
      "Your Password": "Ваш пароль",
      "Sign in": "Войти",
      "Signing in ...": "Вход в систему...",
      "Already have an account? Sign in": "У вас уже есть аккаунт? Войти",
      "Send Magic Link": "Отправить волшебную ссылку",
      "Sending Magic Link ...": "Отправка Magic Link...",
      "Send a magic link email": "Отправьте электронное письмо с волшебной ссылкой",
      "Check your email for the magic link": "Проверьте свою электронную почту на наличие волшебной ссылки",
      "Send reset password instructions": "Отправить инструкции по сбросу пароля",
      "Sending reset instructions ...": "Отправка инструкций по сбросу...",
      "Forgot your password?": "Забыли пароль?",
      "Check your email for the password reset link": "Проверьте свою электронную почту, чтобы найти ссылку для сброса пароля.",
      "New password": "Новый пароль",
      "Your new password": "ваш новый пароль",
      "Update password": "Обновить пароль",
      "Updating password ...": "Обновление пароля...",
      "Your password has been updated": "Ваш пароль был обновлен",
      "Your phone number": "Ваш номер телефона",
      "Token": "Токен",
      "Your Otp token": "Ваш токен OTP",
      "Verify token": "Подтвердить токен",
      "We need a sponsor": "Нам нужен спонсор",
      "Help us break even": "Помогите нам выйти на уровень безубыточности",
      "Help us keep the platform free": "Помогите нам сохранить платформу бесплатной",
      "My images": "Мои изображения",
      "Gallery": "Галерея",
      "Super Voice": "Супер Голос",
      "Create speech from text": "Создать речь из текста",
      "Create speech": "Создать речь",
      "This is Gordon Ramsay, what would you like to eat?": "Это Гордон Рамзи, что бы вы хотели съесть?",
      "New": "Новый",
      "Images": "Изображений",
      "GIFs": "гифки"
    }
  },
  "tr": {
    "translation": {
      "Home": "Ev",
      "Logout": "Çıkış Yap",
      "Profile": "Profil",
      "Onboard": "Gemide",
      "Blog": "Blog",
      "Contact Us": "Bize Ulaşın",
      "Login": "Giriş yapmak",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "Lütfen gidin ve e-posta adresinizi doğrulayın, böylece web sitesini kullanmaya devam edebilirsiniz. Size tıklayabileceğiniz bir bağlantı içeren bir e-posta gönderdik.",
      "Resend verification email": "Doğrulama e-postasını tekrar gönder",
      "Call us at +1-877-775-5232": "+1-877-775-5232 numaralı telefondan bizi arayın",
      "Super Pipeline": "Süper Boru Hattı",
      "About": "Hakkında",
      "Signup Free": "Ücretsiz kaydol",
      "Disclaimer": "Sorumluluk reddi beyanı",
      "Contact us with any question at": "Herhangi bir sorunuz için bizimle iletişime geçin:",
      "Call us at": "Bizi şu numaradan arayın:",
      "Plans": "Planlar",
      "Privacy policy": "Gizlilik Politikası",
      "Terms of service": "Kullanım Şartları",
      "Finished": "Bitti",
      "Continue": "Devam etmek",
      "Start Over": "Baştan başlamak",
      "Start (100% FREE)": "Başla (%100 ÜCRETSİZ)",
      "Submit": "Göndermek",
      "Examples": "Örnekler",
      "Select character": "Karakter seç",
      "Video Examples": "Video Örnekleri",
      "Write a poem about life": "Hayata dair bir şiir yazın",
      "Explain how molecules work": "Moleküllerin nasıl çalıştığını açıklayın",
      "Write a story about life in Mars": "Mars'ta yaşam hakkında bir hikaye yazın",
      "Create an entire video from a single prompt": "Tek bir istemden videonun tamamını oluşturun",
      "Entertain, inform and inspire": "Eğlendirin, bilgilendirin ve ilham verin",
      "through the power of AI": "Yapay zekanın gücü sayesinde",
      "Create Video!": "Video oluştur!",
      "one more step before you start Just": "başlamadan önce bir adım daha",
      "First name": "İlk adı",
      "What is your first name": "İlk adın ne",
      "Please input your first name!": "Lütfen adınızı girin!",
      "Last/family name": "Soyadı/soyadı",
      "What is your last/family name": "Soyadınız / soyadınız nedir",
      "Please input your last/family name!": "Lütfen soyadınızı/aile adınızı girin!",
      "Nickname": "Takma ad",
      "What do you want others to call you?": "Başkalarının sana ne demesini istiyorsun?",
      "Please input your nickname!": "Lütfen takma adınızı giriniz!",
      "Company": "Şirket",
      "What is the company you represent?": "Temsil ettiğiniz şirket nedir?",
      "Please input your company!": "Lütfen firmanızı giriniz!",
      "Phone Number": "Telefon numarası",
      "What is your phone number?": "Telefon numaran nedir?",
      "Why we ask for your phone number": "Neden telefon numaranızı istiyoruz?",
      "Account Recovery": "Hesap kurtarma",
      "If you forget your password or get locked out of your account, a phone number provides an additional way to verify your identity and regain access.": "Parolanızı unutursanız veya hesabınız kilitlenirse, telefon numarası kimliğinizi doğrulamanız ve yeniden erişim kazanmanız için ek bir yol sağlar.",
      "Fraud Prevention": "Dolandırıcılığın Önlenmesi",
      "by asking for phone number it limits fake or fraudulent account creation. It's more challenging for scammers to maintain numerous unique phone numbers than it is for email addresses.": "telefon numarası istenerek sahte veya hileli hesap oluşturulması sınırlandırılır. Dolandırıcıların çok sayıda benzersiz telefon numarası tutması, e-posta adreslerine göre daha zordur.",
      "Communication": "İletişim",
      "we may communicate with users directly through phone calls or SMS, whether for support or account issues": "destek veya hesap sorunları için kullanıcılarla doğrudan telefon görüşmeleri veya SMS yoluyla iletişim kurabiliriz",
      "Please input your phone number! Example +18777755232": "Lütfen telefon numaranızı giriniz! Örnek +18777755232",
      "I agree to the": "Katılıyorum",
      "privacy policy": "Gizlilik Politikası",
      "terms of service": "kullanım Şartları",
      "disclaimer": "sorumluluk reddi beyanı",
      "Just one more step before you start": "Başlamadan önce sadece bir adım daha",
      "Phone number": "Telefon numarası",
      "CONTACT US": "BİZE ULAŞIN",
      "BLOGS": "BLOGLAR",
      "Published": "Yayınlanan",
      "Office": "Ofis",
      "Super Character": "Süper Karakter",
      "Super Meme": "Süper Meme",
      "Hello, how are you?": "Nasılsın?",
      "Say something": "Bir şey söylemek",
      "Download video": "Video indir",
      "Check out this cool AI text to video tool": "Bu harika AI metinden videoya dönüştürme aracına göz atın",
      "Share": "Paylaşmak",
      "Download": "İndirmek",
      "Select or search a character": "Bir karakter seçin veya arayın",
      "Copy link": "Bağlantıyı kopyala",
      "Link copied to clipboard": "link kopyalandı",
      "This character cannot create videos for now": "Bu karakter şimdilik video oluşturamıyor",
      "Create GIFs from your prompts": "İstemlerinizden GIF'ler oluşturun",
      "Create GIF": "GIF oluştur",
      "Monkey sitting on a chair and eating Pasta": "Maymun bir sandalyede oturuyor ve makarna yiyor",
      "Please wait (30-60 seconds)": "Lütfen bekleyin (30-60 saniye)",
      "Please wait (30-120 seconds)": "Lütfen bekleyin (30-120 saniye)",
      "Prompt": "Çabuk",
      "The future of A.I. content creation": "A.I.'nin geleceği içerik yaratımı",
      "Try these prompts": "Bu istemleri deneyin",
      "Signup FREE": "Ücretsiz kaydol",
      "Cancel": "İptal etmek",
      "Error": "Hata",
      "Hide video": "Videoyu gizle",
      "It's 100% FREE!": "%100 ÜCRETSİZ!",
      "Win $100 Gift Cards for the best GIF!": "En iyi GIF için 100$'lık Hediye Kartları kazanın!",
      "How can I win?": "Nasıl kazanabilirim?",
      "Create a GIF": "GIF oluştur",
      "Post it on Reddit": "Reddit'te yayınla",
      "Send us a link to your post": "Bize gönderinizin bağlantısını gönderin",
      "The GIF with the most upvotes wins a weekly $100 Gift Card!": "En çok olumlu oyu alan GIF, haftalık 100$'lık Hediye Kartını kazanıyor!",
      "Minimum 20 upvotes required": "Minimum 20 olumlu oy gerekli",
      "Email us your GIF": "GIF'inizi bize e-postayla gönderin",
      "Create your GIF": "GIF'inizi oluşturun",
      "Submit your GIF to Reddit - Get $100 Gift Card!": "GIF'inizi Reddit'e gönderin - 100$ Hediye Kartı Kazanın!",
      "Welcome to the chat room with": "ile sohbet odasına hoş geldiniz",
      "Click on one of these messages": "Bu mesajlardan birine tıklayın",
      "Super GIF": "Süper GIF",
      "Generating your video": "Videonuz oluşturuluyor",
      "Click this button in order to generate a video from this response": "Bu yanıttan bir video oluşturmak için bu düğmeyi tıklayın",
      "Start": "Başlangıç",
      "You need to signup for free in order to create a GIF": "GIF oluşturmak için ücretsiz kaydolmanız gerekir",
      "Create video": "Video oluştur",
      "Share video": "Videoyu paylaş",
      "Create images from your prompts": "İstemlerinizden görseller oluşturun",
      "Create image": "Görüntü yarat",
      "Please wait (10-30 seconds)": "Lütfen bekleyin (10-30 saniye)",
      "Download GIF": "GIF'i indir",
      "Download image": "Resmi indir",
      "Super Image": "Süper Resim",
      "Super Person": "Süper Kişi",
      "Create fake person": "Sahte kişi oluştur",
      "Select gender": "Cinsiyet seç",
      "Select age": "Yaş seçin",
      "Select ethnic": "Etnik seç",
      "Fake Person Generator": "Sahte Kişi Oluşturucu",
      "We create AI tools for content creators": "İçerik oluşturucular için yapay zeka araçları oluşturuyoruz",
      "You need to signup for free in order to use our tools": "Araçlarımızı kullanabilmek için ücretsiz kayıt olmanız gerekmektedir.",
      "Show more": "Daha fazla göster",
      "Chat with your favorite characters": "En sevdiğiniz karakterlerle sohbet edin",
      "Chat with over 60 characters": "60'tan fazla karakterle sohbet edin",
      "Style": "Stil",
      "Error sending the chat message. Please try again": "Sohbet mesajı gönderilirken hata oluştu. Lütfen tekrar deneyin",
      "Error generating the video": "Video oluşturulurken hata oluştu",
      "Twitch stream": "Twitch yayını",
      "Join the stream and chat with AI Characters": "Akışa katılın ve AI Karakterleriyle sohbet edin",
      "Please complete your onboarding in order to keep using our platform for free.": "Platformumuzu ücretsiz kullanmaya devam etmek için lütfen katılım işleminizi tamamlayın.",
      "Looks like you are a heavy user of our platform!": "Görünüşe göre platformumuzun yoğun bir kullanıcısısınız!",
      "You need to complete your onboarding in order to continue": "Devam etmek için katılım işleminizi tamamlamanız gerekiyor",
      "Complete onboarding": "İlk katılım işlemini tamamlayın",
      "Shape": "Şekil",
      "Yes, show me ads. I understand why you do it": "Evet, bana reklam göster. bunu neden yaptığını anlıyorum",
      "No, do not show me ads, I hate ads": "Hayır, bana reklam gösterme, reklamlardan nefret ediyorum",
      "It costs us a lot of money to operate this platform.": "Bu platformu işletmek bize çok paraya mal oluyor.",
      "Just one question": "Sadece bir soru",
      "It would really help us if you allowed us to show you some ads.": "Size bazı reklam göstermemize izin verirseniz, bu gerçekten bize yardımcı olur.",
      "This way you can keep using our platform for free, while helping us break even.": "Bu şekilde platformumuzu ücretsiz olarak kullanmaya devam edebilir ve aynı zamanda başabaşta olmamıza yardımcı olabilirsiniz.",
      "Email address": "E-posta adresi",
      "Create a Password": "Bir şifre oluşturun",
      "Your email address": "E-posta adresiniz",
      "Your password": "Şifreniz",
      "Sign up": "Üye olmak",
      "Signing up ...": "Kayıt olmak ...",
      "Sign in with": "Şununla giriş yap",
      "Don't have an account? Sign up": "Hesabınız yok mu? Üye olmak",
      "Check your email for the confirmation link": "Konfirmasyon linki için e-postanızı kontrol edin",
      "Your Password": "Şifreniz",
      "Sign in": "Kayıt olmak",
      "Signing in ...": "Giriş yapılıyor ...",
      "Already have an account? Sign in": "Zaten hesabınız var mı? Kayıt olmak",
      "Send Magic Link": "Sihirli Bağlantı Gönder",
      "Sending Magic Link ...": "Sihirli Bağlantı Gönderiliyor...",
      "Send a magic link email": "Sihirli bağlantı e-postası gönder",
      "Check your email for the magic link": "Sihirli bağlantı için e-postanızı kontrol edin",
      "Send reset password instructions": "Şifre sıfırlama talimatlarını gönder",
      "Sending reset instructions ...": "Sıfırlama talimatları gönderiliyor...",
      "Forgot your password?": "Parolanızı mı unuttunuz?",
      "Check your email for the password reset link": "Şifre sıfırlama bağlantısı için e-postanızı kontrol edin",
      "New password": "Yeni Şifre",
      "Your new password": "Yeni parolanız",
      "Update password": "Şifre güncelle",
      "Updating password ...": "Şifre güncelleniyor...",
      "Your password has been updated": "şifreniz güncellenmiştir",
      "Your phone number": "Telefon numaran",
      "Token": "Jeton",
      "Your Otp token": "Otp jetonunuz",
      "Verify token": "Jetonu doğrula",
      "We need a sponsor": "Bir sponsora ihtiyacımız var",
      "Help us break even": "Dengeyi sağlamamıza yardım et",
      "Help us keep the platform free": "Platformu ücretsiz tutmamıza yardımcı olun",
      "My images": "Resimlerim",
      "Gallery": "Galeri",
      "Super Voice": "Süper Ses",
      "Create speech from text": "Metinden konuşma oluşturma",
      "Create speech": "Konuşma oluştur",
      "This is Gordon Ramsay, what would you like to eat?": "Ben Gordon Ramsay, ne yemek istersin?",
      "New": "Yeni",
      "Images": "Görüntüler",
      "GIFs": "GIF'ler"
    }
  },
  "no": {
    "translation": {
      "Home": "Hjem",
      "Logout": "Logg ut",
      "Profile": "Profil",
      "Onboard": "Om bord",
      "Blog": "Blogg",
      "Contact Us": "Kontakt oss",
      "Login": "Logg Inn",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "Gå og bekreft e-posten din slik at du kan fortsette å bruke nettstedet. Vi har sendt deg en e-post med en lenke du kan klikke på.",
      "Resend verification email": "Send bekreftelses-e-post på nytt",
      "Call us at +1-877-775-5232": "Ring oss på +1-877-775-5232",
      "Super Pipeline": "Super Pipeline",
      "About": "Om",
      "Signup Free": "Registrer deg gratis",
      "Disclaimer": "Ansvarsfraskrivelse",
      "Contact us with any question at": "Kontakt oss med spørsmål på",
      "Call us at": "Ring oss på",
      "Plans": "Planer",
      "Privacy policy": "Personvernerklæring",
      "Terms of service": "Vilkår for bruk",
      "Finished": "Ferdig",
      "Continue": "Fortsette",
      "Start Over": "Begynne på nytt",
      "Start (100% FREE)": "Start (100 % GRATIS)",
      "Submit": "Sende inn",
      "Examples": "Eksempler",
      "Select character": "Velg tegn",
      "Video Examples": "Videoeksempler",
      "Write a poem about life": "Skriv et dikt om livet",
      "Explain how molecules work": "Forklar hvordan molekyler fungerer",
      "Write a story about life in Mars": "Skriv en historie om livet på Mars",
      "Create an entire video from a single prompt": "Lag en hel video fra én enkelt melding",
      "Entertain, inform and inspire": "Underholde, informere og inspirere",
      "through the power of AI": "gjennom kraften til AI",
      "Create Video!": "Lag video!",
      "one more step before you start Just": "ett steg til før du starter",
      "First name": "Fornavn",
      "What is your first name": "Hva er ditt fornavn",
      "Please input your first name!": "Skriv inn fornavnet ditt!",
      "Last/family name": "Etternavn/familienavn",
      "What is your last/family name": "Hva er ditt etternavn/familienavn",
      "Please input your last/family name!": "Vennligst skriv inn ditt etternavn/familienavn!",
      "Nickname": "Kallenavn",
      "What do you want others to call you?": "Hva vil du at andre skal kalle deg?",
      "Please input your nickname!": "Vennligst skriv inn kallenavnet ditt!",
      "Company": "Selskap",
      "What is the company you represent?": "Hva er selskapet du representerer?",
      "Please input your company!": "Vennligst skriv inn ditt firma!",
      "Phone Number": "Telefonnummer",
      "What is your phone number?": "Hva er telefonnummeret ditt?",
      "Why we ask for your phone number": "Hvorfor ber vi om telefonnummeret ditt",
      "Account Recovery": "Gjenopprettelse av konto",
      "If you forget your password or get locked out of your account, a phone number provides an additional way to verify your identity and regain access.": "Hvis du glemmer passordet ditt eller blir utestengt fra kontoen din, gir et telefonnummer en ekstra måte å bekrefte identiteten din og få tilgang på igjen.",
      "Fraud Prevention": "Forebygging av svindel",
      "by asking for phone number it limits fake or fraudulent account creation. It's more challenging for scammers to maintain numerous unique phone numbers than it is for email addresses.": "ved å spørre om telefonnummer begrenser det opprettelse av falske eller uredelige kontoer. Det er mer utfordrende for svindlere å opprettholde mange unike telefonnumre enn det er for e-postadresser.",
      "Communication": "Kommunikasjon",
      "we may communicate with users directly through phone calls or SMS, whether for support or account issues": "vi kan kommunisere med brukere direkte gjennom telefonsamtaler eller SMS, enten det er for støtte eller kontoproblemer",
      "Please input your phone number! Example +18777755232": "Vennligst skriv inn telefonnummeret ditt! Eksempel +18777755232",
      "I agree to the": "Jeg er enig i",
      "privacy policy": "personvernerklæring",
      "terms of service": "vilkår for bruk",
      "disclaimer": "ansvarsfraskrivelse",
      "Just one more step before you start": "Bare ett skritt til før du begynner",
      "Phone number": "Telefonnummer",
      "CONTACT US": "KONTAKT OSS",
      "BLOGS": "BLOGGER",
      "Published": "Publisert",
      "Office": "Kontor",
      "Super Character": "Super karakter",
      "Super Meme": "Super meme",
      "Hello, how are you?": "Hei, hvordan har du det?",
      "Say something": "Si noe",
      "Download video": "Last ned video",
      "Check out this cool AI text to video tool": "Sjekk ut dette kule AI-tekst-til-videoverktøyet",
      "Share": "Dele",
      "Download": "nedlasting",
      "Select or search a character": "Velg eller søk etter et tegn",
      "Copy link": "Kopier link",
      "Link copied to clipboard": "Linken er kopiert til utklippstavlen",
      "This character cannot create videos for now": "Denne karakteren kan ikke lage videoer foreløpig",
      "Create GIFs from your prompts": "Lag GIF-er fra ledetekstene dine",
      "Create GIF": "Lag GIF",
      "Monkey sitting on a chair and eating Pasta": "Monkey sitter på en stol og spiser Pasta",
      "Please wait (30-60 seconds)": "Vennligst vent (30-60 sekunder)",
      "Please wait (30-120 seconds)": "Vennligst vent (30-120 sekunder)",
      "Prompt": "Spør",
      "The future of A.I. content creation": "Fremtiden til A.I. innholdsskaping",
      "Try these prompts": "Prøv disse ledetekstene",
      "Signup FREE": "Registrer deg gratis",
      "Cancel": "Avbryt",
      "Error": "Feil",
      "Hide video": "Skjul video",
      "It's 100% FREE!": "Det er 100 % GRATIS!",
      "Win $100 Gift Cards for the best GIF!": "Vinn gavekort på $100 for den beste GIF!",
      "How can I win?": "Hvordan kan jeg vinne?",
      "Create a GIF": "Lag en GIF",
      "Post it on Reddit": "Legg det ut på Reddit",
      "Send us a link to your post": "Send oss ​​en link til innlegget ditt",
      "The GIF with the most upvotes wins a weekly $100 Gift Card!": "GIF-en med flest oppstemmer vinner et ukentlig gavekort på $100!",
      "Minimum 20 upvotes required": "Minimum 20 oppstemmer kreves",
      "Email us your GIF": "Send oss ​​en e-post med din GIF",
      "Create your GIF": "Lag din GIF",
      "Submit your GIF to Reddit - Get $100 Gift Card!": "Send inn GIF-en din til Reddit - Få gavekort på $100!",
      "Welcome to the chat room with": "Velkommen til chatterom med",
      "Click on one of these messages": "Klikk på en av disse meldingene",
      "Super GIF": "Super GIF",
      "Generating your video": "Genererer videoen din",
      "Click this button in order to generate a video from this response": "Klikk på denne knappen for å generere en video fra dette svaret",
      "Start": "Start",
      "You need to signup for free in order to create a GIF": "Du må registrere deg gratis for å lage en GIF",
      "Create video": "Lag video",
      "Share video": "Del video",
      "Create images from your prompts": "Lag bilder fra spørsmålene dine",
      "Create image": "Lag bilde",
      "Please wait (10-30 seconds)": "Vennligst vent (10-30 sekunder)",
      "Download GIF": "Last ned GIF",
      "Download image": "Last ned bilde",
      "Super Image": "Supert bilde",
      "Super Person": "Super person",
      "Create fake person": "Lag en falsk person",
      "Select gender": "Velg kjønn",
      "Select age": "Velg alder",
      "Select ethnic": "Velg etnisk",
      "Fake Person Generator": "Generator for falske personer",
      "We create AI tools for content creators": "Vi lager AI-verktøy for innholdsskapere",
      "You need to signup for free in order to use our tools": "Du må registrere deg gratis for å bruke verktøyene våre",
      "Show more": "Vis mer",
      "Chat with your favorite characters": "Chat med favorittkarakterene dine",
      "Chat with over 60 characters": "Chat med over 60 tegn",
      "Style": "Stil",
      "Error sending the chat message. Please try again": "Feil ved sending av chat-meldingen. Vær så snill, prøv på nytt",
      "Error generating the video": "Feil under generering av videoen",
      "Twitch stream": "Twitch stream",
      "Join the stream and chat with AI Characters": "Bli med i strømmen og chat med AI-karakterer",
      "Please complete your onboarding in order to keep using our platform for free.": "Fullfør onboarding for å fortsette å bruke plattformen vår gratis.",
      "Looks like you are a heavy user of our platform!": "Ser ut som du er en storbruker av plattformen vår!",
      "You need to complete your onboarding in order to continue": "Du må fullføre onboarding for å fortsette",
      "Complete onboarding": "Fullfør onboarding",
      "Shape": "Form",
      "Yes, show me ads. I understand why you do it": "Ja, vis meg annonser. Jeg forstår hvorfor du gjør det",
      "No, do not show me ads, I hate ads": "Nei, ikke vis meg annonser, jeg hater annonser",
      "It costs us a lot of money to operate this platform.": "Det koster oss mye penger å drifte denne plattformen.",
      "Just one question": "Bare ett spørsmål",
      "It would really help us if you allowed us to show you some ads.": "Det ville virkelig hjelpe oss hvis du lot oss vise deg noen annonser.",
      "This way you can keep using our platform for free, while helping us break even.": "På denne måten kan du fortsette å bruke plattformen vår gratis, samtidig som du hjelper oss å gå i balanse.",
      "Email address": "Epostadresse",
      "Create a Password": "Lag et passord",
      "Your email address": "Din epostadresse",
      "Your password": "Ditt passord",
      "Sign up": "Melde deg på",
      "Signing up ...": "Registrere seg ...",
      "Sign in with": "Logg inn med",
      "Don't have an account? Sign up": "Har du ikke en konto? Melde deg på",
      "Check your email for the confirmation link": "Sjekk e-posten din for bekreftelseslenken",
      "Your Password": "Ditt passord",
      "Sign in": "Logg inn",
      "Signing in ...": "Logger inn ...",
      "Already have an account? Sign in": "Har du allerede en konto? Logg inn",
      "Send Magic Link": "Send Magic Link",
      "Sending Magic Link ...": "Sender Magic Link ...",
      "Send a magic link email": "Send en magisk lenke-e-post",
      "Check your email for the magic link": "Sjekk e-posten din for den magiske lenken",
      "Send reset password instructions": "Send instruksjoner for tilbakestilling av passord",
      "Sending reset instructions ...": "Sender tilbakestillingsinstruksjoner ...",
      "Forgot your password?": "Glemt passordet?",
      "Check your email for the password reset link": "Sjekk e-posten din for lenken for tilbakestilling av passord",
      "New password": "Nytt passord",
      "Your new password": "Ditt nye passord",
      "Update password": "Oppdater passord",
      "Updating password ...": "Oppdaterer passord ...",
      "Your password has been updated": "Passordet ditt er oppdatert",
      "Your phone number": "Telefonnummeret ditt",
      "Token": "Token",
      "Your Otp token": "Ditt otp-token",
      "Verify token": "Bekreft token",
      "We need a sponsor": "Vi trenger en sponsor",
      "Help us break even": "Hjelp oss å komme i balanse",
      "Help us keep the platform free": "Hjelp oss å holde plattformen fri",
      "My images": "Mine bilder",
      "Gallery": "Galleri",
      "Super Voice": "Super stemme",
      "Create speech from text": "Lag tale fra tekst",
      "Create speech": "Lag tale",
      "This is Gordon Ramsay, what would you like to eat?": "Dette er Gordon Ramsay, hva vil du spise?",
      "New": "Ny",
      "Images": "Bilder",
      "GIFs": "GIF-er"
    }
  },
  "el": {
    "translation": {
      "Home": "Σπίτι",
      "Logout": "Αποσύνδεση",
      "Profile": "Προφίλ",
      "Onboard": "Εν πλω",
      "Blog": "Ιστολόγιο",
      "Contact Us": "Επικοινωνήστε μαζί μας",
      "Login": "Σύνδεση",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "Πηγαίνετε και επαληθεύστε το email σας για να συνεχίσετε να χρησιμοποιείτε τον ιστότοπο. Σας στείλαμε ένα email με έναν σύνδεσμο για να κάνετε κλικ.",
      "Resend verification email": "Επαναποστολή μηνύματος επιβεβαίωσης",
      "Call us at +1-877-775-5232": "Καλέστε μας στο +1-877-775-5232",
      "Super Pipeline": "Super Pipeline",
      "About": "Σχετικά με",
      "Signup Free": "Εγγράψου δωρεάν",
      "Disclaimer": "Αποποίηση ευθυνών",
      "Contact us with any question at": "Επικοινωνήστε μαζί μας για οποιαδήποτε ερώτηση στο",
      "Call us at": "Καλέστε μας στο",
      "Plans": "Σχέδια",
      "Privacy policy": "Πολιτική απορρήτου",
      "Terms of service": "Οροι χρήσης",
      "Finished": "Πεπερασμένος",
      "Continue": "Να συνεχίσει",
      "Start Over": "Ξανά από την αρχή",
      "Start (100% FREE)": "Έναρξη (100% ΔΩΡΕΑΝ)",
      "Submit": "υποβάλλουν",
      "Examples": "Παραδείγματα",
      "Select character": "Επιλέξτε χαρακτήρα",
      "Video Examples": "Παραδείγματα βίντεο",
      "Write a poem about life": "Γράψε ένα ποίημα για τη ζωή",
      "Explain how molecules work": "Εξηγήστε πώς λειτουργούν τα μόρια",
      "Write a story about life in Mars": "Γράψτε μια ιστορία για τη ζωή στον Άρη",
      "Create an entire video from a single prompt": "Δημιουργήστε ένα ολόκληρο βίντεο από ένα μόνο μήνυμα",
      "Entertain, inform and inspire": "Διασκεδάστε, ενημερώστε και εμπνεύστε",
      "through the power of AI": "μέσω της δύναμης του AI",
      "Create Video!": "Δημιουργία βίντεο!",
      "one more step before you start Just": "ένα ακόμη βήμα πριν ξεκινήσετε",
      "First name": "Ονομα",
      "What is your first name": "Ποιο είναι το μικρό σου όνομα",
      "Please input your first name!": "Παρακαλώ εισάγετε το όνομά σας!",
      "Last/family name": "Επώνυμο/επώνυμο",
      "What is your last/family name": "Ποιο είναι το επίθετό/το οικογενειακό σας όνομα",
      "Please input your last/family name!": "Παρακαλώ εισάγετε το επώνυμό σας!",
      "Nickname": "Παρατσούκλι",
      "What do you want others to call you?": "Πώς θέλεις να σε λένε οι άλλοι;",
      "Please input your nickname!": "Παρακαλώ εισάγετε το ψευδώνυμό σας!",
      "Company": "Εταιρία",
      "What is the company you represent?": "Ποια είναι η εταιρεία που εκπροσωπείτε;",
      "Please input your company!": "Παρακαλώ εισάγετε την εταιρεία σας!",
      "Phone Number": "Τηλεφωνικό νούμερο",
      "What is your phone number?": "Ποιος είναι ο τηλεφωνικός αριθμός σας?",
      "Why we ask for your phone number": "Γιατί ζητάμε τον αριθμό τηλεφώνου σας",
      "Account Recovery": "Ανάκτηση Λογαριασμού",
      "If you forget your password or get locked out of your account, a phone number provides an additional way to verify your identity and regain access.": "Εάν ξεχάσετε τον κωδικό πρόσβασής σας ή κλειδωθείτε έξω από τον λογαριασμό σας, ένας αριθμός τηλεφώνου παρέχει έναν επιπλέον τρόπο για να επαληθεύσετε την ταυτότητά σας και να αποκτήσετε ξανά πρόσβαση.",
      "Fraud Prevention": "Πρόληψη Απάτης",
      "by asking for phone number it limits fake or fraudulent account creation. It's more challenging for scammers to maintain numerous unique phone numbers than it is for email addresses.": "Ζητώντας τον αριθμό τηλεφώνου περιορίζει τη δημιουργία πλαστών ή δόλιων λογαριασμών. Είναι πιο δύσκολο για τους απατεώνες να διατηρούν πολυάριθμους μοναδικούς αριθμούς τηλεφώνου παρά για διευθύνσεις email.",
      "Communication": "Επικοινωνία",
      "we may communicate with users directly through phone calls or SMS, whether for support or account issues": "ενδέχεται να επικοινωνήσουμε με τους χρήστες απευθείας μέσω τηλεφωνικών κλήσεων ή SMS, είτε για θέματα υποστήριξης είτε για θέματα λογαριασμού",
      "Please input your phone number! Example +18777755232": "Εισαγάγετε τον αριθμό τηλεφώνου σας! Παράδειγμα +18777755232",
      "I agree to the": "συμφωνώ στο",
      "privacy policy": "πολιτική απορρήτου",
      "terms of service": "Όροι χρήσης",
      "disclaimer": "αποποίηση ευθυνών",
      "Just one more step before you start": "Ένα ακόμη βήμα πριν ξεκινήσετε",
      "Phone number": "Τηλεφωνικό νούμερο",
      "CONTACT US": "ΕΠΙΚΟΙΝΩΝΗΣΤΕ ΜΑΖΙ ΜΑΣ",
      "BLOGS": "BLOGS",
      "Published": "Δημοσίευσε",
      "Office": "Γραφείο",
      "Super Character": "Σούπερ χαρακτήρας",
      "Super Meme": "Super Meme",
      "Hello, how are you?": "Γεια πώς είσαι?",
      "Say something": "Πες κάτι",
      "Download video": "Λήψη βίντεο",
      "Check out this cool AI text to video tool": "Δείτε αυτό το υπέροχο εργαλείο τεχνητής νοημοσύνης από κείμενο σε βίντεο",
      "Share": "Μερίδιο",
      "Download": "Κατεβάστε",
      "Select or search a character": "Επιλέξτε ή αναζητήστε έναν χαρακτήρα",
      "Copy link": "Αντιγραφή συνδέσμου",
      "Link copied to clipboard": "Ο σύνδεσμος αντιγράφηκε στο πρόχειρο",
      "This character cannot create videos for now": "Αυτός ο χαρακτήρας δεν μπορεί να δημιουργήσει βίντεο προς το παρόν",
      "Create GIFs from your prompts": "Δημιουργήστε GIF από τις προτροπές σας",
      "Create GIF": "Δημιουργία GIF",
      "Monkey sitting on a chair and eating Pasta": "Πίθηκος κάθεται σε μια καρέκλα και τρώει ζυμαρικά",
      "Please wait (30-60 seconds)": "Περιμένετε (30-60 δευτερόλεπτα)",
      "Please wait (30-120 seconds)": "Περιμένετε (30-120 δευτερόλεπτα)",
      "Prompt": "Προτροπή",
      "The future of A.I. content creation": "Το μέλλον της A.I. δημιουργία περιεχομένου",
      "Try these prompts": "Δοκιμάστε αυτές τις προτροπές",
      "Signup FREE": "Εγγράψου δωρεάν",
      "Cancel": "Ματαίωση",
      "Error": "Λάθος",
      "Hide video": "Απόκρυψη βίντεο",
      "It's 100% FREE!": "Είναι 100% ΔΩΡΕΑΝ!",
      "Win $100 Gift Cards for the best GIF!": "Κερδίστε δωροκάρτες 100$ για το καλύτερο GIF!",
      "How can I win?": "Πώς μπορώ να κερδίσω;",
      "Create a GIF": "Δημιουργήστε ένα GIF",
      "Post it on Reddit": "Δημοσιεύστε το στο Reddit",
      "Send us a link to your post": "Στείλτε μας έναν σύνδεσμο για την ανάρτησή σας",
      "The GIF with the most upvotes wins a weekly $100 Gift Card!": "Το GIF με τις περισσότερες θετικές ψήφους κερδίζει μια εβδομαδιαία δωροκάρτα 100 $!",
      "Minimum 20 upvotes required": "Απαιτούνται τουλάχιστον 20 θετικές ψήφοι",
      "Email us your GIF": "Στείλτε μας με email το GIF σας",
      "Create your GIF": "Δημιουργήστε το GIF σας",
      "Submit your GIF to Reddit - Get $100 Gift Card!": "Υποβάλετε το GIF σας στο Reddit - Λάβετε δωροκάρτα 100 $!",
      "Welcome to the chat room with": "Καλώς ήρθατε στο chat room με",
      "Click on one of these messages": "Κάντε κλικ σε ένα από αυτά τα μηνύματα",
      "Super GIF": "Super GIF",
      "Generating your video": "Δημιουργία βίντεο",
      "Click this button in order to generate a video from this response": "Κάντε κλικ σε αυτό το κουμπί για να δημιουργήσετε ένα βίντεο από αυτήν την απάντηση",
      "Start": "Αρχή",
      "You need to signup for free in order to create a GIF": "Πρέπει να εγγραφείτε δωρεάν για να δημιουργήσετε ένα GIF",
      "Create video": "Δημιουργία βίντεο",
      "Share video": "Κοινοποίησε το βίντεο",
      "Create images from your prompts": "Δημιουργήστε εικόνες από τις προτροπές σας",
      "Create image": "Δημιουργία εικόνας",
      "Please wait (10-30 seconds)": "Περιμένετε (10-30 δευτερόλεπτα)",
      "Download GIF": "Λήψη GIF",
      "Download image": "Λήψη εικόνας",
      "Super Image": "Σούπερ εικόνα",
      "Super Person": "Σούπερ Άνθρωπος",
      "Create fake person": "Δημιουργήστε ψεύτικο άτομο",
      "Select gender": "Επιλέξτε γένος",
      "Select age": "Επιλέξτε ηλικία",
      "Select ethnic": "Επιλέξτε εθνοτική",
      "Fake Person Generator": "Fake Person Generator",
      "We create AI tools for content creators": "Δημιουργούμε εργαλεία AI για δημιουργούς περιεχομένου",
      "You need to signup for free in order to use our tools": "Πρέπει να εγγραφείτε δωρεάν για να χρησιμοποιήσετε τα εργαλεία μας",
      "Show more": "Δείτε περισσότερα",
      "Chat with your favorite characters": "Συνομιλήστε με τους αγαπημένους σας χαρακτήρες",
      "Chat with over 60 characters": "Συνομιλία με περισσότερους από 60 χαρακτήρες",
      "Style": "Στυλ",
      "Error sending the chat message. Please try again": "Σφάλμα κατά την αποστολή του μηνύματος συνομιλίας. ΠΑΡΑΚΑΛΩ προσπαθησε ξανα",
      "Error generating the video": "Σφάλμα κατά τη δημιουργία του βίντεο",
      "Twitch stream": "Ροή με συσπάσεις",
      "Join the stream and chat with AI Characters": "Εγγραφείτε στη ροή και συνομιλήστε με χαρακτήρες AI",
      "Please complete your onboarding in order to keep using our platform for free.": "Ολοκληρώστε την ένταξή σας για να συνεχίσετε να χρησιμοποιείτε την πλατφόρμα μας δωρεάν.",
      "Looks like you are a heavy user of our platform!": "Φαίνεται ότι είστε μεγάλος χρήστης της πλατφόρμας μας!",
      "You need to complete your onboarding in order to continue": "Πρέπει να ολοκληρώσετε την ένταξή σας για να συνεχίσετε",
      "Complete onboarding": "Ολοκληρώστε την ενσωμάτωση",
      "Shape": "Σχήμα",
      "Yes, show me ads. I understand why you do it": "Ναι, δείξτε μου διαφημίσεις. Καταλαβαίνω γιατί το κάνεις",
      "No, do not show me ads, I hate ads": "Όχι, μη μου εμφανίζετε διαφημίσεις, μισώ τις διαφημίσεις",
      "It costs us a lot of money to operate this platform.": "Μας κοστίζει πολλά χρήματα για να λειτουργήσουμε αυτήν την πλατφόρμα.",
      "Just one question": "Μόνο μια ερώτηση",
      "It would really help us if you allowed us to show you some ads.": "Θα μας βοηθούσε πραγματικά αν μας επιτρέπατε να σας δείξουμε κάποιες διαφημίσεις.",
      "This way you can keep using our platform for free, while helping us break even.": "Με αυτόν τον τρόπο μπορείτε να συνεχίσετε να χρησιμοποιείτε την πλατφόρμα μας δωρεάν, βοηθώντας μας παράλληλα να ξεπεράσουμε την ισορροπία.",
      "Email address": "Διεύθυνση ηλεκτρονικού ταχυδρομείου",
      "Create a Password": "Δημιούργησε έναν κωδικό",
      "Your email address": "η ηλεκτρονική σου διεύθυνση",
      "Your password": "Ο κωδικός σας",
      "Sign up": "Εγγραφείτε",
      "Signing up ...": "Εγραφομαι ...",
      "Sign in with": "Συνδεθείτε με",
      "Don't have an account? Sign up": "Δεν έχετε λογαριασμό; Εγγραφείτε",
      "Check your email for the confirmation link": "Ελέγξτε e-mail σας για το σύνδεσμο επιβεβαίωσης",
      "Your Password": "Ο κωδικός σας",
      "Sign in": "Συνδεθείτε",
      "Signing in ...": "Σύνδεση...",
      "Already have an account? Sign in": "Έχετε ήδη λογαριασμό? Συνδεθείτε",
      "Send Magic Link": "Αποστολή Magic Link",
      "Sending Magic Link ...": "Αποστολή Magic Link...",
      "Send a magic link email": "Στείλτε ένα μαγικό σύνδεσμο email",
      "Check your email for the magic link": "Ελέγξτε το email σας για τον μαγικό σύνδεσμο",
      "Send reset password instructions": "Στείλτε οδηγίες επαναφοράς κωδικού πρόσβασης",
      "Sending reset instructions ...": "Αποστολή οδηγιών επαναφοράς...",
      "Forgot your password?": "Ξεχάσατε τον κωδικό σας;",
      "Check your email for the password reset link": "Ελέγξτε το email σας για τον σύνδεσμο επαναφοράς κωδικού πρόσβασης",
      "New password": "Νέος Κωδικός",
      "Your new password": "Ο νέος σας κωδικός πρόσβασης",
      "Update password": "Ενημέρωση κωδικού πρόσβασης",
      "Updating password ...": "Ενημέρωση κωδικού πρόσβασης...",
      "Your password has been updated": "Ο κωδικός πρόσβασής σας έχει ενημερωθεί",
      "Your phone number": "Τον αριθμό του τηλεφώνου σας",
      "Token": "Ενδειξη",
      "Your Otp token": "Το διακριτικό Otp σας",
      "Verify token": "Επαλήθευση διακριτικού",
      "We need a sponsor": "Χρειαζόμαστε χορηγό",
      "Help us break even": "Βοηθήστε μας να ξεπεράσουμε την ισορροπία",
      "Help us keep the platform free": "Βοηθήστε μας να διατηρήσουμε την πλατφόρμα ελεύθερη",
      "My images": "Οι εικόνες μου",
      "Gallery": "Εκθεσιακός χώρος",
      "Super Voice": "Super Voice",
      "Create speech from text": "Δημιουργία ομιλίας από κείμενο",
      "Create speech": "Δημιουργία ομιλίας",
      "This is Gordon Ramsay, what would you like to eat?": "Αυτός είναι ο Γκόρντον Ράμσεϊ, τι θα θέλατε να φάτε;",
      "New": "Νέος",
      "Images": "εικόνες",
      "GIFs": "GIF"
    }
  },
  "ar": {
    "translation": {
      "Home": "بيت",
      "Logout": "تسجيل خروج",
      "Profile": "حساب تعريفي",
      "Onboard": "صعد على متنها",
      "Blog": "مدونة",
      "Contact Us": "اتصل بنا",
      "Login": "تسجيل الدخول",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "يرجى الذهاب والتحقق من بريدك الإلكتروني حتى تتمكن من الاستمرار في استخدام الموقع. لقد أرسلنا لك بريدًا إلكترونيًا يحتوي على رابط للنقر عليه.",
      "Resend verification email": "إعادة ارسال بريد التحقق",
      "Call us at +1-877-775-5232": "اتصل بنا على +1-877-775-5232",
      "Super Pipeline": "خط أنابيب سوبر",
      "About": "عن",
      "Signup Free": "الاشتراك مجانا",
      "Disclaimer": "تنصل",
      "Contact us with any question at": "اتصل بنا بخصوص أي سؤال على",
      "Call us at": "اتصل بنا على",
      "Plans": "الخطط",
      "Privacy policy": "سياسة الخصوصية",
      "Terms of service": "شروط الخدمة",
      "Finished": "انتهى",
      "Continue": "يكمل",
      "Start Over": "ابدأ من جديد",
      "Start (100% FREE)": "ابدأ (مجاني 100%)",
      "Submit": "يُقدِّم",
      "Examples": "أمثلة",
      "Select character": "حدد الحرف",
      "Video Examples": "أمثلة الفيديو",
      "Write a poem about life": "اكتب قصيدة عن الحياة",
      "Explain how molecules work": "اشرح كيفية عمل الجزيئات",
      "Write a story about life in Mars": "اكتب قصة عن الحياة في المريخ",
      "Create an entire video from a single prompt": "أنشئ مقطع فيديو كاملاً من خلال مطالبة واحدة",
      "Entertain, inform and inspire": "الترفيه والإعلام والإلهام",
      "through the power of AI": "من خلال قوة الذكاء الاصطناعي",
      "Create Video!": "إنشاء فيديو!",
      "one more step before you start Just": "خطوة أخرى قبل البدء",
      "First name": "الاسم الأول",
      "What is your first name": "ما هو اسمك الأول",
      "Please input your first name!": "الرجاء إدخال اسمك الأول!",
      "Last/family name": "اسم العائلة/العائلة",
      "What is your last/family name": "ما هو اسم عائلتك/عائلتك",
      "Please input your last/family name!": "الرجاء إدخال اسم العائلة/الاسم الأخير!",
      "Nickname": "كنية",
      "What do you want others to call you?": "ماذا تريد أن يناديك الآخرون؟",
      "Please input your nickname!": "الرجاء إدخال اللقب الخاص بك!",
      "Company": "شركة",
      "What is the company you represent?": "ما هي الشركة التي تمثلها؟",
      "Please input your company!": "يرجى إدخال شركتك!",
      "Phone Number": "رقم التليفون",
      "What is your phone number?": "ما هو رقم هاتفك؟",
      "Why we ask for your phone number": "لماذا نطلب رقم هاتفك",
      "Account Recovery": "استرجاع الحساب",
      "If you forget your password or get locked out of your account, a phone number provides an additional way to verify your identity and regain access.": "إذا نسيت كلمة المرور الخاصة بك أو تم حظر دخولك إلى حسابك، فإن رقم الهاتف يوفر طريقة إضافية للتحقق من هويتك واستعادة الوصول.",
      "Fraud Prevention": "منع الغش",
      "by asking for phone number it limits fake or fraudulent account creation. It's more challenging for scammers to maintain numerous unique phone numbers than it is for email addresses.": "من خلال طلب رقم الهاتف، فإنه يحد من إنشاء حساب مزيف أو احتيالي. يعد الاحتفاظ بالعديد من أرقام الهواتف الفريدة أكثر صعوبة بالنسبة للمحتالين مقارنة بعناوين البريد الإلكتروني.",
      "Communication": "تواصل",
      "we may communicate with users directly through phone calls or SMS, whether for support or account issues": "قد نتواصل مع المستخدمين مباشرة من خلال المكالمات الهاتفية أو الرسائل النصية القصيرة، سواء فيما يتعلق بالدعم أو مشكلات الحساب",
      "Please input your phone number! Example +18777755232": "الرجاء إدخال رقم هاتفك! مثال +18777755232",
      "I agree to the": "أنا أوافق على",
      "privacy policy": "سياسة الخصوصية",
      "terms of service": "شروط الخدمة",
      "disclaimer": "تنصل",
      "Just one more step before you start": "فقط خطوة واحدة أخرى قبل أن تبدأ",
      "Phone number": "رقم التليفون",
      "CONTACT US": "اتصل بنا",
      "BLOGS": "المدونات",
      "Published": "نشرت",
      "Office": "مكتب",
      "Super Character": "شخصية سوبر",
      "Super Meme": "سوبر ميمي",
      "Hello, how are you?": "مرحبا، كيف حالك؟",
      "Say something": "قل شيئا",
      "Download video": "تحميل الفيديو",
      "Check out this cool AI text to video tool": "تحقق من أداة تحويل النص AI الرائعة إلى فيديو",
      "Share": "يشارك",
      "Download": "تحميل",
      "Select or search a character": "حدد أو ابحث عن شخصية",
      "Copy link": "نسخ الوصلة",
      "Link copied to clipboard": "تم نسخ الرابط إلى الحافظة",
      "This character cannot create videos for now": "لا يمكن لهذه الشخصية إنشاء مقاطع فيديو في الوقت الحالي",
      "Create GIFs from your prompts": "قم بإنشاء صور GIF من مطالباتك",
      "Create GIF": "إنشاء GIF",
      "Monkey sitting on a chair and eating Pasta": "قرد يجلس على كرسي ويأكل المعكرونة",
      "Please wait (30-60 seconds)": "يرجى الانتظار (30-60 ثانية)",
      "Please wait (30-120 seconds)": "يرجى الانتظار (30-120 ثانية)",
      "Prompt": "اِسْتَدْعَى",
      "The future of A.I. content creation": "مستقبل أ. انشاء محتوى",
      "Try these prompts": "جرب هذه المطالبات",
      "Signup FREE": "الاشتراك مجانا",
      "Cancel": "يلغي",
      "Error": "خطأ",
      "Hide video": "إخفاء الفيديو",
      "It's 100% FREE!": "إنه مجاني 100%!",
      "Win $100 Gift Cards for the best GIF!": "اربح بطاقات هدايا بقيمة 100 دولار للحصول على أفضل صورة GIF!",
      "How can I win?": "كيف يمكنني الفوز؟",
      "Create a GIF": "إنشاء صورة GIF",
      "Post it on Reddit": "انشرها على رديت",
      "Send us a link to your post": "ارسل لنا رابط لمنشورك",
      "The GIF with the most upvotes wins a weekly $100 Gift Card!": "صورة GIF الحاصلة على أكبر عدد من الأصوات تفوز ببطاقة هدايا أسبوعية بقيمة 100 دولار!",
      "Minimum 20 upvotes required": "الحد الأدنى 20 التصويتات المطلوبة",
      "Email us your GIF": "أرسل لنا GIF الخاص بك",
      "Create your GIF": "قم بإنشاء ملف GIF الخاص بك",
      "Submit your GIF to Reddit - Get $100 Gift Card!": "أرسل ملف GIF الخاص بك إلى Reddit - احصل على بطاقة هدايا بقيمة 100 دولار!",
      "Welcome to the chat room with": "مرحبا بكم في غرفة الدردشة مع",
      "Click on one of these messages": "انقر على واحدة من هذه الرسائل",
      "Super GIF": "سوبر جيف",
      "Generating your video": "توليد الفيديو الخاص بك",
      "Click this button in order to generate a video from this response": "انقر فوق هذا الزر لإنشاء مقطع فيديو من هذا الرد",
      "Start": "يبدأ",
      "You need to signup for free in order to create a GIF": "تحتاج إلى التسجيل مجانًا حتى تتمكن من إنشاء صورة GIF",
      "Create video": "إنشاء فيديو",
      "Share video": "شارك الفيديو",
      "Create images from your prompts": "إنشاء صور من المطالبات الخاصة بك",
      "Create image": "خلق صورة",
      "Please wait (10-30 seconds)": "يرجى الانتظار (10-30 ثانية)",
      "Download GIF": "تحميل GIF",
      "Download image": "تحميل الصورة",
      "Super Image": "صورة فائقة",
      "Super Person": "شخص خارق",
      "Create fake person": "إنشاء شخص مزيف",
      "Select gender": "حدد نوع الجنس",
      "Select age": "اختر العمر",
      "Select ethnic": "اختر العرقية",
      "Fake Person Generator": "مولد شخص مزيف",
      "We create AI tools for content creators": "نقوم بإنشاء أدوات الذكاء الاصطناعي لمنشئي المحتوى",
      "You need to signup for free in order to use our tools": "تحتاج إلى التسجيل مجانًا حتى تتمكن من استخدام أدواتنا",
      "Show more": "أظهر المزيد",
      "Chat with your favorite characters": "الدردشة مع الشخصيات المفضلة لديك",
      "Chat with over 60 characters": "الدردشة مع أكثر من 60 حرفا",
      "Style": "أسلوب",
      "Error sending the chat message. Please try again": "حدث خطأ أثناء إرسال رسالة الدردشة. حاول مرة اخرى",
      "Error generating the video": "حدث خطأ أثناء إنشاء الفيديو",
      "Twitch stream": "تيار نشل",
      "Join the stream and chat with AI Characters": "انضم إلى البث والدردشة مع شخصيات الذكاء الاصطناعي",
      "Please complete your onboarding in order to keep using our platform for free.": "يرجى إكمال عملية الإعداد الخاصة بك لمواصلة استخدام منصتنا مجانًا.",
      "Looks like you are a heavy user of our platform!": "يبدو أنك مستخدم كثيف لمنصتنا!",
      "You need to complete your onboarding in order to continue": "تحتاج إلى إكمال عملية الإعداد الخاصة بك من أجل الاستمرار",
      "Complete onboarding": "استكمال الإعداد",
      "Shape": "شكل",
      "Yes, show me ads. I understand why you do it": "نعم، أرني الإعلانات. أنا أفهم لماذا تفعل ذلك",
      "No, do not show me ads, I hate ads": "لا، لا تظهر لي الإعلانات، أنا أكره الإعلانات",
      "It costs us a lot of money to operate this platform.": "لقد كلفنا الكثير من المال لتشغيل هذه المنصة.",
      "Just one question": "سؤال واحد فقط",
      "It would really help us if you allowed us to show you some ads.": "سيكون من المفيد لنا حقًا أن تسمح لنا بعرض بعض الإعلانات لك.",
      "This way you can keep using our platform for free, while helping us break even.": "بهذه الطريقة يمكنك الاستمرار في استخدام منصتنا مجانًا، بينما تساعدنا في تحقيق التعادل.",
      "Email address": "عنوان البريد الإلكتروني",
      "Create a Password": "أنشئ كلمة مرور",
      "Your email address": "عنوان بريدك  الإلكتروني",
      "Your password": "كلمة السر خاصتك",
      "Sign up": "اشتراك",
      "Signing up ...": "توقيع ...",
      "Sign in with": "تسجيل الدخول ب",
      "Don't have an account? Sign up": "ليس لديك حساب؟ اشتراك",
      "Check your email for the confirmation link": "تحقق من بريدك الإلكتروني للحصول على رابط التأكيد",
      "Your Password": "كلمة السر خاصتك",
      "Sign in": "تسجيل الدخول",
      "Signing in ...": "تسجيل الدخول ...",
      "Already have an account? Sign in": "هل لديك حساب؟ تسجيل الدخول",
      "Send Magic Link": "إرسال الرابط السحري",
      "Sending Magic Link ...": "إرسال الرابط السحري...",
      "Send a magic link email": "أرسل بريدًا إلكترونيًا يحتوي على رابط سحري",
      "Check your email for the magic link": "تحقق من بريدك الإلكتروني للحصول على الرابط السحري",
      "Send reset password instructions": "إرسال تعليمات إعادة تعيين كلمة المرور",
      "Sending reset instructions ...": "جارٍ إرسال تعليمات إعادة التعيين...",
      "Forgot your password?": "نسيت كلمة السر؟",
      "Check your email for the password reset link": "تحقق من بريدك الإلكتروني للحصول على رابط إعادة تعيين كلمة المرور",
      "New password": "كلمة المرور الجديدة",
      "Your new password": "كلمة سرك الجديدة",
      "Update password": "تطوير كلمة السر",
      "Updating password ...": "جارٍ تحديث كلمة المرور...",
      "Your password has been updated": "لقد تم تحديث كلمة السر الخاصة بك",
      "Your phone number": "رقم تليفونك",
      "Token": "رمز مميز",
      "Your Otp token": "رمز Otp الخاص بك",
      "Verify token": "التحقق من الرمز المميز",
      "We need a sponsor": "نحن بحاجة إلى الراعي",
      "Help us break even": "ساعدونا في تحقيق التعادل",
      "Help us keep the platform free": "ساعدنا في الحفاظ على المنصة مجانية",
      "My images": "صوري",
      "Gallery": "صالة عرض",
      "Super Voice": "سوبر صوت",
      "Create speech from text": "إنشاء خطاب من النص",
      "Create speech": "خلق الكلام",
      "This is Gordon Ramsay, what would you like to eat?": "هذا هو جوردون رامزي، ماذا تريد أن تأكل؟",
      "New": "جديد",
      "Images": "الصور",
      "GIFs": "صور GIF"
    }
  },
  "ko": {
    "translation": {
      "Home": "집",
      "Logout": "로그 아웃",
      "Profile": "프로필",
      "Onboard": "온보드",
      "Blog": "블로그",
      "Contact Us": "문의하기",
      "Login": "로그인",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "웹사이트를 계속 사용할 수 있도록 이메일을 확인해 주세요. 클릭할 수 있는 링크가 포함된 이메일을 보내드렸습니다.",
      "Resend verification email": "확인 이메일을 다시 보내",
      "Call us at +1-877-775-5232": "+1-877-775-5232로 전화하세요.",
      "Super Pipeline": "슈퍼 파이프라인",
      "About": "에 대한",
      "Signup Free": "가입 무료",
      "Disclaimer": "부인 성명",
      "Contact us with any question at": "질문이 있으시면 다음 주소로 문의해 주세요.",
      "Call us at": "우리에게 전화하세요",
      "Plans": "계획",
      "Privacy policy": "개인 정보 정책",
      "Terms of service": "서비스 약관",
      "Finished": "완성된",
      "Continue": "계속하다",
      "Start Over": "다시 시작하다",
      "Start (100% FREE)": "시작(100% 무료)",
      "Submit": "제출하다",
      "Examples": "예",
      "Select character": "캐릭터 선택",
      "Video Examples": "비디오 예",
      "Write a poem about life": "인생에 관한 시를 써 보세요.",
      "Explain how molecules work": "분자가 어떻게 작동하는지 설명하세요",
      "Write a story about life in Mars": "화성 생활에 관한 이야기 ​​쓰기",
      "Create an entire video from a single prompt": "단일 프롬프트로 전체 비디오 만들기",
      "Entertain, inform and inspire": "즐거움을 주고, 정보를 제공하고, 영감을 주세요",
      "through the power of AI": "AI의 힘으로",
      "Create Video!": "비디오 만들기!",
      "one more step before you start Just": "시작하기 전에 한 단계 더",
      "First name": "이름",
      "What is your first name": "당신의 이름은 무엇인가",
      "Please input your first name!": "이름을 입력해주세요!",
      "Last/family name": "성/성",
      "What is your last/family name": "당신의 성/성은 무엇입니까",
      "Please input your last/family name!": "성/성을 입력해주세요!",
      "Nickname": "별명",
      "What do you want others to call you?": "다른 사람들이 당신을 뭐라고 부르길 바라나요?",
      "Please input your nickname!": "닉네임을 입력해주세요!",
      "Company": "회사",
      "What is the company you represent?": "당신이 대표하는 회사는 무엇입니까?",
      "Please input your company!": "회사를 입력해주세요!",
      "Phone Number": "전화 번호",
      "What is your phone number?": "전화 번호는 뭐 지요?",
      "Why we ask for your phone number": "전화번호를 묻는 이유",
      "Account Recovery": "계정 복구",
      "If you forget your password or get locked out of your account, a phone number provides an additional way to verify your identity and regain access.": "비밀번호를 잊어버렸거나 계정이 잠긴 경우 전화번호를 통해 신원을 확인하고 액세스 권한을 다시 얻을 수 있습니다.",
      "Fraud Prevention": "사기 예방",
      "by asking for phone number it limits fake or fraudulent account creation. It's more challenging for scammers to maintain numerous unique phone numbers than it is for email addresses.": "전화번호를 요청함으로써 가짜 또는 사기성 계정 생성을 제한합니다. 사기꾼이 이메일 주소보다 고유한 전화번호를 많이 유지하는 것이 더 어렵습니다.",
      "Communication": "의사소통",
      "we may communicate with users directly through phone calls or SMS, whether for support or account issues": "당사는 지원이나 계정 문제 등 전화 통화나 SMS를 통해 사용자와 직접 소통할 수 있습니다.",
      "Please input your phone number! Example +18777755232": "전화번호를 입력해주세요! 예 +18777755232",
      "I agree to the": "나는 동의 함",
      "privacy policy": "개인 정보 정책",
      "terms of service": "서비스 약관",
      "disclaimer": "부인 성명",
      "Just one more step before you start": "시작하기 전에 한 단계만 더",
      "Phone number": "전화 번호",
      "CONTACT US": "문의하기",
      "BLOGS": "블로그",
      "Published": "게시됨",
      "Office": "사무실",
      "Super Character": "슈퍼 캐릭터",
      "Super Meme": "슈퍼 밈",
      "Hello, how are you?": "안녕하세요. 어떻게 지내세요?",
      "Say something": "뭐라고 말해보세요",
      "Download video": "비디오 다운로드",
      "Check out this cool AI text to video tool": "이 멋진 AI 텍스트를 비디오 도구로 확인해 보세요.",
      "Share": "공유하다",
      "Download": "다운로드",
      "Select or search a character": "캐릭터 선택 또는 검색",
      "Copy link": "링크 복사",
      "Link copied to clipboard": "링크가 클립보드에 복사되었습니다.",
      "This character cannot create videos for now": "이 캐릭터는 현재 동영상을 만들 수 없습니다.",
      "Create GIFs from your prompts": "프롬프트에서 GIF 만들기",
      "Create GIF": "GIF 만들기",
      "Monkey sitting on a chair and eating Pasta": "의자에 앉아서 파스타를 먹는 원숭이",
      "Please wait (30-60 seconds)": "잠시 기다려주세요(30~60초)",
      "Please wait (30-120 seconds)": "잠시 기다려주세요(30~120초)",
      "Prompt": "즉각적인",
      "The future of A.I. content creation": "AI의 미래 콘텐츠 제작",
      "Try these prompts": "다음 메시지를 시도해 보세요.",
      "Signup FREE": "무료 가입",
      "Cancel": "취소",
      "Error": "오류",
      "Hide video": "영상 숨기기",
      "It's 100% FREE!": "100% 무료입니다!",
      "Win $100 Gift Cards for the best GIF!": "최고의 GIF를 위해 $100 기프트 카드를 받으세요!",
      "How can I win?": "어떻게 하면 이길 수 있나요?",
      "Create a GIF": "GIF 만들기",
      "Post it on Reddit": "레딧에 게시하세요",
      "Send us a link to your post": "귀하의 게시물에 대한 링크를 보내주십시오.",
      "The GIF with the most upvotes wins a weekly $100 Gift Card!": "가장 많은 찬성표를 얻은 GIF는 매주 $100 기프트 카드를 획득합니다!",
      "Minimum 20 upvotes required": "최소 20개의 찬성표가 필요합니다.",
      "Email us your GIF": "GIF를 이메일로 보내주세요",
      "Create your GIF": "GIF 만들기",
      "Submit your GIF to Reddit - Get $100 Gift Card!": "Reddit에 GIF를 제출하면 $100 기프트 카드를 받을 수 있습니다!",
      "Welcome to the chat room with": "채팅방에 오신 것을 환영합니다",
      "Click on one of these messages": "이 메시지 중 하나를 클릭하세요",
      "Super GIF": "슈퍼 GIF",
      "Generating your video": "비디오 생성 중",
      "Click this button in order to generate a video from this response": "이 응답으로 동영상을 생성하려면 이 버튼을 클릭하세요.",
      "Start": "시작",
      "You need to signup for free in order to create a GIF": "GIF를 만들려면 무료 회원가입이 필요합니다.",
      "Create video": "비디오 만들기",
      "Share video": "공유 영상",
      "Create images from your prompts": "프롬프트에서 이미지 만들기",
      "Create image": "이미지 생성",
      "Please wait (10-30 seconds)": "잠시 기다려주세요(10~30초)",
      "Download GIF": "GIF 다운로드",
      "Download image": "이미지 다운로드",
      "Super Image": "슈퍼 이미지",
      "Super Person": "슈퍼퍼슨",
      "Create fake person": "가짜 사람 만들기",
      "Select gender": "성별을 선택하세요",
      "Select age": "연령 선택",
      "Select ethnic": "민족 선택",
      "Fake Person Generator": "가짜 사람 생성기",
      "We create AI tools for content creators": "우리는 콘텐츠 제작자를 위한 AI 도구를 만듭니다.",
      "You need to signup for free in order to use our tools": "도구를 사용하려면 무료로 가입해야 합니다.",
      "Show more": "자세히보기",
      "Chat with your favorite characters": "좋아하는 캐릭터와 채팅하세요",
      "Chat with over 60 characters": "60자 이상의 캐릭터와 채팅",
      "Style": "스타일",
      "Error sending the chat message. Please try again": "채팅 메시지를 보내는 중에 오류가 발생했습니다. 다시 시도해 주세요",
      "Error generating the video": "동영상을 생성하는 중에 오류가 발생했습니다.",
      "Twitch stream": "트위치 스트림",
      "Join the stream and chat with AI Characters": "스트림에 참여하고 AI 캐릭터와 채팅하세요",
      "Please complete your onboarding in order to keep using our platform for free.": "우리 플랫폼을 계속 무료로 사용하려면 온보딩을 완료하세요.",
      "Looks like you are a heavy user of our platform!": "귀하는 우리 플랫폼의 헤비 유저인 것 같습니다!",
      "You need to complete your onboarding in order to continue": "계속하려면 온보딩을 완료해야 합니다.",
      "Complete onboarding": "온보딩 완료",
      "Shape": "모양",
      "Yes, show me ads. I understand why you do it": "예, 광고를 보여주세요. 왜 그러는지 이해가 가네요",
      "No, do not show me ads, I hate ads": "아니요, 광고를 표시하지 마세요. 광고가 싫습니다.",
      "It costs us a lot of money to operate this platform.": "이 플랫폼을 운영하는 데는 많은 비용이 듭니다.",
      "Just one question": "질문 하나만요",
      "It would really help us if you allowed us to show you some ads.": "광고를 표시하도록 허용해 주시면 정말 도움이 될 것입니다.",
      "This way you can keep using our platform for free, while helping us break even.": "이렇게 하면 귀하는 우리 플랫폼을 무료로 계속 사용하면서 우리가 손익분기점에 도달하도록 도울 수 있습니다.",
      "Email address": "이메일 주소",
      "Create a Password": "비밀번호 만들기",
      "Your email address": "귀하의 이메일 주소",
      "Your password": "너의 비밀번호",
      "Sign up": "가입하기",
      "Signing up ...": "가입 중 ...",
      "Sign in with": "으로 로그인",
      "Don't have an account? Sign up": "계정이 없나요? 가입하기",
      "Check your email for the confirmation link": "이메일에서 확인 링크를 확인하세요",
      "Your Password": "너의 비밀번호",
      "Sign in": "로그인",
      "Signing in ...": "로그인 ...",
      "Already have an account? Sign in": "이미 계정이 있나요? 로그인",
      "Send Magic Link": "매직링크 보내기",
      "Sending Magic Link ...": "매직링크 보내는 중...",
      "Send a magic link email": "매직 링크 이메일 보내기",
      "Check your email for the magic link": "이메일에서 매직 링크를 확인하세요",
      "Send reset password instructions": "비밀번호 재설정 지침 보내기",
      "Sending reset instructions ...": "재설정 지침을 보내는 중...",
      "Forgot your password?": "비밀번호를 잊어 버렸습니까?",
      "Check your email for the password reset link": "이메일에서 비밀번호 재설정 링크를 확인하세요.",
      "New password": "새 비밀번호",
      "Your new password": "새 비밀번호",
      "Update password": "비밀번호 업데이트",
      "Updating password ...": "비밀번호 업데이트 중...",
      "Your password has been updated": "비밀번호가 업데이트되었습니다.",
      "Your phone number": "전화 번호",
      "Token": "토큰",
      "Your Otp token": "귀하의 OTP 토큰",
      "Verify token": "토큰 확인",
      "We need a sponsor": "우리는 스폰서가 필요해요",
      "Help us break even": "우리가 손익분기점에 도달하도록 도와주세요",
      "Help us keep the platform free": "플랫폼을 무료로 유지할 수 있도록 도와주세요",
      "My images": "내 이미지",
      "Gallery": "갤러리",
      "Super Voice": "슈퍼보이스",
      "Create speech from text": "텍스트에서 음성 만들기",
      "Create speech": "음성 만들기",
      "This is Gordon Ramsay, what would you like to eat?": "고든 램지입니다. 뭐 드실래요?",
      "New": "새로운",
      "Images": "이미지",
      "GIFs": "GIF"
    }
  },
  "vi": {
    "translation": {
      "Home": "Trang chủ",
      "Logout": "Đăng xuất",
      "Profile": "Hồ sơ",
      "Onboard": "Trên tàu",
      "Blog": "Blog",
      "Contact Us": "Liên hệ chúng tôi",
      "Login": "Đăng nhập",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "Vui lòng đi và xác minh email của bạn để bạn có thể tiếp tục sử dụng trang web. Chúng tôi đã gửi cho bạn một email có liên kết để nhấp vào.",
      "Resend verification email": "Gửi lại email xác minh",
      "Call us at +1-877-775-5232": "Gọi cho chúng tôi theo số +1-877-775-5232",
      "Super Pipeline": "Siêu đường ống",
      "About": "Về",
      "Signup Free": "Đăng kí miễn phí",
      "Disclaimer": "Tuyên bố miễn trừ trách nhiệm",
      "Contact us with any question at": "Liên hệ với chúng tôi với bất kỳ câu hỏi tại",
      "Call us at": "Gọi cho chúng tôi tại",
      "Plans": "Các kế hoạch",
      "Privacy policy": "Chính sách bảo mật",
      "Terms of service": "Điều khoản dịch vụ",
      "Finished": "Hoàn thành",
      "Continue": "Tiếp tục",
      "Start Over": "Bắt đầu lại",
      "Start (100% FREE)": "Bắt đầu (MIỄN PHÍ 100%)",
      "Submit": "Nộp",
      "Examples": "Ví dụ",
      "Select character": "Chọn ký tự",
      "Video Examples": "Ví dụ về Video",
      "Write a poem about life": "Viết bài thơ về cuộc đời",
      "Explain how molecules work": "Giải thích cách thức hoạt động của các phân tử",
      "Write a story about life in Mars": "Viết một câu chuyện về cuộc sống trên sao Hỏa",
      "Create an entire video from a single prompt": "Tạo toàn bộ video từ một lời nhắc duy nhất",
      "Entertain, inform and inspire": "Giải trí, thông tin và truyền cảm hứng",
      "through the power of AI": "thông qua sức mạnh của AI",
      "Create Video!": "Tạo Video!",
      "one more step before you start Just": "một bước nữa trước khi bạn bắt đầu",
      "First name": "Tên đầu tiên",
      "What is your first name": "Tên bạn là gì",
      "Please input your first name!": "Vui lòng nhập tên của bạn!",
      "Last/family name": "Họ/họ",
      "What is your last/family name": "Họ/họ của bạn là gì",
      "Please input your last/family name!": "Vui lòng nhập họ/họ của bạn!",
      "Nickname": "Tên nick",
      "What do you want others to call you?": "Bạn muốn người khác gọi bạn là gì?",
      "Please input your nickname!": "Vui lòng nhập biệt danh của bạn!",
      "Company": "Công ty",
      "What is the company you represent?": "Công ty bạn đại diện là gì?",
      "Please input your company!": "Vui lòng nhập công ty của bạn!",
      "Phone Number": "Số điện thoại",
      "What is your phone number?": "Số điện thoại của bạn là gì?",
      "Why we ask for your phone number": "Tại sao chúng tôi yêu cầu số điện thoại của bạn",
      "Account Recovery": "Khôi phục tài khoản",
      "If you forget your password or get locked out of your account, a phone number provides an additional way to verify your identity and regain access.": "Nếu bạn quên mật khẩu hoặc bị khóa tài khoản, số điện thoại sẽ cung cấp một cách bổ sung để xác minh danh tính của bạn và lấy lại quyền truy cập.",
      "Fraud Prevention": "Phòng chống gian lận",
      "by asking for phone number it limits fake or fraudulent account creation. It's more challenging for scammers to maintain numerous unique phone numbers than it is for email addresses.": "bằng cách yêu cầu số điện thoại, nó sẽ hạn chế việc tạo tài khoản giả mạo hoặc gian lận. Đối với những kẻ lừa đảo, việc duy trì nhiều số điện thoại duy nhất sẽ khó khăn hơn so với địa chỉ email.",
      "Communication": "Giao tiếp",
      "we may communicate with users directly through phone calls or SMS, whether for support or account issues": "chúng tôi có thể liên lạc trực tiếp với người dùng thông qua cuộc gọi điện thoại hoặc SMS, cho dù là để hỗ trợ hay giải quyết vấn đề về tài khoản",
      "Please input your phone number! Example +18777755232": "Vui lòng nhập số điện thoại của bạn! Ví dụ +18777755232",
      "I agree to the": "tôi đồng ý với",
      "privacy policy": "Chính sách bảo mật",
      "terms of service": "điều khoản dịch vụ",
      "disclaimer": "từ chối trách nhiệm",
      "Just one more step before you start": "Chỉ còn một bước nữa trước khi bạn bắt đầu",
      "Phone number": "Số điện thoại",
      "CONTACT US": "LIÊN HỆ CHÚNG TÔI",
      "BLOGS": "BLOG",
      "Published": "Được phát hành",
      "Office": "Văn phòng",
      "Super Character": "Siêu nhân vật",
      "Super Meme": "siêu meme",
      "Hello, how are you?": "Xin chào bạn khoẻ không?",
      "Say something": "Nói gì đó đi",
      "Download video": "Tải video",
      "Check out this cool AI text to video tool": "Hãy xem công cụ chuyển văn bản thành video AI thú vị này",
      "Share": "Chia sẻ",
      "Download": "Tải xuống",
      "Select or search a character": "Chọn hoặc tìm kiếm một ký tự",
      "Copy link": "Sao chép đường dẫn",
      "Link copied to clipboard": "Liên kết được sao chép vào khay nhớ tạm",
      "This character cannot create videos for now": "Nhân vật này hiện không thể tạo video",
      "Create GIFs from your prompts": "Tạo ảnh GIF từ lời nhắc của bạn",
      "Create GIF": "Tạo GIF",
      "Monkey sitting on a chair and eating Pasta": "Khỉ ngồi trên ghế và ăn mì ống",
      "Please wait (30-60 seconds)": "Vui lòng đợi (30-60 giây)",
      "Please wait (30-120 seconds)": "Vui lòng đợi (30-120 giây)",
      "Prompt": "Lời nhắc",
      "The future of A.I. content creation": "Tương lai của A.I. sáng tạo nội dung",
      "Try these prompts": "Hãy thử những lời nhắc này",
      "Signup FREE": "Đăng kí miễn phí",
      "Cancel": "Hủy bỏ",
      "Error": "Lỗi",
      "Hide video": "Ẩn video",
      "It's 100% FREE!": "Nó MIỄN PHÍ 100%!",
      "Win $100 Gift Cards for the best GIF!": "Giành được Thẻ quà tặng trị giá $100 để có ảnh GIF đẹp nhất!",
      "How can I win?": "Làm thế nào tôi có thể giành chiến thắng?",
      "Create a GIF": "Tạo ảnh GIF",
      "Post it on Reddit": "Đăng nó lên Reddit",
      "Send us a link to your post": "Gửi cho chúng tôi một liên kết đến bài viết của bạn",
      "The GIF with the most upvotes wins a weekly $100 Gift Card!": "GIF có nhiều lượt tán thành nhất sẽ giành được Thẻ quà tặng trị giá $100 hàng tuần!",
      "Minimum 20 upvotes required": "Yêu cầu tối thiểu 20 lượt tán thành",
      "Email us your GIF": "Gửi email cho chúng tôi GIF của bạn",
      "Create your GIF": "Tạo GIF của bạn",
      "Submit your GIF to Reddit - Get $100 Gift Card!": "Gửi ảnh GIF của bạn tới Reddit - Nhận Thẻ quà tặng trị giá $100!",
      "Welcome to the chat room with": "Chào mừng đến với phòng trò chuyện với",
      "Click on one of these messages": "Bấm vào một trong những tin nhắn này",
      "Super GIF": "Siêu GIF",
      "Generating your video": "Đang tạo video của bạn",
      "Click this button in order to generate a video from this response": "Nhấp vào nút này để tạo video từ phản hồi này",
      "Start": "Bắt đầu",
      "You need to signup for free in order to create a GIF": "Bạn cần đăng ký miễn phí để tạo ảnh GIF",
      "Create video": "Tạo video",
      "Share video": "Chia sẻ video",
      "Create images from your prompts": "Tạo hình ảnh từ lời nhắc của bạn",
      "Create image": "Tạo hình ảnh",
      "Please wait (10-30 seconds)": "Vui lòng đợi (10-30 giây)",
      "Download GIF": "Tải xuống ảnh GIF",
      "Download image": "Tải hình ảnh",
      "Super Image": "Siêu hình ảnh",
      "Super Person": "Siêu nhân",
      "Create fake person": "Tạo người giả",
      "Select gender": "Chọn giới tính",
      "Select age": "Chọn độ tuổi",
      "Select ethnic": "Chọn dân tộc",
      "Fake Person Generator": "Trình tạo người giả",
      "We create AI tools for content creators": "Chúng tôi tạo ra các công cụ AI cho người sáng tạo nội dung",
      "You need to signup for free in order to use our tools": "Bạn cần đăng ký miễn phí để sử dụng các công cụ của chúng tôi",
      "Show more": "Cho xem nhiều hơn",
      "Chat with your favorite characters": "Trò chuyện với các nhân vật yêu thích của bạn",
      "Chat with over 60 characters": "Trò chuyện với hơn 60 ký tự",
      "Style": "Phong cách",
      "Error sending the chat message. Please try again": "Lỗi gửi tin nhắn trò chuyện. Vui lòng thử lại",
      "Error generating the video": "Lỗi tạo video",
      "Twitch stream": "Truyền phát Twitch",
      "Join the stream and chat with AI Characters": "Tham gia luồng và trò chuyện với Nhân vật AI",
      "Please complete your onboarding in order to keep using our platform for free.": "Vui lòng hoàn tất quá trình đăng ký của bạn để tiếp tục sử dụng nền tảng của chúng tôi miễn phí.",
      "Looks like you are a heavy user of our platform!": "Có vẻ như bạn là người sử dụng thường xuyên nền tảng của chúng tôi!",
      "You need to complete your onboarding in order to continue": "Bạn cần hoàn tất quá trình giới thiệu để tiếp tục",
      "Complete onboarding": "Hoàn tất quá trình giới thiệu",
      "Shape": "Hình dạng",
      "Yes, show me ads. I understand why you do it": "Có, cho tôi xem quảng cáo. Tôi hiểu tại sao bạn làm điều đó",
      "No, do not show me ads, I hate ads": "Không, đừng cho tôi xem quảng cáo, tôi ghét quảng cáo",
      "It costs us a lot of money to operate this platform.": "Chúng tôi tốn rất nhiều tiền để vận hành nền tảng này.",
      "Just one question": "Chỉ một câu hỏi",
      "It would really help us if you allowed us to show you some ads.": "Sẽ thực sự hữu ích nếu bạn cho phép chúng tôi hiển thị cho bạn một số quảng cáo.",
      "This way you can keep using our platform for free, while helping us break even.": "Bằng cách này, bạn có thể tiếp tục sử dụng nền tảng của chúng tôi miễn phí, đồng thời giúp chúng tôi hòa vốn.",
      "Email address": "Địa chỉ email",
      "Create a Password": "Tạo một mật khẩu",
      "Your email address": "địa chỉ email của bạn",
      "Your password": "Mật khẩu của bạn",
      "Sign up": "Đăng ký",
      "Signing up ...": "Đăng ký ...",
      "Sign in with": "Đăng nhập với",
      "Don't have an account? Sign up": "Bạn chưa có tài khoản? Đăng ký",
      "Check your email for the confirmation link": "Kiểm tra email của bạn để lấy liên kết xác nhận",
      "Your Password": "Mật khẩu của bạn",
      "Sign in": "Đăng nhập",
      "Signing in ...": "Đang đăng nhập ...",
      "Already have an account? Sign in": "Bạn co săn san để tạo một tai khoản? Đăng nhập",
      "Send Magic Link": "Gửi liên kết kỳ diệu",
      "Sending Magic Link ...": "Đang gửi liên kết ma thuật ...",
      "Send a magic link email": "Gửi email liên kết kỳ diệu",
      "Check your email for the magic link": "Kiểm tra email của bạn để tìm liên kết ma thuật",
      "Send reset password instructions": "Gửi hướng dẫn đặt lại mật khẩu",
      "Sending reset instructions ...": "Đang gửi hướng dẫn đặt lại ...",
      "Forgot your password?": "Quên mật khẩu?",
      "Check your email for the password reset link": "Kiểm tra email của bạn để lấy liên kết đặt lại mật khẩu",
      "New password": "Mật khẩu mới",
      "Your new password": "Mật khẩu mới của bạn",
      "Update password": "Cập nhật mật khẩu",
      "Updating password ...": "Đang cập nhật mật khẩu...",
      "Your password has been updated": "Mật khẩu của bạn đã được cập nhật",
      "Your phone number": "Số điện thoại của bạn",
      "Token": "Mã thông báo",
      "Your Otp token": "Mã thông báo Otp của bạn",
      "Verify token": "Xác minh mã thông báo",
      "We need a sponsor": "Chúng tôi cần một nhà tài trợ",
      "Help us break even": "Hãy giúp chúng tôi hòa vốn",
      "Help us keep the platform free": "Hãy giúp chúng tôi giữ cho nền tảng này được miễn phí",
      "My images": "Hình ảnh của tôi",
      "Gallery": "Phòng trưng bày",
      "Super Voice": "Siêu giọng nói",
      "Create speech from text": "Tạo lời nói từ văn bản",
      "Create speech": "Tạo lời nói",
      "This is Gordon Ramsay, what would you like to eat?": "Đây là Gordon Ramsay, bạn muốn ăn gì?",
      "New": "Mới",
      "Images": "Hình ảnh",
      "GIFs": "GIF"
    }
  },
  "he": {
    "translation": {
      "Home": "בית",
      "Logout": "להתנתק",
      "Profile": "פּרוֹפִיל",
      "Onboard": "בעניין",
      "Blog": "בלוג",
      "Contact Us": "צור קשר",
      "Login": "התחברות",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "נא לאמת את הדוא\"ל שלך כדי שתוכל להמשיך להשתמש באתר. שלחנו לך מייל עם קישור ללחוץ עליו.",
      "Resend verification email": "לשלוח דוא\"ל לאימות",
      "Call us at +1-877-775-5232": "התקשר אלינו למספר 1-877-775-5232",
      "Super Pipeline": "סופר צינור",
      "About": "על אודות",
      "Signup Free": "הירשם בחינם",
      "Disclaimer": "כתב ויתור",
      "Contact us with any question at": "פנה אלינו בכל שאלה בכתובת",
      "Call us at": "תתקשר אלינו ב",
      "Plans": "תוכניות",
      "Privacy policy": "מדיניות הפרטיות",
      "Terms of service": "תנאי השירות",
      "Finished": "גָמוּר",
      "Continue": "לְהַמשִׁיך",
      "Start Over": "להתחיל מחדש",
      "Start (100% FREE)": "התחל (100% בחינם)",
      "Submit": "שלח",
      "Examples": "דוגמאות",
      "Select character": "בחר דמות",
      "Video Examples": "דוגמאות וידאו",
      "Write a poem about life": "כתבו שיר על החיים",
      "Explain how molecules work": "הסבר כיצד פועלות מולקולות",
      "Write a story about life in Mars": "כתבו סיפור על החיים במאדים",
      "Create an entire video from a single prompt": "צור סרטון שלם מתוך הנחיה אחת",
      "Entertain, inform and inspire": "לבדר, ליידע ולעורר השראה",
      "through the power of AI": "באמצעות הכוח של AI",
      "Create Video!": "צור סרטון!",
      "one more step before you start Just": "עוד צעד אחד לפני שתתחיל",
      "First name": "שם פרטי",
      "What is your first name": "מה השם הפרטי שלך",
      "Please input your first name!": "אנא הזן את שמך הפרטי!",
      "Last/family name": "שם משפחה אחרון",
      "What is your last/family name": "מה שם המשפחה/משפחה שלך",
      "Please input your last/family name!": "אנא הזן את שם המשפחה/משפחה שלך!",
      "Nickname": "כינוי",
      "What do you want others to call you?": "איך אתה רוצה שאחרים יקראו לך?",
      "Please input your nickname!": "אנא הזן את הכינוי שלך!",
      "Company": "חֶברָה",
      "What is the company you represent?": "מהי החברה שאתה מייצג?",
      "Please input your company!": "אנא הזן את החברה שלך!",
      "Phone Number": "מספר טלפון",
      "What is your phone number?": "מה מספר הטלפון שלך?",
      "Why we ask for your phone number": "מדוע אנו מבקשים את מספר הטלפון שלך",
      "Account Recovery": "שחזור חשבון",
      "If you forget your password or get locked out of your account, a phone number provides an additional way to verify your identity and regain access.": "אם תשכח את הסיסמה שלך או ננעל מחוץ לחשבון שלך, מספר טלפון מספק דרך נוספת לאמת את זהותך ולהשיג גישה מחדש.",
      "Fraud Prevention": "מניעת הונאה",
      "by asking for phone number it limits fake or fraudulent account creation. It's more challenging for scammers to maintain numerous unique phone numbers than it is for email addresses.": "על ידי בקשת מספר טלפון זה מגביל יצירת חשבון מזויף או הונאה. זה יותר מאתגר עבור רמאים לשמור על מספרי טלפון ייחודיים רבים מאשר עבור כתובות דוא\"ל.",
      "Communication": "תִקשׁוֹרֶת",
      "we may communicate with users directly through phone calls or SMS, whether for support or account issues": "אנו עשויים לתקשר עם משתמשים ישירות באמצעות שיחות טלפון או SMS, בין אם לצורך תמיכה או בעיות בחשבון",
      "Please input your phone number! Example +18777755232": "אנא הזן את מספר הטלפון שלך! דוגמה +18777755232",
      "I agree to the": "אני מסכים ל",
      "privacy policy": "מדיניות הפרטיות",
      "terms of service": "תנאי השירות",
      "disclaimer": "כתב ויתור",
      "Just one more step before you start": "רק עוד שלב אחד לפני שתתחיל",
      "Phone number": "מספר טלפון",
      "CONTACT US": "צור קשר",
      "BLOGS": "בלוגים",
      "Published": "יצא לאור",
      "Office": "מִשׂרָד",
      "Super Character": "דמות על",
      "Super Meme": "סופר מם",
      "Hello, how are you?": "שלום מה שלומך?",
      "Say something": "תגיד משהו",
      "Download video": "הורד סרטון",
      "Check out this cool AI text to video tool": "בדוק את הכלי המגניב הזה של טקסט לווידאו בינה מלאכותית",
      "Share": "לַחֲלוֹק",
      "Download": "הורד",
      "Select or search a character": "בחר או חפש דמות",
      "Copy link": "העתק קישור",
      "Link copied to clipboard": "הקישור הועתק ללוח",
      "This character cannot create videos for now": "הדמות הזו לא יכולה ליצור סרטונים לעת עתה",
      "Create GIFs from your prompts": "צור קובצי GIF מההנחיות שלך",
      "Create GIF": "צור GIF",
      "Monkey sitting on a chair and eating Pasta": "קוף יושב על כיסא ואוכל פסטה",
      "Please wait (30-60 seconds)": "אנא המתן (30-60 שניות)",
      "Please wait (30-120 seconds)": "אנא המתן (30-120 שניות)",
      "Prompt": "מיידי",
      "The future of A.I. content creation": "העתיד של A.I. יצירת תוכן",
      "Try these prompts": "נסה את ההנחיות האלה",
      "Signup FREE": "הירשם בחינם",
      "Cancel": "לְבַטֵל",
      "Error": "שְׁגִיאָה",
      "Hide video": "הסתר סרטון",
      "It's 100% FREE!": "זה 100% בחינם!",
      "Win $100 Gift Cards for the best GIF!": "זכה בכרטיסי מתנה של $100 עבור ה-GIF הטוב ביותר!",
      "How can I win?": "איך אני יכול לנצח?",
      "Create a GIF": "צור GIF",
      "Post it on Reddit": "פרסם את זה ב- Reddit",
      "Send us a link to your post": "שלח לנו קישור לפוסט שלך",
      "The GIF with the most upvotes wins a weekly $100 Gift Card!": "ה-GIF עם הכי הרבה הצבעות בעד זוכה בכרטיס מתנה שבועי של $100!",
      "Minimum 20 upvotes required": "נדרשים לפחות 20 הצבעות בעד",
      "Email us your GIF": "שלח לנו דוא\"ל GIF שלך",
      "Create your GIF": "צור את ה-GIF שלך",
      "Submit your GIF to Reddit - Get $100 Gift Card!": "שלח את ה-GIF שלך ל-Reddit - קבל כרטיס מתנה של $100!",
      "Welcome to the chat room with": "ברוכים הבאים לחדר הצ'אט עם",
      "Click on one of these messages": "לחץ על אחת מההודעות הללו",
      "Super GIF": "סופר GIF",
      "Generating your video": "יצירת הסרטון שלך",
      "Click this button in order to generate a video from this response": "לחץ על כפתור זה כדי ליצור סרטון מתגובה זו",
      "Start": "הַתחָלָה",
      "You need to signup for free in order to create a GIF": "אתה צריך להירשם בחינם כדי ליצור GIF",
      "Create video": "צור וידאו",
      "Share video": "שתף סרטון",
      "Create images from your prompts": "צור תמונות מההנחיות שלך",
      "Create image": "צור תמונה",
      "Please wait (10-30 seconds)": "אנא המתן (10-30 שניות)",
      "Download GIF": "הורד GIF",
      "Download image": "הורד תמונה",
      "Super Image": "סופר תמונה",
      "Super Person": "סופר אדם",
      "Create fake person": "צור אדם מזויף",
      "Select gender": "בחר מגדר",
      "Select age": "בחר גיל",
      "Select ethnic": "בחר אתנית",
      "Fake Person Generator": "מחולל אנשים מזויפים",
      "We create AI tools for content creators": "אנו יוצרים כלי AI עבור יוצרי תוכן",
      "You need to signup for free in order to use our tools": "אתה צריך להירשם בחינם כדי להשתמש בכלים שלנו",
      "Show more": "להראות יותר",
      "Chat with your favorite characters": "צ'אט עם הדמויות האהובות עליך",
      "Chat with over 60 characters": "צ'אט עם למעלה מ-60 תווים",
      "Style": "סִגְנוֹן",
      "Error sending the chat message. Please try again": "שגיאה בשליחת הודעת הצ'אט. בבקשה נסה שוב",
      "Error generating the video": "שגיאה ביצירת הסרטון",
      "Twitch stream": "זרם עוויתות",
      "Join the stream and chat with AI Characters": "הצטרף לזרם ושוחח עם דמויות AI",
      "Please complete your onboarding in order to keep using our platform for free.": "אנא השלם את ההצטרפות שלך כדי להמשיך להשתמש בפלטפורמה שלנו בחינם.",
      "Looks like you are a heavy user of our platform!": "נראה שאתה משתמש כבד בפלטפורמה שלנו!",
      "You need to complete your onboarding in order to continue": "עליך להשלים את ההצטרפות שלך כדי להמשיך",
      "Complete onboarding": "השלמה עלייה למטוס",
      "Shape": "צוּרָה",
      "Yes, show me ads. I understand why you do it": "כן, תראה לי מודעות. אני מבין למה אתה עושה את זה",
      "No, do not show me ads, I hate ads": "לא, אל תראה לי פרסומות, אני שונא פרסומות",
      "It costs us a lot of money to operate this platform.": "זה עולה לנו הרבה כסף לתפעל את הפלטפורמה הזו.",
      "Just one question": "רק שאלה אחת",
      "It would really help us if you allowed us to show you some ads.": "זה באמת יעזור לנו אם תאפשר לנו להציג לך כמה מודעות.",
      "This way you can keep using our platform for free, while helping us break even.": "כך תוכלו להמשיך להשתמש בפלטפורמה שלנו בחינם, תוך כדי סיוע לנו לשבור איזון.",
      "Email address": "כתובת דוא\"ל",
      "Create a Password": "צור סיסמה",
      "Your email address": "כתובת הדוא\"ל שלך",
      "Your password": "הסיסמה שלך",
      "Sign up": "הירשם",
      "Signing up ...": "נרשם...",
      "Sign in with": "להתחבר עם",
      "Don't have an account? Sign up": "אין לך חשבון? הירשם",
      "Check your email for the confirmation link": "תבדוק את האימייל שלך ללינק מאשר",
      "Your Password": "הסיסמה שלך",
      "Sign in": "להתחבר",
      "Signing in ...": "נכנס...",
      "Already have an account? Sign in": "כבר יש לך חשבון? להתחבר",
      "Send Magic Link": "שלח קישור קסם",
      "Sending Magic Link ...": "שולח קישור קסם...",
      "Send a magic link email": "שלח אימייל קישור קסום",
      "Check your email for the magic link": "בדוק באימייל שלך את הקישור הקסום",
      "Send reset password instructions": "שלח הוראות לאיפוס סיסמה",
      "Sending reset instructions ...": "שולח הוראות איפוס...",
      "Forgot your password?": "שכחת ססמה?",
      "Check your email for the password reset link": "בדוק באימייל שלך את הקישור לאיפוס הסיסמה",
      "New password": "סיסמה חדשה",
      "Your new password": "הסיסמא החדשה שלך",
      "Update password": "עדכן סיסמה",
      "Updating password ...": "מעדכן סיסמה...",
      "Your password has been updated": "הסיסמא שלך עודכנה",
      "Your phone number": "מספר הטלפון שלך",
      "Token": "אֲסִימוֹן",
      "Your Otp token": "אסימון ה-Otp שלך",
      "Verify token": "אמת אסימון",
      "We need a sponsor": "אנחנו צריכים ספונסר",
      "Help us break even": "עזרו לנו לשבור איזון",
      "Help us keep the platform free": "עזרו לנו לשמור על הפלטפורמה בחינם",
      "My images": "התמונות שלי",
      "Gallery": "גלריה",
      "Super Voice": "סופר קול",
      "Create speech from text": "צור דיבור מטקסט",
      "Create speech": "צור דיבור",
      "This is Gordon Ramsay, what would you like to eat?": "זה גורדון רמזי, מה היית רוצה לאכול?",
      "New": "חָדָשׁ",
      "Images": "תמונות",
      "GIFs": "קובצי GIF"
    }
  }
};
/* eslint-enable quote-props,quotes,max-len */

export default E;
