// LICENSE_CODE ABCAI
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useLocation} from 'react-router-dom';
import {Col, Row, Avatar, Button, Space, Typography} from 'antd';
import auth from './auth.js';
import eserf from '../../../util/eserf.js';
import je from '../../../util/je.js';
import back_app from './back_app.js';
import metric from './metric.js';
import config_ext from './config_ext.js';
import {Clickable, use_focus} from './comp.js';

let {Title, Text} = Typography;
let E = ()=>{
  let {t} = useTranslation();
  let location = useLocation();
  let search = location.search;
  let {user, get_access_token_silently} = auth.use_auth_ext();
  let user_email_resend = ()=>eserf(function* _user_email_resend(){
    let res = yield auth.token_get(get_access_token_silently);
    if (config_ext.is_sbase)
      return yield auth.sbase.resend(res.token, user.email);
    yield back_app.user_email_resend(res.token, user.email);
  });
  let {is_focus} = use_focus();
  useEffect(()=>{
    metric.event('email_verif', true);
  }, []);
  useEffect(()=>{
    if (!is_focus || user.email_verified)
      return;
    let es = eserf(function* email_verif_focus(){
      while (1)
      {
        let res = yield auth.token_get(get_access_token_silently);
        let user_full = yield back_app.user_get(res.token, user.email);
        if (!user_full.err || (/is not.*org/u).test(user_full.err_data.err))
        {
          je.set('auth.is_email_verif', true);
          je.set_inc('email_verif.update_n');
          break;
        }
        yield eserf.sleep(5000);
      }
    });
    return ()=>es.return();
  }, [is_focus, get_access_token_silently, user]);
  return (
    <>
      <Row justify="center">
        <Col span={20}>
          {!user.email_verified && <Row>
            <Col>
              <Space direction="vertical" size="large" align="center">
                <Row>
                  <Col>
                    <Title level={2}>
                      {t('Please go and verify your email so you can '
                        +'continue to use the website. We sent you an '
                        +'email with a link to click on.')}
                    </Title>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Avatar src={!config_ext.is_local&&user.picture}/></Col>
                  <Col>
                    <Title level={5}>{user.email}</Title>
                    {name && <Text>{name}</Text>}
                  </Col>
                </Row>
                <Row>
                  <Button onClick={user_email_resend}>
                    {t('Resend verification email')}
                  </Button>
                </Row>
              </Space>
            </Col>
          </Row>}
          {user.email_verified && <Row>
            <Title level={2}>{t('Thank you for verifing your email')}</Title>
            <Clickable>
              <Button>
                <Link to={{pathname: '/simg', search}}>
                  {t('Start')}</Link>
              </Button>
            </Clickable>
          </Row>}
        </Col>
      </Row>
    </>
  );
};

export default auth.with_auth_req(E);
