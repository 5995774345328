// LICENSE_CODE ABCAI
import {Button, Col, Input, Row, Space, Typography, Modal,
  Tooltip, message} from 'antd';
import {isEmpty, get, map, find, trim, size, last, flow, sample, sampleSize,
  toLower, filter, includes} from 'lodash/fp.js';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import auth from './auth.js';
import eserf from '../../../util/eserf.js';
import back_app from './back_app.js';
import {Loading, Video, Chars} from './comp.js';
import {SendOutlined, VideoCameraOutlined, DownloadOutlined, ShareAltOutlined,
  CopyOutlined} from '@ant-design/icons';

let {TextArea} = Input;
let {Text, Title, Link} = Typography;
let char_img = 'https://abcai-front-share-prod.s3.amazonaws.com/img';
let url2share_url = _url=>{
  return _url;
  //return `${window.location.origin}/vid?url=${_url}`;
};

export const E = ()=>{
  let {t} = useTranslation();
  let [messageApi, contextHolder] = message.useMessage();
  let {user, token, org, user_full} = auth.use_auth();
  let [loading, set_loading] = useState(false);
  let [vid_gen_id, set_vid_gen_id] = useState(false);
  let [vid_url_modal, set_vid_url_modal] = useState(null);
  let [error, set_error] = useState('');
  let [chars, set_chars] = useState([]);
  let [chat, set_chat] = useState([]);
  let chat_el_ref = useRef(null);
  let [val, set_val] = useState('');
  let [char_id, set_char_id] = useState(null);
  let char = useMemo(()=>find(_char=>_char.char_id === char_id, chars),
    [chars, char_id]);
  useEffect(()=>{
    if (!char)
      return;
    let greeting = flow(
      get('greetings'),
      sample,
    )(char) || t('Hello, how are you?');
    set_chat([{name: char.lbl, txt: greeting,
      img: `${char_img}/${char.img}`, is_char: true}]);
  }, [char, t]);
  useEffect(()=>{
    let es = eserf(function* _get_chars_use_effect(){
      let _ret = yield back_app.produce_char_get();
      if (_ret.err)
      {
        set_error(_ret.err);
        return;
      }
      set_chars(_ret.chars);
    });
    return ()=>es.return();
  }, []);
  useEffect(()=>{
    window.clarity && window.clarity('set', 'schar', 'true');
  }, []);
  let has_data = useMemo(()=>!isEmpty(chars), [chars]);
  let on_submit = prompt=>eserf(function* _on_submit(){
    set_loading(true);
    let my_msg = {name: user.nickname || user.name, img: user.picture,
      txt: prompt};
    set_chat(curr=>[...curr, my_msg]);
    let gid = get('gid', last(chat));
    let prompt_id;
    let _ret;
    let _prompt = (prompt||'').slice(0, 400);
    if (!gid)
    {
      _ret = yield back_app.schar_start(token, user.email, org?.id, _prompt,
        char_id);
      gid = _ret.gid;
      prompt_id = _ret.prompt_id;
    }
    else
    {
      _ret = yield back_app.schar_continue(token, user.email, org?.id,
        _prompt, gid, char_id);
      prompt_id = _ret.prompt_id;
    }
    if (_ret.err)
    {
      set_error(_ret.err);
      messageApi.open({
        type: 'error',
        content: t('Error sending the chat message. Please try again'),
      });
      set_loading(false);
      return;
    }
    let {txt} = _ret;
    let msg = {is_char: true, name: char.lbl, txt, img:
      `${char_img}/${char.img}`, gid, prompt_id};
    set_chat(curr=>[...curr, msg]);
    set_loading(false);
    setTimeout(()=>{
      if (size(chat)>=8)
      {
        let scroll = ()=>chat_el_ref.current?.scrollIntoView({
          behavior: 'smooth', block: 'end'});
        scroll();
        setTimeout(scroll, 50);
      }
    }, 0);
  });
  let on_create_vid = msg=>eserf(function* _on_create_vid(){
    if (!char.is_video)
    {
      messageApi.open({
        type: 'error',
        content: t('This character cannot create videos for now'),
      });
      return;
    }
    set_vid_gen_id(msg.prompt_id);
    let _ret = yield back_app.schar_video(token, user.email, org?.id,
      msg.prompt_id, char_id, msg.txt, msg.gid);
    if (_ret.err)
    {
      set_error(_ret.err);
      set_vid_gen_id(null);
      messageApi.open({
        type: 'error',
        content: t('Error generating the video'),
      });
      return;
    }
    set_chat(_chat=>map(_msg=>{
      if (_msg === msg)
        return {..._msg, url: _ret.url};
      return _msg;
    }, _chat));
    set_vid_gen_id(null);
    set_vid_url_modal(_ret.url);
  });
  let on_copy_url = _url=>{
    navigator.clipboard.writeText(url2share_url(_url));
    messageApi.open({
      type: 'success',
      content: t('Link copied to clipboard'),
    });
  };
  let on_download = _url=>{
    set_vid_url_modal(_url);
  };
  let on_share = (_char, _url)=>{
    let data={
      title: 'ABCAIShow.com video',
      text: `A video message from ${char.lbl}`,
      url: url2share_url(_url),
    };
    window.navigator.share && window.navigator.share(data)
      .catch(console.error);
  };
  if (!token || !has_data || !user_full)
    return <Loading/>;

  if (!char_id)
    return <Select_char on_select={_char_id=>set_char_id(_char_id)}/>;

  return (
    <>{contextHolder}
      <Row style={{position: 'realtive'}} className="schar-container">
        {!vid_url_modal && <img className="schar-bg"
          src={`${char_img}/${char.img}`} />}
        <Col ref={chat_el_ref} span={22} offset={1} style={{minHeight: '90vh',
          paddingBottom: '10vh'}}>
          {chat.map((msg, index)=>{
            let is_last = index === size(chat) - 1;
            return <React.Fragment key={index/* todo: fix */}>
              <Row data-aos={is_last && msg.is_char ? 'fade-up':''}
                data-aos-once="true"
                style={{marginTop: 24}}>
                <Col xs={{span: 4}} md={{span: 2}} lg={{span: 1}}>
                  <img src={msg.img}
                    style={{width: 50, borderRadius: 8, marginBottom: 8,
                      objectFit: 'cover', height: 50}} />
                </Col>
                <Col offset={1} span={16}>
                  <Text>{msg.txt}</Text>
                </Col>
                {msg.is_char && index > 0 && !msg.url && <Col><Button
                  icon={<VideoCameraOutlined/>} size="large"
                  onClick={()=>on_create_vid(msg)}
                  loading={vid_gen_id === msg.prompt_id}
                  disabled={!!vid_gen_id}
                />
                </Col>}
                {msg.url && <Col><Download_vid url={msg.url}
                  on_click={on_download}/></Col>}
              </Row>
            </React.Fragment>;})}
          {size(chat) <= 1 && isEmpty(val) && <Examples char={char}
            on_select={_val=>on_submit(_val)}/>}
          <div style={{position: 'fixed', bottom: 0, left: 0, right: 0,
            zIndex: 99999999}}>
            <Input_box on_submit={on_submit} loading={loading} val={val}
              set_val={set_val} disabled={loading || !!vid_gen_id}/>
          </div>
        </Col>
      </Row>
      <Modal open={vid_url_modal} footer={null} destroyOnClose={true}
        onCancel={()=>set_vid_url_modal(null)}>
        <Row>
          <Col xs={{offset: 0, span: 24}} md={{offset: 0, span: 24}}>
            <Video url={vid_url_modal} />
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col xs={{span: 24}}>
            <Link href={vid_url_modal} download target="_blank">
              <Button type="primary" block icon={<DownloadOutlined/>}>
                {t('Download')}
              </Button>
            </Link>
          </Col>
          <Col xs={{span: 24}}>
            <Button type="primary" block
              onClick={()=>on_share(char, vid_url_modal)}
              icon={<ShareAltOutlined/>}>
              {t('Share')}
            </Button>
          </Col>
          <Col xs={{span: 24}}>
            <Button type="primary" onClick={()=>on_copy_url(vid_url_modal)}
              icon={<CopyOutlined/>} block>
              <Text size="small">
                {t('Copy link')}
              </Text>
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};


let Download_vid = ({url, on_click})=>{
  let {t} = useTranslation();
  let [is_tooltip, set_is_tooltip]=useState(false);
  useEffect(()=>{
    if (!localStorage.getItem('did_download_vid'))
      set_is_tooltip(true);
  }, []);

  let _on_click=()=>{
    localStorage.setItem('did_download_vid', 'yes');
    set_is_tooltip(false);
    on_click(url);
  };
  return <Tooltip open={is_tooltip} title={t('Download video')}
    trigger={['click', 'hover', 'focus']} placement="bottomRight">
    <Button icon={<DownloadOutlined/>} size="large" type="primary"
      onClick={_on_click}/>
  </Tooltip>;
};

let Input_box=({on_submit, loading, val, set_val, disabled})=>{
  let {t} = useTranslation();
  let ref = useRef(null);
  let _on_submit=()=>{
    if (loading || disabled)
      return;
    let _val = trim(val);
    set_val('');
    if (isEmpty(_val))
      return;
    on_submit(_val);
  };
  useEffect(()=>{
    false && ref.current?.focus();
  }, []);
  return <Space.Compact style={{width: '100%', height: 50}}>
    <Input value={val} onChange={e=>set_val(e.target.value)}
      autoComplete="off" placeholder={t('Say something')} ref={ref}
      onPressEnter={_on_submit} />
    <Button type="primary" onClick={_on_submit} icon={<SendOutlined/>}
      loading={loading} style={{height: '100%', minWidth: 60}} />
  </Space.Compact>;
};

let Examples=({char, on_select})=>{
  let {t} = useTranslation();
  let examples = useMemo(()=>flow(
    get('example_questions'),
    sampleSize(3),
  )(char) || [], [char]);

  if (isEmpty(examples))
    return;

  return <div data-aos="fade-up" data-aos-once="true" data-aos-delay="800">
    <Row style={{marginTop: 24}}>
      <Title level={4}>{t('Examples')}</Title>
    </Row>
    <Row>
      <Space direction="vertical">
        {map(example=><Button key={example} type="primary" level={4}
          onClick={()=>on_select(example)} style={{whiteSpace: 'normal',
            height: 'auto'}}>
          {example}
        </Button>
        , examples)}
      </Space>
    </Row>
  </div>;
};


let Select_char = ({on_select})=>{
  let {t} = useTranslation();
  let [chars, set_chars] = useState([]);
  let [search, set_search] = useState('');
  let [error, set_error]= useState(null);
  let {token} = auth.use_auth();
  let _chars = useMemo(()=>{
    if (!search)
      return chars;
    let _search = toLower(search);
    return filter(char=>includes(_search, toLower(char.lbl)), chars);
  }, [search, chars]);

  useEffect(()=>{
    let es = eserf(function* _get_chars_use_effect(){
      let _ret = yield back_app.produce_char_get();
      if (_ret.err)
      {
        set_error(_ret.err);
        return;
      }
      set_chars(_ret.chars);
    });
    return ()=>es.return();
  }, []);

  if (!token || isEmpty(chars))
    return <Loading/>;

  return <>
    <Row>
      <TextArea rows={1} value={search}
        onChange={e=>set_search(e.target.value)} autoComplete="off"
        placeholder={t('Select or search a character')} bordered={false}
        style={{lineHeight: 2.5, marginBottom: 16}}/>
    </Row>
    <Chars chars={_chars} on_select={on_select}/>
  </>;
};

export default auth.with_auth_req(E);
