// LICENSE_CODE ABCAI
import React, {useState, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Badge, Grid, Row, Col, Typography, Modal, Button} from 'antd';
import {useNavigate} from 'react-router-dom';
import {Clickable, Video, char_img, spipe_examples,
  Task_tooltip} from './comp.js';
import auth from './auth.js';
import eserf from '../../../util/eserf.js';
import back_app from './back_app.js';
import {isEmpty, filter, get, flow, shuffle, take} from 'lodash/fp.js';
import {DownOutlined} from '@ant-design/icons';
import config_ext from './config_ext.js';

let {Title} = Typography;
let {useBreakpoint} = Grid;
const Land_simg = ()=>{
  let {t} = useTranslation();
  let nav = useNavigate();
  let {signup, is_auth} = auth.use_auth_ext();
  let [show_login_modal, set_show_login_modal] = useState(false);

  let [error, set_error] = useState(null);
  let [chars, set_chars] = useState([]);
  let [imgs, set_imgs] = useState([]);
  let [persons, set_persons] = useState([]);
  let [gifs, set_gifs] = useState([]);
  let [voices, set_voices] = useState([]);
  let [show_all_chars, set_show_all_chars]=useState(false);
  let visible_chars = useMemo(()=>{
    return show_all_chars ? chars: chars.slice(0, 12);
  }, [chars, show_all_chars]);
  // XXX refacor all spub gets
  useEffect(()=>{
    let es = eserf(function* _get_chars_use_effect(){
      let _ret = yield back_app.produce_char_get();
      if (_ret.err)
      {
        set_error(_ret.err);
        return;
      }

      let _chars = _ret.chars.sort((a, b)=>{
        // XXX fix sort by 'order' key
        let a_order = a.order || 0;
        let b_order = b.order || 10;
        return a_order - b_order;
      });
      set_chars(_chars);
    });
    return ()=>es.return();
  }, []);

  useEffect(()=>{
    let es = eserf(function* _tasks_get_imgs(){
      let _ret = yield back_app.task_pub_get_list('simg', 6);
      if (_ret.err)
      {
        set_error(_ret.err);
        return;
      }
      set_imgs(_ret);
    });
    return ()=>es.return();
  }, []);
  useEffect(()=>{
    let es = eserf(function* _tasks_get_persons(){
      let _ret = yield back_app.task_pub_get_list('sperson', 3);
      if (_ret.err)
      {
        set_error(_ret.err);
        return;
      }
      set_persons(_ret);
    });
    return ()=>es.return();
  }, []);
  useEffect(()=>{
    let es = eserf(function* _tasks_get_voices(){
      let _ret = yield back_app.task_pub_get_list('svoice', 4);
      if (_ret.err)
      {
        set_error(_ret.err);
        return;
      }
      set_voices(_ret);
    });
    return ()=>es.return();
  }, []);
  useEffect(()=>{
    let es = eserf(function* _tasks_get_gifs(){
      let _ret = yield back_app.task_pub_get_list('sgif', 6);
      if (_ret.err)
      {
        set_error(_ret.err);
        return;
      }
      set_gifs(_ret);
    });
    return ()=>es.return();
  }, []);

  let screens = useBreakpoint();
  let num_media = useMemo(()=>{
    if (get('md', screens))
      return 6;
    return 3;
  }, [screens]);
  // XXX: refactor this
  let _imgs = useMemo(()=>flow(
    filter(_task=>get('type', _task) === 'simg'),
    take(num_media),
    shuffle,
  )(imgs), [imgs, num_media]);
  let _gifs = useMemo(()=>flow(
    filter(_task=>get('type', _task) === 'sgif'),
    take(num_media),
    shuffle,
  )(gifs), [gifs, num_media]);
  let _persons = useMemo(()=>flow(
    filter(_task=>get('type', _task) === 'sperson'),
    take(3),
    shuffle,
  )(persons), [persons]);
  let _voices = useMemo(()=>flow(
    filter(_task=>get('type', _task) === 'svoice'),
    take(3),
    shuffle,
  )(voices), [voices]);
  let on_feature_click=page=>{
    if (!is_auth)
      set_show_login_modal(page);
    else
      nav('/'+page);
  };

  let sizes = {
    xs: {span: 22, offset: 1},
    md: {span: 22, offset: 1},
  };
  let img_col = {
    xs: {span: 24},
    sm: {span: 18},
    md: {span: 16},
    lg: {span: 12}
  };

  return <>
    <Row style={{background: '#20262E'}}><Col>
      <Row style={{marginTop: 32}}>
        <Col {...sizes}>
          <Title level={1}>
            {t('We create AI tools for content creators')}
          </Title>
        </Col>
      </Row>
      <Row style={{paddingTop: 64}}>
        <Col {...sizes}>
          <Row>
            <Header number={1} title={t('Create images from your prompts')}
              on_click={()=>on_feature_click('simg')}
            />
          </Row>
          <Row>
            <Col {...img_col}>
              <Row gutter={[12, 12]}>
                {_imgs.map(_img=>{
                  return <Col xs={{span: 24}}
                    md={{span: 8}} key={get('_id', _img)}>
                    <Clickable cursor onClick={()=>on_feature_click('simg')}>
                      <Task_tooltip task={_img}>
                        <img src={get('url', _img)}
                          style={{width: '100%', height: 'auto',
                            borderRadius: 16}}/></Task_tooltip>
                    </Clickable></Col>;
                })}
              </Row><Row style={{marginTop: 16}}>
                <Button type="primary" size="large" block
                  onClick={()=>on_feature_click('simg')}
                >{t('Create image')}</Button>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        style={{paddingTop: 128,
          background: 'linear-gradient(0turn, #913175, 80%, #20262E)'}}>
        <Col {...sizes}>
          <Row>
            <Header number={2} title={t('Create GIFs from your prompts')}
              on_click={()=>on_feature_click('sgif')}
            />
          </Row>
          <Row>
            <Col {...img_col}>
              <Row gutter={[12, 12]}>
                {_gifs.map(_gif=><Col
                  xs={{span: 24}} md={{span: 8}} key={_gif.url}>
                  <Clickable cursor onClick={()=>on_feature_click('sgif')}>
                    <Task_tooltip task={_gif}>
                      <img src={_gif.url} style={{width: '100%',
                        borderRadius: 8, height: 'auto'}}
                      /></Task_tooltip></Clickable>
                </Col>)}
              </Row>
              <Row style={{marginTop: 16}}>
                <Button type="primary" size="large"
                  onClick={()=>on_feature_click('sgif')} block
                >{t('Create GIF')}</Button>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{paddingTop: 128,
        background: 'linear-gradient(0turn, #CD5888, 80%, #913175)'}}>
        <Col {...sizes}>
          <Row>
            <Header number={3} title={t('Create fake person')}
              on_click={()=>on_feature_click('sperson')}
            />
          </Row>
          <Row>
            <Col {...img_col}>
              <Row gutter={[12, 12]}>
                {_persons.map(_person=><Col xs={{span: 24}}
                  md={{span: 8}} key={_person.url}>
                  <Clickable cursor onClick={()=>on_feature_click('sperson')}>
                    <img src={_person.url}
                      style={{width: '100%', height: 'auto',
                        borderRadius: 16}}/></Clickable></Col>)}
              </Row><Row style={{marginTop: 16}}>
                <Button type="primary" size="large" block
                  onClick={()=>on_feature_click('sperson')}
                >{t('Create fake person')}</Button>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      {!isEmpty(chars) && <Row
        style={{paddingTop: 128,
          background: 'linear-gradient(0turn, #913175, 80%, #CD5888)'}}>
        <Col {...sizes}>
          <Row>
            <Header number={4} title={t('Chat with your favorite characters')}
              on_click={()=>on_feature_click('schar')}/>
          </Row>
          <Row>
            <Col {...img_col}>
              <Row gutter={[12, 12]} style={{position: 'relative'}}>
                {visible_chars.map(_char=>{
                  let _src = `${char_img}/${_char.img}`;
                  return <Col
                    xs={{span: 24}} md={{span: 8}} lg={{span: 6}} key={_src}>
                    <Clickable cursor style={{height: '100%'}} onClick={()=>{
                      on_feature_click('schar?char_id='+_char.char_id);
                    }}><img src={_src} title={_char.lbl}
                        style={{width: '100%', height: '100%', borderRadius: 8,
                          objectFit: 'cover'}} />
                    </Clickable>
                  </Col>;})}
                {!show_all_chars && <div style={{position: 'absolute',
                  bottom: 0, left: '50%', transform: 'translate(-50%,-50%)'}}>
                  <Button icon={<DownOutlined/>} size="small"
                    onClick={e=>{
                      e.stopPropagation();
                      set_show_all_chars(true);
                    }}>
                    {t('Show more')}</Button>
                </div>}
              </Row>
              <Row style={{marginTop: 16}}>
                <Button type="primary" size="large"
                  onClick={()=>on_feature_click('schar')} block
                >{t('Chat with over 60 characters')}</Button>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>}
      <Row
        style={{paddingTop: 128,
          background: 'linear-gradient(0turn, #20262E, 80%, #913175)'}}>
        <Col {...sizes}>
          <Row>
            <Badge.Ribbon text={t('New')} color="red" placement="start"
              style={{marginTop: -30}}>
              <Header number={5}
                title={t('Create speech from text')}
                on_click={()=>on_feature_click('svoice')}
              />
            </Badge.Ribbon>
          </Row>
          <Row>
            <Col {...img_col}>
              {_voices.map(_voice=><Row key={_voice.url}
                style={{marginTop: 16}}>
                <audio controls src={_voice.url}
                  style={{width: '100%'}} />
              </Row>)}
              <Row style={{marginTop: 16}}>
                <Button type="primary" size="large"
                  onClick={()=>on_feature_click('svoice')} block
                >{t('Create speech')}</Button>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        style={{paddingTop: 128,
          background: 'linear-gradient(0turn, #913175, 80%, #20262E)'}}>
        <Col {...sizes}>
          <Row>
            <Header number={6}
              title={t('Create an entire video from a single prompt')}
              on_click={()=>on_feature_click('spipe')}
            />
          </Row>
          <Row>
            <Col {...img_col}>
              <Row gutter={[12, 12]}>
                {spipe_examples.slice(0, 3).map(example=><Col
                  xs={{span: 24}} md={{span: 8}} key={example}>
                  <Clickable cursor>
                    <Video auto_play={false} src={example}/>
                  </Clickable>
                </Col>)}
              </Row>
              <Row style={{marginTop: 16}}>
                <Button type="primary" size="large"
                  onClick={()=>on_feature_click('spipe')} block
                >{t('Create video')}</Button>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        style={{paddingBlock: 128,
          background: 'linear-gradient(0turn, #20262E, 80%, #913175)'}}>
        <Col {...sizes}>
          <Row>
            <Header number={7}
              title={t('Twitch stream')}
              on_click={()=>on_feature_click('spipe')}
            />
          </Row>
          <Row>
            <Col {...img_col}>
              <Row gutter={[12, 12]}>
                <iframe
                  src={`https://player.twitch.tv/?channel=chat_with_ai&parent=
${config_ext.domain}`}
                  height="500"
                  width="100%">
                </iframe>
                <Button type="primary" block
                  onClick={()=>window.open('https://twitch.com/chat_with_ai',
                    '_blank')}>
                  {t('Join the stream and chat with AI Characters')}
                </Button>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
    </Row>
    <Modal
      title={t('You need to signup for free in order to use our tools')}
      open={!!show_login_modal}
      okText={t('Signup FREE')} cancelText={t('Cancel')}
      onOk={()=>{
        signup('/'+show_login_modal);
      }}
      onCancel={()=>set_show_login_modal(null)}>
    </Modal>
  </>;
};

let Header = React.memo(({title, number, on_click})=>{
  return <Row><Clickable cursor onClick={on_click}>
    <Title level={3}><span style={{fontSize: '150%'}}
    >{number}</span>. {title}</Title></Clickable>
  </Row>;
});


export default Land_simg;

