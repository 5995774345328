// LICENSE_CODE MIT
import React, {useEffect, useState, useCallback} from 'react';
import {useLocation} from 'react-router-dom';
import {LoadingOutlined, CloseCircleOutlined} from '@ant-design/icons';
import {Spin, Button, Row, Col, Typography, Tooltip} from 'antd';
import {map, size, endsWith, toLower, isNumber, get} from 'lodash/fp.js';
import xurl from '../../../util/xurl.js';
import xdate from '../../../util/date.js';
import config_ext from './config_ext.js';

let E = {};
let {Text} = Typography;
const ant_icon = <LoadingOutlined style={{fontSize: '25vh'}} spin />;

export const Clickable = React.memo(({children, style, cursor, ...rest})=>{
  return <div className={`grow ${cursor ? 'cursor' : ''}`}
    style={style} {...rest}>{children}</div>;
});

export const Bounce = React.memo(({children, style, is_bounce})=>{
  let classname = is_bounce?
    'animate__animated animate__bounce animate__repeat-2':'';
  return <div className={classname} style={style}>{children}</div>;
});

export const Closable = React.memo(({children, style={}, on_close, tooltip})=>{
  let [is_visible, set_is_visible]=useState(true);
  let _on_close = useCallback(()=>{
    if (on_close)
    {
      on_close();
      return;
    }
    set_is_visible(false);
  }, [on_close]);

  if (!is_visible)
    return null;
  return <div style={{...style, position: 'relative'}}>
    {children}
    <Button shape="circle" title={tooltip} icon={<CloseCircleOutlined/>}
      onClick={_on_close} style={{position: 'absolute', top: 0, right: 0,
        transform: 'translate(50%, -50%)'}} />
  </div>;
});

// XXX colin: support different background colors
let is_dark = !config_ext.is_b2b;
export const Loading = ()=><>
  <Spin style={{textAlign: 'center', paddingTop: '25vh',
    position: 'absolute', zIndex: '99',
    top: '0', left: '0', width: '100vw',
    height: '100vh', background: is_dark ? '#000000' : '#ffffff'}}
  indicator={ant_icon} />
</>;


export const Scroll_to_top = ()=>{
  let {pathname} = useLocation();
  useEffect(()=>{
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

export const Gctx = React.createContext({});


export let spipe_examples = [
  's3_bill.mp4',
  's3_bezos.mp4',
  's3_gordon.mp4',
  's3_gordon_2.mp4',
  's3_gus.mp4',
  's3_kim.mp4',
  's3_rogan.mp4',
  's3_tate.mp4',
  'spipe_mat.mp4',
].map(file=>'https://abcai-front-share-prod.s3.amazonaws.com/upload/'+
  `${file}#t=0.1`);

export let VOD = ({limit})=>{
  let _files = isNumber(limit) ? spipe_examples.slice(0, limit):
    spipe_examples;
  return <Row gutter={[16, 16]}>
    {_files.map(file=><Col xs={{span: 24, offset: 0}}
      md={{span: 12, offset: 0}}
      lg={{span: 8, offset: 0}} xl={{span: 6, offset: 0}} key={file}>
      <Clickable><Video url={file} auto_play={false}/></Clickable>
    </Col>)}
  </Row>;
};

export let Video = React.memo(({url, auto_play=true, is_circle, style={},
  on_end=()=>{}, ...rest})=>{
  let is_gif = endsWith('gif', toLower(url));
  let _style = {width: '100%', height: 'auto',
    borderRadius: is_circle ? '100%':'auto', ...style};
  if (is_gif)
    return <img src={url} style={_style}/>;
  return <video src={url} autoPlay={auto_play} controls style={_style}
    onEnded={on_end} {...rest}/>;
});

//todo: delete
export let char_img = 'https://abcai-front-share-prod.s3.amazonaws.com/img';
export let Chars = React.memo(({chars, on_select})=>{
  return <Row gutter={[16, 16]}>
    {map(_char=>{
      let ellipsis = size(_char.lbl) >= 18;
      return <Col xs={{offset: 1, span: 7}} md={{ofset: 1, span: 3}}
        key={_char.lbl}>
        <Clickable cursor onClick={()=>on_select(_char.char_id)}
          style={{cursor: 'pointer'}}>
          <img src={`${char_img}/${_char.img}`}
            style={{width: 90, height: 90, borderRadius: 8, marginBottom: 8,
              objectFit: 'cover', display: 'block'}} />
          <Text ellipsis={ellipsis}>{_char.lbl}</Text>
        </Clickable>
      </Col>;}, chars)}
  </Row>;
});


export let use_count_views = E.use_count_views = E.useCountViews = ()=>{
  let [count, set_count] = useState(0);
  useEffect(()=>{
    let _count = localStorage.getItem('view_count');
    _count = _count ? JSON.parse(_count): 0;
    _count++;
    set_count(_count);
    localStorage.setItem('view_count', JSON.stringify(_count));
  }, []);

  return count;
};

export let use_focus = E.use_focus = E.useFocus = ()=>{
  let [is_focus, set_is_focus] = useState(true);
  useEffect(()=>{
    let focus_cb = ()=>set_is_focus(true);
    let blur_cb = ()=>set_is_focus(false);
    window.addEventListener('focus', focus_cb);
    window.addEventListener('blur', blur_cb);
    return ()=>{
      window.removeEventListener('focus', focus_cb);
      window.removeEventListener('blur', blur_cb);
    };
  }, []);
  return {is_focus};
};

export let use_qs = E.use_qs = E.useQs = ()=>{
  let location = useLocation();
  let [qs_o, qs_o_set] = useState(xurl.qs_parse(location.search, true));
  useEffect(()=>{
    let _qs_o = xurl.qs_parse(location.search, true);
    qs_o_set(_qs_o);
  }, [location]);
  return {qs_o, search: location.search, pathname: location.pathname};
};

export let Task_tooltip = React.memo(({task, children})=>{
  let now = new Date();
  let _date = get(['ts', 'insert'], task);
  let _txt = get('txt', task);

  if (!_date || !_txt)
    return <>{children}</>;

  let diff_min = Math.ceil(xdate.diffdates(new Date(_date),
    now, 'minutes'));
  let date_str = `Created ${diff_min} minutes ago`;
  let tooltip = `Prompt: ${_txt}`;
  return <Tooltip title={<><div>{tooltip}</div><div
    style={{marginTop: 16, fontSize: '80%'}}>{date_str}
  </div></>}>{children}</Tooltip>;
});

export default E;
