// LICENSE_CODE ISC
/* eslint-disable nonblock-statement-body-position */
import Events from 'events';
import assert from 'assert';
import process from 'process';

let _process = process;
let xlog = function(...args){ console.log(...args); };
xlog.debug = function(){};
xlog.notice = (...args)=>console.log(...args);
xlog.exit = function(msg){
  let is_node = _process.title!='browser';
  if (is_node)
    console.error(msg, E.ps());
  else
  {
    msg = msg.replace(/^\s+at .*eserf.*\n?/uigm, '');
    console.error('exit', msg);
    console.trace('async backtrace:');
  }
  if (on_exit_cb)
    return void on_exit_cb('xexit', msg, E.ps());
  _process.exit(1);
};
xlog.is = function(){ return false; };
xlog.L = {DEBUG: 0};
xlog.on_crit = (err, es)=>{
  let msg, stack = es instanceof Eserf ? '\neserf:\n'+es.stack() : '';
  if (err instanceof Error)
    msg = err.stack;
  else if (typeof err=='string')
    msg = err;
  else
    msg = JSON.stringify(err);
  xlog.exit(msg+stack);
};
if (!_process) // XXX: remove already created in polyfill
{
  _process = {
    nextTick: function(fn){ setTimeout(fn, 0); },
    env: {},
  };
}
if (!_process.exit)
  _process.exit = ()=>{};
const rm_elm_tail = function(a, elm){
  let i = a.length-1;
  if (elm===a[i]) // fast-path
  {
    a.pop();
    return elm;
  }
  if ((i = a.lastIndexOf(elm, i-1))<0)
    return;
  a.splice(i, 1);
  return elm;
};

const clamp = function(lower_bound, value, upper_bound){
  if (value < lower_bound)
    return lower_bound;
  if (value < upper_bound)
    return value;
  return upper_bound;
};

const Eserf = function(opt, states){
  if (!(this instanceof Eserf))
    return new Eserf(opt, states);
  opt = opt||{};
  if (Array.isArray(opt) || typeof opt=='function')
  {
    states = opt;
    opt = {};
  }
  else if (typeof opt=='string')
    opt = {name: opt};
  if (typeof states=='function')
  {
    if (states.constructor.name=='GeneratorFunction')
      return E.gen_init(null, states, opt);
    states = [states];
  }
  // performance: set all fields to undefined
  this.cur_state = this.states = this._finally = this.error =
    this.at_return = this.next_state = this.use_retval = this.running =
    this.at_continue = this.cancel = this.wait_timer = this.retval =
    this.run_state = this._stack = this.down = this.up = this.child =
    this.name = this._name = this.parent = this.cancelable =
    this.tm_create = this._alarm = this.tm_completed = this.parent_type =
    this.info = this.then_waiting = this.free = this.parent_guess =
    this.child_guess = this.wait_retval = undefined;
  // init fields
  this.name = opt.name;
  this._name = this.name===undefined ? 'noname' : this.name;
  this.cancelable = opt.cancel;
  this.then_waiting = [];
  this.child = [];
  this.child_guess = [];
  this.cur_state = -1;
  this.states = [];
  this._stack = use_bt ? stack_get() : undefined;
  this.tm_create = Date.now();
  this.info = {};
  let idx = this.states.idx = {};
  for (let i=0; i<states.length; i++)
  {
    let pstate = states[i], t, pstate_func, pstate_name;
    if (pstate instanceof Array)
    {
      if (typeof pstate[0]!='string' || typeof pstate[1]!='function')
        assert(0, 'invalid state no name or function');
      pstate_name = pstate[0];
      pstate_func = pstate[1];
    }
    else if (typeof pstate=='function')
    {
      pstate_func = pstate;
      pstate_name = 'anon';
    }
    else
      assert(0, 'invalid state not function');
    t = this._get_func_type(pstate_func, pstate_name);
    let state = {f: pstate_func, label: t.label, try_catch: t.try_catch,
      catch: t.catch, finally: t.finally, cancel: t.cancel,
      sig: undefined};
    if (i==0 && opt.state0_args)
      state.f = state.f.bind(this, ...opt.state0_args);
    if (state.label)
      idx[state.label] = i;
    assert((state.catch||state.try_catch?1:0)
      +(state.finally?1:0)+(state.cancel?1:0)<=1,
    'invalid multiple state types');
    state.sig = state.finally||state.cancel;
    if (state.finally)
    {
      assert(this._finally===undefined, 'invalid >1 finally');
      this._finally = i;
    }
    if (state.cancel)
    {
      assert(this.cancel===undefined, 'invalid >1 cancel');
      this.cancel = i;
    }
    this.states[i] = state;
  }
  es_root.push(this);
  let in_run = E.in_run_top();
  if (opt.spawn_parent)
    this.spawn_parent(opt.spawn_parent);
  else if (opt.up)
    opt.up._set_down(this);
  else if (in_run)
    this._spawn_parent_guess(in_run);
  if (opt.init)
    opt.init.call(this);
  if (opt.async)
  {
    let wait_retval = this._set_wait_retval();
    next_tick(()=>{
      if (this.running!==undefined)
        return;
      this._got_retval(wait_retval);
    });
  }
  else
    this.state_next();
  return this;
};

const inherits = function inherits(ctor, super_ctor){
  ctor.super_ = super_ctor;
  ctor.prototype = Object.create(super_ctor.prototype,
    {constructor: {value: ctor, enumerable: false, writable: true,
      configurable: true}});
};

inherits(Eserf, Events.EventEmitter);

let E = Eserf;
let eserf = Eserf;
let env = _process.env, assign = Object.assign;
let use_bt; // = +env.ESERF_BT;
let es_root = [];
const next_tick = _process.nextTick;
let events = new Events();
let cb_pre, cb_post, cb_ctx, longcb_ms, perf_enable;
let perf_stat = {};
const _cb_pre = function(es){ return {start: Date.now()}; };
const _cb_post = function(es, ctx){
  ctx = ctx||cb_ctx;
  let ms = Date.now()-ctx.start;
  if (longcb_ms && ms>longcb_ms)
  {
    let msg = 'long state '+ms+'ms: '+es.get_name()+', '
      +es.run_state.f.toString().slice(0, 128);
    xlog.notice(msg);
    let is_node = _process.title!='browser';
    if (on_long_cb)
      on_long_cb(msg, is_node ? E.ps() : '');
  }
  if (perf_enable)
  {
    let name = es.get_name();
    let perf = perf_stat[name] ||
      (perf_stat[name] = {ms: 0, n: 0, max: 0});
    if (perf.max<ms)
      perf.max = ms;
    perf.ms += ms;
    perf.n++;
  }
};
const cb_set = ()=>{
  if (longcb_ms || perf_enable)
  {
    cb_pre = _cb_pre;
    cb_post = _cb_post;
    cb_ctx = {start: Date.now()};
  }
  else
    cb_pre = cb_post = cb_ctx = undefined;
};
E.long_state = ms=>{
  longcb_ms = ms;
  cb_set();
};
//E.perf_set = enable=>{
//  perf_enable = enable;
//  cb_set();
//  return perf_enable;
//};

//E.perf_get = enable=>{
//  return perf_enable;
//};

E.long_state(+env.LONG_STATE);
//E.perf_set(+env.ESERF_PERF);

let on_exit_cb;
E.on_exit = cb=>{
  on_exit_cb = cb;
};
let on_long_cb;
E.on_long = cb=>{
  on_long_cb = cb;
};

const stack_get = function(){
  let prev = Error.stackTraceLimit, err;
  Error.stackTraceLimit = 4;
  err = new Error();
  Error.stackTraceLimit = prev;
  return err;
};

E.prototype._root_rm = function(){
  assert(!this.parent, 'invalid remove from root when has parent');
  if (!rm_elm_tail(es_root, this))
    assert(0, 'eserf not in root\n'+E.ps({MARK: this}));
};

E.prototype._parent_rm = function(){
  if (this.up)
  {
    let up = this.up;
    this.up = this.up.down = undefined;
    if (up.tm_completed)
      up._check_free();
    return;
  }
  if (this.parent_guess)
    this._parent_guess_remove();
  if (!this.parent)
    return this._root_rm();
  if (!rm_elm_tail(this.parent.child, this))
  {
    assert(0, 'child not in parent\n'
      +E.ps({MARK: [['child', this], ['parent', this.parent]]}));
  }
  if (this.parent.tm_completed)
    this.parent._check_free();
  this.parent = undefined;
};

E.prototype._check_free = function(){
  if (this.down || this.child.length)
    return;
  this._parent_rm();
  this.free = true;
};

E.prototype._call_err = function(e){
  E.ef(e);
  assert(0, 'eserf err in signal: '+e);
};
E.prototype.emit_safe = function(...args){
  try {
    this.emit(...args);
  } catch(e) {
    this._call_err(e);
  }
};
E.prototype._call_safe = function(state_fn){
  try {
    return state_fn.call(this);
  } catch(e) {
    this._call_err(e);
  }
};
E.prototype._complete = function(){
  xlog.debug(this._name+': close');
  this.tm_completed = Date.now();
  this.parent_type = this.up ? 'call' : 'spawn';
  if (this.error)
    this.emit_safe('uncaught', this.error);
  if (this._finally!==undefined)
  {
    let ret = this._call_safe(this.states[this._finally].f);
    if (E.is_err(ret))
      this._set_retval(ret);
  }
  this.emit_safe('finally');
  this.emit_safe('ensure');
  if (this.error && !this.up && !this.parent && !this.parent_guess)
    events.emit('uncaught', this);
  if (this.parent)
    this.parent.emit('child', this);
  if (this.up && (this.down || this.child.length))
  {
    let up = this.up;
    this.up = this.up.down = undefined;
    this.parent = up;
    up.child.push(this);
  }
  this._check_free();
  this._del_wait_timer();
  this.del_alarm();
  this._ecancel_child();
  this.emit_safe('finally1');
  while (this.then_waiting.length)
    this.then_waiting.shift()();
};
E.prototype.es_next = function(rv){
  if (this.tm_completed)
    return true;
  rv = rv||{ret: undefined, err: undefined};
  let states = this.states;
  let state = this.at_return ? states.length :
    this.next_state!==undefined ? this.next_state : this.cur_state+1;
  this.retval = rv.ret;
  this.error = rv.err;
  if (rv.err!==undefined)
  {
    if (this.run_state.try_catch)
    {
      this.use_retval = true;
      for (; state<states.length && states[state].sig; state++);
    }
    else
      for (; state<states.length && !states[state].catch; state++);
    if (state==states.length)
      E.ef(rv.err);
  }
  else
  {
    for (; state<states.length &&
      (states[state].sig || states[state].catch); state++);
  }
  this.cur_state = state;
  this.run_state = states[state];
  this.next_state = undefined;
  if (this.cur_state<states.length)
    return false;
  this._complete();
  return true;
};

E.prototype.state_next = function(rv){
  if (this.es_next(rv))
    return;
  this.state_now();
};
E.prototype._handle_rv = function(rv){
  let wait_retval, _this = this, ret = rv.ret;
  if (ret===this.retval); // fast-path: retval already set
  else if (!ret);
  else if (ret instanceof Eserf)
  {
    if (!ret.tm_completed)
    {
      this._set_down(ret);
      wait_retval = this._set_wait_retval();
      ret.then_waiting.push(function(){
        _this._got_retval(wait_retval, E.err_res(ret.error,
          ret.retval));
      });
      return true;
    }
    rv.err = ret.error;
    rv.ret = ret.retval;
  }
  else if (ret instanceof Eserf_err)
  {
    rv.err = ret.error;
    rv.ret = undefined;
  }
  else if (typeof ret.then=='function') // promise
  {
    wait_retval = this._set_wait_retval();
    ret.then(function(_ret){ _this._got_retval(wait_retval, _ret); },
      function(err){ _this._got_retval(wait_retval, E.err(err)); });
    return true;
  }
  // generator
  else if (typeof ret.next=='function' && typeof ret.throw=='function')
  {
    rv.ret = E.gen_init(ret, this.states[this.cur_state]);
    return this._handle_rv(rv);
  }
  return false;
};
E.prototype._set_retval = function(ret){
  if (ret===this.retval && !this.error); // fast-path retval already set
  else if (!ret)
  {
    this.retval = ret;
    this.error = undefined;
  }
  else if (ret instanceof Eserf)
  {
    if (ret.tm_completed)
    {
      this.retval = ret.retval;
      this.error = ret.error;
    }
  }
  else if (ret instanceof Eserf_err)
  {
    this.retval = undefined;
    this.error = ret.error;
  }
  else if (typeof ret.then=='function'); // promise
  // generator
  else if (typeof ret.next=='function' && typeof ret.throw=='function');
  else
  {
    this.retval = ret;
    this.error = undefined;
  }
  return ret;
};

E.prototype._set_wait_retval = function(){
  return this.wait_retval = new Eserf_wait(this, 'wait_int');
};
E.in_run = [];
E.in_run_top = function(){ return E.in_run[E.in_run.length-1]; };
E.prototype.state_now = function(){
  let rv = {ret: undefined, err: undefined};
  // eslint-disable-next-line no-constant-condition
  while (1)
  {
    let _cb_ctx;
    let arg = this.error && !this.use_retval ? this.error : this.retval;
    this.use_retval = false;
    this.running = true;
    rv.ret = rv.err = undefined;
    E.in_run.push(this);
    xlog.debug(this._name+':S'+this.cur_state+': running');
    if (cb_pre)
      _cb_ctx = cb_pre(this);
    try {
      rv.ret = this.run_state.f.call(this, arg);
    } catch(e) {
      rv.err = e;
      if (rv.err instanceof Error)
        rv.err.eserf = this;
    }
    if (cb_post)
      cb_post(this, _cb_ctx);
    this.running = false;
    E.in_run.pop();
    for (; this.child_guess.length;
      this.child_guess.pop().parent_guess = undefined);
    if (rv.ret instanceof Eserf_wait)
    {
      let wait_completed = false, wait = rv.ret;
      if (!this.at_continue && !wait.ready)
      {
        this.wait_retval = wait;
        if (wait.op=='wait_child')
          wait_completed = this._set_wait_child(wait);
        if (wait.timeout)
          this._set_wait_timer(wait.timeout);
        if (!wait_completed)
          return;
        this.wait_retval = undefined;
      }
      rv.ret = this.at_continue ? this.at_continue.ret :
        wait.ready && !wait.completed ? wait.ready.ret : undefined;
      wait.completed = true;
    }
    this.at_continue = undefined;
    if (this._handle_rv(rv))
      return;
    if (this.es_next(rv))
      return;
  }
};

E.prototype._set_down = function(down){
  if (this.down)
    assert(0, 'caller already has a down\n'+this.ps());
  if (down.parent_guess)
    down._parent_guess_remove();
  assert(!down.parent, 'returned eserf already has a spawn parent');
  assert(!down.up, 'returned eserf already has a caller parent');
  down._parent_rm();
  this.down = down;
  down.up = this;
};

let func_type_cache = {};
E.prototype._get_func_type = function(func, name, on_fail){
  let type = func_type_cache[name];
  if (type)
    return type;
  type = func_type_cache[name] = {name: undefined, label: undefined,
    try_catch: undefined, catch: undefined, finally: undefined,
    cancel: undefined};
  if (!name)
    return type;
  type.name = name;
  let n = name.split('$');
  if (n.length==1)
  {
    type.label = n[0];
    return type;
  }
  if (n.length>2)
    return type;
  if (n[1].length)
    type.label = n[1];
  let f = n[0].split('_');
  for (let j=0; j<f.length; j++)
  {
    if (f[j]=='try')
    {
      type.try_catch = true;
      if (f[j+1]=='catch')
        j++;
    }
    else if (f[j]=='catch')
      type.catch = true;
    else if (f[j]=='finally' || f[j]=='ensure')
      type.finally = true;
    else if (f[j]=='cancel')
      type.cancel = true;
    else
    {
      return void (on_fail||assert.bind(null, false))(
        'unknown func name '+name);
    }
  }
  return type;
};

E.prototype.spawn = function(child, replace){
  if (!(child instanceof Eserf) && child && typeof child.then=='function')
  {
    let promise = child;
    child = eserf([function(){ return promise; }]);
  }
  if (!(child instanceof Eserf)) // promise already completed?
  {
    this.emit('child', child);
    return child;
  }
  if (!replace && child.parent)
    assert(0, 'child already has a parent\n'+child.parent.ps());
  child.spawn_parent(this);
  return child;
};

E.prototype._spawn_parent_guess = function(parent){
  this.parent_guess = parent;
  parent.child_guess.push(this);
};
E.prototype._parent_guess_remove = function(){
  if (!rm_elm_tail(this.parent_guess.child_guess, this))
    assert(0, 'eserf not in parent_guess\n'+E.ps({MARK: this}));
  this.parent_guess = undefined;
};
E.prototype.spawn_parent = function(parent){
  if (this.up)
    assert(0, 'child already has an up\n'+this.up.ps());
  if (this.tm_completed && !this.parent)
    return;
  this._parent_rm();
  if (parent && parent.free)
    parent = undefined;
  if (!parent)
    return void es_root.push(this);
  parent.child.push(this);
  this.parent = parent;
};

E.prototype.set_state = function(name){
  let state = this.states.idx[name];
  assert(state!==undefined, 'named func "'+name+'" not found');
  return this.next_state = state;
};

E.prototype.finally = function(cb){
  this.prependListener('finally', cb);
};

E.prototype.goto_fn = function(name){
  return this.goto.bind(this, name);
};

E.prototype.goto = function(name, promise){
  this.set_state(name);
  let state = this.states[this.next_state];
  assert(!state.sig, 'goto to sig');
  return this.continue(promise);
};

E.prototype.loop = function(promise){
  this.next_state = this.cur_state;
  return promise;
};

E.prototype._set_wait_timer = function(timeout){
  let _this = this;
  this.wait_timer = setTimeout(function(){
    _this.wait_timer = undefined;
    _this.state_next({ret: undefined, err: 'timeout'});
  }, timeout);
};
E.prototype._del_wait_timer = function(){
  if (this.wait_timer)
    this.wait_timer = clearTimeout(this.wait_timer);
  this.wait_retval = undefined;
};
E.prototype._get_child_running = function(from){
  let i, child = this.child;
  for (i=from||0; i<child.length && child[i].tm_completed; i++);
  return i>=child.length ? -1 : i;
};
E.prototype._set_wait_child = function(wait_retval){
  let i, _this = this, child = wait_retval.child;
  let cond = wait_retval.cond, wait_on;
  assert(!cond || child=='any', 'condition supported only for "any" '+
    'option, you can add support if needed');
  if (child=='any')
  {
    if (this._get_child_running()<0)
      return true;
    wait_on = function(){
      _this.once('child', function(_child){
        if (!cond || cond.call(_child, _child.retval))
          return _this._got_retval(wait_retval, {child: _child});
        if (_this._get_child_running()<0)
          return _this._got_retval(wait_retval);
        wait_on();
      });
    };
    wait_on();
  }
  else if (child=='all')
  {
    if ((i = this._get_child_running())<0)
      return true;
    wait_on = function(_child){
      _this.once('child', function(__child){
        let j;
        if ((j = _this._get_child_running())<0)
          return _this._got_retval(wait_retval);
        wait_on(_this.child[j]);
      });
    };
    wait_on(this.child[i]);
  }
  else
  {
    assert(child, 'no child provided');
    assert(this===child.parent, 'child does not belong to parent');
    if (child.tm_completed)
      return true;
    child.once('finally', function(){
      return _this._got_retval(wait_retval, {child: child});
    });
  }
  this.emit_safe('wait_on_child');
};

E.prototype._got_retval = function(wait_retval, res){
  if (this.wait_retval!==wait_retval || wait_retval.completed)
    return;
  wait_retval.completed = true;
  // inline state_next to reduce stack depth
  if (!this.es_next(E._res2rv(res)))
    this.state_now();
};
E.prototype.continue_fn = function(){
  return this.continue.bind(this);
};
E.continue_depth = 0;
E.prototype.continue = function(promise, sync){
  this.wait_retval = undefined;
  this._set_retval(promise);
  if (this.tm_completed)
    return promise;
  if (this.down)
    this.down._ecancel();
  this._del_wait_timer();
  let rv = {ret: promise, err: undefined};
  if (this.running)
  {
    this.at_continue = rv;
    return promise;
  }
  if (this._handle_rv(rv))
    return rv.ret;
  if (E.is_final(promise) &&
    (!E.continue_depth && !E.in_run.length || sync))
  {
    E.continue_depth++;
    this.state_next(rv);
    E.continue_depth--;
  }
  else // avoid high stack depth
  {
    // XXX: remove { onces fixed curlyplus rule
    next_tick(()=>{ this.state_next(rv); });
  }
  return promise;
};

E.prototype._ecancel = function(){
  if (this.tm_completed)
    return this;
  this.emit_safe('cancel');
  if (this.cancel!==undefined)
    return this._call_safe(this.states[this.cancel].f);
  if (this.cancelable)
    return this.return();
};

E.prototype._ecancel_child = function(){
  if (!this.child.length)
    return;
  // copy array, since ecancel has side affects and can modify array
  let child = Array.from(this.child);
  for (let i=0; i<child.length; i++)
    child[i]._ecancel();
};

E.prototype.return_fn = function(){
  return this.return.bind(this);
};
E.prototype.return = function(promise){
  if (this.tm_completed)
    return this._set_retval(promise);
  this.at_return = true;
  this.next_state = undefined;
  return this.continue(promise, true);
};

E.prototype.del_alarm = function(){
  let a = this._alarm;
  if (!a)
    return;
  clearTimeout(a.id);
  if (a.cb)
    this.removeListener('sig_alarm', a.cb);
  this._alarm = undefined;
};

E.prototype.alarm_left = function(){
  let a = this._alarm;
  if (!a)
    return 0;
  return a.start-Date.now();
};

E.prototype._operation_opt = function(opt){
  if (opt.goto)
    return {ret: this.goto(opt.goto, opt.ret)};
  if (opt.throw)
    return {ret: this.throw(opt.throw)};
  if (opt.return!==undefined)
    return {ret: this.return(opt.return)};
  if (opt.continue!==undefined)
    return {ret: this.continue(opt.continue)};
};

E.prototype.alarm = function(ms, cb){
  let opt, a;
  if (cb && typeof cb!='function')
  {
    opt = cb;
    cb = ()=>{
      let v;
      if (!(v = this._operation_opt(opt)))
        assert(0, 'invalid alarm cb opt');
      return v.ret;
    };
  }
  this.del_alarm();
  a = this._alarm = {ms, cb, start: Date.now()};
  a.id = setTimeout(()=>{
    this._alarm = undefined;
    this.emit('sig_alarm');
  }, a.ms);
  if (cb)
    this.once('sig_alarm', cb);
};

const Eserf_wait = function(es, op, timeout){
  this.timeout = timeout;
  this.es = es;
  this.op = op;
  this.child = this.at_child = this.cond = undefined;
  this.ready = this.completed = undefined;
};
Eserf_wait.prototype.continue = function(res){
  if (this.completed)
    return;
  if (!this.es.wait_retval)
    return void (this.ready = {ret: res});
  if (this!==this.es.wait_retval)
    return;
  this.es.continue(res);
};
Eserf_wait.prototype.continue_fn = function(){
  return this.continue.bind(this);
};
Eserf_wait.prototype.throw = function(err){
  return this.continue(E.err(err));
};
Eserf_wait.prototype.throw_fn = function(){
  return this.throw.bind(this);
};
E.prototype.wait = function(timeout){
  return new Eserf_wait(this, 'wait', timeout);
};
E.prototype.wait_child = function(child, timeout, cond){
  if (typeof timeout=='function')
  {
    cond = timeout;
    timeout = 0;
  }
  let wait = new Eserf_wait(this, 'wait_child', timeout);
  wait.child = child;
  wait.at_child = null;
  wait.cond = cond;
  return wait;
};

E.prototype.throw_fn = function(err){
  return err ? this.throw.bind(this, err) : this.throw.bind(this);
};
E.prototype.throw = function(err){
  return this.continue(E.err(err));
};

E.prototype.get_name = function(flags){
  let stack = this._stack instanceof Error ? this._stack.stack.split('\n') :
    undefined;
  let caller;
  flags = flags||{};
  if (stack)
  {
    caller = (/^ {4}at (.*)$/u).exec(stack[4]);
    caller = caller ? caller[1] : undefined;
  }
  let names = [];
  if (this.name)
    names.push(this.name);
  if (caller && !(this.name && flags.SHORT_NAME))
    names.push(caller);
  if (!names.length)
    names.push('noname');
  return names.join(' ');
};

E.prototype.state_str = function(){
  return this.cur_state+(this.next_state ? '->'+this.next_state : '');
};

E.prototype.get_depth = function(){
  let i=0, es = this;
  for (; es; es = es.up, i++);
  return i;
};

const trim_space = function(s){
  if (s[s.length-1]!=' ')
    return s;
  return s.slice(0, -1);
};
const ms_to_str = function(ms){
  let s = ''+ms;
  return s.length<=3 ? s+'ms' : s.slice(0, -3)+'.'+s.slice(-3)+'s';
};
E.prototype.get_time_passed = function(){
  return ms_to_str(Date.now()-this.tm_create);
};
E.prototype.get_time_completed = function(){
  return ms_to_str(Date.now()-this.tm_completed);
};
E.prototype.get_info = function(){
  let info = this.info, s = '', _i;
  if (!info)
    return '';
  for (let i in info)
  {
    _i = info[i];
    if (!_i)
      continue;
    if (s!=='')
      s += ' ';
    if (typeof _i=='function')
      s += _i();
    else
      s += _i;
  }
  return trim_space(s);
};

// light-weight efficient eserf/promise error value
const Eserf_err = function(err){
  this.error = err || new Error();
};
E.Eserf_err = Eserf_err;
E.err = function(err){ return new Eserf_err(err); };
E.is_err = function(v){
  return v instanceof Eserf && v.error!==undefined ||
    v instanceof Eserf_err;
};
E.err_res = function(err, res){ return err ? E.err(err) : res; };
E._res2rv = function(res){
  return E.is_err(res) ? {ret: undefined, err: res.error}
    : {ret: res, err: undefined};
};
E.is_final = function(v){
  return !v || typeof v.then!='function' || v instanceof Eserf_err ||
    v instanceof Eserf && !!v.tm_completed;
};

// promise compliant .then() implementation for Eserf and Eserf_err.
// for unit-test comfort, also .otherwise(), .catch(), .ensure(), resolve() and
// reject() are implemented.
E.prototype.then = function(on_res, on_err){
  let _this = this;
  const on_done = function(){
    if (!_this.error)
      return !on_res ? _this.retval : on_res(_this.retval);
    return !on_err ? E.err(_this.error) : on_err(_this.error);
  };
  if (this.tm_completed)
    return eserf('then_completed', [function(){ return on_done(); }]);
  let then_wait = eserf('then_wait', [function(){ return this.wait(); }]);
  this.then_waiting.push(function(){
    try {
      then_wait.continue(on_done());
    } catch(e) {
      then_wait.throw(e);
    }
  });
  return then_wait;
};
E.prototype.otherwise = E.prototype.catch = function(on_err){
  return this.then(null, on_err);
};
E.prototype.ensure = function(on_ensure){
  return this.then(function(res){ on_ensure(); return res; },
    function(err){ on_ensure(); throw err; });
};
Eserf_err.prototype.then = function(on_res, on_err){
  let _this = this;
  return eserf('then_err', [function(){
    return !on_err ? E.err(_this.error) : on_err(_this.error);
  }]);
};
Eserf_err.prototype.otherwise = Eserf_err.prototype.catch = function(on_err){
  return this.then(null, on_err);
};
Eserf_err.prototype.ensure = function(on_ensure){
  this.then(null, function(){ on_ensure(); });
  return this;
};
E.resolve = function(res){ return eserf([function(){ return res; }]); };
E.reject = function(err){ return eserf([function(){ throw err; }]); };

E.prototype.wait_ext = function(promise){
  if (!promise || typeof promise.then!='function')
    return promise;
  let wait = this.wait();
  promise.then(wait.continue_fn(), wait.throw_fn());
  return wait;
};

E.prototype.wait_ext2 = function(promise){
  if (!promise || typeof promise.then!='function')
    return promise;
  let wait = this.wait();
  promise.then(res=>{ this.continue(res); }).catch(err=>{
    this.continue({err});
  });
  return wait;
};

E.prototype.longname = function(flags){
  flags = flags||{TIME: 1};
  let s = '', _s;
  if (this.running)
    s += 'RUNNING ';
  s += this.get_name(flags)+(!this.tm_completed ? '.'+this.state_str() : '')
    +' ';
  if (this.tm_completed)
    s += 'COMPLETED'+(flags.TIME ? ' '+this.get_time_completed() : '')+' ';
  if (flags.TIME)
    s += this.get_time_passed()+' ';
  if (_s=this.get_info())
    s += _s+' ';
  return trim_space(s);
};
E.prototype.stack = function(flags){
  let es = this, s = '';
  flags = assign({STACK: 1, RECURSIVE: 1, GUESS: 1}, flags);
  while (es)
  {
    let _s = es.longname(flags)+'\n';
    if (es.up)
      es = es.up;
    else if (es.parent)
    {
      _s = (es.parent_type=='call' ? 'CALL' : 'SPAWN')+' '+_s;
      es = es.parent;
    }
    else if (es.parent_guess && flags.GUESS)
    {
      _s = 'SPAWN? '+_s;
      es = es.parent_guess;
    }
    else
      es = undefined;
    if (flags.TOPDOWN)
      s = _s+s;
    else
      s += _s;
  }
  return s;
};
E.prototype._ps = function(pre_first, pre_next, flags){
  let i, s = '', task_trail, es = this, child_guess;
  if (++flags.limit_n>=flags.LIMIT)
    return flags.limit_n==flags.LIMIT ? '\nLIMIT '+flags.LIMIT+'\n': '';
  // get top-most es
  for (; es.up; es = es.up);
  // print the es from ess frames
  for (let first = 1; es; es = es.down, first = 0)
  {
    s += first ? pre_first : pre_next;
    first = 0;
    // MARK==Eserf.ess
    if (flags.MARK && (i = flags.MARK.ess.indexOf(es))>=0)
      s += (flags.MARK.name[i]||'***')+' ';
    s += es.longname(flags)+'\n';
    if (flags.RECURSIVE)
    {
      let stack_trail = es.down ? '.' : ' ';
      let child = es.child;
      if (flags.GUESS)
        child = child.concat(es.child_guess);
      for (i = 0; i<child.length; i++)
      {
        task_trail = i<child.length-1 ? '|' : stack_trail;
        child_guess = child[i].parent_guess ? '\\? ' :
          child[i].parent_type=='call' ? '\\> ' : '\\_ ';
        s += child[i]._ps(pre_next+task_trail+child_guess,
          pre_next+task_trail+'   ', flags);
      }
    }
  }
  return s;
};
const ps_flags = function(flags){
  let m, _m;
  if (m = flags.MARK)
  {
    if (!Array.isArray(m))
      _m = {ess: [m], name: []};
    else if (!Array.isArray(m[0]))
      _m = {ess: m, name: []};
    else
    {
      _m = {ess: [], name: []};
      for (let i=0; i<m.length; i++)
      {
        // XXX: merge into 1 object _m.o = {name, es}
        // _m.o.push = {ess: m[i].ess, name: m[i].name};
        _m.name.push(m[i][0]);
        _m.ess.push(m[i][1]);
      }
    }
    flags.MARK = _m;
  }
};
E.prototype.ps = function(flags){
  flags = assign({STACK: 1, RECURSIVE: 1, LIMIT: 10000000, TIME: 1,
    GUESS: 1}, flags, {limit_n: 0});
  ps_flags(flags);
  return this._ps('', '', flags);
};
E._longname_root = function(){ return 'pid '+_process.pid; };
E.ps = function(flags){
  let i, s = '', task_trail;
  flags = assign({STACK: 1, RECURSIVE: 1, LIMIT: 10000000, TIME: 1,
    GUESS: 1}, flags, {limit_n: 0});
  ps_flags(flags);
  s += E._longname_root()+'\n';
  let child = es_root;
  if (flags.GUESS)
  {
    child = [];
    for (i=0; i<es_root.length; i++)
    {
      if (!es_root[i].parent_guess)
        child.push(es_root[i]);
    }
  }
  for (i=0; i<child.length; i++)
  {
    task_trail = i<child.length-1 ? '|' : ' ';
    s += child[i]._ps(task_trail+'\\_ ', task_trail+'   ', flags);
  }
  return s;
};

E.prototype.wait_ret = function(ess){
  let count = 0, _this = this, ret = new Array(ess.length);
  for (let i=0; i<ess.length; i++)
  {
    let es = ess[i];
    // eslint-disable-next-line no-loop-func
    this.spawn(eserf(function*(){
      ret[i] = yield es;
      count++;
      if (count!=ess.length)
        return;
      _this.continue(ret);
    }));
  }
  if (ess.length === 0)
    return [];
  return this.wait();
};
E.prototype.return_child = function(){
  // copy array, since return() has side affects and can modify array
  let child = Array.from(this.child);
  for (let i=0; i<child.length; i++)
    child[i].return();
};

E.sleep = function(ms){
  return eserf(function* _sleep(){
    let timer;
    ms = ms||0;
    this.finally(()=>{
      clearTimeout(timer);
    });
    this.info.ms = ms+'ms';
    timer = setTimeout(this.continue_fn(), ms);
    yield this.wait();
  });
};

let ebreak_obj = {ebreak: 1};
E.prototype.break = function(ret){
  return this.throw({ebreak: ebreak_obj, ret: ret});
};
E.for = function(cond, inc, opt, states){
  if (Array.isArray(opt) || typeof opt=='function')
  {
    states = opt;
    opt = {};
  }
  if (typeof states=='function')
    states = [states];
  opt = opt||{};
  return eserf({name: 'for', cancel: true, init: opt.init_parent},
    [['loop', function(){
      return !cond || cond.call(this);
    }], ['try_catch$', function(res){
      if (!res)
        return this.return();
      return eserf({name: 'for_iter', cancel: true, init: opt.init},
        states||[]);
    }], function(){
      if (this.error)
      {
        if (this.error.ebreak===ebreak_obj)
          return this.return(this.error.ret);
        return this.throw(this.error);
      }
      return inc && inc.call(this);
    }, function(){
      return this.goto('loop');
    }]);
};
E.for_each = function(obj, states){
  let keys = Object.keys(obj);
  let iter = {obj: obj, keys: keys, i: 0, key: undefined, val: undefined};
  const init_iter = function(){ this.iter = iter; };
  return E.for(function(){
    this.iter = this.iter||iter;
    iter.key = keys[iter.i];
    iter.val = obj[keys[iter.i]];
    return iter.i<keys.length;
  }, ()=>iter.i++,
  {init: init_iter, init_parent: init_iter}, states);
};
E.while = function(cond, states){ return E.for(cond, null, states); };

E.wait = function(timeout){
  return eserf({name: 'wait', cancel: true},
    [function(){ return this.wait(timeout); }]);
};
E.to_nfn = function(promise, cb, opt){
  return eserf({name: 'to_nfn', async: true}, [['try_catch$', function(){
    return promise;
  }], function(res){
    let ret = [this.error];
    if (opt && opt.ret_a)
      ret = ret.concat(res);
    else
      ret.push(res);
    return cb(null, ...ret);
  }]);
};
const eserf_fn = function(opt, states, push_this){
  if (Array.isArray(opt) || typeof opt=='function')
  {
    states = opt;
    opt = undefined;
  }
  let is_generator = typeof states=='function' &&
    states.constructor.name=='GeneratorFunction';
  return function(...args){
    let _opt = assign({}, opt);
    _opt.state0_args = Array.from(args);
    if (push_this)
      _opt.state0_args.unshift(this);
    if (is_generator)
      return E.gen_init(null, states, _opt);
    return new Eserf(_opt, states);
  };
};
E.fn = function(opt, states){ return eserf_fn(opt, states, false); };
E._fn = function(opt, states){ return eserf_fn(opt, states, true); };
E.gen_init = function(gen, ctor, opt){
  opt = opt||{};
  opt.name = opt.name || ctor && ctor.name || 'generator';
  if (opt.cancel===undefined)
    opt.cancel = true;
  let done;
  return new Eserf(opt, [function(){
    this.generator = gen = gen||ctor.apply(this, opt.state0_args||[]);
    this.generator_ctor = ctor;
    return {ret: undefined, err: undefined};
  }, ['try_catch$loop', function(rv){
    let res;
    try {
      res = rv.err ? gen.throw(rv.err) : gen.next(rv.ret);
    } catch(e) {
      return this.return(E.err(e));
    }
    if (res.done)
    {
      done = true;
      return this.return(res.value);
    }
    return res.value;
  }], function(ret){
    return this.goto('loop', this.error ?
      {ret: undefined, err: this.error} : {ret: ret, err: undefined});
  }, ['finally$', function(){
    // https://kangax.github.io/compat-table/es6/#test-generators_%GeneratorPrototype%.return
    // .return() supported only in node>=6.x.x
    if (!done && gen && gen.return)
      try { gen.return(); } catch(e) {}
  }]]);
};
E.ef = function(err){ // error filter
  if (xlog.on_crit)
    xlog.on_crit(err, this);
  return err;
};
// similar to setInterval
// opt==10000 (or opt.ms==10000) - call states every 10 seconds
// opt.mode=='smart' - default mode, like setInterval. If states take
//   longer than 'ms' to execute, next execution is delayed.
// opt.mode=='fixed' - always sleep 10 seconds between states
// opt.mode=='spawn' - spawn every 10 seconds
E.interval = function(opt, states){
  if (typeof opt=='number')
    opt = {ms: opt};
  if (opt.mode=='fixed')
  {
    return E.for(null, function(){ return eserf.sleep(opt.ms); },
      states);
  }
  if (opt.mode=='smart' || !opt.mode)
  {
    let now;
    return E.for(function(){ now = Date.now(); return true; },
      function(){
        let delay = clamp(0, now+opt.ms-Date.now(), Infinity);
        return eserf.sleep(delay);
      }, states);
  }
  if (opt.mode=='spawn')
  {
    let stopped = false;
    return eserf([['loop', function(){
      eserf([['try_catch$', function(){
        return eserf(states);
      }], function(res){
        if (!this.error)
          return;
        if (this.error.ebreak!==ebreak_obj)
          return this.throw(this.error);
        stopped = true;
      }]);
    }], function(){
      if (stopped)
        return this.return();
      return eserf.sleep(opt.ms);
    }, function(){
      if (stopped) // stopped during sleep by prev long iteration
        return this.return();
      return this.goto('loop');
    }]);
  }
  throw new Error('unexpected mode '+opt.mode);
};
E._class = function(cls){
  let proto = cls.prototype, keys = Object.getOwnPropertyNames(proto);
  for (let i=0; i<keys.length; i++)
  {
    let key = keys[i];
    let p = proto[key];
    if (p && p.constructor && p.constructor.name=='GeneratorFunction')
      proto[key] = E._fn(p);
  }
  return cls;
};
E.shutdown = function(){
  let prev;
  while (es_root.length)
  {
    let e = es_root[0];
    if (e==prev)
    {
      assert(e.tm_completed);
      xlog.exit('eserf root not removed after return - '+
        'fix non-cancelable child eserf'+E.ps({MARK: e}));
    }
    prev = e;
    e.return();
  }
};

let uniqs = {};
E.uniq = (opt, states)=>eserf(function* _uniq(){
  if (typeof opt=='string')
    opt = {id: opt};
  if (!opt.id)
    assert(0, 'must include uniq id');
  let o = uniqs[opt.id];
  if (o)
  {
    let es = this.wait();
    o.ess.push(es);
    let _res = yield es;
    return _res;
  }
  uniqs[opt.id] = o = {ess: []};
  let res = yield eserf(states);
  let ess = o.ess, es;
  delete uniqs[opt.id];
  o.ess = [];
  while (es=ess.pop())
    es.continue(res);
  return res;
});

let pools = {};
E.pool = (opt, func)=>eserf(function* _eserf_pool(){
  let id = opt.id;
  assert(id, 'no id');
  let pool = pools[id];
  if (!pool)
    pool = pools[id] = {count: 0, limit: opt.limit||1, ess: [], id};
  pool.count++;
  this.finally(()=>{
    pool.count--;
    if (!pool.ess.length)
      return;
    let es = pool.ess.pop();
    es.continue();
  });
  if (pool.limit && pool.count>pool.limit)
  {
    let es = this.wait();
    pool.ess.push(es);
    yield es;
  }
  let ret = yield func();
  return ret;
});

export default E;
