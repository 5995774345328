// LICENSE_CODE ABCAI
import {Modal, Button, Col, Input, Grid, Row, Space, Typography,
  Tooltip} from 'antd';
import {isEmpty, get, trim, map, find, filter, includes, toLower
} from 'lodash/fp.js';
import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import auth from './auth.js';
import xurl from '../../../util/xurl.js';
import eserf from '../../../util/eserf.js';
import back_app from './back_app.js';
import {Clickable, Video, VOD, Loading, Bounce, Closable} from './comp.js';
import {ShareAltOutlined, DownloadOutlined} from '@ant-design/icons';

let {useBreakpoint} = Grid;
let {Title, Paragraph, Link} = Typography;
let {TextArea} = Input;
let placeholder = 'Write a poem about life on Mars';
let waiting_vid = 'https://abcai-front-share-prod.s3.amazonaws.com/upload/'
  +'spipe_wait_rogan_2.mp4';

export const E = ()=>{
  let {t} = useTranslation();
  let screens = useBreakpoint();
  let is_mobile = useMemo(()=>get('xs', screens), [screens]);
  let {user, token, user_full} = auth.use_auth();
  let [concept, set_concept] =useState('');
  let [ret, set_ret] = useState({});
  let [loading, set_loading] = useState(false);
  let [error, set_error] = useState('');
  let [char_id, set_char_id] = useState('');
  let [chars, set_chars] = useState([]);
  let [is_show_waiting_vid, set_is_show_waiting_vid] = useState(true);
  useEffect(()=>{
    let _ret = JSON.parse(localStorage.getItem('__ret'))||{};
    set_ret(_ret);
    let _char_id = localStorage.getItem('__char_id')||'gordon_ramsay';
    set_char_id(_char_id);
    let _is_hide_waiting_vid = localStorage.getItem('is_hide_waiting_vid')||
      false;
    set_is_show_waiting_vid(!_is_hide_waiting_vid);
  }, []);
  useEffect(()=>{
    let ls_concept = localStorage.getItem('_spipe_concept');
    if (isEmpty(ls_concept))
      return;
    set_concept(trim(ls_concept));
    localStorage.removeItem('_spipe_concept');
  }, []);
  useEffect(()=>{
    let es = eserf(function* _get_chars_use_effect(){
      let _ret = yield back_app.produce_char_get();
      if (_ret.err)
      {
        // handle error
        return;
      }
      set_chars(_ret.chars);
    });
    return ()=>es.return();
  }, []);
  let has_data = useMemo(()=>!isEmpty(ret), [ret]);
  let is_done = useMemo(()=>has_data && ret.i_curr>=ret.len, [has_data, ret]);
  let on_submit = ()=>eserf(function* _on_submit(){
    set_error(null);
    set_loading(true);
    let _ret = yield back_app.produce_start(token, user.email, user.org,
      concept, char_id);
    set_loading(false);
    if (_ret.err)
      return void set_error(_ret.err);
    set_ret(_ret);
    localStorage.setItem('__ret', JSON.stringify(_ret));
    localStorage.setItem('__char_id', char_id);
  });
  let on_continue = ()=>eserf(function* _on_continue(){
    set_error(null);
    set_loading(true);
    let _ret = yield back_app.produce_continue(token, user.email, user.org,
      ret.prompt_id, ret.file||ret.file_prev, ret.i_prev+1, ret.limit+1,
      ret.gid, concept, char_id);
    set_loading(false);
    if (_ret.err)
      return void set_error(_ret.err);
    let url = xurl.url2o(_ret.url);
    // XXX: add real rand
    _ret.url = xurl.url(url.uri, {...url.qs_o, r: Math.random()});
    set_ret(_ret);
    localStorage.setItem('__ret', JSON.stringify(_ret));
    localStorage.setItem('__char_id', char_id);
  });
  let on_reset = ()=>{
    set_ret({});
    set_concept('');
    localStorage.removeItem('__ret');
    localStorage.removeItem('__char_id');
    set_error(null);
  };
  let on_share = ()=>{
    let data={
      title: 'My ABCAIShow.com video',
      text: concept,
      url: ret.url,
    };
    window.navigator.share && window.navigator.share(data)
      .catch(console.error);
  };
  let on_select_char = _char_id=>{
    if (!isEmpty(_char_id))
      set_char_id(_char_id);
  };
  let on_hide_waiting_vid=()=>{
    localStorage.setItem('is_hide_waiting_vid', 1);
    set_is_show_waiting_vid(false);
  };
  if (!token || !user_full)
    return <Loading/>;

  let props = {t, is_done, has_data, concept, loading, on_submit, on_continue,
    ret, on_reset, set_concept, error, on_select_char, chars,
    char_id, on_share, on_hide_waiting_vid, is_show_waiting_vid};
  return is_mobile ? <Mobile {...props}/>:<Desktop {...props}/>;
};

let Mobile = React.memo(({t, is_done, has_data, concept, loading, on_submit,
  on_continue, ret, on_reset, set_concept, error,
  on_select_char, chars, char_id, on_share, is_show_waiting_vid,
  on_hide_waiting_vid})=>{
  return (
    <Row>
      <Col span={22} offset={1}>
        {!has_data &&
          <Space direction="vertical" style={{width: '100%'}}>
            <Row><Title level={4}>
              {t('Create an entire video from a single prompt')}
            </Title></Row>
            <Row>
              <TextArea value={concept} onChange={e=>set_concept(
                e.target.value)} placeholder={placeholder}
              rows={3} maxLength={300}
              />
            </Row>
            <Row align="middle" justify="space-between">
              <Col>
                <Button type="primary" size="large"
                  loading={loading} disabled={isEmpty(concept)}
                  onClick={on_submit}>
                  {t('Create video')}
                </Button>
              </Col>
              <Col>
                <Chars chars={chars} on_select={on_select_char}
                  char_id={char_id} concept={concept} loading={loading}/>
              </Col>
            </Row>
          </Space>
        }
        {has_data && <Row justify="center"><Video url={ret.url}/></Row>}
        {has_data && !is_done &&
          <>
            <Row>
              <Title level={4}>
                {t('Finished')} {ret.i_prev+1} / {ret.len}
              </Title>
            </Row>
            <Row gutter={[12, 0]} style={{marginBottom: 64}}>
              <Col span={12}>
                <Button block type="primary" onClick={on_continue}
                  disabled={loading} align="center" loading={loading}>
                  {t('Continue')}
                </Button>
              </Col>
              <Col span={12}>
                <Button type="primary" onClick={on_reset} disabled={loading}
                  align="center" block>
                  {t('Start Over')}
                </Button>
              </Col>
            </Row>
          </>}
        {error && <Row justify="center">{t('Oops, something went wrong')}
        </Row>}
        {loading && !has_data && <>
          <Row justify="center">
            <Title level={5}>{t('Expected time: 3-4 min.')}</Title>
          </Row>
          {is_show_waiting_vid && <Row justify="center">
            <Closable on_close={on_hide_waiting_vid}>
              <Video url={waiting_vid}/>
            </Closable>
          </Row>
          }
        </>
        }
        {is_done && <>
          <Row>
            <Title level={3}>{t('Your video is ready!')}</Title>
          </Row>
          <Row gutter={[12, 0]}>
            <Col span={12}>
              <Link href={ret.url} download target="_blank">
                <Button block type="primary"
                  icon={<DownloadOutlined/>}
                >{t('Download video')}
                </Button>
              </Link>
            </Col>
            <Col span={12}>
              <Button block type="primary" onClick={on_share}
                icon={<ShareAltOutlined/>}
              >{t('Share')}
              </Button>
            </Col>
          </Row>
          <Row style={{marginBottom: 64}}>
            <Col span={24}>
              <Button type="primary" onClick={on_reset} block
                style={{marginTop: 16}}>
                {t('Start Over')}
              </Button>
            </Col>
          </Row>
        </>}
        {isEmpty(concept) && !has_data &&
          <>
            <Row style={{marginTop: 16}}>
              <Title level={4}>{t('Try these prompts')}</Title>
            </Row>
            <Row>
              <Examples_mobile on_select={set_concept} t={t}/>
            </Row>
          </>}
        {isEmpty(concept) && !has_data && <>
          <Row style={{marginTop: 32}}>
            <Title level={4}>{t('Video Examples')}</Title>
          </Row>
          <VOD/>
        </>}
      </Col>
    </Row>
  );
});

let Desktop = React.memo(({t, is_done, has_data, concept, loading, on_submit,
  on_continue, ret, on_reset, set_concept, error,
  on_select_char, chars, char_id, on_share, is_show_waiting_vid,
  on_hide_waiting_vid})=>{
  return (
    <Row>
      <Col span={18} offset={1}>
        {!has_data &&
          <Space direction="vertical" style={{width: '100%'}}>
            <Row><Title level={3}>
              {t('Create an entire video from a single prompt')}
            </Title></Row>
            <Row>
              <TextArea value={concept} onChange={e=>set_concept(
                e.target.value)} placeholder={placeholder}
              rows={3} maxLength={200}
              />
            </Row>
            <Row align="middle" justify="space-between">
              <Col>
                <Clickable>
                  <Button type="primary" size="large"
                    loading={loading} disabled={isEmpty(concept)}
                    onClick={on_submit}>
                    {t('Create video')}
                  </Button>
                </Clickable>
              </Col>
              <Col>
                <Chars chars={chars} on_select={on_select_char}
                  char_id={char_id} concept={concept} loading={loading}/>
              </Col>
            </Row>
          </Space>
        }
        {has_data && <Row><Video style={{maxWidth: 500}}
          url={ret.url}/></Row>}
        {has_data && !is_done &&
          <>
            <Row>
              <Title level={4}>
                {t('Finished')} {ret.i_prev+1} / {ret.len}
              </Title>
            </Row>
            <Row gutter={[24, 0]} style={{marginBottom: 64}}>
              <Col>
                <Clickable>
                  <Button type="primary" onClick={on_continue}
                    disabled={loading} block
                    loading={loading}>
                    {t('Continue')}
                  </Button>
                </Clickable>
              </Col>
              <Col>
                <Clickable>
                  <Button type="primary" onClick={on_reset} disabled={loading}
                    block>
                    {t('Start Over')}
                  </Button>
                </Clickable>
              </Col>
            </Row>
          </>}
        {error && <Row justify="center">{t('Oops, something went wrong')}
        </Row>}
        {loading && !has_data && <>
          <Row justify="center">
            <Title level={5}>{t('Expected time: 3-4 min.')}</Title>
          </Row>
          {is_show_waiting_vid && <Row justify="center">
            <div style={{maxHeight: 200, aspectRatio: 9/6}}>
              <Closable on_close={on_hide_waiting_vid}>
                <Video url={waiting_vid}/>
              </Closable>
            </div>
          </Row>
          }
        </>
        }

        {is_done && <>
          <Row>
            <Title level={3}>{t('Your video is ready!')}</Title>
          </Row>
          <Row style={{marginBottom: 64}} gutter={[24, 0]}>
            <Col>
              <Clickable>
                <Link href={ret.url} download target="_blank">
                  <Button block type="primary"
                    icon={<DownloadOutlined/>}
                  >{t('Download video')}
                  </Button>
                </Link>
              </Clickable>
            </Col>
            <Col>
              <Clickable>
                <Button type="primary" onClick={on_share}
                  icon={<ShareAltOutlined/>}
                >{t('Share')}
                </Button>
              </Clickable>
            </Col>
            <Col>
              <Clickable>
                <Button onClick={on_reset} type="primary">
                  {t('Start Over')}
                </Button>
              </Clickable>
            </Col>
          </Row>
        </>}
        {isEmpty(concept) && !has_data &&
          <>
            <Row>
              <Title level={4}>{t('Try these prompts')}</Title>
            </Row>
            <Row>
              <Examples_desktop on_select={set_concept} t={t}/>
            </Row>
          </>}
        {isEmpty(concept) && !has_data && <>
          <Row style={{marginTop: 32}}>
            <Title level={4}>{t('Video Examples')}</Title>
          </Row>
          <VOD/>
        </>}
      </Col>
    </Row>
  );
});

let examples = ['Write a poem about life',
  'Explain how molecules work',
  'Write a story about life in Mars',
];

let Examples_mobile = React.memo(({on_select, t})=>{
  return <Space direction="vertical">
    {examples.map(example=><Button key={example} type="primary" level={4}
      onClick={()=>on_select(example)}>
      {t(example)}
    </Button>
    )}
  </Space>;
});

let Examples_desktop = ({on_select, t})=>{
  return <Space direction="horizontal">
    {examples.map(example=><Clickable key={example}><Button type="primary"
      level={4} onClick={()=>on_select(example)}>
      {t(example)}
    </Button></Clickable>
    )}
  </Space>;
};


let char_img = 'https://abcai-front-share-prod.s3.amazonaws.com/img';
let Chars = ({chars, on_select, char_id, concept, loading})=>{
  let {t} = useTranslation();
  let [is_open, set_is_open] = useState(false);
  let [is_tooltip, set_is_tooltip] = useState(false);
  let [search, set_search] = useState('');
  let _chars = useMemo(()=>{
    if (!search)
      return chars;
    let _search = toLower(search);
    return filter(char=>includes(_search, toLower(char.lbl)), chars);
  }, [search, chars]);

  let curr_lbl = useMemo(()=>get('lbl', find(char=>char.char_id === char_id,
    chars)), [chars, char_id]);
  let _on_select=_char_id=>{
    on_select(_char_id);
    set_is_open(false);
  };
  let on_modal_open=()=>{
    set_is_open(true);
    set_is_tooltip(false);
    localStorage.setItem('did_change_char', 'yes');
  };
  useEffect(()=>{
    if (concept && !localStorage.getItem('did_change_char'))
      set_is_tooltip(true);
  }, [concept]);

  return <>
    <Tooltip open={is_tooltip && !loading} title={t('Select character')}
      trigger={['click', 'hover', 'focus']} placement="bottomRight"
      color="#1668dc">
      {!loading && <Bounce is_bounce={is_tooltip}>
        <img src={`${char_img}/${char_id}.png`} style={{borderRadius: '100%',
          width: 60, height: 60, objectFit: 'cover', cursor: 'pointer'}}
        onClick={on_modal_open}
        alt={curr_lbl} title={curr_lbl} />
      </Bounce>
      }
      <Modal open={is_open} onOk={_on_select}
        onCancel={()=>set_is_open(false)} okButtonProps={{disabled: true}}>
        <>
          <Row>
            <TextArea rows={1} value={search}
              onChange={e=>set_search(e.target.value)} autoComplete="off"
              placeholder={t('Select or search a character')} bordered={false}
              style={{lineHeight: 2.5, marginBottom: 16}}/>
          </Row>
          <Row gutter={[16, 16]}>
            {map(_char=><Col offset={0} xs={{span: 8}} md={{span: 6}}
              key={_char.lbl}>
              <Tooltip title={_char.lbl}>
                <div onClick={()=>_on_select(_char.char_id)}
                  style={{cursor: 'pointer'}}>
                  <img src={`${char_img}/${_char.img}`}
                    style={{width: 50, borderRadius: 8, marginBottom: 8,
                      objectFit: 'cover', height: 50}} />
                  <Paragraph>{_char.lbl}</Paragraph>
                </div>
              </Tooltip>
            </Col>, _chars)}
          </Row>
        </>
      </Modal>
    </Tooltip>
  </>;
};

export default auth.with_auth_req(E);

