// LICENSE_CODE ABC
import React from 'react';
import {Button, Checkbox, Col, Form, Input, Row, Typography} from 'antd';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import auth from './auth.js';
import back_app from './back_app.js';
import eserf from '../../../util/eserf.js';
import je from '../../../util/je.js';
import config_ext from './config_ext.js';

let {Title, Paragraph} = Typography;
let E = ()=>{
  if (config_ext.is_schar)
    return <Onboard_schar/>;
  return <Onboard/>;
};

let Onboard = ()=>{
  let {t} = useTranslation();
  let [form] = Form.useForm();
  let {user, token} = auth.use_auth();
  let [loading, loading_set] = React.useState(false);
  let on_finish = values=>eserf(function* _on_finish(){
    loading_set(true);
    yield back_app.user_onboard(token, user.email, values.first, values.last,
      values.nick, values.phone, values.company);
    yield back_app.user_get(token, user.email);
    // XXX colin: handle err of user_full and user_onboard
    je.set_inc('onboard.update_n');
    loading_set(false);
  });
  let form_item_layout = {
    labelCol: {xs: {span: 24}, sm: {span: 8}},
    wrapperCol: {xs: {span: 24}, sm: {span: 16}}
  };
  let tail_form_item_layout = {
    wrapperCol: {xs: {span: 24, offset: 0}, sm: {span: 16, offset: 8}},
  };
  return <Row justify="center">
    <Col justify="center" xs={{offset: 1, span: 22}} md={{span: 16, offset: 4}}>
      <Title level={2}>
        {t('Just one more step before you start')}
      </Title>
      <Form
        form={form}
        {...form_item_layout}
        style={{maxWidth: 600}}
        name="signup"
        onFinish={on_finish}
        initialValues={{}}
        scrollToFirstError
      >
        <Form.Item
          name="first"
          label={t('First name')}
          tooltip={t('What is your first name')}
          rules={[
            {
              required: true,
              message: t('Please input your first name!'),
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="last"
          label={t('Last/family name')}
          tooltip={t('What is your last/family name')}
          rules={[
            {
              required: true,
              message: t('Please input your last/family name!'),
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="nickname"
          label={t('Nickname')}
          tooltip={t('What do you want others to call you?')}
          rules={[
            {
              required: false,
              message: t('Please input your nickname!'),
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="company"
          label={t('Company')}
          tooltip={t('What is the company you represent?')}
          rules={[
            {
              required: true,
              message: t('Please input your company!'),
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phone"
          label={t('Phone Number')}
          tooltip={<>
            <Paragraph>{t('What is your phone number?')}</Paragraph>
            <Paragraph>{t('Why we ask for your phone number')}</Paragraph>
            <Paragraph>{t('Account Recovery')}</Paragraph>
            <Paragraph>
              {t('If you forget your password or get locked out '
            +'of your account, a phone number provides an additional way to '
            +'verify your identity and regain access.')}
            </Paragraph>
            <Paragraph>{t('Fraud Prevention')}</Paragraph>
            <Paragraph>
              {t('by asking for phone number it limits fake '
              +'or fraudulent account creation. It\'s more challenging for '
              +'scammers to maintain numerous unique phone numbers than it is '
              +'for email addresses.')}
            </Paragraph>
            <Paragraph>{t('Communication')}</Paragraph>
            <Paragraph>
              {t('we may communicate with '
              +'users directly through phone calls or SMS, whether for '
              +'support or account issues')}
            </Paragraph>
          </>}
          rules={[
            {
              required: true,
              message: t('Please input your phone number! Example '
                +'+18777755232'),
              pattern: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
            },
          ]}
        >
          <Input style={{width: '100%'}} />
        </Form.Item>
        <Form.Item
          {...tail_form_item_layout}
          name="privacy"
          valuePropName="checked"
          rules={[
            {
              validator: (err, value)=>{
                return value ? Promise.resolve()
                : Promise.reject(new Error('Should accept agreement'));
              },
            },
          ]}
        >
          <Checkbox>{t('I agree to the')} <Link to="/privacy">
            {t('privacy policy')}</Link>
          </Checkbox>
        </Form.Item>
        <Form.Item
          {...tail_form_item_layout}
          name="tos"
          valuePropName="checked"
          rules={[
            {
              validator: (err, value) =>
                value ? Promise.resolve()
                : Promise.reject(new Error('Should accept agreement')),
            },
          ]}
        >
          <Checkbox>{t('I agree to the')} <Link to="/tos">
            {t('terms of service')}</Link>
          </Checkbox>
        </Form.Item>
        <Form.Item
          {...tail_form_item_layout}
          name="disclaimer"
          valuePropName="checked"
          rules={[
            {
              validator: (err, value)=>value ? Promise.resolve()
              : Promise.reject(new Error('Should accept agreement')),
            },
          ]}
        >
          <Checkbox>{t('I agree to the')} <Link to="/disclaimer">
            {t('disclaimer')}</Link>
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{span: 24}} >
          <Button loading={loading} style={{width: '100%'}} type="primary"
            htmlType="submit">
            {t('Submit')}
          </Button>
        </Form.Item>
      </Form>
    </Col>
  </Row>;
};

let Onboard_schar = ()=>{
  let {t} = useTranslation();
  let [form] = Form.useForm();
  let {user, token} = auth.use_auth();
  let [loading, loading_set] = React.useState(false);
  let on_finish = values=>eserf(function* _on_finish(){
    loading_set(true);
    yield back_app.user_onboard(token, user.email, values.first, values.last,
      values.nick, values.phone, values.company);
    yield back_app.user_get(token, user.email);
    // XXX colin: handle err of user_full and user_onboard
    je.set_inc('onboard.update_n');
    loading_set(false);
  });
  let form_item_layout = {
    labelCol: {xs: {span: 24}, sm: {span: 8}},
    wrapperCol: {xs: {span: 24}, sm: {span: 16}}
  };
  let tail_form_item_layout = {
    wrapperCol: {xs: {span: 24, offset: 0}, sm: {span: 16, offset: 8}},
  };
  return <Row justify="center">
    <Col justify="center" xs={{offset: 1, span: 22}} md={{span: 16, offset: 4}}>
      <Title level={2}>
        {t('Just one more step before you start')}
      </Title>
      <Form
        form={form}
        {...form_item_layout}
        style={{maxWidth: 600}}
        name="signup"
        onFinish={on_finish}
        initialValues={{}}
        scrollToFirstError
      >
        <Form.Item
          name="nickname"
          label={t('Nickname')}
          tooltip={t('What do you want others to call you?')}
          rules={[
            {
              required: true,
              message: t('Please input your nickname!'),
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="first"
          label={t('First name')}
          tooltip={t('What is your first name')}
          rules={[
            {
              required: false,
              message: t('Please input your first name!'),
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="last"
          label={t('Last/family name')}
          tooltip={t('What is your last/family name')}
          rules={[
            {
              required: false,
              message: t('Please input your last/family name!'),
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="company"
          label={t('Company')}
          tooltip={t('What is the company you represent?')}
          rules={[
            {
              required: false,
              message: t('Please input your company!'),
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phone"
          label={t('Phone Number')}
          tooltip={<>
            <Paragraph>{t('What is your phone number?')}</Paragraph>
            <Paragraph>{t('Why we ask for your phone number')}</Paragraph>
            <Paragraph>{t('Account Recovery')}</Paragraph>
            <Paragraph>
              {t('If you forget your password or get locked out '
            +'of your account, a phone number provides an additional way to '
            +'verify your identity and regain access.')}
            </Paragraph>
            <Paragraph>{t('Fraud Prevention')}</Paragraph>
            <Paragraph>
              {t('by asking for phone number it limits fake '
              +'or fraudulent account creation. It\'s more challenging for '
              +'scammers to maintain numerous unique phone numbers than it is '
              +'for email addresses.')}
            </Paragraph>
            <Paragraph>{t('Communication')}</Paragraph>
            <Paragraph>
              {t('we may communicate with '
              +'users directly through phone calls or SMS, whether for '
              +'support or account issues')}
            </Paragraph>
          </>}
          rules={[
            {
              required: false,
              message: t('Please input your phone number! Example '
                +'+18777755232'),
              pattern: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
            },
          ]}
        >
          <Input style={{width: '100%'}} />
        </Form.Item>
        <Form.Item
          {...tail_form_item_layout}
          name="privacy"
          valuePropName="checked"
          rules={[
            {
              validator: (err, value)=>{
                return value ? Promise.resolve()
                : Promise.reject(new Error('Should accept agreement'));
              },
            },
          ]}
        >
          <Checkbox>{t('I agree to the')} <Link to="/privacy">
            {t('privacy policy')}</Link>
          </Checkbox>
        </Form.Item>
        <Form.Item
          {...tail_form_item_layout}
          name="tos"
          valuePropName="checked"
          rules={[
            {
              validator: (err, value) =>
                value ? Promise.resolve()
                : Promise.reject(new Error('Should accept agreement')),
            },
          ]}
        >
          <Checkbox>{t('I agree to the')} <Link to="/tos">
            {t('terms of service')}</Link>
          </Checkbox>
        </Form.Item>
        <Form.Item
          {...tail_form_item_layout}
          name="disclaimer"
          valuePropName="checked"
          rules={[
            {
              validator: (err, value)=>value ? Promise.resolve()
              : Promise.reject(new Error('Should accept agreement')),
            },
          ]}
        >
          <Checkbox>{t('I agree to the')} <Link to="/disclaimer">
            {t('disclaimer')}</Link>
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{span: 24}} >
          <Button loading={loading} style={{width: '100%'}} type="primary"
            htmlType="submit">
            {t('Submit')}
          </Button>
        </Form.Item>
      </Form>
    </Col>
  </Row>;
};

export default auth.with_auth_req(E);
