// LICENSE_CODE ABC
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import Language_detector from 'i18next-browser-languagedetector';
import config_ext from './config_ext.js';
import i18n_translation from './i18n_translation.js';

let is_missing_key_once;
i18n.use(Language_detector).use(initReactI18next).init({
  debug: config_ext.is_local,
  fallbackLng: 'en',
  interpolation: {
    // not needed for react as it escapes by default
    escapeValue: false,
  },
  resources: i18n_translation,
  saveMissing: config_ext.is_local,
  missingKeyHandler: (ng, ns, key, fallback_value)=>{
    if (!is_missing_key_once)
    {
      console.log('missing keys add to i18n_translation.js and run '
        +'translate.js');
    }
    is_missing_key_once = true;
    console.log(`"${key}": "${key}",`);
  },
});


export default i18n;
