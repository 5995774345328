// LICENSE_CODE ABC
import React, {useEffect, useState} from 'react';
import {useLocation, Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Col, Row, Card, Button, Typography, Divider} from 'antd';
import {Clickable} from './comp.js';
import metric from './metric.js';
import back_app from './back_app.js';
import eserf from '../../../util/eserf.js';

let unit2lbl = ({unit, v})=>{
  if (unit=='usd')
    return '$'+v;
  if (unit=='%')
    return v+'%';
};

let n2lbl = n=>n.toLocaleString('en-US');

let {Title, Text} = Typography;

let Text_container = React.memo(({children})=>{
  return <div style={{height: 40}}>{children}</div>;
});

export const E = ()=>{
  let {t} = useTranslation();
  let location = useLocation();
  let [plans, plans_set] = useState({});
  useEffect(()=>{
    let es = eserf(function* _plan_use_effect(){
      let res = yield back_app.org_get_plan();
      if (res.err)
        metric.error('org_get_plan_err', res.err);
      plans_set(res);
    });
    return ()=>es.return();
  }, []);

  return (
    <Row justify="center">
      <Col xs={{span: 22, offset: 1}} md={{span: 20, offset: 0}}
        lg={{span: 16, offset: 0}} style={{maxWidth: 800}}>
        <Row align="center"><Title>{t('PLANS')}</Title></Row>
        <Row align="center"><Title level={3}>
          {t('Choose a plan that works for you')}
        </Title></Row>
        <Row gutter={[16, 16]}>
          {Object.values(plans).map((plan, index)=><Col key={index}
            xs={{span: 24}} md={{span: 8}}
            data-aos="flip-left" data-aos-anchor-placement="top-center"
            data-aos-duration="2000">
            <Clickable>
              <Card style={{textAlign: 'center'}}>
                <Text_container>
                  {plan.is_recommended && <Text>{t('Recommended')}
                  </Text>}</Text_container>
                <Title level={4}>{t(plan.lbl)}</Title>
                <Title level={5}>
                  {n2lbl(plan.credit)} {t('images')}
                </Title>
                <Text>
                  {plan.is_no_watermark ? t('No watermark'): t('Watermark')}
                </Text>
                <Divider/>
                <Title level={5}>
                  {unit2lbl(plan.cost)}/{t('Month')}
                </Title>
                <Divider/>
                <Clickable>
                  <Button>
                    <Link
                      to={{pathname: '/contact', search: location.search}}>
                      {t('Contact us')}
                    </Link>
                  </Button>
                </Clickable>
              </Card>
            </Clickable>
          </Col>)}
        </Row>
      </Col>
    </Row>
  );
};

export default E;
