// LICENSE_CODE MIT
import eserf from '../../../util/eserf.js';
import ereq from '../../../util/ereq.js';
import je from '../../../util/je.js';
import config_ext from './config_ext.js';
import {orderBy} from 'lodash/fp.js';

let E = {};
export default E;
let prefix = config_ext.back.app.url;

E.user_get = (token, email)=>eserf(function* user_get(){
  let res = yield ereq.get(prefix+'/private/user/get.json',
    {qs: {email, ver: config_ext.ver}, no_print: true,
      headers: ereq.auth_hdr(token)});
  if (res.err)
  {
    je.delete('user'); // XXX colin: can set user.err
    return res;
  }
  je.set('user', res.data, {recursive: true});
  return res.data;
});

E.org_get_plan = ()=>eserf(function* org_get_plan(){
  let res = yield ereq.get(prefix+'/pub/org/get_plan.json',
    {qs: {ver: config_ext.ver}, no_print: true});
  if (res.err)
    return res;
  return res.data;
});

E.user_init = (token, email)=>eserf(function* user_init(){
  let res = yield ereq.get(prefix+`/pub/user/init_${
    config_ext.is_sbase ? 'sbase' : 'auth0'}.json`,
    {qs: {email, ver: config_ext.ver, insert: true},
      headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  je.set('user', res.data, {recursive: true});
  return res.data;
});

E.user_set_rate = (token, email, rate)=>eserf(function* user_set_rate(){
  return yield user_set(token, email, 'rate', rate);
});

let user_set = (token, email, key, v)=>eserf(function* _user_set(){
  let res = yield ereq.get(prefix+'/private/user/set.json',
    {qs: {email, ver: config_ext.ver, key, v},
      headers: ereq.auth_hdr(token)});
  // XXX colin: add metric.error on failure
  if (res.err)
    return {err: res.err};
  je.set('user.'+key, v);
  return res.data;
});

E.user_set_is_x_start_pay = (token, email, is_x_start_pay)=>eserf(function*
user_set_is_x_start_pay(){
  return yield user_set(token, email, 'is_x_start_pay', is_x_start_pay);
});

E.user_email_resend = (token, email)=>eserf(function* user_email_resend(){
  let res = yield ereq.get(prefix+'/pub/user/email_resend.json', {qs: {
    email, ver: config_ext.ver, token}, headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.user_onboard = (token, email, first, last, nick, phone,
  company)=>eserf(function* user_onboard(){
  let ess = [];
  ess.push(user_set(token, email, 'first', first));
  ess.push(user_set(token, email, 'last', last));
  // XXX colin: add nick support
  ess.push(user_set(token, email, 'phone', phone));
  ess.push(user_set(token, email, 'company', company));
  ess.push(user_set(token, email, 'flag.is_onboard', true));
  let ess_ret = yield this.wait_ret(ess);
  return ess_ret;
});

E.org_order_create = (token, email, org_id, order_id, sub_id,
  vendor, src)=>eserf(function* org_pay_success(){
  let res = yield ereq.get(prefix+'/private/org/order/create.json',
    {qs: {email, ver: config_ext.ver, order_id, sub_id, src, vendor},
      headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.org_order_capture = (token, email, org_id, order_id, sub_id,
  vendor, src)=>eserf(function* org_pay_success(){
  let res = yield ereq.get(prefix+'/private/org/order/capture.json',
    {qs: {email, ver: config_ext.ver, order_id, sub_id, src, vendor},
      headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.org_pay_success = (token, email, org_id, order_id, sub_id,
  vendor, src)=>eserf(function* org_pay_success(){
  let res = yield ereq.get(prefix+'/private/org/pay/success.json',
    {qs: {email, ver: config_ext.ver, order_id, sub_id, src, vendor},
      headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.org_task_list = (token, email, org_id)=>eserf(function* org_task_list(){
  let res = yield ereq.get(prefix+'/private/org/task/get_list.json',
    {qs: {email, ver: config_ext.ver, org_id},
      headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.task_pub_get_list = (type, limit)=>eserf(function* task_pub_get_list(){
  let res = yield ereq.get(prefix+'/pub/task_pub/get_list.json',
    {qs: {ver: config_ext.ver, type, limit}});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.user_task_pub_get_list = (token, email, type)=>eserf(function*
user_task_pub_get_list(){
  let res = yield ereq.get(prefix+'/private/task_pub/get_list.json',
    {qs: {email, ver: config_ext.ver, type}, headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.schar_start = (token, email, org_id, prompt_txt, char_id)=>eserf(function*
schar_start(){
  let res = yield ereq.get(prefix+'/private/schar/start.json',
    {qs: {email, ver: config_ext.ver, org_id, prompt_txt, char_id},
      headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  let {gid, prompt_id, txt} = res.data;
  return {gid, prompt_id, txt};
});

E.schar_continue = (token, email, org_id, prompt_txt, gid,
  char_id)=>eserf(function* schar_continue(){
  let res = yield ereq.get(prefix+'/private/schar/continue.json',
    {qs: {email, ver: config_ext.ver, prompt_txt, org_id,
      char_id, gid}, headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  let {txt, prompt_id} = res.data;
  return {gid, txt, prompt_id};
});

E.schar_video = (token, email, org_id, prompt_id, char_id,
  prompt_txt, gid)=>eserf(function* schar_create_vid(){
  if (0)
  {
    yield eserf.sleep(3000);
    let url ='https://abcai-front-share-prod.s3.amazonaws.com/'
      +'rogan_waiting_cto.mp4';
    return {prompt_id, url, char_id, gid};
  }
  let res = yield ereq.get(prefix+'/private/schar/video.json',
    {qs: {email, ver: config_ext.ver, prompt_id, prompt_txt, org_id,
      char_id, gid}, headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  let {url} = res.data;
  return {url};
});

E.produce_start = (token, email, org_id, txt, char_id)=>eserf(function*
produce_start(){
  let res;
  // XXX colin/eilam: add debug to choose these in qs
  let runway_id, playht_id, prompt_id, mid_id;
  res = yield ereq.get(prefix+'/private/produce/start.json',
    {qs: {email, ver: config_ext.ver, concept: txt, txt, char_id,
      runway_id, playht_id, prompt_id, mid_id
    }, headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.produce_continue = (token, email, org_id, prompt_id, file_prev, i_prev,
  limit, gid, concept, char_id = 'gordon_ramsay')=>eserf(function*
produce_continue(){
  let res;
  res = yield ereq.get(prefix+'/private/produce/continue.json',
    {qs: {email, ver: config_ext.ver, prompt_id, i_prev, limit, file_prev,
      char_id, gid, txt: concept}, headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.produce_char_get = ()=>eserf(function* _produce_char_get(){
  let res = yield ereq.get(prefix+'/pub/produce/char_get.json',
    {qs: {ver: config_ext.ver}, no_print: true});
  if (res.err)
    return res;
  res.data.chars = Object.entries(res.data.chars).map(([_char_id, _char])=>{
    let order = _char.is_video? 1: 0;
    return {..._char, char_id: _char_id, order};
  });
  res.data.chars = orderBy(['order'], ['desc'], res.data.chars);
  return res.data;
});

E.sgif_start = (token, email, org_id, txt, is_video)=>eserf(function*
sgif_start(){
  if (0)
  {
    return {
      url: 'https://abcai-front-share-prod.s3.amazonaws.com/sgif/cat.gif',
      file: 'ret_7add1c34-358c-4e2c-b36b-7088818e5c25.mp4',
      gid: 'spipe_b9aa47b7-95b3-493a-8250-8c491a9776c3'
    };
  }
  let res;
  // XXX colin/eilam: add debug to choose these in qs
  let runway_id, mid_id;
  res = yield ereq.get(prefix+'/private/sgif/start.json',
    {qs: {email, ver: config_ext.ver, txt,
      runway_id, mid_id, is_video}, headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.sperson_start = (token, email, org_id, {age, ethnic, gender,
  txt})=>eserf(function*
sperson_start(){
  let res;
  // XXX colin/eilam: add debug to choose these in qs
  let sperson_id;
  res = yield ereq.get(prefix+'/private/sperson/start.json',
    {qs: {email, ver: config_ext.ver, txt, age, ethnic, gender, sperson_id},
      headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.simg_start = (token, email, org_id, txt,
  style, size)=>eserf(function* simg_start(){
  let res;
  // XXX colin/eilam: add debug to choose these in qs
  let simg_id;
  res = yield ereq.get(prefix+'/private/simg/start.json',
    {qs: {email, ver: config_ext.ver, txt, simg_id, style, size},
      headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.svoice_start = (token, email, org_id, txt,
  char_id)=>eserf(function* svoice_start(){
  let res;
  // XXX colin/eilam: add debug to choose these in qs
  let svoice_id;
  if (0)
  {
    yield eserf.sleep(3000);
    return {url: 'https://peregrine-results.s3.amazonaws.com/'+
      'pigeon/uvxS0BjizCftyEzIZH_0.mp3'};
  }
  res = yield ereq.get(prefix+'/private/svoice/start.json',
    {qs: {email, ver: config_ext.ver, txt, svoice_id, char_id},
      headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.smusic_start = (token, email, org_id, txt)=>eserf(function* smusic_start(){
  let res;
  // XXX colin/eilam: add debug to choose these in qs
  let smusic_id;
  if (0)
  {
    yield eserf.sleep(3000);
    return {url: 'https://peregrine-results.s3.amazonaws.com/'+
      'pigeon/uvxS0BjizCftyEzIZH_0.mp3'};
  }
  res = yield ereq.get(prefix+'/private/smusic/start.json',
    {qs: {email, ver: config_ext.ver, txt, smusic_id},
      headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.slip_start = (token, email, org_id, simg_url, svoice_url)=>eserf(function*
slip_start(){
  let res = yield ereq.get(prefix+'/private/slip/start.json',
    {qs: {email, ver: config_ext.ver, simg_url, svoice_url},
      headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.ping = ()=>eserf(function* _ping(){
  let res = yield ereq.get(prefix+'/pub/ping.json',
    {qs: {ver: config_ext.ver}, no_print: true});
  if (res.err)
    return res;
  return res.data;
});

