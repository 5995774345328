// LICENSE_CODE ABCAI
import {Row, Col} from 'antd';
import React, {useMemo} from 'react';
import xurl from '../../../util/xurl.js';
import {Video} from './comp.js';
import {get} from 'lodash/fp.js';
import {Helmet} from 'react-helmet';

export const E = ()=>{
  let qs_o = useMemo(()=>xurl.qs_parse(location.search, true), []);
  let url = useMemo(()=>get('url', qs_o), [qs_o]);
  return <>
    <Row>
      <Col xs={{offset: 0, span: 24}} md={{offset: 6, span: 12}}>
        <Video url={url}/>
      </Col>
    </Row>
    <Helmet>
      <meta property="og:video" content={url}/>
      <meta property="og:image" content={url}/>
    </Helmet></>;
};

export default E;
