// LICENSE_CODE ABCAI
import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Row, Col, Button, Divider, Typography, Space, Input} from 'antd';
import {FastForwardOutlined, RobotOutlined,
  DollarCircleOutlined, CheckCircleFilled, PlayCircleOutlined}
  from '@ant-design/icons';
import {Clickable} from './comp.js';

let {Title, Text} = Typography;
let {TextArea} = Input;
const About = ()=>{
  let {t} = useTranslation();
  let [concept, set_concept] = useState('');
  let nav = useNavigate();

  return <>
    <Row data-aos="fade-down" justify="space-around" gutter={[32, 32]}>
      <Col span={{xs: 20, md: 10}}>
        <Row justify="center">
          <Title level={4} justify="center">
            {t('Entertain, inform and inspire')}
          </Title>
        </Row>
        <Row justify="center">
          <Title level={5} justify="center">
            {t('through the power of AI')}
          </Title>
        </Row>
      </Col>
      <Col span={{xs: 24, sm: 24, md: 14, lg: 14}}>
        <iframe width="100%" height="100%" type="text/html"
          src={'https://www.youtube.com/embed/IIZjVz2JEXg?autoplay=1&'+
              'mute=1&showinfo=0&controls=0'}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope;
          picture-in-picture" allowFullScreen>
        </iframe>
      </Col>
    </Row>
    <Row data-aos="fade-up" xdata-aos-anchor-placement="top-center">
      <Col xs={{span: 20, offset: 2}} md={{offset: 3, span: 18}}>
        <Space direction="vertical" size="large">
          <Row style={{textAlign: 'center'}}>
            <Divider/>
            <Title level={4}>
              {t('The next generation of AI content pipelines')}
            </Title>
            <Title level={5}>
              {t('The mission of AbcAi is to entertain, inform and inspire people around the globe through the power of AI')}
            </Title>
          </Row>
          <Row justify="space-around" gutter={[32, 64]}>
            <Col xs={{span: 20}} md={{span: 6}}
              style={{textAlign: 'center'}}>
              <Space direction="vertical" size="large">
                <Title level={5}>{t('Idea to content quickly')}</Title>
                <Text>
                  <FastForwardOutlined style={{fontSize: '500%'}}/>
                </Text>
              </Space>
            </Col>
            <Col xs={{span: 20}} md={{span: 6}}
              style={{textAlign: 'center'}}>
              <Space direction="vertical" size="large">
                <Title level={5}>{t('Reduce manual AI pipeline work')}</Title>
                <Text>
                  <RobotOutlined style={{fontSize: '500%'}}/>
                </Text>
              </Space>
            </Col>
            <Col xs={{span: 20}} md={{span: 6}}
              style={{textAlign: 'center'}}>
              <Space direction="vertical" size="large">
                <Title level={5}>{t('Save money on content creation')}</Title>
                <Text>
                  <DollarCircleOutlined style={{fontSize: '500%'}}/>
                </Text>
              </Space>
            </Col>
          </Row>
        </Space>
      </Col>
    </Row>
    <Row data-aos="fade-up" data-aos-anchor-placement="top-center">
      <Col xs={{span: 20, offset: 2}} md={{span: 18}}>
        <Divider/>
        <Title level={3}>
          {t('Super Pipeline')}
        </Title>
        <Title level={5}>
          {t('Pipeline for producing content at scale + streaming using AI')}
        </Title>
        <Title level={4}>
          {t('Features')}
        </Title>
        <Title level={5}>
          <CheckCircleFilled /> {t('AI Talk Show [News PARODY] - Anchor and 2 pundits (can switch characters)')}
        </Title>
        <Title level={5}>
          <CheckCircleFilled /> {t('Investment Deck Analysis - SharkTank PARODY (includes multiple angle shots)')}
        </Title>
        <Title level={5}>
          <CheckCircleFilled /> {t('Monologue for Shorts')}
        </Title>
        <Title level={5}>
          <CheckCircleFilled /> {t('Q&A - Panel PARDOY - Ask the characters a question and they all reply within the personality. One at a time.')}
        </Title>
        <Title level={5}>
          <CheckCircleFilled /> {t('Memes - Respond to memes with our pipeline in a parody')}
        </Title>
        <br/>
        <Row justify="center">
          <Clickable>
            <Button type="primary" size="large">
              <Link to={{pathname: '/dash', search: location.search}}>
                <Title level={4}>{t('Join the team')}</Title>
              </Link>
            </Button>
          </Clickable>
        </Row>
      </Col>
    </Row>
    <Row data-aos="fade-up" data-aos-anchor-placement="top-center">
      <Col xs={{span: 20, offset: 2}} md={{span: 18}}>
        <Divider/>
        <Title level={1}>
          {t('Join the best')}
        </Title>
        <Title level={5}>
          {t('You are in good hands.')}
        </Title>
        <Title level={4}>
          <Link target="blank" to="https://www.twitch.tv/ai_kim_kardashian">
            AI PARODY LIVE
          </Link>
        </Title>
        <Title level={4}>
          <Link target="blank" to="https://www.youtube.com/@ai_talk_show">
            AI talk show PARODY
          </Link>
        </Title>
        <Title level={4}>
          <Link target="blank" to="https://www.youtube.com/@ai_joe_rogan_2">
            AI Joe Rogan PARODY
          </Link>
        </Title>
      </Col>
    </Row>
    <div><Text style={{color: 'transparent'}}>x</Text></div>
    <div><Text style={{color: 'transparent'}}>x</Text></div>
    <div><Text style={{color: 'transparent'}}>x</Text></div>
    <br/>
  </>;
};

export default About;
