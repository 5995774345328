// LICENSE_CODE ABCAI
import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Col, Row, Avatar, Button, Space, Typography, Switch} from 'antd';
import {JSONTree} from 'react-json-tree';
import comp from './comp.js';
import auth from './auth.js';
import xurl from '../../../util/xurl.js';
import back_app from './back_app.js';
import config_ext from './config_ext.js';

let {Title} = Typography;
export const E = ()=>{
  let {t} = useTranslation();
  let {qs_o} = comp.use_qs();
  let {user, token, user_full, org} = auth.use_auth();
  let name;
  if (user_full && user_full.first && user_full.last)
    name = user_full.first+' '+user_full.last;
  return <Row justify="center">
    <Row>
      <Col offset={1} span={22}>
        <Space direction="vertical" size="large" align="center">
          <Row><Title>{t('PROFILE')}</Title></Row>
          <Row>
            <Col span={{xs: 24, md: 4}}>
              <Avatar src={!config_ext.is_local&&user.picture}/>
            </Col>
            <Col span={{xs: 24, md: 8}}>
              <Title level={3}>{user.email}</Title>
              {name && <Title level={3}>{name}</Title>}
            </Col>
          </Row>
          {!user.email_verified && <Row>
            <Button onClick={()=>back_app.user_email_resend(token, user.email)}>
              {t('Resend verification email')}
            </Button>
          </Row>}
          <Row>
            <Space>
              <Col><Title level={4}>{t('Your current credits')}</Title></Col>
              <Col><Title level={4}>{org?.credit?.v}</Title></Col>
            </Space>
          </Row>
          {false && user.email_verified && <>
            <Row><Title level={2}>{t('Labs experimental features')}</Title>
            </Row>
          </>}
          {qs_o.dbg && <>
            <Row><JSONTree data={user||{}} /></Row>
            <Row><JSONTree data={user_full||{}} /></Row>
          </>}
        </Space>
      </Col>
    </Row>
  </Row>;
};

export default auth.with_auth_req(E);
