// LICENSE_CODE ABCAI
import {Button, Col, Input, Row, Typography, Progress, message,
  Modal, Tooltip} from 'antd';
import {isEmpty, get, map, find, filter, includes, toLower
} from 'lodash/fp.js';
import React, {useMemo, useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import auth from './auth.js';
import eserf from '../../../util/eserf.js';
import back_app from './back_app.js';
import {Loading, Bounce} from './comp.js';
import {DownloadOutlined, ShareAltOutlined,
  CopyOutlined} from '@ant-design/icons';

let {TextArea} = Input;
let {Title, Link, Paragraph} = Typography;
let url2share_url = _url=>{
  return _url;
  //return `${window.location.origin}/vid?url=${_url}`;
};

let _img_url = 'https://abcai-front-share-prod.s3.amazonaws.com/img/larry_david_2.png';
let _voice_url = 'http://localhost:4000/s3/abcai-front-share-local/ret_c75d768e-ba46-4298-9ef7-99551731c8a8.mp3';

export let E = React.memo(()=>{
  let {t} = useTranslation();
  let [messageApi, contextHolder] = message.useMessage();
  let {user, token, org, user_full} = auth.use_auth();
  let [img_url, set_img_url] =useState(_img_url);
  let [voice_url, set_voice_url] =useState(_voice_url);
  let [loading, set_loading] = useState(false);
  let [error, set_error] = useState('');
  let [ret, set_ret] = useState(null);

  let on_submit = ()=>eserf(function* _on_submit(){
    set_loading(true);
    let _ret = yield back_app.slip_start(token, user.email, org?.id, img_url,
      voice_url);
    if (_ret.err)
    {
      set_error(_ret.err);
      set_loading(false);
      return;
    }
    set_loading(false);
    set_ret(_ret);
  });
  let on_share = _url=>{
    let data={
      title: 'ABCAIShow.com lip sync',
      text: 'Check out this amazing lip sync',
      url: url2share_url(_url),
    };
    window.navigator.share && window.navigator.share(data)
      .catch(console.error);
  };
  let on_copy_url = _url=>{
    navigator.clipboard.writeText(url2share_url(_url));
    messageApi.open({
      type: 'success',
      content: t('Link copied to clipboard'),
    });
  };

  if (!token || !user_full)
    return <Loading/>;

  if (error)
    return <Row>{t('Error')} {error}</Row>;

  if (loading)
    return <Slip_loading/>;

  if (!ret)
  {
    return <><Row justify="space-around">
      <Col xs={22} md={16}>
        <Row style={{marginTop: 24}}>
          <Title level={4}>
            {t('Create lipsync videos')}
          </Title>
        </Row>
        <Row>
          <input value={img_url} placeholder="img"
            onChange={e=>set_img_url(e.target.value)} autoComplete="off" />
        </Row>
        <Row>
          <input value={voice_url} placeholder="voice"
            onChange={e=>set_voice_url(e.target.value)} autoComplete="off" />
        </Row>
        <Row style={{marginTop: 16}} align="middle" justify="space-between">
          <Col>
            <Button type="primary" size="large" block
              disabled={isEmpty(img_url) || isEmpty(voice_url)}
              onClick={on_submit}>
              { t('Create lipsync')}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
    </>;
  }

  return <>{contextHolder}
    <Row justify="space-around">
      <Col xs={22} md={16}>
        <Row style={{marginTop: 16}}>
          <Col xs={{span: 20, offset: 2}} md={{span: 16, offset: 4}}>
            <audio controls src={ret.url} autoPlay style={{width: '100%'}} />
          </Col>
        </Row>
        <Row align="center" style={{marginTop: 16}}>
          <Col xs={{span: 2}}>
            <Link href={ret.url} download target="_blank">
              <Button title={t('Download image')} type="primary"
                icon={<DownloadOutlined/>}/>
            </Link>
          </Col>
          <Col xs={{span: 2, offset: 1}}>
            <Button title={t('Share')} type="primary"
              onClick={()=>on_share(ret.url)}
              icon={<ShareAltOutlined/>}/>
          </Col>
          <Col xs={{span: 2, offset: 1}}>
            <Button type="primary" onClick={()=>on_copy_url(ret.url)}
              icon={<CopyOutlined/>} title={t('Copy link')}/>
          </Col>
        </Row>
        <Row style={{marginBlock: 32}}>
          <Col xs={{span: 20, offset: 2}} md={{span: 16, offset: 4}}>
            <Button block type="primary" onClick={()=>set_ret(null)}>
              {t('Start Over')}</Button>
          </Col>
        </Row>
      </Col>
    </Row>
  </>;
});

let Slip_loading = React.memo(()=>{
  let {t} = useTranslation();
  let [percent, set_percent] = useState(5);
  useEffect(()=>{
    let interval_id = setInterval(()=>{
      set_percent(curr=>Math.min(95, curr+3));
    }, 1000);
    return ()=>{
      clearInterval(interval_id);
    };
  }, []);
  return <Row justify="space-around">
    <Col xs={22} md={16}>
      <Row justify="space-around"><Col xs={24} md={12} style={{marginTop: 16}}>
        <Title level={5}>{t('Please wait (10-30 seconds)')}</Title>
        <Progress percent={percent} status="active" showInfo={false}/>
      </Col></Row>
    </Col>
  </Row>;
});



let char_img = 'https://abcai-front-share-prod.s3.amazonaws.com/img';
let Svoice_chars = ({chars, on_select, char_id, txt, loading})=>{
  let {t} = useTranslation();
  let [is_open, set_is_open] = useState(false);
  let [is_tooltip, set_is_tooltip] = useState(false);
  let [search, set_search] = useState('');
  let _chars = useMemo(()=>{
    if (!search)
      return chars;
    let _search = toLower(search);
    return filter(char=>includes(_search, toLower(char.lbl)), chars);
  }, [search, chars]);

  let curr_lbl = useMemo(()=>get('lbl', find(char=>char.char_id === char_id,
    chars)), [chars, char_id]);
  let _on_select=_char_id=>{
    on_select(_char_id);
    set_is_open(false);
  };
  let on_modal_open=()=>{
    set_is_open(true);
    set_is_tooltip(false);
    localStorage.setItem('svoice_did_change_char', 'yes');
  };
  useEffect(()=>{
    if (txt && !localStorage.getItem('svoice_did_change_char'))
      set_is_tooltip(true);
  }, [txt]);

  return <>
    <Tooltip open={is_tooltip && !loading} title={t('Select character')}
      trigger={['click', 'hover', 'focus']} placement="bottomRight"
      color="#1668dc">
      {!loading && <Bounce is_bounce={is_tooltip}>
        <img src={`${char_img}/${char_id}.png`} style={{borderRadius: '100%',
          width: 60, height: 60, objectFit: 'cover', cursor: 'pointer'}}
        onClick={on_modal_open}
        alt={curr_lbl} title={curr_lbl} />
      </Bounce>
      }
      <Modal open={is_open} onOk={_on_select}
        onCancel={()=>set_is_open(false)} okButtonProps={{disabled: true}}>
        <>
          <Row>
            <TextArea rows={1} value={search}
              onChange={e=>set_search(e.target.value)} autoComplete="off"
              placeholder={t('Select or search a character')} bordered={false}
              style={{lineHeight: 2.5, marginBottom: 16}}/>
          </Row>
          <Row gutter={[16, 16]}>
            {map(_char=><Col offset={0} xs={{span: 8}} md={{span: 6}}
              key={_char.lbl}>
              <Tooltip title={_char.lbl}>
                <div onClick={()=>_on_select(_char.char_id)}
                  style={{cursor: 'pointer'}}>
                  <img src={`${char_img}/${_char.img}`}
                    style={{width: 50, borderRadius: 8, marginBottom: 8,
                      objectFit: 'scale-down', height: 50}} />
                  <Paragraph>{_char.lbl}</Paragraph>
                </div>
              </Tooltip>
            </Col>, _chars)}
          </Row>
        </>
      </Modal>
    </Tooltip>
  </>;
};


export default auth.with_auth_req(E);


