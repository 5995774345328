// LICENSE_CODE ABCAI
import {Button, Col, Input, Row, Typography, Progress, message} from 'antd';
import {isEmpty, sample, map} from 'lodash/fp.js';
import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import auth from './auth.js';
import eserf from '../../../util/eserf.js';
import back_app from './back_app.js';
import {Closable, Loading, Video, Task_tooltip, Clickable} from './comp.js';
import {DownloadOutlined, ShareAltOutlined,
  CopyOutlined} from '@ant-design/icons';

let {TextArea} = Input;
let {Title, Link} = Typography;
let url2share_url = _url=>{
  return _url;
  //return `${window.location.origin}/vid?url=${_url}`;
};

let loading_base_url = 'https://abcai-front-share-prod.s3.amazonaws.com/'
  +'sgif/sgif_loading';
export let sgif_loading_videos = [
  'bill_cto_1.mp4',
  'chris_cto_1.mp4',
  'dwight_cto_1.mp4',
  'gordon_cto_1.mp4',
  'kevin_cto_1.mp4',
  'rogan_cto_1.mp4',
  'rogan_cto_2.mp4',
  'russell_cto_1.mp4',
  'trump_cto_1.mp4',
  'kim_cto_1.mp4',
  'zuckerberg_cto_1.mp4',
  'tate_cto_1.mp4',
  'michael_cto_1.mp4',
  'mat_cto_1.mp4',
  'larry_cto_1.mp4',
].map(char=>`${loading_base_url}/${char}`);

export let E = React.memo(()=>{
  let {t} = useTranslation();
  let [messageApi, contextHolder] = message.useMessage();
  let {user, token, org, user_full} = auth.use_auth();
  let [concept, set_concept] =useState('');
  let [loading, set_loading] = useState(false);
  let [sgif_url, set_sgif_url] = useState(null);
  let [error, set_error] = useState('');
  let [tasks, set_tasks] = useState([]);
  useEffect(()=>{
    if (!token || !user_full)
      return;
    let es = eserf(function* _user_task_pub_get_list(){
      let _ret = yield back_app.user_task_pub_get_list(token, user_full.email,
        'sgif');
      if (_ret.err)
      {
        set_error(_ret.err);
        return;
      }
      set_tasks(_ret);
    });
    return ()=>es.return();
  }, [token, user_full]);
  let on_submit = ()=>eserf(function* _on_submit(){
    set_loading(true);
    let _ret = yield back_app.sgif_start(token, user.email, org?.id, concept);
    if (_ret.err)
    {
      set_error(_ret.err);
      set_loading(false);
      return;
    }
    let _url = _ret.url;
    set_loading(false);
    set_tasks(curr=>[_ret, ...curr]);
    set_sgif_url(_url);
  });
  let on_share = _url=>{
    let data={
      title: 'ABCAIShow.com GIF',
      text: 'Check out this amazing GIF',
      url: url2share_url(_url),
    };
    window.navigator.share && window.navigator.share(data)
      .catch(console.error);
  };
  let on_copy_url = _url=>{
    navigator.clipboard.writeText(url2share_url(_url));
    messageApi.open({
      type: 'success',
      content: t('Link copied to clipboard'),
    });
  };

  if (!token || !user_full)
    return <Loading/>;

  if (error)
    return <Row>{t('Error')} {error}</Row>;

  if (loading)
    return <Sgif_loading/>;

  if (!sgif_url)
  {
    return <Row justify="space-around">
      <Col xs={22} md={16}>
        <Row>
          <Title level={4}>
            {t('Create GIFs from your prompts')}
          </Title>
        </Row>
        <Row>
          <TextArea rows={2} value={concept} maxLength={300}
            onChange={e=>set_concept(e.target.value)} autoComplete="off"
            placeholder={t('Monkey sitting on a chair and eating Pasta')}
            style={{lineHeight: 2.5, marginBottom: 16}}/>
        </Row>
        <Row>
          <Button type="primary" size="large" block
            disabled={isEmpty(concept)}
            onClick={on_submit}>
            { t('Create GIF')}
          </Button>
        </Row>
        {!isEmpty(tasks) &&
        <>
          <Row style={{marginTop: 32}}>
            <Title level={4}>{t('My GIFs')}</Title>
          </Row>
          <Row gutter={[12, 12]}>{map(_task=>{
            return <Col xs={{span: 24}} md={{span: 4}} key={_task.url}>
              <Clickable cursor onClick={()=>window.open(_task.url, '_blank')}>
                <Task_tooltip task={_task}>
                  <img src={_task.url} style={{width: '100%', height: 'auto',
                    objectFit: 'contain'}}/></Task_tooltip></Clickable>
            </Col>;}, tasks)}
          </Row>
        </>
        }
      </Col>
    </Row>;
  }

  return <>{contextHolder}
    <Row justify="space-around">
      <Col xs={22} md={12}>
        <Row justify="space-around">
          <Col xs={22} md={16}>
            <Video url={sgif_url}/>
          </Col>
        </Row>
        <Row align="center" style={{marginTop: 16}}>
          <Col xs={{span: 2}}>
            <Link href={sgif_url} download target="_blank">
              <Button title={t('Download GIF')} type="primary"
                icon={<DownloadOutlined/>}/>
            </Link>
          </Col>
          <Col xs={{span: 2, offset: 1}}>
            <Button title={t('Share')} type="primary"
              onClick={()=>on_share(sgif_url)}
              icon={<ShareAltOutlined/>}/>
          </Col>
          <Col xs={{span: 2, offset: 1}}>
            <Button type="primary" onClick={()=>on_copy_url(sgif_url)}
              icon={<CopyOutlined/>} title={t('Copy link')}/>
          </Col>
        </Row>
        <Row style={{marginTop: 32}}>
          <Button block type="primary" onClick={()=>set_sgif_url(null)}>
            {t('Start Over')}</Button>
        </Row>
      </Col>
    </Row>
  </>;
});

export let Sgif_loading = React.memo(()=>{
  let {t} = useTranslation();
  let [percent, set_percent] = useState(5);
  let [vid] = useState(sample(sgif_loading_videos));
  let [is_show_waiting_vid, set_is_show_waiting_vid] = useState(true);
  useEffect(()=>{
    let _is_hide_waiting_vid = localStorage.getItem(
      'sgif_is_hide_waiting_vid')||
      false;
    set_is_show_waiting_vid(!_is_hide_waiting_vid);
  }, []);
  useEffect(()=>{
    let interval_id = setInterval(()=>{
      if (percent<=95)
        set_percent(curr=>curr+1);
    }, 1000);
    return ()=>{
      clearInterval(interval_id);
    };
  }, []);
  let on_hide_waiting_vid=()=>{
    localStorage.setItem('sgif_is_hide_waiting_vid', 1);
    set_is_show_waiting_vid(false);
  };
  return <Row justify="space-around">
    <Col xs={22} md={16}>
      <Row justify="space-around">
        <Col xs={24} md={12}>
          {is_show_waiting_vid && vid && <Closable tooltip={t('Hide video')}
            on_close={on_hide_waiting_vid}>
            <Video url={vid}
              style={{maxHeight: '50vh'}}/>
          </Closable>}
        </Col>
      </Row>
      <Row justify="space-around"><Col xs={24} md={12} style={{marginTop: 16}}>
        <Title level={5}>{t('Please wait (30-120 seconds)')}</Title>
        <Progress percent={percent} status="active" showInfo={false}/>
      </Col></Row>
    </Col>
  </Row>;
});

export default auth.with_auth_req(E);

