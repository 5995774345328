// LICENSE_CODE MIT
/* eslint-disable */
export default {
  "is_sbase": true,
  "sbase": {
    "url": "https://rngvtndhlzodcqszxhfj.supabase.co",
    "anon_pub": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJuZ3Z0bmRobHpvZGNxc3p4aGZqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTEwMTUwMzcsImV4cCI6MjAyNjU5MTAzN30.UGTks2IVdq3_7xHZA4b5Bw52aflnDREIK8w4iqBdfVQ",
    "is_google": false,
    "is_email_corp": true
  },
  "front": {
    "company_lbl": "AbcAiArt",
    "url": "https://abcaiart.com",
    "email": "support@abcaiart.com",
    "phone": "+18777755232",
    "stream": {
      "url": "http://localhost:3000"
    }
  },
  "back": {
    "stream": {},
    "app": {
      "url": "https://api.abcaiart.com"
    }
  },
  "sentry": {
    "front": {
      "dsn": "https://cb0f4357a52968cd8c7c561ab2add5e0@o4506851424927744.ingest.sentry.io/4506851441246208"
    }
  },
  "auth0": {
    "domain": "abcaishow.us.auth0.com",
    "client_id": "oCpcH2WZYT0TnpuvQQ4feTvnsofRf2FY",
    "audience": "https://abcaishow.us.auth0.com/api/v2/"
  },
  "is_b2b": true,
  "domain": "abcaiart.com",
  "is_prod": true,
  "ver": "1.0.17",
  "is_local": false,
  "stage": "prod",
  "bucket_ext": "abcaiart-front-share-prod"
}