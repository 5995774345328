// LICENSE_CODE ABCAI
import {Button, Col, Input, Row, Space, Typography,
  message, Spin} from 'antd';
import {isEmpty, get, map, find, trim, size, last, flow, sample, sampleSize,
} from 'lodash/fp.js';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import auth from './auth.js';
import eserf from '../../../util/eserf.js';
import back_app from './back_app.js';
import {Loading, Video, Bounce} from './comp.js';
import {SendOutlined, VideoCameraOutlined, DownloadOutlined, ShareAltOutlined,
  CopyOutlined} from '@ant-design/icons';
import xurl from '../../../util/xurl.js';
import {useNavigate} from 'react-router-dom';

let {Text, Title, Link} = Typography;
let default_char_id = 'joe_rogan';
let char_img_to_url = _char_img=>
`https://abcai-front-share-prod.s3.amazonaws.com/img/${_char_img}`;
let char_video_file_to_url = video_file=>
  `https://abcai-front-share-prod.s3.amazonaws.com/${video_file}`;

export const E = ()=>{
  let {t} = useTranslation();
  let navigate = useNavigate();
  let [messageApi, contextHolder] = message.useMessage();
  let {user, token, org, user_full} = auth.use_auth();
  let [loading_txt, set_loading_txt] = useState(false);
  let [loading_vid, set_loading_vid] = useState(false);
  let [vid, set_vid] = useState(null);
  let [error, set_error] = useState('');
  let [chars, set_chars] = useState([]);
  let [chat, set_chat] = useState([]);
  let chat_el_ref = useRef(null);
  let [val, set_val] = useState('');
  let qs_o = useMemo(()=>xurl.qs_parse(location.search, true), []);
  let char_id = useMemo(()=>get('char_id', qs_o), [qs_o]);
  let char = useMemo(()=>find(_char=>_char.char_id === char_id, chars),
    [chars, char_id]);
  useEffect(()=>{
    if (!char_id)
      navigate('?char_id='+default_char_id);
  }, [char_id, navigate]);
  useEffect(()=>{
    if (!char)
      return;
    let greeting = flow(
      get('greetings_new'),
      sample,
    )(char);
    let greeting_vid = get('video_file', greeting);
    let greeting_txt = get('txt', greeting);
    set_chat([{name: char.lbl, txt: greeting_txt,
      img: char_img_to_url(char.img), is_char: true,
      video_file: greeting_vid}]);
    set_vid({url: greeting_vid});
  }, [char, t]);

  useEffect(()=>{
    let es = eserf(function* _get_chars_use_effect(){
      let _ret = yield back_app.produce_char_get();
      if (_ret.err)
      {
        set_error(_ret.err);
        return;
      }
      set_chars(_ret.chars);
    });
    return ()=>es.return();
  }, []);
  let [did_click_create_vid, set_did_click_create_vid] = useState(false);
  let [did_click_on_suggestion, set_did_click_on_suggestion]=useState(true);
  useEffect(()=>{
    set_did_click_on_suggestion(
      localStorage.getItem('did_click_on_suggestion'));
    set_did_click_create_vid(
      localStorage.getItem('did_click_create_vid'));
  }, []);

  let on_example_selected=_example=>{
    localStorage.setItem('did_click_on_suggestion', 'true');
    set_did_click_on_suggestion(true);
    let user_msg = {name: user.nickname || user.name, img: user.picture,
      txt: _example.txt, is_user: true};
    set_loading_txt(true);
    set_chat([user_msg]);
    setTimeout(()=>{
      let char_msg = {name: char.lbl, txt: _example.reply,
        img: char_img_to_url(char.img), is_char: true,
        video_file: _example.video_file};
      set_chat(curr=>[...curr, char_msg]);
      set_loading_txt(false);
      set_loading_vid(true);
      setTimeout(()=>{
        set_loading_vid(false);
        set_vid({url: _example.video_file});
      }, 2000);
    }, 2000);
  };
  let has_data = useMemo(()=>!isEmpty(chars), [chars]);
  let on_submit = prompt=>eserf(function* _on_submit(){
    set_loading_txt(true);
    let user_msg = {name: user.nickname || user.name, img: user.picture,
      txt: prompt, is_user: true};
    set_chat(curr=>[...curr, user_msg]);
    let gid = get('gid', last(chat));
    let prompt_id;
    let _ret;
    if (!gid)
    {
      _ret = yield back_app.schar_start(token, user.email, org?.id, prompt,
        char_id);
      gid = _ret.gid;
      prompt_id = _ret.prompt_id;
    }
    else
    {
      _ret = yield back_app.schar_continue(token, user.email, org?.id,
        prompt, gid, char_id);
      prompt_id = _ret.prompt_id;
    }
    if (_ret.err)
    {
      set_error(_ret.err);
      return;
    }
    let char_msg = {is_char: true, name: char.lbl, txt: _ret.txt, img:
      char_img_to_url(char.img), gid, prompt_id};
    set_chat(curr=>[...curr, char_msg]);
    set_loading_txt(false);
    if (_ret.url)
    {
      set_vid({url: _ret.url});
      char_msg.video_file=_ret.url;
    }
    let scroll = ()=>chat_el_ref.current?.scrollIntoView({
      behavior: 'smooth', block: 'end'});
    setTimeout(()=>{
      scroll();
      setTimeout(scroll, 50);
    }, 0);
  });
  let on_create_vid = msg=>eserf(function* _on_create_vid(){
    if (loading_vid)
      return;
    if (!char.is_video)
    {
      messageApi.open({
        type: 'error',
        content: t('This character cannot create videos for now'),
      });
      return;
    }
    localStorage.setItem('did_click_create_vid', 'true');
    set_did_click_create_vid(true);
    set_loading_vid(true);
    let _ret = yield back_app.schar_video(token, user.email, org?.id,
      msg.prompt_id, char_id, msg.txt, msg.gid);
    set_loading_vid(false);
    if (_ret.err)
    {
      set_error(_ret.err);
      return;
    }
    set_chat(_chat=>map(_msg=>{
      if (_msg === msg)
        return {..._msg, video_file: _ret.url};
      return _msg;
    }, _chat));
    set_vid({url: _ret.url, type: 'reply'});
  });
  let on_vid_end = ()=>{
    let url = char_video_file_to_url(char.video_file);
    set_vid({url, type: 'silent'});
  };

  if (!token || !has_data || !user_full)
    return <Loading/>;

  if (!char)
    return null;

  return <>
    {contextHolder}
    <Row style={{height: '30vh', position: 'relative'}}>
      <Video url={get('url', vid)} loop={get('type', vid) === 'silent'}
        style={{width: '100%', height: '100%', objectFit: 'contain'}}
        on_end={on_vid_end}/>
      {loading_vid && <div style={{position: 'absolute', top: '50%',
        left: '50%', transform: 'translate(-50%, -50%)', display: 'flex',
        alignItems: 'center', flexDirection: 'column'}}>
        <Spin size="large" style={{marginBottom: 16}}/>
        <Text>{t('Generating your video')}</Text>
      </div>}
      {!loading_vid && get('type', vid) === 'reply' &&
        <Vid_buttons url={get('url', vid)} msg_api={messageApi}/>}
    </Row>
    <Row style={{height: 'calc(68vh - 114px)', //todo: fix,
      overflowY: 'auto'}}>
      <Col span={24} ref={chat_el_ref}>
        <Row data-aos="fade-up">
          <Col offset={1}>
            <div style={{minHeight: 50}}>
              {get('type', vid) !== 'reply' && <Text>
                {t('Welcome to the chat room with')} {get('lbl', char)}
              </Text>}
            </div>
          </Col>
        </Row>
        <Row style={{paddingBottom: 20}}>
          <Col span={22} offset={1}>
            {chat.map((msg, index)=>{
              let is_last = index === size(chat) - 1;
              let is_first = index === 0;
              return <React.Fragment key={index/* todo: fix */}>
                <Row
                  style={{marginTop: is_first ? 0: 24}}>
                  <Char_img_col img={msg.img}/>
                  <Col offset={1} span={16}>
                    <Text style={{color: 'white'}}>{msg.txt}</Text>
                  </Col>
                </Row>
                {msg.is_char && is_last && !msg.video_file && <>
                  <Row style={{marginTop: 16}}>
                    <Bounce style={{width: '100%'}}
                      is_bounce={!did_click_create_vid}>
                      <Button block type="primary"
                        icon={<VideoCameraOutlined/>}
                        onClick={()=>on_create_vid(msg)} loading={loading_vid}
                      >{t('Create video')}</Button>
                    </Bounce>
                  </Row>
                  {!loading_vid && <Row>
                    <Text type="success">
                      {t('Click this button in order to generate a video '
                        +'from this response')}</Text></Row>}
                </>}
              </React.Fragment>;})}
            {loading_txt && <Row style={{marginTop: 24}}>
              <Char_img_col img={char_img_to_url(char.img)}/>
              <Col offset={1}>
                <Spin/>
              </Col>
            </Row>}
            {size(chat)<=1 && !did_click_on_suggestion && <Examples char={char}
              on_select={on_example_selected}/>}
            {did_click_on_suggestion && <div
              style={{position: 'fixed', bottom: '2vh', left: 0, right: 0,
                zIndex: 99999999}}>
              <Row><Col xs={{span: 22, offset: 1}}>
                <Input_box on_submit={on_submit}
                  loading={loading_txt} val={val} set_val={set_val}
                  disabled={loading_txt}/>
              </Col></Row>
            </div>}
          </Col>
        </Row>
      </Col>
    </Row>
  </>;
};

let Char_img_col = React.memo(({img})=>{
  return <Col xs={{span: 3}} md={{span: 2}} lg={{span: 1}}>
    <img src={img}
      style={{width: 50, borderRadius: 8, marginBottom: 8,
        objectFit: 'scale-down', height: 50}} />
  </Col>;
});

let Input_box=({on_submit, loading, val, set_val, disabled})=>{
  let {t} = useTranslation();
  let ref = useRef(null);
  let _on_submit=()=>{
    if (loading || disabled)
      return;
    let _val = trim(val);
    set_val('');
    if (isEmpty(_val))
      return;
    on_submit(_val);
  };
  useEffect(()=>{
    ref.current?.focus();
  }, []);
  return <Space.Compact style={{width: '100%', height: 50}}>
    <Input bordered={false} value={val} onChange={e=>set_val(e.target.value)}
      autoComplete="off" placeholder={t('Say something')} ref={ref}
      onPressEnter={_on_submit} className="schar-input"/>
    {!isEmpty(val) && <Button type="primary" onClick={_on_submit}
      icon={<SendOutlined/>} loading={loading}
      style={{height: '100%', minWidth: 60}}
    />}
  </Space.Compact>;
};

let Vid_buttons=React.memo(({url, msg_api})=>{
  let {t} = useTranslation();
  let on_share = ()=>{
    let data={
      title: 'AbcAiShow.com video',
      text: 'AbcAiShow.com video',
      url,
    };
    window.navigator.share && window.navigator.share(data)
      .catch(console.error);
  };
  let on_copy_url = ()=>{
    navigator.clipboard.writeText(url);
    msg_api.open({
      type: 'success',
      content: t('Link copied to clipboard'),
    });
  };
  return <div style={{position: 'absolute', bottom: 0, left: '50%',
    transform: 'translate(-50%, 100%)', zIndex: 999999}}>
    <Row gutter={[24, 24]}>
      <Col xs={{span: 14}}>
        <Button type="primary"
          title={t('Share video')}
          onClick={on_share}
          icon={<ShareAltOutlined/>}>
          {t('Share video')}
        </Button>
      </Col>
      <Col xs={{span: 2}}>
        <Link href={url} download target="_blank">
          <Button type="primary" icon={<DownloadOutlined/>}
            title={t('Download')}>
          </Button>
        </Link>
      </Col>
      <Col xs={{span: 2, offset: 1}}>
        <Button type="primary" onClick={on_copy_url} title={t('Copy link')}
          icon={<CopyOutlined/>}>
        </Button>
      </Col>
    </Row>
  </div>
});

let Examples=({char, on_select})=>{
  let {t} = useTranslation();
  let examples = useMemo(()=>flow(
    get('example_questions_new'),
    sampleSize(3),
  )(char) || [], [char]);

  if (isEmpty(examples))
    return;

  return <div data-aos="fade-up" data-aos-once="true" data-aos-delay="800">
    <Row style={{marginTop: 24}}>
      <Title level={4}>{t('Click on one of these messages')}</Title>
    </Row>
    <Row>
      <Space direction="vertical">
        {map(example=><Button key={example.txt} type="primary" level={4}
          onClick={()=>on_select(example)} style={{whiteSpace: 'normal',
            height: 'auto'}}>
          {example.txt}
        </Button>
        , examples)}
      </Space>
    </Row>
  </div>;
};

export default auth.with_auth_req(E);

