// LICENSE_CODE ABCAI
import React, {useState, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Grid, Row, Col, Typography, Modal, Button} from 'antd';
import {useNavigate} from 'react-router-dom';
import {Clickable, Video, Task_tooltip} from './comp.js';
import auth from './auth.js';
import eserf from '../../../util/eserf.js';
import back_app from './back_app.js';
import {filter, get, flow, shuffle, take} from 'lodash/fp.js';

let {Title} = Typography;
let {useBreakpoint} = Grid;
const Land_b2b = ()=>{
  let {t} = useTranslation();
  let nav = useNavigate();
  let {signup, is_auth} = auth.use_auth_ext();
  let [show_login_modal, set_show_login_modal] = useState(false);
  let [error, set_error] = useState(null);
  let screens = useBreakpoint();
  let imgs = [{
    type: 'simg',
    url: 'https://abcaiart-front-share-prod.s3.amazonaws.com/simg_ret_2fbfce7d-9425-440d-8bc1-db9d2a646c3a.png',
  }, {
    type: 'simg',
    url: 'https://abcaiart-front-share-prod.s3.amazonaws.com/simg_ret_a9df2106-4398-466d-a4d0-b41f27c08144.png',
  }, {
    type: 'simg',
    url: 'https://abcaiart-front-share-prod.s3.amazonaws.com/simg_ret_66d0271d-5612-4e62-b450-1f5787effb46.png'
  }];
  let vids = [{
    type: 'svid',
    url: 'https://abcaiart-front-share-prod.s3.amazonaws.com/sgif_ret_69aee959-3af3-4a75-9cf8-28863bffd9fb.mp4',
  }, {
    type: 'svid',
    url: 'https://abcaiart-front-share-prod.s3.amazonaws.com/sgif_ret_1aa65f70-5151-402d-bbc3-31d03fb9a83d.mp4',
  }, {
    type: 'svid',
    url: 'https://abcaiart-front-share-prod.s3.amazonaws.com/sgif_ret_15d6eabb-24f0-4865-a51c-363d91a1d00b.mp4',
  }];
  let num_media = useMemo(()=>{
    if (get('md', screens))
      return 6;
    return 3;
  }, [screens]);
  // XXX: refactor this
  let _imgs = useMemo(()=>flow(
    filter(_task=>get('type', _task) === 'simg'),
    take(num_media),
    //shuffle,
  )(imgs), [imgs, num_media]);
  let _vids = useMemo(()=>flow(
    filter(_task=>get('type', _task) === 'svid'),
    take(num_media),
    //shuffle,
  )(vids), [vids, num_media]);
  let on_feature_click=page=>{
    if (!is_auth)
      set_show_login_modal(page);
    else
      nav('/'+page);
  };
  let sizes = {
    xs: {span: 22, offset: 1},
    md: {span: 22, offset: 1},
  };
  let img_col = {
    xs: {span: 24},
    sm: {span: 18},
    md: {span: 16},
    lg: {span: 12}
  };

  if (error)
    return <div>{t('Error')}</div>;

  return <>
    <Row ><Col>
      <Row style={{marginTop: 32}}>
        <Col {...sizes}>
          <Title level={1}>
            {t('We create AI tools for content creators')}
          </Title>
        </Col>
      </Row>
      <Row style={{paddingTop: 64}}>
        <Col {...sizes}>
          <Row>
            <Header number={1} title={t('Create images from your prompts')}
              on_click={()=>on_feature_click('simg')}
            />
          </Row>
          <Row>
            <Col {...img_col}>
              <Row gutter={[12, 12]}>
                {_imgs.map(_img=>{
                  return <Col xs={{span: 24}}
                    md={{span: 8}} key={get('_id', _img)}>
                    <Clickable cursor onClick={()=>on_feature_click('simg')}>
                      <Task_tooltip task={_img}>
                        <img src={get('url', _img)}
                          style={{width: '100%', height: 'auto',
                            borderRadius: 16}}/></Task_tooltip>
                    </Clickable></Col>;
                })}
              </Row><Row style={{marginTop: 16}}>
                <Button type="primary" size="large" block
                  onClick={()=>on_feature_click('simg')}
                >{t('Create image')}</Button>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        style={{paddingTop: 128}}>
        <Col {...sizes}>
          <Row>
            <Header number={2}
              title={t('Create short videos from your prompts')}
              on_click={()=>on_feature_click('svid')}
            />
          </Row>
          <Row>
            <Col {...img_col}>
              <Row gutter={[12, 12]}>
                {_vids.map(_vid=><Col
                  xs={{span: 24}} md={{span: 8}} key={_vid.url}>
                  <Clickable cursor onClick={()=>on_feature_click('svid')}>
                    <Video auto_play={false} src={_vid.url}/>
                  </Clickable>
                </Col>)}
              </Row>
              <Row style={{marginTop: 16}}>
                <Button type="primary" size="large"
                  onClick={()=>on_feature_click('svid')} block
                >{t('Create video')}</Button>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
    </Row>
    <Modal
      title={t('You need to signup for free in order to use our tools')}
      open={!!show_login_modal}
      okText={t('Signup FREE')} cancelText={t('Cancel')}
      onOk={()=>{
        signup('/'+show_login_modal);
      }}
      onCancel={()=>set_show_login_modal(null)}>
    </Modal>
  </>;
};

let Header = React.memo(({title, number, on_click})=>{
  return <Row><Clickable cursor onClick={on_click}>
    <Title level={3}><span style={{fontSize: '150%'}}
    >{number}</span>. {title}</Title></Clickable>
  </Row>;
});


export default Land_b2b;


