// LICENSE_CODE MIT
import React from 'react';
import {Route, Link, useLocation, useNavigate, Navigate}
  from 'react-router-dom';
import {Layout, Menu, Avatar, Row, Col, Affix, Button, Tooltip, Typography,
  Space} from 'antd';
import Icon, {VideoCameraAddOutlined, InfoCircleOutlined,
  ReadOutlined, ContactsOutlined, MenuOutlined, CreditCardOutlined,
  PictureOutlined, TeamOutlined, FileGifOutlined,
  SmileOutlined, InsertRowBelowOutlined, YoutubeOutlined,
  SoundOutlined} from '@ant-design/icons';
import Slide_routes from 'react-slide-routes';
import {useTranslation} from 'react-i18next';
import country2flag from 'country-flag-icons/react/3x2';
import ISO6391 from 'iso-639-1';
import AOS from 'aos';
import 'aos/dist/aos.css';
import About from './about.js';
import Land_simg from './land_simg.js';
import Land_b2b from './land_b2b.js';
import Land_schar from './land_schar.js';
import Vid from './vid.js';
import Profile from './profile.js';
import Spipe from './spipe.js';
import Gallery from './gallery.js';
import Svoice from './svoice.js';
import Slip from './slip.js';
import Sgif from './sgif.js';
import Svid from './svid.js';
import Simg from './simg.js';
import Sperson from './sperson.js';
import Gift_card from './gift_card.js';
import Schar from './schar.js';
import Schar_v2 from './schar_v2.js';
import Pay from './pay.js';
import Privacy from './privacy.js';
import Plan from './plan.js';
import Contact from './contact.js';
import Onboard from './onboard.js';
import Blog from './blog.js';
import Sponsor, {Sponsor_banner} from './sponsor.js';
import Email_verif from './email_verif.js';
import Tos from './tos.js';
import Disclaimer from './disclaimer.js';
import auth from './auth.js';
import Chat from './chat.js';
import './app.css';
import xurl from '../../../util/xurl.js';
import logo from './assets/logo.png';
import {Clickable, Loading, Scroll_to_top, use_count_views, use_qs}
  from './comp.js';
import config_ext from './config_ext.js';
import je from '../../../util/je.js';
import metric from './metric.js';
import 'animate.css';

let is_main = !config_ext.is_b2b && !config_ext.is_schar;
let is_dark = is_main;
AOS.init();
let {Text} = Typography;
let supp_lngs = {
  en: {country: 'gb'}, fr: {country: 'fr'}, de: {country: 'de'},
  it: {country: 'it'}, hi: {country: 'in'}, bn: {country: 'bd'},
  zh: {country: 'cn'}, es: {country: 'es'}, pl: {country: 'pl'},
  pt: {country: 'pt'}, nl: {country: 'nl'}, sv: {country: 'se'},
  hu: {country: 'hu'}, da: {country: 'dk'}, id: {country: 'id'},
  ru: {country: 'ru'}, tr: {country: 'tr'}, no: {country: 'no'},
  el: {country: 'gr'}, ar: {country: 'sa', is_rtl: true}, ko: {country: 'kr'},
  vi: {country: 'vn'}, he: {country: 'il', is_rtl: true},
};
let lng2flag = (lng, is_txt)=>{
  let _lng = supp_lngs[lng];
  let Flag = country2flag.GB;
  if (_lng)
    Flag = country2flag[_lng.country.toUpperCase()];
  else if (lng)
    metric.error('missing language', lng);
  return <><Space><Flag style={{height: '24px'}}/>{is_txt
    && <Text>{ISO6391.getNativeName(lng)}</Text>}</Space></>;
};

let i18n2lng = language=>{
  if (!language)
    return 'en';
  let ls = language.toLowerCase().split('-');
  let lng = ls[0];
  let country;
  if (ls.length>=2)
    country = ls[1]; // eslint-disable-line no-unused-vars
  if (lng=='nb')
    return 'no';
  return lng;
};

const Navbar = ()=>{
  let {t, i18n} = useTranslation();
  let navigate = useNavigate();
  let {qs_o, search, pathname} = use_qs();
  let with_onboard = {'/simg': true, '/sgif': true, '/sperson': true,
    '/schar': true, '/svoice': true, '/spipe': true, '/svid': true,
    '/c': true};
  const {user, is_auth, login, signup, logout} = auth.use_auth_ext();
  // XXX colin: causes crash when not logged in
  //let {org} = auth.use_auth();
  let org = {};
  const [current, set_current] = React.useState(pathname);
  const [lng, lng_set] = React.useState(i18n2lng(i18n.language));
  let [nav, nav_set] = React.useState();
  je.set('app.pathname', pathname);
  React.useEffect(()=>{
    if (!nav)
      return;
    navigate(nav);
    nav_set(null);
  }, [nav, navigate]);
  React.useEffect(()=>{
    set_current(pathname);
  }, [pathname]);
  React.useEffect(()=>{
    lng_set(i18n2lng(i18n.language));
  }, [i18n]);
  React.useEffect(()=>{
    i18n.changeLanguage(lng);
    let _lng = supp_lngs[lng];
    // handling missing _lng in flag code
    je.set('app.is_rtl', !!_lng?.is_rtl);
  }, [lng, i18n]);
  React.useEffect(()=>{
    let listeners = [];
    listeners.push(je.on(['auth.is_onboard', 'app.pathname'], ()=>{
      let _pathname = je.get('app.pathname');
      let is_onboard = je.get('auth.is_onboard');
      if (is_onboard && _pathname=='/onboard')
      {
        nav_set(xurl.url('/', qs_o));
        return;
      }
      if (is_onboard || is_onboard===undefined || _pathname=='/onboard')
        return;
      if (with_onboard[_pathname])
        nav_set(xurl.url('/onboard', qs_o));
    }));
    listeners.push(je.on('auth.is_redirect_signup', ()=>{
      let is_redirect_signup = je.get('auth.is_redirect_signup');
      if (is_redirect_signup)
      {
        // XXX colin: change is_redirect -> return_to which can be true
        qs_o.return_to = is_redirect_signup;
        je.delete('auth.is_redirect_signup');
        return void nav_set(xurl.url('/signup', qs_o));
      }
    }));
    listeners.push(je.on('auth.return_to', ()=>{
      let return_to = je.get('auth.return_to');
      if (return_to)
      {
        // XXX colin: change is_redirect -> return_to which can be true
        je.delete('auth.return_to');
        return void nav_set(xurl.url(return_to));
      }
    }));
    listeners.push(je.on(['auth.is_email_verif', 'app.pathname'], ()=>{
      let _pathname = je.get('app.pathname');
      let is_email_verif = je.get('auth.is_email_verif');
      if (is_email_verif && _pathname=='/email_verif')
      {
        nav_set(xurl.url('/', qs_o));
        return;
      }
      if (is_email_verif || is_email_verif===undefined
        || _pathname=='/email_verif')
      {
        return;
      }
      nav_set(xurl.url('/email_verif', qs_o));
    }));
    return ()=>je.off(listeners);
  }, []);
  let menu_lngs = [];
  let icon_flag_props = {};
  for (let _lng in supp_lngs)
  {
    menu_lngs.push({
      label: <Clickable><Icon {...icon_flag_props} onClick={()=>lng_set(_lng)}
        component={()=>lng2flag(_lng, true)}/></Clickable>,
      key: 'lng_'+_lng,
    });
  }
  let icon_props = {style: {fontSize: '150%'}};
  const logout_with_redirect = ()=>logout({
    returnTo: window.location.origin+search,
    //return_to: window.location.origin+search,
  });

  let on_share=()=>{
    let data={
      title: 'ABCAIShow.com',
      text: t('Check out this cool AI text to video tool'),
      url: window.location.origin,
    };
    window.navigator.share && window.navigator.share(data)
      .catch(console.error);
  };

  let on_click = ({key, keyPath, domEvent})=>{
    set_current(key);
    if (key=='/logout')
      return void logout_with_redirect({});
    if (key=='/login')
    {
      if (config_ext.is_sbase)
        nav_set(xurl.url(key, qs_o));
      return void login();
    }
    if (key=='/signup')
    {
      if (config_ext.is_sbase)
        return void nav_set(xurl.url(key, qs_o));
      return void signup();
    }
    if (key=='/share')
      return void on_share();
    if (!key.startsWith('/'))
      return;
    nav_set(xurl.url(key, qs_o));
  };
  let _logo = user?.picture!=org?.logo_url&&org?.logo_url||logo;
  let logo_style = {borderRadius: '100%'};
  if (_logo!=logo)
    logo_style.filter = 'invert(1)';
  let items = [{
    label: <Clickable>
      <Tooltip placement="bottom" title={t('Home')}>
        <Link to={{pathname: '/', search}}>
          <img src={_logo} alt="logo" width="48" style={logo_style}/>
        </Link>
      </Tooltip>
    </Clickable>,
  }, {
    disabled: config_ext.is_schar,
    label: <Clickable><Tooltip placement="bottom" title={t('Super Image')}>
      <Link
        to={{pathname: '/simg', search}}>
        <PictureOutlined {...icon_props}/></Link></Tooltip></Clickable>,
    key: '/simg',
  }, {
    disabled: !is_main,
    label: <Clickable><Tooltip placement="bottom" title={t('Super GIF')}>
      <Link
        to={{pathname: '/sgif', search}}>
        <FileGifOutlined {...icon_props}/></Link></Tooltip></Clickable>,
    key: '/sgif',
  }, {
    disabled: !is_main,
    label: <Clickable><Tooltip placement="bottom" title={t('Super Person')}>
      <Link
        to={{pathname: '/sperson', search}}>
        <SmileOutlined {...icon_props}/></Link></Tooltip></Clickable>,
    key: '/sperson',
  }, {
    disabled: !is_main,
    label: <Clickable><Tooltip placement="bottom" title={t('Super Character')}>
      <Link
        to={{pathname: '/schar', search}}>
        <TeamOutlined {...icon_props}/></Link></Tooltip></Clickable>,
    key: '/schar',
  }, {
    disabled: !is_main,
    label: <Clickable><Tooltip placement="bottom" title={t('Super Voice')}>
      <Link
        to={{pathname: '/svoice', search}}>
        <SoundOutlined {...icon_props}/></Link></Tooltip></Clickable>,
    key: '/svoice',
  }, {
    disabled: config_ext.is_schar,
    label: <Clickable><Tooltip placement="bottom" title={t('Super Video')}>
      <Link
        to={{pathname: '/svid', search}}>
        <YoutubeOutlined {...icon_props}/></Link></Tooltip></Clickable>,
    key: '/svid',
  }, {
    disabled: !is_main,
    label: <Clickable><Tooltip placement="bottom" title={t('Super Pipeline')}>
      <Link
        to={{pathname: '/spipe', search}}>
        <VideoCameraAddOutlined {...icon_props}/></Link></Tooltip></Clickable>,
    key: '/spipe',
  }, {
    disabled: !is_main,
    label: <Clickable><Tooltip placement="bottom" title={t('Gallery')}>
      <Link
        to={{pathname: '/gallery', search}}>
        <InsertRowBelowOutlined {...icon_props}/></Link></Tooltip></Clickable>,
    key: '/gallery',
  }, {
    label: <Clickable><Link to={{pathname: '/onboard', search}}>
      {t('Onboard')}</Link></Clickable>, key: '/onboard', disabled: true,
  }, {
    disabled: true,
    label: <Clickable><Tooltip placement="bottom" title={t('Blog')}><Link
      to={{pathname: '/blog', search}}>
      <ReadOutlined {...icon_props}/>
    </Link></Tooltip></Clickable>, key: '/blog',
  }, {
    disabled: !config_ext.is_b2b,
    label: <Clickable><Tooltip placement="bottom" title={t('Plans')}><Link
      to={{pathname: '/plan', search}}>
      <CreditCardOutlined {...icon_props}/>
    </Link></Tooltip></Clickable>, key: '/plan',
  }, {
    disabled: true,
    label: <Clickable><Tooltip placement="bottom" title={t('About')}><Link
      to={{pathname: '/about', search}}>
      <InfoCircleOutlined {...icon_props}/>
    </Link></Tooltip></Clickable>, key: '/about',
  }, {
    disabled: !config_ext.is_b2b,
    label: <Clickable><Tooltip placement="bottom" title={t('Contact Us')}><Link
      to={{pathname: '/contact', search}}>
      <ContactsOutlined {...icon_props}/>
    </Link></Tooltip></Clickable>, key: '/contact',
  }, {
    label: <Clickable><Button type="dashed">{t('Login')}</Button></Clickable>,
    key: '/login', disabled: is_auth
  }, {
    label: <Clickable><Button type="primary">
      {t('Signup Free')}</Button></Clickable>, key: '/signup',
    disabled: is_auth
  }, {
    label: <Avatar src={!config_ext.is_local&&(user||{}).picture}
      alt="Profile" style={{marginTop: '-5px'}}/>,
    disabled: !is_auth,
    children: [{
      label: (user||{}).name, type: 'group'
    }, {
      label: <Clickable><Link to={{pathname: '/profile', search}}>
        {t('Profile')}</Link></Clickable>,
      key: '/profile', disabled: !is_auth
    }, {
      label: <Clickable>{t('Logout')}</Clickable>, key: '/logout',
    }]}, {
    label: <Icon {...icon_flag_props} component={()=>lng2flag(lng, false)}/>,
    key: 'lng',
    children: menu_lngs,
  }, {
    label: <Clickable><Button block>{t('Share')}</Button>
    </Clickable>,
    key: '/share',
  }];
  for (let i in items)
  {
    let item = items[i];
    if (item.disabled)
      delete items[i];
  }
  return (
    <Menu className={is_dark ? 'menu-dark': ''}
      overflowedIndicator={<MenuOutlined {...icon_props}/>}
      onClick={on_click} theme={is_dark ? 'dark': 'light'}
      mode="horizontal" selectedKeys={[current]} items={items} />
  );
};

let year = new Date().getUTCFullYear();
const Content_footer = ({children, is_hide_footer, is_no_padding})=>{
  let {t} = useTranslation();
  let {search} = use_qs();
  return <Layout style={{paddingTop: is_no_padding ? 0: '4vw'}}>
    <Layout.Content style={{minHeight: '70vh'}}>
      <Row justify="center">
        <Col span={24}>{children}</Col>
      </Row>
    </Layout.Content>
    {!is_hide_footer && <Layout.Footer style={{paddingTop: '4vw',
      paddingInline: 0, backgroundColor: !is_dark ? 'inherit':
        '#20262E'}}>
      <Row gutter={[16, 24]} justify="space-around">
        <Col xs={24} md={6} style={{textAlign: 'center'}}>
          <Text>
            Copyright © {year} {config_ext.front.company_lbl}.
            All Rights Reserved.
          </Text>
        </Col>
        <Col xs={24} md={4} style={{textAlign: 'center'}}>
          <Clickable>
            <Link to={{pathname: '/privacy', search}}>
              {t('Privacy policy')}
            </Link>
          </Clickable>
        </Col>
        <Col xs={24} md={4} style={{textAlign: 'center'}}>
          <Clickable>
            <Link to={{pathname: '/tos', search}}>
              {t('Terms of service')}
            </Link>
          </Clickable>
        </Col>
        <Col xs={24} md={4} style={{textAlign: 'center'}}>
          <Clickable>
            <Link to={{pathname: '/disclaimer', search}}>
              {t('Disclaimer')}
            </Link>
          </Clickable>
        </Col>
        <Col xs={24} md={6} style={{textAlign: 'center'}}>
          <Contact is_center={1}/>
        </Col>
      </Row>
    </Layout.Footer>
    }
  </Layout>;
};

let Not_found = ()=>{
  let {pathname, search} = use_qs();
  metric.error('not_found_404', pathname, search);
  return <Navigate to="/" replace />;
};
const Cf_home = ()=><Content_footer is_no_padding><Land_simg/>
</Content_footer>;

const Cf_home_b2b = ()=><Content_footer><Land_b2b/>
</Content_footer>;
const Cf_home_schar = ()=><Content_footer><Land_schar/>
</Content_footer>;
const Cf_vid = ()=><Content_footer><Vid/></Content_footer>;
const Cf_about = ()=><Content_footer><About/></Content_footer>;
const Cf_profile = ()=><Content_footer><Profile/></Content_footer>;
const Cf_spipe = ()=><Content_footer><Spipe/></Content_footer>;
const Cf_svoice = ()=><Content_footer><Svoice/></Content_footer>;
const Cf_slip = ()=><Content_footer><Slip/></Content_footer>;
const Cf_gallery = ()=><Content_footer><Gallery/></Content_footer>;
const Cf_sgif = ()=><Content_footer><Sgif/></Content_footer>;
const Cf_svid = ()=><Content_footer><Svid/></Content_footer>;
const Cf_simg = ()=><Content_footer is_no_padding><Simg/></Content_footer>;
const Cf_sperson = ()=><Content_footer is_no_padding><Sperson/>
</Content_footer>;
const Cf_gift_card = ()=><Content_footer><Gift_card/></Content_footer>;
const Cf_schar = ()=><Content_footer is_hide_footer is_no_padding><Schar/>
</Content_footer>;
const Cf_schar_v2 = ()=><Content_footer is_hide_footer is_no_padding><Schar_v2/>
</Content_footer>;
const Cf_pay = ()=><Content_footer><Pay/></Content_footer>;
const Cf_privacy = ()=><Content_footer><Privacy/></Content_footer>;
const Cf_tos = ()=><Content_footer><Tos/></Content_footer>;
const Cf_disclaimer = ()=><Content_footer><Disclaimer/></Content_footer>;
const Cf_contact = ()=><Content_footer>
  <Contact is_addr={true}/>
</Content_footer>;
const Cf_onboard = ()=><Content_footer><Onboard/></Content_footer>;
const Cf_blog = ()=><Content_footer><Blog/></Content_footer>;
const Cf_sponsor = ()=><Content_footer><Sponsor/></Content_footer>;
const Cf_plan = ()=><Content_footer><Plan/></Content_footer>;
const Cf_sbase_login = ()=><Content_footer><auth.Sbase_login/>
</Content_footer>;
const Cf_sbase_signup = ()=><Content_footer><auth.Sbase_signup/>
</Content_footer>;
const Cf_email_verif = ()=><Content_footer><Email_verif/></Content_footer>;
const Cf_chat = ()=><Content_footer is_hide_footer is_no_padding><Chat/>
</Content_footer>;
const Cf_not_found = ()=><Content_footer><Not_found/></Content_footer>;
const App = ()=>{
  let {is_loading, error} = auth.use_auth_ext();
  let view_count = use_count_views();
  // XXX colin: handle error in auth0 and sbase in the same way
  if (error)
  {
    return <div style={{backgroundColor: 'red'}}>
      Oops... {config_ext.is_sbase ? error : error.message}</div>;
  }
  if (is_loading)
    return <Loading />;
  // XXX colin: add affix to Navbar
  let is_show_sponsor = view_count >= 2 && is_main;
  return (
    <Layout>
      <Affix offsetTop="0">
        <Layout.Header style={{paddingInline: 0,
          height: is_show_sponsor ? 128: 64}}><Navbar />
          {is_show_sponsor && <Sponsor_banner/>}
        </Layout.Header>
      </Affix>
      <Scroll_to_top/>
      <Slide_routes>
        {config_ext.is_b2b && <Route path="/" exact element={<Cf_home_b2b/>}
        />}
        {config_ext.is_schar && <Route path="/" exact
          element={<Cf_home_schar/>} />}
        {config_ext.is_schar && <Route path="/c/:char_id"
          element={<Cf_chat/>} />}
        {is_main && <Route path="/" exact element={<Cf_home/>} />}
        <Route path="/" exact element={<Cf_home/>} />
        <Route path="/pay" element={<Cf_pay/>} />
        <Route path="/simage" element={<Navigate to="/simg" replace />} />
        <Route path="/simg" element={<Cf_simg/>} />
        <Route path="/sgif" element={<Cf_sgif/>} />
        <Route path="/sperson" element={<Cf_sperson/>} />
        <Route path="/schar" element={<Cf_schar/>} />
        <Route path="/schar2" element={<Cf_schar_v2/>} />
        <Route path="/svoice" element={<Cf_svoice/>} />
        <Route path="/slip" element={<Cf_slip/>} />
        <Route path="/svid" element={<Cf_svid/>} />
        <Route path="/spipe" element={<Cf_spipe/>} />
        <Route path="/gallery" element={<Cf_gallery/>} />
        <Route path="/gift_card" element={<Cf_gift_card/>} />
        <Route path="/vid" exact element={<Cf_vid/>} />
        <Route path="/about" exact element={<Cf_about/>} />
        <Route path="/blog" element={<Cf_blog/>} />
        <Route path="/sponsor" element={<Cf_sponsor/>} />
        <Route path="/plan" element={<Cf_plan/>} />
        <Route path="/contact" element={<Cf_contact/>} />
        <Route path="/contactus" element={<Cf_contact/>} />
        <Route path="/profile" element={<Cf_profile/>} />
        <Route path="/privacy" element={<Cf_privacy/>} />
        <Route path="/tos" element={<Cf_tos/>} />
        <Route path="/disclaimer" element={<Cf_disclaimer/>} />
        <Route path="/onboard" element={<Cf_onboard/>} />
        <Route path="/email_verif" element={<Cf_email_verif/>} />
        <Route path="/logout" element={<Navigate to="/" replace />} />
        <Route path="/login" element={<Cf_sbase_login/>} />
        <Route path="/signup" element={<Cf_sbase_signup/>} />
        <Route path="*" element={<Cf_not_found/>} />
      </Slide_routes>
    </Layout>
  );
};

export default App;
