// LICENSE_CODE MIT
import * as Sentry from '@sentry/react';
import os from 'os';
import assert from 'assert';
import eserf from '../../../util/eserf.js';
import xdate from '../../../util/date.js';
import config_ext from './config_ext.js';

let E = {};
export default E;
let app_name = 'front_app_'+config_ext.stage;
let is_test = process.env.IS_TEST;
let inited, is_perf;
let is_localhost = os.hostname()=='localhost';
E.init = ()=>{
  if (inited)
    assert(0, 'sentry already inited');
  inited = true;
  if (is_test || config_ext.is_local || is_localhost)
    return;
  let ver = config_ext.ver;
  console.log('sentry init', app_name, ver);
  Sentry.init({
    dsn: config_ext.sentry.front.dsn,
    integrations: [new Sentry.BrowserTracing()],
    release: ver,
    serverName: os.hostname(),
    environment: config_ext.stage,
    tracesSampleRate: is_perf ? 1.0 : undefined,
  });
  eserf.on_exit((msg, stack)=>{
    E.error(msg, stack);
    // polyfill process.exit(1)
    Sentry.flush(2000).then(()=>{}).catch(()=>{});
  });
  let ts = new Date();
  let err_cache = JSON.parse(localStorage.getItem('err_cache'))||{};
  Object.entries(err_cache).forEach(([message, error_ts])=>{
    if (ts.getTime() - new Date(error_ts).getTime() > 1*xdate.MS_DAY)
      delete err_cache[message];
  });
  localStorage.setItem('err_cache', JSON.stringify(err_cache));
};

E.error = (...args)=>{
  let message = args.join('\n');
  let ts = new Date();
  // XXX colin: use xurl.json_parse
  let err_cache = JSON.parse(localStorage.getItem('err_cache')) || {};
  let prev_ts = new Date(err_cache[message]);
  let time_delta = ts.getTime() - prev_ts.getTime();
  if (err_cache[message] && time_delta <= 1*xdate.MS_DAY)
    return;
  if (is_test || config_ext.is_local || process.env.IS_LOCAL || is_localhost)
    return;
  Sentry.withScope(function(scope){
    Sentry.captureException(new Error(message));
  });
  err_cache[message] = ts.toISOString();
  localStorage.setItem('err_cache', JSON.stringify(err_cache));
};

E.clarity_event = (...args)=>{
  if (is_test || config_ext.is_local || is_localhost)
    return;
  let clarity = window.clarity||(()=>{});
  clarity(...args);
};

E.event = (k, v, opt)=>{
  E.clarity_event('set', k, v);
  Sentry.configureScope(scope=>scope.setTag(k, v));
};

E.set_id = (user_id, org_id)=>{
  E.clarity_event('identify', user_id);
  let is_add_tag;
  if (is_add_tag)
  {
    E.clarity_event('set', 'user_id', user_id);
    E.clarity_event('set', 'org_id', org_id);
  }
  Sentry.configureScope(scope=>scope.setUser({id: user_id, user_id, org_id}));
};

