// LICENSE_CODE ABC
import React, {useState, useEffect} from 'react';
import React_markdown from 'react-markdown';
import Rehype_raw from 'rehype-raw';
import Remark_gfm from 'remark-gfm';
import {Typography, Divider, Row, Col, theme} from 'antd';
import {useTranslation} from 'react-i18next';
import blog__001 from './blog__001__test1.md';
import blog__002 from './blog__002.md';
import eserf from '../../../util/eserf.js';
import ereq from '../../../util/ereq.js';

// XXX colin: move into config
const {Title} = Typography;
// order is important first is most recent
let blogs = [{
  is_hide: 1,
}, {
  is_hide: 1,
  url: blog__001,
  author: 'Some guy',
  author_link: 'https://www.example.com/some_guy',
  title: 'Blog test',
  subtitle: 'Sometitle',
  ts: {insert: new Date('2022-11-13')},
}, {
  url: blog__002,
  //author: 'Sam',
  //author_link: 'https://www.example.com/some_guy',
  title: 'Harnessing the Power of Generative AI in Video Production: Introducing AbcAi',
  subtitle: 'What are wo doing so far?',
  ts: {insert: new Date('2023-08-08')},
}];
let E = ()=>{
  let {t} = useTranslation();
  let {token} = theme.useToken();
  let [blog_data, blog_data_set] = useState([]);
  useEffect(()=>{
    eserf(function* blog_get(){
      let ess = [], _blogs = [];
      for (let i=0; i<blogs.length; i++)
      {
        if (blogs[i].is_hide)
          continue;
        ess.push(ereq.get(blogs[i].url));
        _blogs.push(blogs[i]);
      }
      let ess_ret = yield this.wait_ret(ess);
      for (let i=0; i<ess_ret.length; i++)
      {
        let b = _blogs[i];
        let es_ret = ess_ret[i];
        b.txt = es_ret.data;
      }
      blog_data_set(_blogs);
    });
  }, []);
  return <>
    <Row justify="center"><Title level={3}>{t('Blog')}</Title></Row>
    <Row justify="left" style={{color: token.colorTextBase}}>
      <Col xs={{span: 22, offset: 1}} md={{span: 16, offset: 4}}>
        {blog_data.map(b=><div key={b.url}>
          <Title key={b.url+b.ts.insert.toISOString()} level={4}>
            {b.title}
          </Title>
          <Title level={5}>
            {t('Published')} {b.ts.insert.toLocaleDateString()}
          </Title>
          {b.author && <Title level={5}>{t('Author')} <a href={b.author_link}
            target="_blank" rel="noreferrer">
            {b.author}</a>
          </Title>}
          <Title level={5} style={{backgroundColor: token.colorPrimary}}>
            {b.subtitle}
          </Title>
          <React_markdown
            remarkPlugins={[Remark_gfm]}
            rehypePlugins={[Rehype_raw]} key={b.url+'__txt'}>
            {b.txt}
          </React_markdown>
          <Divider/>
        </div>)}
      </Col>
    </Row>
  </>;
};

export default E;
